<template>
  <div class="apply-wrap">
    <p class="title">请填写申请售后原因</p>
    <div class="reason-wrap">
      <div class="goods-info">
        <img :src="goodInfo.goodsImgUrl" class="iamge" alt />
        <p
          class="name mt10"
        >{{ goodInfo.goodsName }}</p>
        <div class="productNum">
          <p class="sku mt10">{{ goodInfo.goodsName }}</p>
          <p class="mt10 font16">X</p>
          <p class="font16 mt10">{{ goodInfo.goodsNum }}</p>
        </div>
        <p class="price mt10 font14">¥{{ goodInfo.saleUnitPrice }}</p>
      </div>
      <!-- 申请售后原因 -->
      <div class="reason-detail">
        <!-- 售后类型 -->
        <div class="apply-type mb10">
          <span class="font14">售后类型：</span>
          <select name="type" v-model="applyType" @click="typeSelected" class="selected">
            <option value="1">仅退款未发货</option>
            <option value="2">仅退款已发货</option>
            <option value="3">退款退货</option>
            <option value="4">换货</option>
          </select>
        </div>
        <!-- 售后原因 -->
        <div class="apply-reason">
          <span class="font14">售后原因：</span>
          <select name="type" v-model="applyReason" @click="reasonSelected" class="selected">
            <option
              v-for="(item,index) in reasonOptions"
              :key="index"
              :value="item.value"
            >{{ item.text }}</option>
          </select>
        </div>

        <!-- 退换货时显示 物流公司 物流单号 -->
        <div class="apply-reason mt10" v-if="applyType == 3 || applyType == 4">
          <span class="font14">物流公司：</span>
          <select
            name="type"
            v-model="logisticsCompany"
            @change="logisticsSelected"
            class="selected"
          >
            <option
              v-for="(item,index) in logisticsArr"
              :key="index"
              :value="item.value"
            >{{ item.text }}</option>
          </select>
        </div>
        <div class="input-wrap mt10" v-if="applyType == 3 || applyType == 4">
          <span class="font14">物流编号：</span>
          <input type="text" class="input" v-model="logisticsNumber" placeholder="请输入密码" />
        </div>

        <!-- 备注信息 -->
        <div class="note mt10">
          <div class="tips font14">原因描述：</div>
          <textarea
            class="textarea"
            name="note"
            v-model="notedata"
            id
            cols="30"
            rows="10"
            style="resize:none"
            placeholder="申请售后说明"
          ></textarea>
        </div>
        <!-- 上传图片 -->
        <!-- <div class="upload">上传图片：</div> -->
      </div>
    </div>
    <!-- 提交按钮 -->
    <div class="submit cursor" @click="submit">提交</div>
  </div>
</template>
<script>
import {
  apply,
  getOrderDetail,
  getLogisticsList,
} from "@/api/api";
import utils from "@/utils/utils";
export default {
  name: "apply",
  components: {},
  data() {
    return {
      goodInfo: {}, // 订单中的商品信息
      applyType: "1", // 售后类型
      applyReason: "1", // 售后原因
      // 售后原因下拉框数据
      reasonOptions: [
        {
          value: "1",
          text: "质量问题"
        },
        {
          value: "2",
          text: "尺码太大"
        },
        {
          value: "3",
          text: "颜色不喜欢"
        },
        {
          value: "4",
          text: "7天无理由退货"
        },
        {
          value: "5",
          text: "价格问题"
        },
        {
          value: "6",
          text: "发票问题"
        },
        {
          value: "7",
          text: "物流问题"
        },
        {
          value: "8",
          text: "售后问题"
        },
        {
          value: "9",
          text: "其他问题"
        }
      ],
      logisticsCompany: "1", // 物流公司
      logisticsArr: [], // 物流公司下拉数据
      logisticsNumber: "", // 物流单号
      notedata: "", // 备注信息
      orderNum: "", // 售后单号
      goodId: "", // 售后商品ID
    };
  },
  methods: {
    // 下拉框触发事件
    typeSelected(event) {
      this.applyType = event.target.value;
    },

    // 售后原因下拉框
    reasonSelected(event) {
      this.applyReason = event.target.value;
    },

    // 选择物流出发事件
    logisticsSelected(event) {
      this.logisticsCompany = event.target.value;
      
    },

    // 提交申请数据
    async submit() {
      let params = {};
      // 先获取退款类型   退款原因  退换货需要填写物流和公司和物流编号  没有就弹框提示
      if (this.applyType === "") {
        return this.$toast("请选择售后类型");
      }
      if (this.applyReason === "") {
        return this.$toast("请选择售后原因");
      }

      if (this.applyType == 3 || this.applyType == 4) {
        if (this.logisticsId === "") {
          return this.$toast("请选择物流公司");
        }
        if (this.logisticsNumber === "") {
          return this.$toast("请输入物流编号");
        }
        params = {
          afterSaleType: this.applyType,
          applyReason: this.applyReason,
          note: this.notedata || "",
          orderNo: this.orderNum,
          goodsId: this.goodInfo.goodsId,
          goodsNum: this.goodInfo.goodsNum,
          restLogisticsCompany: this.logisticsCompany,
          restLogisticsNo: this.logisticsNumber
        };
      } else {
        params = {
          afterSaleType: this.applyType,
          applyReason: this.applyReason,
          note: this.notedata || "",
          // proofPics: this.proofPics,
          orderNo: this.orderNum,
          goodsId: this.goodInfo.goodsId,
          goodsNum: this.goodInfo.goodsNum
        };
      }
      const res = await apply(params);  
      if(res.code == '0000'){
         this.$toast('申请成功')
         setTimeout(() => {
           this.$router.push({
             path:'/afterSale/aftersaleList'
           })
         })
      }else{
         this.$toast(res.msg)
      }  
    },
    // 查询物流公司信息
    async getLogistics() {
      const res = await getLogisticsList();
      if (res.code == "0000") {
        let array = res.data;
        let newArray = utils.changeKey(array, ["value", "text"]);
        this.logisticsArr = newArray;
      } else {
        this.$toast(res.msg);
      }
    },

    // 查询订单售后详情
    async afterDetail() {
      this.orderNum = this.$route.query.number;
      const id = this.$route.query.id
      const sendData = {
         orderNo:this.orderNum 
      }
      const res = await getOrderDetail(sendData) 
      if(res.code == '0000'){
        // 通过子订单号码获取到对应的子订单信息
        // 再通过商品ID获取到对应的商品信息 
        const list = res.data.orderList[0].goodsInfoList
        list.forEach((item,index) => {
          if(item.goodsId == id){
             this.goodInfo = list[index]
             console.log(this.goodInfo);      
          }
        })   
      }else{
         this.$toast(res.msg)
      }     
    }
  },

  mounted() {
    this.getLogistics();
    this.afterDetail();
  }
};
</script>
<style lang="scss" scoped>
.apply-wrap {
  width: 100%;
  height: auto;
  .title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #f0f0f0;
    padding-left: 20px;
  }
  .reason-wrap {
    border: 1px solid #ccc;
    padding: 30px;
    display: flex;
    .goods-info {
      width: 30%;
      .iamge {
        display: block;
        width: 100px;
        height: 100px;
      }
      .productNum{
        display: flex;
        p{
          &:nth-of-type(1){
            padding-right: 50px;
          }
        }
      }
      .name {
        font-size: 14px;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .sku {
        color: #999;
      }
    }
    .reason-detail {
      width: 70%;
      padding-left: 30px;
      .selected {
        min-width: 135px;
        padding: 5px 10px;
        border: 1px solid #ccc;
      }
      .note {
        display: flex;
        .tips {
          width: 12%;
        }
        .textarea {
          width: 88%;
          border: 1px solid #ccc;
          padding: 10px;
        }
      }
    }
  }
  /* 提交按钮 */
  .submit {
    width: 100px;
    height: 30px;
    line-height: 30px;
    background-color: $themeColor;
    text-align: center;
    color: #fff;
    border-radius: 4px;
    margin: 50px auto;
  }
  .input {
    border: 1px solid #ccc;
    width: 200px;
    height: 30px;
    padding-left: 10px;
  }
}
.font16{
 font-size: 16px;
}
</style>


