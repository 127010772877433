<template>
<div class="orderdetails-main">
    <div class="details-title">订单详情</div>
    <div class="details-content">
        <div class="content-left">
            <div class="tui-goods-item">
                <img :src="details.url" class="tui-goods-img" />
                <div class="tui-goods-center">
                    <div>
                        <div class="tui-goods-top">
                            <div>
                                <p class="tui-goods-name">{{ details.name }}</p>
                            </div>
                        </div>
                        <div class="tui-goods-attr">
                            <p>{{ details.subtitle }}</p>
                        </div>
                    </div>
                    <div class="tui-goods">
                        <div class="tui-price-right">{{ details.totalPoint }}海贝</div>
                        <p class="tui-num-right">x{{ details.amount }}</p>
                    </div>
                </div>
            </div>
            <div class="left-item">
                <span>收货人:</span>
                <span>{{details.receiverName}}</span>
            </div>
            <div class="left-item">
                <span>收货地址:</span>
                <span>{{details.receiver}}</span>
            </div>
            <div class="left-item">
                <span>订单编号:</span>
                <span>{{details.orderNo}}</span>
            </div>
            <div class="left-item">
                <span>买家留言:</span>
                <span>{{details.mark}}</span>
            </div>
        </div>
        <div class="content-right">
            <el-timeline>
                <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.timestamp">
                    {{activity.content}}
                </el-timeline-item>
            </el-timeline>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            activities: [{
                content: '订单创建成功',
                timestamp: ''
            }],
            details: {}
        };
    },

    methods: {

    },

    mounted() {
        this.details = this.$route.query.details;
        this.activities[0].timestamp = this.details.orderCreate
    },
};
</script>

<style lang="scss" scoped>
.tui-goods-item {
    width: 100%;
    // padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    // border-bottom: 1px solid #efefef;
}

.tui-goods-img {
    width: 100px;
    height: 100px;
    display: block;
    flex-shrink: 0;
    border-radius: 10px;
}

.tui-goods-center {
    flex: 1;
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tui-goods {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}

.tui-goods-top {
    display: flex;
    justify-content: space-between;
}

.tui-goods-name {
    max-width: 160px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    // font-size: 28px;
    /* line-height: 32px; */
}

.tui-goods-attr {
    font-size: 26px;
    color: #999999;
    padding-top: 10px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: flex;
    justify-content: space-between;
}

.tui-price-right {
    color: #999999;
}

.tui-num-right {
    text-align: right;
    // font-size: 24px;
    color: #888888;
}

.orderdetails-main {
    width: 100%;
    height: auto;
    border: 1px solid #e1e1e1;

    .details-title {
        width: 100%;
        height: 30px;
        background-color: #f3f3f3;
        color: #333;
        text-indent: 20px;
        line-height: 30px;
        font-weight: bold;
        border-bottom: 1px solid #e5e5e5;
    }

    .details-content {
        min-height: 235px;
        display: flex;
        flex-direction: row;

        .content-left {
            flex: 1;
            padding: 20px;
            background-color: #fbfbfb;
            border-right: 1px solid #e5e5e5;

            .left-item {
                display: flex;
                flex-direction: row;
                margin-bottom: 16px;

                span:first-child {
                    width: 65px;
                    line-height: 26px;
                }

                span:last-child {
                    flex: 1;
                    line-height: 26px;
                }
            }
        }

        .content-right {
            flex: 2;
            padding: 20px;
        }
    }
}
</style>
