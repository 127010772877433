<template>
<div>
    <div class="container">
        <div class="title">{{notice.title}}</div>
        <div class="data">日期：{{notice.createTime}}</div>
        <div class="text">
            <p v-html="notice.context"></p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            notice: {}
        }
    },
    mounted() {
        // let accountInfo = JSON.parse(this.$route.query.accountInfo);
        this.notice = JSON.parse(this.$route.query.notice);
        // this.notice = this.$route.query.notice;
        console.log(this.notice);
        // beforeRouteEnter(to, from, next){
        //     next(vm => {
        //         // vm.targetUser = JSON.parse(to.params.user)
        //         vm.notice = JSON.parse(to.query.notice)
        //     });

        // }
    }
}
</script>

<style lang="scss" scoped>
.text {
    padding-top: 20px;
    font-size: 13px;
}

.container {
    width: 100%;
    // border: 1px solid #2283e2;
    padding: 20px;
}

.title {
    text-align: center;
    font-size: 18px;
    padding-bottom: 20px;
}

.text {
    padding-top: 10px;
    text-indent: 2em;
}

.data {
    border-bottom: 1px solid #AC8C4F;
    text-align: center;
    padding-bottom: 5px;
    font-size: 13px;
}

.paging {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}
</style>
