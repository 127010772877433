<template>
	<div class="classify-wrap main-container">
		<!-- 一级导航 -->
		<ul class="list-wrap">
			<li class="list" v-for='(item1,index) in list' :key="index">
				<span to class="oneA" @click.self="skipOneLevel(item1.id)">
					{{ item1.name }}
					<div class="faterBox">
						<ul class="ulOne">
							<li v-for="(item2,index) in item1.categoryList" :key="index" class="listTwo">
								<span class="twoA">
									{{ item2.name }}
									<ul class="ulTwo">
										<li class="listThree" v-for="(item3,index) in item2.categoryList" :key="index">
											<router-link to class="threeA">
												<div class="imgs">
													<img :src="item3.pictureUrl" alt="#" />
												</div>
												<div class="content">
													<span @click.stop="skipThreeLevel(item1.id,item3.id)">{{ item3.name }}</span>
												</div>
											</router-link>
										</li>
									</ul>
								</span>
							</li>
						</ul>
					</div>
				</span>
				<i class="el-icon-arrow-right"></i>
			</li>
		</ul>
		<!-- 二三级导航
    <div class="children-wrap">子导航部分</div>-->
		<classify :classList="list"></classify>
	</div>
</template>
<script>
	import {
		getMenuConfiguration
	} from "@/api/api";
	import classify from '@/components/classify'
	export default {
		name: "",
		components: {
			classify
		},
		props: {},
		data() {
			return {
				list: []
			};
		},

		methods: {
			// 获取菜单数据
			async getData() {
				const res = await getMenuConfiguration();
				if (res.code == "0000") {
					this.list = res.data

				} else {
					this.$toast(res.msg);
				}
			},

			// 点击一级标题跳转页面携带id
			skipOneLevel(id) {
				const routerUrl = this.$router.resolve({
					path: `/list/index?fatherId=${id}`
				})
				window.open(routerUrl.href, "_blank")
			},

			// 点击三级标题,携带一级和三级id
			skipThreeLevel(id1, id2) {
				const fatherId = id1
				const sonId = id2
				const routeUrl = this.$router.resolve({
					path: `/list/index?fatherId=${fatherId}&sonId=${sonId}`
				})
				window.open(routeUrl.href, "_blank")
			},
		},
		mounted() {
			this.getData();
		},
		watch: {
			list(value1, value2) {
				// console.log(value1);
			}
		},
	};
</script>
<style lang="scss" scoped>
	.main-container {
		position: relative;

		.list-wrap {
			/*display: flex;*/
			background: #314951;
			width: 200px;
			padding: 20px 0;

			.list {
				color: #fff;
				width: 200px;
				padding: 0 20px;
				/* text-align: center; */
				height: 50px;
				display: flex;
				justify-content: space-between;
				font-size: 16px;
				align-items: center;

				&:hover {
					.oneA {
						color: #2283E2;

						.faterBox {
							display: block;
						}
					}
				}

				.oneA {
					color: #ffffff;
					font-size: 16px;
					font-weight: 500;
					/* line-height: 40px; */
					/* padding-bottom: 5px; */
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					cursor: pointer;

					.faterBox {
						display: none;
						transition: all 1s ease-in-out;
						position: absolute;
						z-index: 9999;
						padding-top: 10px;
						top: 0px;
						background-color: white;
						left: 37.5%;
						transform: translateX(-50%);

						.ulOne {
							/*display: flex;*/
							width: 500px;
							border: 1px solid #ccc;
							padding: 5px 0px 20px 50px;

							.listTwo {
								margin-right: 20px;
								text-align: left;
								margin-top: 20px;

								.twoA {
									color: #2283E2;
									font-size: 16px;
									font-weight: 600;
									border-bottom: 1px solid #ccc;
									display: inline-block;
									height: 40px;
									text-align: left;
									padding-bottom: 10px;
									cursor: pointer;

									.ulTwo {
										display: flex;
										padding-top: 25px;

										.listThree {
											padding-top: 10px;

											.threeA {
												font-size: 14px;
												display: inline-block;
												height: 50px;
												line-height: 50px;
												margin-bottom: 15px;
												cursor: pointer;
												display: flex;

												.imgs {
													img {
														display: inline-block;
														width: 50px;
														height: 40px;
													}
												}

												.content {
													height: 50px;
													padding-left: 10px;

													span {
														font-size: 14px;
														width: 90px;
														display: inline-block;
														overflow-wrap: normal;
														line-height: 22px;
													}
												}

												&:hover {
													color: #2283E2;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.lh45 {
		line-height: 45px;
	}

	.font16 {
		font-size: 16px;
	}

	.fw700 {
		font-weight: 700;
	}

	.hover_c {
		cursor: pointer;
	}
</style>
