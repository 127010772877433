export default {
    // domainPath: 'https://haierrest.dreamane.com',  //  开发环境域名
    //  domainPath: 'http://52.130.86.205:8081',  //  开发环境域名2
    // domainPath: 'http://10.190.45.211:8091',  // 学会
    // domainPath: 'http://10.190.45.211:8091',  // 学会3
    // domainPath: 'http://172.18.55.231:8081/api',  // 学会2
    // domainPath: 'http://10.190.47.12:8081/api',  // 封瑞
    // domainPath: 'http://10.190.180.193:8081/api',  // 封瑞2
    // domainPath: 'http://10.190.47.172:8091',  // 鸿峰
    // domainPath: 'http://localhost:8087',  // 本地开发环境
    // domainPath: 'http://172.17.56.142:8087'
    domainPath: 'https://point.haier.net/api'
    // domainPath: 'http://172.18.55.61:8081/api'
};
