<template>
  <div class="order-detail-wrap">
    <!-- 订单信息 -->
    <div class="order-detail">
      <div class="order-header">
        <span class="order-number">售后单号：</span>
        <span class="common-operation">{{ productList.afterSaleNo }}</span>
        <span class="order-time ml20">售后类型：</span>
        <!-- 售后类型 -->
        <span class="common-operation" v-if="productList.afterSaleType == 1">仅退款未发货</span>
        <span class="common-operation" v-if="productList.afterSaleType == 2">仅退款已发货</span>
        <span class="common-operation" v-if="productList.afterSaleType == 3">退款退货</span>
        <span class="common-operation" v-if="productList.afterSaleType == 4">换货</span>
        <span class="order-time ml20">售后原因：</span>
        <!-- 售后原因 -->
        <span class="common-operation" v-if="productList.applyReason == 10">质量问题</span>
        <span class="common-operation" v-else-if="productList.applyReason == 20">尺码过大</span>
        <span class="common-operation" v-else-if="productList.applyReason == 30">颜色不喜欢</span>
        <span class="common-operation" v-else-if="productList.applyReason == 40">7天无理由退</span>
        <span class="common-operation" v-else-if="productList.applyReason == 50">价格问题</span>
        <span class="common-operation" v-else-if="productList.applyReason == 60">发票问题</span>
        <span class="common-operation" v-else-if="productList.applyReason == 70">物流问题</span>
        <span class="common-operation" v-else-if="productList.applyReason == 80">售后问题</span>
        <span class="common-operation" v-else>其他问题</span>

        <span class="order-time ml20">申请时间：</span>
        <span class="common-operation">{{ productList.applyTime }}</span>
        <span class="order-time ml20">售后状态：</span>

        <!-- 售后状态 -->
        <span class="common-operation" v-if="productList.status=='1010'">申请退款待处理</span>
        <span class="common-operation" v-if="productList.status=='1020'">售后已取消</span>
        <span class="common-operation" v-if="productList.status=='1030'">审核通过</span>
        <span class="common-operation" v-if="productList.status=='1040'">审核拒绝</span>
        <span class="common-operation" v-if="productList.status=='1050'">审核后取消售后</span>
        <span class="common-operation" v-if="productList.status=='1060'">退款中</span>
        <span class="common-operation" v-if="productList.status=='1070'">退款成功</span>
        <span class="common-operation" v-if="productList.status=='2010'">取消售后</span>
      </div>

      <!-- 收货地址 -->
      <div class="content">
        <div class="address fl">
          <p class="comon-address">
            <span class="key">退款金额：</span>
            <span class="value themeColor">￥{{ productList.returnRealAmount }}</span>
          </p>
          <p class="comon-address">
            <span class="key">收件人：</span>
            <span class="value">刘兵</span>
          </p>
          <p class="comon-address">
            <span class="key">联系方式：</span>
            <span class="value">15900672937</span>
          </p>
          <p class="comon-address">
            <span class="key">收货地址：</span>
            <span class="value">上海市上海市浦东新区张江镇盛荣路188号5号楼701</span>
          </p>
        </div>
      </div>
    </div>

    <!-- 商品信息 -->
    <div class="goods-detail">
      <p class="header">
        <span class="order-number">订单编号：{{ productList.orderNo }}</span>
        <span class="order-time ml30">下单时间：{{ productList.orderTime }}</span>
      </p>
      <div class="content">
        <div class="common-style">
          <div class="message themeColor">申请售后商品</div>
          <div class="number">商品数量</div>
        </div>

        <div class="common-content">
          <div class="message">
            <img :src="productList.goodsImgUrl" class="img" alt />
          </div>
          <div class="number">{{ productList.goodsNum }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { afterDetail } from "@/api/api";
export default {
  name: "detail",
  components: {},
  data() {
    return {
      orderNum: "",
      productList: {}
    };
  },
  created(){
    this.getRoutes();
    this.afterDetail();
  },
  methods: {
    // 跳转到申请售后页面
    apply() {
      this.$router.push({
        path: "/afterSale/apply"
      });
    },

    // 获取售后详情数据
    async afterDetail() {
      const sendData = {
        afterSaleNo: this.orderNum
      };
      const res = await afterDetail(sendData);
      if (res.code == "0000") {
        this.productList = res.data;
      } else {
        this.$toast(res.msg);
      }
      console.log(this.productList.afterSaleNo);    
    },

    // 获取页面路由参数
    getRoutes() {
      this.orderNum = this.$route.query.num;
    }
  },

  mounted() {
  }
};
</script>
<style lang="scss" scoped>
.order-detail-wrap {
  width: 110%;
  height: auto;
  .order-detail {
    width: 100%;
    border: 1px solid #eaeaea;
    margin-bottom: 20px;
    font-size: 14px;
    .order-header {
      height: 52px;
      line-height: 52px;
      padding: 0 20px;
      background: #f5f5f5;
      .common-operation {
        color: #69c;
        cursor: pointer;
      }
    }
    /* 收货地址信息 */
    .content {
      padding: 23px 20px 30px;
      overflow: hidden;
      .address {
        width: 560px;
        .comon-address {
          height: 30px;
          line-height: 30px;
          .key {
            color: #999;
          }
        }
      }
      .detail {
        width: 280px;
        .comon-detail {
          height: 30px;
          line-height: 30px;
          .key {
            display: inline-block;
            color: #999;
            width: 100px;
            text-align: right;
          }
        }
      }
    }
  }
  /* 商品列表 */
  .goods-detail {
    font-size: 14px;
    margin-bottom: 20px;
    border: 1px solid #eaeaea;
    .header {
      padding: 10px 20px;
      background: #f5f5f5;
      overflow: hidden;
      .store-image {
        display: inline-block;
        width: 30px;
        height: 30px;
        vertical-align: middle;
      }
      .store-name {
        display: inline-block;
        vertical-align: middle;
        margin-top: 5px;
      }
    }
    .content {
      padding: 10px 20px 0;
      .common-style,
      .common-content {
        display: flex;
        height: 30px;
        line-height: 40px;
        .message {
          text-align: left;
          width: 60%;
        }
        .price,
        .number {
          width: 20%;
          text-align: center;
        }
      }
      .common-content {
        line-height: 0;
        height: auto;
        border-bottom: 0 none;
        position: relative;
        border-top: 1px dashed #ccc;
        margin-top: 20px;
        padding: 20px 0;
        .message {
          display: flex;
          .img {
            display: block;
            width: 100px;
            height: 100px;
          }
        }
        .number {
          margin-top: 40px;
        }
      }
    }
  }
}
</style>


