<template>
  <div class="user-container main-container">
    <div class="distribution-commission">
      <div class="distribution-commission-left">
        <span>可提现佣金</span>
        <span>{{init.withdrawalMoney|formatMoney}}元</span>
        <div class="distribution-commission-commit" @click="dialogVisible = true">提现</div>
      </div>
      <div class="distribution-commission-mid">
        <span>已提现佣金</span>
        <span>{{init.alerdyWithdrawalMoney|formatMoney}}元</span>
      </div>
      <div class="distribution-commission-right">
        <span>未结算佣金</span>
        <span>{{init.notWithdrawalMoney|formatMoney}}元</span>
      </div>
    </div>

    <ul class="distribution-menu">
      <li
        class="distribution-menu-item"
        v-for="(item,index) in menu"
        :key="index"
        @click="goRouter(item.url)"
      >
        <img :src="item.icon" />
        <span>{{item.name}}</span>
        <!-- <div v-if="item.money">
          <span class="distribution-menu-money">{{item.money}}</span>
          <span>元</span>
        </div> -->
      </li>
    </ul>
    <el-dialog title="佣金提现" :visible.sync="dialogVisible" append-to-body width="60%">
      <ul class="withdraw-list">
        <li class="withdraw-list-item">
          <div class="withdraw-list-item-title">
            <span>可提现佣金</span>
          </div>
          <div class="withdraw-list-item-value">399元</div>
        </li>
        <li class="withdraw-list-item">
          <div class="withdraw-list-item-title">
            <span>已申请佣金</span>
          </div>
          <div class="withdraw-list-item-value">399元</div>
        </li>
        <li class="withdraw-list-item">
          <div class="withdraw-list-item-title">
            <span>待打款佣金</span>
          </div>
          <div class="withdraw-list-item-value">399元</div>
        </li>
        <li class="withdraw-list-item">
          <div class="withdraw-list-item-title">
            <span>成功提现佣金</span>
          </div>
          <div class="withdraw-list-item-value">399元</div>
        </li>
        <li class="withdraw-list-item">
          <div class="withdraw-list-item-title">
            <span>未收获佣金</span>
          </div>
          <div class="withdraw-list-item-value">399元</div>
        </li>
        <li class="withdraw-list-item">
          <div class="withdraw-list-item-title">
            <span>未结算佣金</span>
          </div>
          <div class="withdraw-list-item-value">399元</div>
        </li>
      </ul>
      <div style="margin:30px 0 0 12px">
        <el-input v-model="money" placeholder="请输入提现金额" style="margin-bottom:20px"></el-input>
        <el-radio-group v-model="radio">
          <el-radio :label="1">提现到余额</el-radio>
          <el-radio :label="2">提现到支付宝</el-radio>
          <el-radio :label="3">提现到微信</el-radio>
          <el-radio :label="4">提现到银行卡</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="innerVis = true">提 现</el-button>
      </span>
      <el-dialog title="确认" :visible.sync="innerVis" append-to-body width="40%">
        <div v-if="radio==1">
            确认提现{{money}}到余额吗？
        </div>
        <div v-if="radio==2">
            <el-input v-model="money" placeholder="请输入支付宝账号"></el-input>
        </div>
        <div v-if="radio==3">
            <el-input v-model="money" placeholder="请输入微信账号"></el-input>
        </div>
        <div v-if="radio==4">
            <el-input v-model="money" placeholder="请输入银行名称"></el-input>
            <el-input v-model="money" placeholder="请输入银行卡开户行" style="margin:30px 0"></el-input>
            <el-input v-model="money" placeholder="请输入银行卡号"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerVis = false">取 消</el-button>
          <el-button type="primary" @click="innerVis = false; dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
import { getDistributionUserInfo } from "@/api/api";
export default {
  data() {
    return {
      dialogVisible: false,
      innerVis: false,
      radio: 1,
      money: null,
      menu: [
        { name: "分销佣金", money: "0.00", url: "/user/commission",icon:require('../../../static/images/user/money.png')},
        { name: "分销订单", money: "0.00",icon:require('../../../static/images/user/order.png')},
        { name: "提现明细", money: "", url: "/user/withdrawalDetails",icon:require('../../../static/images/user/tx.png')},
        { name: "我的团队", money: "" , url:"/user/myTeam", icon:require('../../../static/images/user/group.png')},
        { name: "推广二维码", money: "",url: "/user/shareLink" ,icon:require('../../../static/images/user/tg.png')},
        { name: "添加上级", money: "",url: "/user/addInviteNex" ,icon:require('../../../static/images/user/inite.png')},
        { name: "添加下级", money: "",url: "/user/addInvite" ,icon:require('../../../static/images/user/inite.png')},
      ],
      init:{}
    };
  },
  created() {
    this.initFun();
  },
  methods: {
    goRouter(url) {
      this.$router.push(url);
    },
    async initFun(){
      let res = await getDistributionUserInfo();
      if (res.code == "0000") {
        this.init = res.data;
      } else {
        this.$toast(res.msg);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.distribution-commission {
  border: 1px solid #e6eaf0;
  width: calc(80% - 3px);
  height: 120px;
  background-color: #f9f9fa;
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    flex: 1;
    height: 60%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-evenly;
  }
  .distribution-commission-left {
    border-right: 1px solid #e6eaf0;
    display: flex;
    align-items: center;
    span:first-child {
      color: #30893c;
      font-size: 18px;
    }
    span:nth-child(2) {
      font-size: 16px;
    }
  }
  .distribution-commission-mid {
    border-right: 1px solid #e6eaf0;
    span:first-child {
      color: cornflowerblue;
      font-size: 18px;
    }
    span:last-child {
      font-size: 16px;
    }
  }
  .distribution-commission-right {
    span:first-child {
      color: #f3bf4b;
      font-size: 18px;
    }
    span:last-child {
      font-size: 16px;
    }
  }

  .distribution-commission-commit {
    width: 60px;
    background-color: #ea615b;
    padding: 4px 12px;
    border-radius: 2px;
    color: #fff;
    margin-top: 4px;
    font-size: 13px;
    cursor: pointer;
  }
}

.distribution-menu {
  background-color: #fff;
  height: auto;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .distribution-menu-item {
    cursor: pointer;
    padding: 6px 0;
    width: 33.3%;
    height: 140px;
    border: 1px solid #eee;
    border-top: none;
    margin: 0px -1px -1px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px;
    img {
      width: 40px;
      height: 40px;
    }
    .distribution-menu-money {
      color: #ea615b;
    }
  }
}

.withdraw-list {
  width: 100%;
  background-color: #fff;
  margin-top: 12px;
  border-top: 1px solid #eee;
}

.withdraw-list-item {
  padding: 0 12px;
  height: 60px;
  border-bottom: 1px solid #eee;
}

.withdraw-list-item-title {
  font-size: 16px;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  height: 100%;
  img {
    width: 0.18rem;
    height: 0.18rem;
    margin-right: 8px;
  }
}

.withdraw-list-item-value {
  color: #aaa;
  float: right;
  line-height: 60px;
  font-size: 16px;
  line-height: 60px;
  height: 100%;
}
</style>

