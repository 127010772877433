<template>
  <div class="banner" v-if="variablesObject">
    <template v-if="variablesObject.imgUlrList">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item,index) in variablesObject.imgUlrList" :key="index">
          <router-link to>
            <img src="https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1606126556563&di=427d14fc2b3608727a8cc8ab2a72f3a7&imgtype=0&src=http%3A%2F%2Fimg.yunhuzx.com%2Ffiles%2Fuploads%2F2020%2F01-15%2F15790785059b1ec4880254.jpg" alt @click="goOut(item.backUrl)" />
          </router-link>
        </swiper-slide>
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination"></div>
        <!-- 左右箭头 -->
        <div class="swiper-button-prev btns" slot="button-prev"></div>
        <div class="swiper-button-next btns" slot="button-next"></div>
      </swiper>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    variablesObject: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      swiperOption: {
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        //开启循环模式
        loop: true
      }
    };
  },
  methods: {
    goOut(url) {
      window.open(url, "_blank");
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.banner {
  /*width: 1440px;*/
  width: 100%;
  height: 400px;
  margin: 0 auto;
  position: relative;
  top: 1px;
  border: solid;
  img {
    width: 100%;
    height: 400px;
  }
  /deep/ .swiper-container {
    .btns {
      color: #fff;
      outline: none;
      &.swiper-button-prev {
        left: 50px
      }
      &.swiper-button-next {
        right: 50px;
      }
      &::before {
        content: " ";
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.5);
        z-index: -10;
      }
      &::after {
        font-size: 20px;
      }
    }
  }
}
</style>
