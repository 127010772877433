<template>
  <div class="container">
    <!--tab切换栏-->
    <div class="tab-container">
      <ul class="tab-header">
        <li :class="{ active: selected == 1 }" @click="toGoodsCollection">商品收藏({{ goosTotal }})</li>
        <li :class="{ active: selected == 2 }" @click="toShopsCollection">店铺收藏({{ storeTotal }})</li>
      </ul>
      <div class="tab-body">
        <!--商品收藏页签-->
        <div v-if="selected == 1">
          <div class="goodsCollection" v-if='goosTotal>0'>
            <!--商品-->
            <div class="goods" v-for="item in goodsLIst" :key="item.id" @click="goDetail(item)">
              <img :src="item.entityImg" class="goodsImg" />
              <!--商品信息-->
              <div class="goodsInfo">
                <!--商品名称-->
                <span class="goodsName">{{ item.entityName }}</span>
                <!--商品价格-->
                <span class="price">￥{{ item.marketPrice}}</span>
              </div>
              <!--关闭按钮-->
              <span class="close" @click.stop="deleteFavorite(item)">×</span>
            </div>
          </div>
          <!-- 没有信息情况下的提醒 -->
          <div class="goodsCollection" v-else>
            <no-data></no-data>
          </div>
        </div>
        <!--店铺收藏页签-->
        <div v-if="selected == 2">
          <div class="goodsCollection" v-if='storeTotal>0'>
            <!--商品-->
            <div class="goods" v-for="item in storeList" :key="item.id" >
              <img :src="item.entityImg" class="goodsImg" />
              <!--商品信息-->
              <div class="goodsInfo">
                <!--商品名称-->
                <span class="goodsName">{{ item.entityName }}</span>
              </div>
              <!--关闭按钮-->
              <span class="close" @click="deleteStore(item)">×</span>
            </div>
          </div>
          <!-- 没有信息情况下的提醒 -->
          <div class="goodsCollection" v-else>
            <no-data></no-data>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFavoriteList, favorite } from "@/api/api";
import noData from "@/components/noData";
export default {
  components: {
    noData
  },
  data() {
    return {
      selected: 1,             // 点击页签切换
      goodsLIst: [],           // 商品列表
      goosTotal: 0,            // 收藏商品总数
      storeList: [],           // 店铺列表
      storeTotal: 0            // 收藏店铺总数
    };
  },
  created() {
    this.getProduct();
    this.getProductList();
  },
  methods: {
    toGoodsCollection() {
      this.selected = 1;
      this.getProduct();
    },
    toShopsCollection() {
      this.selected = 2;
      this.getProductList();
    },
    // 获取收藏商品列表
    async getProduct() {
      let sendData = {
        type: 1,
        start: 1,
        limit: 100,
      };
      const res = await getFavoriteList(sendData);  
      if (res.code === "0000") {
        this.goodsLIst = res.data.rows;
        this.goosTotal = res.data.total;
      }
    },
    // 点击商品跳转
    goDetail(item) {
      this.$router.push({
        path: `/list/detail?id=${item.entityId}`
      });
    },
    // // 点击店铺跳转
    // goShop(item) {
    //   item.entityId=7;
    //   this.$router.push({
    //     path: `/list/detail?id=${item.entityId}`
    //   });
    // },
    // 删除商品方法
    async deleteFavorite(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要删除收藏？"
        })
        .then(() => {
          this.deleteProduct(item);
        })
        .catch(() => {
          this.$toast("取消成功");
        });
    },
    async deleteProduct(item) {
      let sendData = {
        type: 0, //1:收藏 0:取消收藏
        entityId: item.entityId,
        entityName: item.bizName,
        entityType: 1, //1:商品 2:店铺
        collectIds:[item.entityId],
      };
      const res = await favorite(sendData);
      if (res.code == "0000") {
        // 获取到当前商品ID  在收藏数组中删掉
        const list = this.goodsLIst;
        const id = item.id;
        list.forEach((item, index) => {
          if (id == item.id) {
            list.splice(index, 1);
          }
        });
        this.$toast("删除成功");
        this.list = list;
        this.getProduct();
      } else {
        this.$toast(res.msg);
      }
    },
    // 获取收藏店铺列表
    async getProductList() {
      let sendData = {
        type: 2,
        start: 1,
        limit: 100,
      };
      const res = await getFavoriteList(sendData);   
      if (res.code == "0000") {
        this.storeList = res.data.rows;
        this.storeTotal = res.data.total;
      }
    },
    // 删除店铺方法
    async deleteStore(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要删除收藏？"
        })
        .then(() => {
          this.deleteProductList(item);
        })
        .catch(() => {
          this.$toast("取消成功");
        });
    },
    async deleteProductList(item) {
      let sendData = {
        type: 0, //1:收藏 0:取消收藏
        entityId: item.entityId,
        entityName: item.bizName,
        entityType: 2, //1:商品 2:店铺
        collectIds:[],
      };
      sendData.collectIds.push(item.entityId)
      const res = await favorite(sendData);
      if (res.code == "0000") {
        const list = this.storeList;
        const id = item.id;
        list.forEach((item, index) => {
          if (id == item.id) {
            list.splice(index, 1);
          }
        });
        this.$toast("删除成功");
        this.storeList = list;
        this.getProductList();
      } else {
        this.$toast(res.msg);
      }
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped >
.container {
  .tab-container {
    width: 100%;
    height: 563px;
    border: 1px solid #eaeaea;
    overflow: hidden;
    .tab-header {
      width: 100%;
      overflow: hidden;
      background-color: #f5f5f5;
      li {
        float: left;
        height: 40px;
        width: 170px;
        text-align: center;
        font-size: 14px;
        border-right: 1px solid rgb(224, 218, 218);
        line-height: 40px;
        cursor: pointer;
      }
    }
    .tab-body {
      height: 100%;
      overflow: hidden;
      .goodsCollection {
        // width: 100%;
        // height: 100%;
        padding: 20px 30px;

        .goods {
          width: 200px;
          border: 1px solid #e6eaf0;
          position: relative;
          float: left;
          margin: 0 20px 20px 0;
          .goodsImg {
            display: block;
            width: 198px;
            height: 198px;
          }
          .goodsInfo {
            padding: 30px 20px;
            text-align: center;
            .goodsName {
              display: inline-block;
              width: 100%;
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .price {
              color: $themeColor;
              font-size: 14px;
            }
          }
          .close {
            font-size: 30px;
            position: absolute;
            right: 10px;
            top: 0;
            color: #999999;
            cursor: pointer;
          }
          .close:hover {
            color: $themeColor;
          }
        }
      }
    }
  }
}
.active {
  background-color: #fff;
  border-top: 2px solid $themeColor;
}
.clear {
  height: 0px;
  width: 0px;
}
</style>
