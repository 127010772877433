<template>
  <div class="register">
    <h2 class="title">一、购买实物礼品兑换流程</h2>
    <div class="p">
      1、在首页选择你想要购买的商品或者通过商品名称直接搜索。
    </div>
    <div>
      <img
        src="http://jftupian.haier.com/jfmall/help_center/201608/W020170112571247125153.png"
        width="100%"
        alt=""
      />
    </div>
    <div class="p">
      2、在商品展示页面中点击“立即兑换”。
    </div>
    <div>
      <img
        src="http://jftupian.haier.com/jfmall/help_center/201608/W020170112571247261472.png"
        width="100%"
        alt=""
      />
    </div>
    <div class="p">
      3、在确认收货信息页面中收货地址、花费的海贝正确后，点击提交订单，输入验证信息，即可完成整个购买流程。
    </div>
    <div>
      <img
        src="http://jftupian.haier.com/jfmall/help_center/201608/W020170112571247312064.png"
        width="100%"
        alt=""
      />
    </div>

    <h2 class="title">二、购买虚拟礼品</h2>
    <div class="p">
      1、在首页选择虚拟商品，挑选您所需要的虚拟商品后，直接兑换即可
    </div>
    <div>
      <img
        src="http://jftupian.haier.com/jfmall/help_center/201608/W020170112571247430679.png"
        width="100%"
        alt=""
      />
    </div>
    <div class="p">
      2、在首页选择本地生活，挑选您所需要的增值服务后，即可立即购买。
    </div>
    <div>
      <img
        src="http://jftupian.haier.com/jfmall/help_center/201608/W020170112571247514808.png"
        width="100%"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },

  methods: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
.title {
  color: #808080;
  margin-top: 5px;
}
.p{
  margin-bottom: 5px;
  text-indent: 2em;
  font-size: 16px;
  color: #808080;
  line-height: 2;
}
</style>
