<template>
<div class="evaluate-main">
    <div class="evaluate-title">
        <span>商品评价</span>
    </div>
    <div class="evaluate-content">
        <div class="evaluate-top">
            <div class="top-input">
                <el-input type="textarea" placeholder="请输入内容" v-model="content" maxlength="100" :autosize="{ minRows: 8}" show-word-limit></el-input>
            </div>
            <div class="top-star">
                <div>
                    <span>描述相符:</span>
                    <el-rate v-model="productRating"></el-rate>
                </div>
                <div>
                    <span>卖家服务:</span>
                    <el-rate v-model="serviceRating"></el-rate>
                </div>
                <div>
                    <span>物流服务:</span>
                    <el-rate v-model="logisticsScore"></el-rate>
                </div>
            </div>
        </div>
        <div style="margin-top:20px;text-align:right;">
            <el-button @click="sendcomment()">发送</el-button>
        </div>
        <!-- <div class="evaluate-flag">
            <span class="flag-item">质量超好</span>
            <span class="flag-item">手感超赞</span>
            <span class="flag-item">大小适合</span>
            <span class="flag-item">性价比高</span>
            <span class="flag-item">颜值一流</span>
        </div> -->
    </div>
</div>
</template>

<script>
import {
    mProductComment
} from '@/api/api'
export default {
    data() {
        return {
            content: "",
            orderNo: "",
            productRating:"",
            serviceRating:"",
            logisticsScore:"",
            comment:""
        };
    },
    methods: {
        async sendcomment() {
            let params = {
                buyerId: localStorage.getItem("memberId"),
                content: this.content,
                orderNo: this.$route.query.orderNo,
                productId: this.$route.query.productId,
                productName: this.$route.query.productname,
                productRating:this.productRating,
                serviceRating:this.serviceRating,
                logisticsScore:this.logisticsScore,
            }

            const res = await mProductComment(params);
            if (res.code == "0000") {
                this.$message({
                    message: "评论成功"
                });
                this.$router.push({
                path: '/products/productDetails?id=' + this.$route.query.productId,

            });
            }
        }
    },

    mounted() {},
};
</script>

<style lang="scss" scoped>
.evaluate-main {
    margin-top: 20px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    .evaluate-title {
        height: 40px;
        width: 100%;
        background-color: #EFEFEF;
        border-bottom: 2px solid #ED2856;

        span {
            display: inline-block;
            width: 100px;
            height: 100%;
            background-color: #ED2856;
            color: #fff;
            line-height: 40px;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
        }
    }

    .evaluate-content {
        padding: 40px;
        width: 100%;
        height: auto;
        border: 1px solid #D9E3EB;

        .evaluate-top {
            display: flex;
            flex-direction: row;

            .top-input {
                flex: 3;
            }

            .top-star {
                flex: 2;
                padding-left: 50px;
                display: flex;
                flex-direction: column;
                ;

                div {
                    height: 30px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 6px;

                    span {
                        font-size: 14px;
                        margin-right: 12px;
                    }
                }
            }
        }

        .evaluate-flag {
            margin-top: 40px;
            border-top: 1px dashed #D9E3EB;
            padding-top: 40px;

            .flag-item {
                padding: 10px;
                border: 1px solid #2283e2;
                color: #2283e2;
                border-radius: 4px;
                margin-right: 16px;
            }

            .flag-item:hover {
                background-color: #2283e2;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}
</style>
