<template>
<div class="orderdetails-main">
    <div class="details-title">退换货详情</div>
    <div style="width:80%; margin:20px auto;">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="订单编号">
                <el-input v-model="form.orderNo"></el-input>
            </el-form-item>
            <!-- <el-form-item label="联系方式">
                <el-input v-model="form.memberPhone"></el-input>
            </el-form-item> -->
            <el-form-item label="客诉来源">
                <div style="color:#606266;"> 在线客服</div>
            </el-form-item>
            <el-form-item label="客诉类型">
                <el-select v-model="form.probleType" placeholder="请选择客诉类型">
                    <el-option v-for="item in probleTypelist" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    <!-- <el-option label="产品问题" value="beijing"></el-option>
                    <el-option label="库存问题" value="beijing"></el-option> -->
                </el-select>
            </el-form-item>
            <el-form-item label="具体问题">
                <el-select v-model="form.probleSpecific" placeholder="请选择具体问题">
                    <el-option v-for="item in probleSpecificlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="售后类型">
                <el-radio-group v-model="form.serviceType">
                    <el-radio name="1" label="1">退货退分</el-radio>
                    <el-radio name="2" label="2">换货</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="问题描述">
                <el-input type="textarea" v-model="form.probleDescribe"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">提交</el-button>
                <!-- <el-button>取消</el-button> -->
            </el-form-item>
        </el-form>
    </div>

</div>
</template>

<script>
import {
    orderAfterAaleComplaintByPage
} from "@/api/api";

export default {
    data() {
        return {
            probleTypelist: [{
                    name: "订单问题",
                    id: 1
                },
                {
                    name: "产品问题",
                    id: 2
                },
                {
                    name: "库存问题",
                    id: 3
                },

            ],
            probleMsourcelist: [{
                    name: "在线客服",
                    id: 1
                },
                {
                    name: "电话中心",
                    id: 2
                },
                {
                    name: "留言",
                    id: 3
                },
                {
                    name: "QQ/微信",
                    id: 4
                },
                {
                    name: "小微",
                    id: 5
                },
            ],
            probleSpecificlist: [{
                    name: "产品货损",
                    id: 1
                },
                {
                    name: "产品功能",
                    id: 2
                },
                {
                    name: "发货延迟",
                    id: 3
                },
                {
                    name: "录错运单",
                    id: 4
                },
                {
                    name: "缺少配件",
                    id: 5
                },
                {
                    name: "错发丢失",
                    id: 6
                },
                {
                    name: "售后问题",
                    id: 7
                },
                {
                    name: "备货不足",
                    id: 8
                },
                {
                    name: "产品下市",
                    id: 9
                },
            ],

            form: {
                orderNo: '',
                // memberPhone: '',
                probleMsource: '',
                probleSpecific: '',
                serviceType: '',
                probleType: '',
                probleDescribe: '',

            }
        };
    },

    methods: {
        async onSubmit() {
            if (this.form.orderNo == "") {
                this.$toast("订单编号不能为空！")
                return
            }
            
            if (this.form.probleType == "") {
                this.$toast("客诉类型不能为空！")
                return
            }
            if (this.form.probleSpecific == "") {
                this.$toast("具体问题不能为空！")
                return
            }
            if (this.form.serviceType == "") {
                this.$toast("售后类型不能为空！")
                return
            }

            if (this.form.probleDescribe == "") {
                this.$toast("问题描述不能为空！")
                return
            }
            let params = {
                orderNo: this.form.orderNo,
                // memberPhone: this.form.memberPhone,
                probleMsource: this.form.probleMsource,
                probleSpecific: this.form.probleSpecific,
                serviceType: this.form.serviceType,
                probleType: this.form.probleType,
                probleDescribe: this.form.probleDescribe,

            };
            let res = await orderAfterAaleComplaintByPage(params);
            if (res.code == "0000") {
                this.$toast("提交成功")
                this.form = [];
            } else {
                this.$toast(res.msg)

            }
        }

    },

    mounted() {

    },
};
</script> 

<style lang="scss" scoped>
/deep/.el-button--primary:focus, .el-button--primary:hover{
    background-color: #AC8C4F;
    border-color: #AC8C4F;
}
/deep/.el-button--primary {
    background-color: #AC8C4F;
    border-color: #AC8C4F;
}

.orderdetails-main {
    width: 100%;
    height: auto;
    border: 1px solid #e1e1e1;

    .details-title {
        width: 100%;
        height: 30px;
        background-color: #f3f3f3;
        color: #333;
        text-indent: 20px;
        line-height: 30px;
        font-weight: bold;
        border-bottom: 1px solid #e5e5e5;
    }

    .details-content {
        min-height: 235px;
        display: flex;
        flex-direction: row;

        .content-left {
            flex: 1;
            padding: 20px;
            background-color: #fbfbfb;
            border-right: 1px solid #e5e5e5;

            .left-item {
                display: flex;
                flex-direction: row;
                margin-bottom: 16px;

                span:first-child {
                    width: 65px;
                    line-height: 26px;
                }

                span:last-child {
                    flex: 1;
                    line-height: 26px;
                }
            }
        }

        .content-right {
            flex: 2;
            padding: 20px;
        }
    }
}
</style>
