<template>
  <div class="myTeam-list">
    <div class="myTeam-item-border" v-for="(item,index) in list" :key="index">
      <div>
        <img :src="item.headImage"/>
        <span>姓名：</span>
        <span>{{item.name}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { getMyTeam } from "@/api/api";
export default {
  data() {
    return {
      pageName: "我的团队",
      loading: false, // 是否加载中
      finished: false, // 是否加载完成
      limit: 10, // 每页请求条数
      start: 1, // 请求页数
      isRequestFinished: false, // 请求是否结束
      list: [],
    };
  },
  methods: {
    // list 组件初始化数据
    onLoad() {
      this.getList();
    },
    // 请求数据
    async getList() {
      this.loading = true;
      let params = {
        start: this.start,
        limit: this.limit,
      };
      const res = await getMyTeam(params);
      if (res.code == "0000") {
        this.list = res.data.rows;
        console.log(this.list)
      } else {
        this.$toast(res.msg);
      }
    },
  },
  created() {
    this.onLoad();
  },
};
</script>
<style lang="scss" scoped>
.myTeam-list {
  width: 920px;
  height: auto;
  padding: 12px 0 0;
  border: 1px solid #eee;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #F6F6F6;
}

.myTeam-item-border {
  width: 25%;
  height:120px;
  padding:20px;
  margin-bottom: 12px;
  div{
      border:1px solid #eee;
      background-color: #fff;
      height:100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      img{
          height:50px;
          width:50px;
      }
  }
}
</style>