<template>
  <div class="sort">
    <div class="sort-left">
      <div class="imgs">
        <router-link :to="item.backUrl" v-for="(item,index) in sortLeft.logoImgList" :key="index">
          <img :src="item.imgUrl" alt />
        </router-link>
      </div>
      <div class="labels">
        <router-link
          :to="item.url"
          v-for="(item,index) in sortLeft.thirdTitleTableData"
          :key="index"
        >{{item.name}}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import titles from "@/components/indexComponents/title";
export default {
  props: {
    sortLeft: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.sort {
  display: flex;

  .sort-left {
    border: 1px solid #e6eaf0;
    border-top: 10px solid #2283E2;
    width: 270px;
    height: 481px;
    padding: 20px;
    .imgs {
      max-height: 250px;
      overflow: hidden;
      padding-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      text-align: center;
      border-bottom: 1px solid #e6eaf0;
      a {
        width: 50%;
        margin: 10px 0;
      }
      img {
        width: 80px;
        height: 40px;
      }
    }
    .labels {
      max-height: 175px;
      overflow: hidden;
      padding-top: 25px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      text-align: center;
      a {
        width: 50%;
        display: block;
        line-height: 30px;
        color: #868e96;
        font-size: 14px;
      }
    }
  }
}
</style>