<template>
    <div class="order-detail-wrap">
        <!-- 订单信息 -->
        <div class="order-detail">

            <div class="order-header">
                <span class="order-number">订单号：{{orderInfo.orderNo}}</span>
                <span class="order-time ml30">下单时间：{{orderInfo.buyTime}}</span>
            </div>

            <!-- 收货地址 -->
            <div class="content">
                <div class="address fl">
                    <p class="comon-address">
                        <span class="key">收件人：</span>
                        <span class="value">{{orderInfo.receiverName}}</span>
                    </p>
                    <p class="comon-address">
                        <span class="key">联系方式：</span>
                        <span class="value">{{orderInfo.receiverPhone}}</span>
                    </p>
                    <p class="comon-address">
                        <span class="key">收货地址：</span>
                        <span class="value">{{orderInfo.receiverAddress}}</span>
                    </p>
                </div>
            </div>

        </div>

        <!-- 商品信息 -->
        <div class="goods-detail">
            <p class="header">
                <img :src="productInfo.storeLogo" class="store-image fl " alt="">
                <span class="store-name fl ml20">{{productInfo.storeName}}</span>
            </p>
            <div class="content">
                <div class="common-style">
                    <div class="message">商品信息</div>
                    <div class="price">拼团价格</div>
                    <div class="number">购买数量</div>
                </div>
                <div class="common-content" v-for="(item,index) in productInfo.groupProductResponse" :key="index">
                    <div class="message">
                        <img :src="item.pictureUrl" class="img" alt="">
                        <div class="goods-info">
                            <p class="name">{{item.mpName}}</p>
                            <p class="sku">{{item.goodsProperty}}</p>
                        </div>
                    </div>
                    <div class="price">￥ {{item.availablePrice}}</div>
                    <div class="number">{{item.goodsNum}}</div>
                </div>
            </div>
        </div>

        <!-- 拼团成员信息 -->
        <div class="goods-detail member-detail">
            <p class="header">
                <span class="store-name fl">拼团成员信息</span>
                <span class="store-name fl ml20 themeColor">{{groupDetails.totalMembers === null ? 1 : groupDetails.totalMembers }}人团</span>
            </p>
            <div class="content mb10" v-for="(item,index) in groupDetails.groupInstDetailResponseList" :key="index">
                <img :src="item.memberHead" class="user-img" alt="">
            </div>
        </div>

    </div>
</template>

<script>
    import {getCurrentJoinedGroupInfo} from '@/api/api'

    export default {
        name: 'detail',

        components: {},

        data() {
            return {
                start: 1,                   // 开始页数
                limit: 100,                 // 每次请求条数
                groupDetails: {
                    totalMembers: "",
                    groupInstDetailResponseList: []
                },
                productInfo: {
                    storeLogo: "",
                    storeName: "",
                    groupProductResponse: []
                },
                orderInfo: {
                    buyTime: '',
                    receiverAddress: '',
                    receiverName: '',
                    receiverPhone: '',
                }
            }
        },

        methods: {
            // 获取拼团详情
            async getList() {
                const params = {
                    start: this.start,
                    limit: this.limit,
                    meGrouponFlag: 1,    // 1:表示自己的拼团详情，0:表示要参与的拼团详情
                    groupInstCode: this.$route.query.groupInstCode,
                }
                const res = await getCurrentJoinedGroupInfo(params)
                if (res.code === '0000') {
                    let groupProductResponse = new Array()
                    if (res.data.groupProductResponse != null) {
                        let obj = {}
                        obj.pictureUrl = res.data.groupProductResponse.pictureUrl
                        obj.mpName = res.data.groupProductResponse.mpName
                        obj.goodsNum = res.data.groupProductResponse.goodsNum
                        obj.goodsProperty = res.data.groupProductResponse.goodsProperty
                        obj.availablePrice = res.data.groupProductResponse.availablePrice
                        groupProductResponse.push(obj)
                        this.productInfo = {
                            storeLogo: res.data.groupProductResponse.storeLogo,
                            storeName: res.data.groupProductResponse.storeName,
                            groupProductResponse: groupProductResponse
                        }
                    }
                    if (res.data.orderInfo != null) {
                        this.orderInfo = {
                            buyTime: res.data.orderInfo.buyTime,
                            orderNo: res.data.orderInfo.orderNo,
                            receiverAddress: res.data.orderInfo.receiverAddress,
                            receiverName: res.data.orderInfo.receiverName,
                            receiverPhone: res.data.orderInfo.receiverPhone,
                        }
                    }
                    let groupInstDetailResponseList=new Array();
                    let obj = {}
                    obj.memberHead = res.data.memberHead
                    obj.memberId = res.data.memberId
                    if(res.data.groupInstDetailResponseList!=null){
                         res.data.groupInstDetailResponseList.splice(0, 0, obj)
                    }else{
                        res.data.groupInstDetailResponseList=groupInstDetailResponseList.push(obj)
                    }
                    this.groupDetails = {
                        totalMembers: res.data.totalMembers,
                        groupInstDetailResponseList: res.data.groupInstDetailResponseList
                    }
                } else {
                    this.$toast(res.msg)
                }
            },

            // 跳转到申请售后页面
            apply() {
                this.$router.push({
                    path: '/afterSale/apply'
                })
            },
        },

        mounted() {
            this.getList()
        },

    }
</script>

<style lang="scss" scoped>
    .order-detail-wrap {
        width: 100%;
        height: auto;
        .order-detail {
            width: 100%;
            border: 1px solid #eaeaea;
            margin-bottom: 20px;
            font-size: 14px;
            .order-header {
                height: 52px;
                line-height: 52px;
                padding: 0 20px;
                background: #f5f5f5;
                .common-operation {
                    color: #69c;
                    cursor: pointer;
                }
            }
            /* 收货地址信息 */
            .content {
                padding: 23px 20px 30px;
                overflow: hidden;
                .address {
                    width: 560px;
                    .comon-address {
                        height: 30px;
                        line-height: 30px;
                        .key {
                            color: #999;
                        }
                    }
                }
                .detail {
                    width: 280px;
                    .comon-detail {
                        height: 30px;
                        line-height: 30px;
                        .key {
                            display: inline-block;
                            color: #999;
                            width: 100px;
                            text-align: right;
                        }
                    }
                }
            }
        }
        /* 商品列表 */
        .goods-detail {
            font-size: 14px;
            margin-bottom: 20px;
            border: 1px solid #eaeaea;
            .header {
                padding: 10px 20px;
                background: #f5f5f5;
                overflow: hidden;
                .store-image {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    vertical-align: middle;
                }
                .store-name {
                    display: inline-block;
                    vertical-align: middle;
                    margin-top: 5px;
                }
            }
            .content {
                padding: 10px 20px 0;
                .common-style, .common-content {
                    display: flex;
                    height: 30px;
                    line-height: 40px;
                    .message {
                        text-align: left;
                        width: 60%;
                    }
                    .price, .number {
                        width: 20%;
                        text-align: center;
                    }
                }
                .common-content {
                    line-height: 0;
                    height: auto;
                    border-bottom: 0 none;
                    position: relative;
                    border-top: 1px dashed #ccc;
                    margin-top: 20px;
                    padding: 20px 0;
                    .message {
                        display: flex;
                        .img {
                            display: block;
                            width: 100px;
                            height: 100px;
                        }
                        .goods-info {
                            margin-left: 20px;
                            .name {
                                line-height: 24px;
                                max-width: 400px;
                                margin-top: 10px;
                                font-size: 16px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .sku {
                                margin-top: 10px;
                                color: #999;
                                font-size: 12px;
                            }
                        }
                    }
                    .price, .number {
                        margin-top: 20px;
                    }
                    .apply {
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                        z-index: 9;
                        display: inline-block;
                        padding: 12px 10px;
                        background-color: $themeColor;
                        color: #fff;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
            }
        }
        .member-detail {
            .user-img {
                display: inline-block;
                width: 40px;
                height: 40px;
                margin-right: 20px;
            }
        }
    }
</style>


