<template>
  <div class="tips-wrap">
    <ul class="list">
      <!-- <li>
        <router-link to=''>
          <i class="iconfont icon-liwu"></i>
          <p>新人有礼</p>
        </router-link>
      </li> -->
      <li>
        <router-link to=''>
          <img class="qrcode" src="../../static/images/qrcode.png">
          <p>关注有礼</p>
        </router-link>
      </li>
      <li>
        <router-link to=''>
          <i class="iconfont icon-kefu"></i>
          <p>客服热线</p>
        </router-link>
      </li>
      <li>
        <a  @click="top">
          <i class="iconfont icon-xiangshang"></i>
          <p>TOP</p>
        </a>
      </li>
      
    </ul>
  </div>
</template>
<script>
export default {
  name: 'tips',
  components: {

  },
  props: {

  },
  data() {
    return {

    }
  },
  methods: {
    // 回到顶部
    top() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
  },
  mounted() {

  },
}
</script>
<style lang="scss" scoped>
.tips-wrap {
  width: 80px;
  position: fixed;
  top: 300px;
  right: 20px;
  z-index: 999;
  .list {
    background: #FFFFFF;
    border: 1px solid #E6EAF0;
    border-bottom: none;
    font-size: 10px;
    color: #868E96;
    text-align: center;
    li {
      border-bottom: 1px solid #E6EAF0;
      padding: 6px 0;
      a {
        display: block;
        color: #868E96;
        cursor: pointer;
        .qrcode {
          width: 50px;
          height: 50px;
          vertical-align: top;
        }
        p {
          margin-top: 6px;
        }
      }
    }
  }
}
</style>

