<template>
<div style="width:100%;background:#fff;">
    <div class="logistics-main">
        <div class="logistics-title">
            <span>运单号码：{{ logisticsDetails.number }}</span>
            <span>物流公司：{{ logisticsDetails.expName }}</span>
            <!-- <span>客服电话：{{ logisticsDetails.expPhone }}</span> -->
        </div>
        <div class="logistics-timeline">
            <el-timeline reverse="true">
                <el-timeline-item v-for="(activity, index) in logisticsDetails.list" :key="index" :timestamp="activity.time">
                    {{ activity.status }}   {{activity.context}}
                </el-timeline-item>
            </el-timeline>
        </div>
    </div>
</div>
</template>

<script>
import {
    getLogisticsDetails
} from "@/api/api";
export default {
    name: "App",
    data() {
        return {
            menber: "",
            logisticsDetails: {list:null,expName:null,number:null}
        };
    },

    methods: {

        async getLogistics(logisticsNo) {
            // var str = localStorage.getItem("memberPhone"); //截取后4位
            // console.log(localStorage.getItem("memberPhone"))
            // console.log(localStorage.getItem("memberId"))
            // this.menber = str.substring(str.length - 4);
            // console.log(this.menber)
            let params = {
                logisticsCode: logisticsNo,
                type: ""
            };
            let logistics = await getLogisticsDetails(params);
            if (logistics.code==='0000') {
                
                console.log(logistics)
                this.logisticsDetails.expName=logistics.result.deliveryCompanyName
                this.logisticsDetails.number=logistics.result.wayBillNo
                this.logisticsDetails.list = logistics.result.records;
            }
        },
    },

    mounted() {
        this.getLogistics(this.$route.query.logisticsNo);

    },
};
</script>

<style lang="scss" scoped>
.logistics-main {
    width: 1200px;
    margin: 0 auto;
    height: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid #e6eaf0;

    .logistics-title {
        height: 40px;
        width: 100%;
        background-color: #f7f7f7;
        display: flex;
        flex-direction: row;
        padding: 0 20px;
        align-items: center;

        span {
            margin-right: 50px;
            font-weight: bold;
        }
    }

    .logistics-timeline {
        padding: 40px;
    }
}
</style>
