import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';//引入store
import VueCookies from 'vue-cookies';
import './style/common.css';
import 'vant/lib/index.css';
import utils from '@/utils/utils';
import '../static/icon/iconfont.css';
import vueSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import cookies from 'vue-cookies';

Vue.prototype.$cookies = cookies;
import casarteBanner from './components/haier/index/banner';
import haierJifen from './components/haier/index/jifen';
import haierBacktop from './components/haier/index/backtop';
import haierBrandArea from './components/haier/index/brandArea';
import haierQuality from './components/haier/index/quality';
import casarteTitle from './components/haier/index/title';
import casarteMemberEvent from './components/haier/index/memberEvent';
import selectivePreference from './components/haier/index/selectivePreference';
import casarteRecommend1 from './components/haier/index/recommend1';
import casarteRecommend2 from './components/haier/index/recommend2';
import casarteJifen from './components/haier/index/jifen';

Vue.use(VueCookies);
Vue.use(vueSwiper);
Vue.use(ElementUI);

Vue.use(haierBacktop);
Vue.use(casarteBanner);
Vue.use(haierJifen);
Vue.use(haierBrandArea);
Vue.use(haierQuality);
Vue.use(casarteTitle);
Vue.use(casarteMemberEvent);
Vue.use(selectivePreference);
Vue.use(casarteRecommend1);
Vue.use(casarteRecommend2);
Vue.use(casarteJifen);


Vue.config.productionTip = false;
Vue.prototype.utils = utils;
window.Vue = Vue;

if (window.Statistics) {
  window.Statistics('init', {
    Vue, //vu3 项目传 Vue 实例， app: app实例
    router,// 路由对象实例
    sentry: {
      dsn: 'https://610e2d47ca8e4abeaf97a11e92645fca@ss.haier.net/492', // 替换上报地址
      // 环境信息
      environment: 'prod',  //请配置为 dev 、test、stage、prod 中一种
      // 通过环境变量注入对应版本号
      release: process.env.FRAPPE_VERSION,  // 配置参考 “约定-版本控制约定”
    }
  });
}

router.beforeEach((to, from, next) => {
  // window.location.origin !== 'http://localhost:8088' // 本地启动禁止重定向https
  if (window.location.protocol === 'http:' && window.location.origin !== 'http://localhost:8088') {
    // 当前是HTTP协议，重定向到HTTPS
    window.location.href = window.location.href.replace('http:', 'https:');
  } else {
    if (to.meta.login == true) {
      if (localStorage.getItem('hbscToken')) {
        next();
      } else {
        window.location.href = 'https://account.haier.com/oauth/authorize?client_id=casartepoint&response_type=code&state=xyz&redirect_uri=https://jf.casarte.com';
      }
    } else {
      next();
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
