<template>
  <div class="img" v-if="variablesObject">
    <template v-if="variablesObject.imgUlrList">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item,index) in variablesObject.imgUlrList" :key="index">
          <router-link to>
            <img :src="item.imgUrl" alt @click="goOut(item.backUrl)" />
          </router-link>
        </swiper-slide>
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination"></div>
        <!-- 左右箭头 -->
        <div class="swiper-button-prev btns" slot="button-prev"></div>
        <div class="swiper-button-next btns" slot="button-next"></div>
      </swiper>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    variablesObject: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      swiperOption: {
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        //开启循环模式
        loop: true
      }
    };
  },
  methods: {
    goOut(url) {
      window.open(url, "_blank");
    }
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
.img {
  width: 1200px;
  margin: 0 auto 30px;
  img {
    width: 100%;
    height: 100px;
  }
  .btns {
    color: #fff;
    outline: none;
  }
}
</style>