<template>
	<div class="score">
		<div class="score-left">
			<span>分值浏览</span>
			<!-- <i class="el-icon-arrow-right"></i> -->
		</div>
		<div>
			<ul class="score-ul">
				<li class="score-li">
					<router-link :class="{'active':activeIndex == '1'}" to="/list/index?brandId=1">0-1000</router-link>
				</li>
				<li class="score-li">
					<router-link :class="{'active':activeIndex == '2'}" to="/list/index?brandId=1">1000-2000</router-link>
				</li>
				<li class="score-li">
					<router-link :class="{'active':activeIndex == '3'}" to="/list/index?brandId=1">2000-3000</router-link>
				</li>
				<li class="score-li">
					<router-link :class="{'active':activeIndex == '4'}" to="/list/index?brandId=1">3000-5000</router-link>
				</li>
				<li class="score-li">
					<router-link :class="{'active':activeIndex == '5'}" to="/list/index?brandId=1">5000-10000</router-link>
				</li>
				<li class="score-li">
					<router-link :class="{'active':activeIndex == '6'}" to="/list/index?brandId=1">10000-20000</router-link>
				</li>
				<li class="score-li">
					<router-link :class="{'active':activeIndex == '7'}" to="/list/index?brandId=1">10000以上</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeIndex: '1',
				list: [

				],

			};
		},

		methods: {},
		mounted() {

		}

	};
</script>

<style lang="scss" scoped>
	.score {
		width: 1200px;
		display: flex;
		.score-left{
			display: flex;
			align-items: center;
			width: 200px;
			height: 50px;
			line-height: 50px;
			background-color: #2283E2;
			color: #fff;
			font-size: 16px;
			justify-content: space-between;
			padding: 0 20px;
		}
		.score-ul{
			width: 1000px;
			display: flex;
			align-items: center;
			height: 50px;
			background-color: #F3F9FF;
			.score-li{
				flex:1;
				text-align: center;
				font-size: 16px;
			}
		}
	}
	.score-li a:hover{
		color: #2283E2;
	}
</style>
