<template>
  <div class="list listImgs">
    <ul>
      <router-link tag="li" to="/list/detail" v-for="(l,li) in list" :key="li">
        <img :src="l.img" alt />
        <div class="info">
          <p class="title">{{l.title}}</p>
          <p class="price">
            <span class="nowPrice">{{l.nowPrice|formatMoney}}</span>
            <span class="iconfont icon-touxiang">5人团</span>
          </p>
          <div class="apply">去开团</div>
        </div>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.list {
  width: 1200px;
  margin: 20px auto 0;
  ul {
    width: 1220px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      width: 230px;
      border: 1px solid #e6eaf0;
      .info {
        padding: 0;
      }
      img {
        width: 228px;
        height: 228px;
      }
      .iconfont {
        color: #999;
      }
      .apply {
        background-color: $themeColor;
        color: #fff;
        padding: 10px 0;
        font-size: 14px;
      }
    }
  }
}
</style>