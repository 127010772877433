<template>
<div class="index-main">
    <component :is="item.code" :cmsData="item.cmsData" v-for="item in componentList" :key="item.name"></component>
</div>
</template>

<script>
import {
    haierGetModuleCmsConf
} from "@/api/api";
export default {
    name: "index",
    data() {
        return {
            // componentList: [],
            componentList: [
                // {
            //         code: "haier-banner",
            //         cmsData: {
            //             bannerList: [{
            //                     img: "http://jftupian.haier.com/jfmall/shouye/banner/201907/P020210118405210802193.jpg",
            //                     id: "9489",
            //                 },
            //                 {
            //                     img: "http://jftupian.haier.com/jfmall/shouye/banner/201904/P020210121611083292368.jpg",
            //                     id: "9489",
            //                 },
            //                 {
            //                     img: "http://jftupian.haier.com/jfmall/shouye/banner/201909/P020210118405759634176.jpg",
            //                     id: "9489",
            //                 },
            //             ],
            //         },
            //     },
            //     {
            //         code: "haier-title",

            //     },
            //     {
            //         code: "haier-recommend1",
            //         cmsData: {
            //             bannerImg: "https://img13.360buyimg.com/babel/s580x740_jfs/t1/169705/23/3730/273600/60092dd2E384b4d13/7c2ddaccea1830cc.jpg!cc_290x370.webp",
            //             bannerId: "9489",
            //             newList: [{
            //                     pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                     chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                     integralNum: "2999",
            //                     id: "9489",

            //                 },
            //                 {

            //                     pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                     chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                     integralNum: "2999",
            //                     id: "9489",

            //                 },
            //                 {

            //                     pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                     chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                     integralNum: "2999",
            //                     id: "9489",

            //                 },
            //                 {

            //                     pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                     chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                     integralNum: "2999",
            //                     id: "9489",

            //                 }
            //             ],

            //         },
            //     },
            //     {
            //         code: "haier-jifen",
            //         cmsData: {
            //             integralNumList: [{
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             }]
            //         }
            //     },
            //     {
            //         code: "haier-memberEvent",
            //         cmsData: {
            //             bannerList: [{
            //                 img: "http://jftupian.haier.com/jfmall/shouye/activities/201709/P020210121594886953368.jpg",
            //                 id: "9489",
            //             }],
            //             speciallist: [
                            
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             },
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             },
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             }
            //             ],
            //             seckillist: [
                          
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             },
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             },
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             }
            //             ],
            //             youxuanlist: [
                            
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             },
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             },
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             }
            //             ],
            //         },
            //     },
            //     // {
            //     //     code: "haier-selectivePreference",
            //     //     cmsData: [{
            //     //             name: "母婴健康",
            //     //             preferenceList: [{
            //     //                     id: "123",
            //     //                 },
            //     //                 {
            //     //                     id: "1234",
            //     //                 },
            //     //                 {
            //     //                     id: "12345",
            //     //                 },
            //     //                 {
            //     //                     id: "1111",
            //     //                 },
            //     //             ],
            //     //         },
            //     //         {
            //     //             name: "高端厨卫",
            //     //             preferenceList: [{
            //     //                     id: "123",
            //     //                 },
            //     //                 {
            //     //                     id: "1234",
            //     //                 },
            //     //                 {
            //     //                     id: "12345",
            //     //                 },
            //     //                 {
            //     //                     id: "1111",
            //     //                 },
            //     //             ],
            //     //         },
            //     //         {
            //     //             name: "智能安防",
            //     //             preferenceList: [{
            //     //                     id: "123",
            //     //                 },
            //     //                 {
            //     //                     id: "1234",
            //     //                 },
            //     //                 {
            //     //                     id: "12345",
            //     //                 },
            //     //                 {
            //     //                     id: "1111",
            //     //                 },
            //     //             ],
            //     //         },
            //     //         {
            //     //             name: "轻巧生活",
            //     //             preferenceList: [{
            //     //                     id: "123",
            //     //                 },
            //     //                 {
            //     //                     id: "1234",
            //     //                 },
            //     //                 {
            //     //                     id: "12345",
            //     //                 },
            //     //                 {
            //     //                     id: "1111",
            //     //                 },
            //     //             ],
            //     //         },
            //     //         {
            //     //             name: "智慧数码",
            //     //             preferenceList: [{
            //     //                     id: "123",
            //     //                 },
            //     //                 {
            //     //                     id: "1234",
            //     //                 },
            //     //                 {
            //     //                     id: "12345",
            //     //                 },
            //     //                 {
            //     //                     id: "1111",
            //     //                 },
            //     //             ],
            //     //         },
            //     //     ],
            //     // },

            //     {
            //         code: "haier-recommend2",
            //         cmsData: {
            //             recommendList: [
            //                 {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             },
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             },
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             },
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             },
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             },
            //             {
            //                 pictureUrl: "https://img10.360buyimg.com/ceco/s600x600_jfs/t1/142712/27/11837/57999/5f91a1caE45e29352/ca771d2517c825d9.jpg!q70.jpg",
            //                 chineseName: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //                 integralNum: "2999",
            //                 id: "9489",
            //             }
            //             ],
            //         },
            //     },
            ],
        };
    },

    methods: {
        async getCmsData() {
          let params = {
            platform: 22,
          };
          const res = await haierGetModuleCmsConf(params);
          if (res.code == "0000") {
            // debugger;
            this.componentList = JSON.parse(res.data.cmsData);
          }
        },
    },

    async mounted() {
        this.getCmsData();
    },
};
</script>

<style lang="scss" scoped></style>
