<template>
  <div class="list listImgs">
    <ul>
      <li  v-for="(l,li) in list" :key="li" @click="goDetail(l.id)">
        <div class="img">
          <img :src="l.pictureUrl" alt class="image"/>
        </div>
        <div class="info">
          <p class="title">{{l.chineseName}}</p>
          <p class="price">
            <span class="nowPrice">{{l.marketPrice|formatMoney}}</span>
            <span class="oldPrice">原价{{l.marketPrice|formatMoney}}</span>
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    // 新页面跳转到商品详情
    goDetail(id) {
      let routerUrl = this.$router.resolve({
        path:`/list/detail?id=${id}`
      });
      window.open(routerUrl.href, '_blank');
    },
  }
};
</script>

<style lang="scss" scoped>
.list {
  width: 1200px;
  margin: 20px auto 0;
  ul {
    width: 1220px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      width: 230px;
      border: 1px solid #e6eaf0;
      img {
        display: block;
        width: 228px;
        height: 228px;
        .image {
          display: block;
          width: 228px;
          height: 228px;
        }
      }
      .title {
        text-align: left;
      }
      .price {
        text-align: left;
        padding-left: 10px;
      }
    }
  }
}
</style>