<template>
<div class="loading">
    <!-- <img src="./../assets/img/loading.gif" alt=""> -->
    <van-loading class="loadingVant" vertical type="spinner">加载中...</van-loading>
</div>
</template>

<script>
export default {
    name: 'loading',
    data() {
        return {}
    },
    mounted(){
        
    }
}
</script>

<style scoped lang="scss">
/deep/.van-loading__text{
    color: #fff;
}
/deep/.van-loading--vertical{
    width: 100%;
    height: 100%;
    justify-content: center;
}
.loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200001;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.loading img {
    width: 1rem;
    height: 1rem;
    margin: 7.5rem auto;
}
</style>
