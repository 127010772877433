<template>
<div class="toPay ">
    <div style="width:1200px;margin:0 auto;padding:20px;background:#fff;">

        <div class="toPayInfo">
            <p>订单信息：{{parentOrderNo}}</p>
            <p>
                应付积分：
                <span class="themeColor">{{saleTotalAmount}}</span>
            </p>
        </div>

        <!-- <div class="way">
        <h4 class="title">选择付款方式</h4>
        <div class="flex">
            <form class="label">
                <div class="flex mr30">
                    <input type="radio" name="payMethod" id="aliPay" class="radio" checked />
                    <label for="aliPay">
                        <img src="../../../static/images/aliPay.png" alt />
                    </label>
                </div>
                <div class="flex">
                    <input type="radio" name="payMethod" id="wxPay" class="radio" />
                    <label for="wxPay">
                        <img src="../../../static/images/wxPay.png" alt />
                    </label>
                </div>
            </form>
        </div>
    </div> -->

        <div class="btns">
            <button @click="goPay()">立即付款</button>
        </div>
        <p class="time" v-if="show">
            剩余付款时间：
            <van-count-down :time="time" format="DD 天 HH 时 mm 分 ss 秒" />
        </p>
    </div>
</div>
</template>

<script>
import {
    haierOrderPay,
    selHaierOrderOverdue
} from "@/api/api";
export default {
    data() {
        return {
            parentOrderNo: "", // 父订单编号
            orderNo: "", //子订单编号
            saleTotalAmount: "", // 支付金额
            time: "",
            show: true
        };
    },
    methods: {
        //立即付款
        async goPay() {
            let params = {
                "buyerId": localStorage.getItem("memberId"),
                "payTotalAmount": this.saleTotalAmount,
                "parentOrderNo": this.orderNo,
                "phone":localStorage.getItem("memberPhone")
            }
this.$store.commit('showLoading')
            const res = await haierOrderPay(params);
            if (res.code === "0000") {
                // debugger
                this.$toast("支付成功");
                this.$router.push({
                    path: "/centre/ordersList",
                    query: {},
                });
            } else {
                this.$toast(res.msg);
            }
            this.$store.commit('hideLoading')
        },
        //订单过期时间
        async selHaierOrderOverdue() {
            let params = {
                orderNo: this.orderNo,
            }
            console.log(this.orderNo)
            if (this.orderNo == undefined) {
                this.show = false
            } else if (this.orderNo.length > 0) {
                const res = await selHaierOrderOverdue(params);
                if (res.code == "0000") {
                    this.time = new Date(res.data) - new Date().getTime()
                }
            }

        }
    },
    mounted() {
        // if (this.$route.query.number && this.$route.query.number != "") {
        //   this.parentOrderNo = this.$route.query.number;
        //   this.saleTotalAmount = this.$route.query.money;
        // } else {
        //   this.$router.go(-1);
        // }
        this.parentOrderNo = this.$route.query.parentOrderNo;
        // this.orderNo = this.$route.query.orderNo;
        this.orderNo = this.$route.query.orderNo || this.$route.query.parentOrderNo;

        this.saleTotalAmount = this.$route.query.saleTotalAmount;
        this.selHaierOrderOverdue();
    }
};
</script>

<style lang="scss" scoped>
.toPay {
    padding-top: 20px;
    background: #fff;

    .toPayInfo {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        font-size: 16px;

        p {
            &:first-child {
                color: #999;
            }
        }

        .themeColor {
            color: #AC8C4F;
            font-size: 18px;
        }
    }

    .way {
        border: 1px solid #e6eaf0;

        .flex {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .radio {
                -webkit-appearance: checkbox;
                width: 15px;
                height: 15px;
                border: 1px solid #e6eaf0;
                margin-right: 10px;
            }

            .label {
                padding: 30px;
                display: flex;
                align-items: center;
                cursor: pointer;

                img {
                    width: 133px;
                }
            }
        }
    }

    .btns {
        margin-top: 200px;
        display: flex;
        justify-content: flex-end;

        button {
            width: 168px;
            height: 50px;
            line-height: 50px;
            font-size: 18px;
            color: #fff;
            background: #AC8C4F;
        }
    }

    .time {
        padding-top: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;

        span {
            color: #AC8C4F;
        }
    }
}
</style>
