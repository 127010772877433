<template>
  <div class="feedback-wrap">
    <textarea class="textarea" name="note" v-model="sendData.consigneeName" cols="30" rows="10" style="resize:none" placeholder="对我们的网站,商品，服务您还有什么建议吗？您还希望在商城上买到什么？请告诉我们..."></textarea>
    <div class="submit" @click="addFeedback(1)">提交</div>
  </div>
</template>
<script>
import {
  addFeedback
} from "@/api/api";
export default {
  name: "feedback",
  components: {

  },

  data() {
    return {
      sendData:{
        consigneeName:null,
      }
    };
  },

  methods: {
        // 新增地址
    async addFeedback(value) {
        const res = await addFeedback(this.sendData);
        if (res.code == "0000") {
          this.$toast("反馈成功,我们将尽快进行回复");
        } else {
          this.$toast(res.msg);
        }
     
    },

  },
  mounted() {

  }
};
</script>
<style lang="scss" scoped>
  .textarea {
    width: 100%;
    border: 1px solid #E6EAF0;
    padding: 10px;
    font-size: 14px;
  }
  .submit {
    width: 100px;
    height: 30px;
    line-height: 30px;
    background-color: $themeColor;
    text-align: center;
    color: #fff;
    border-radius: 4px;
    margin: 50px auto;
    cursor: pointer;
  }
</style>