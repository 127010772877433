<template>
  <div class="register">
    <div class="clause">
      客户在海尔海贝商城兑换礼品的同时，有义务遵守以下交易条款。您在下订单之前或接受前，请您仔细阅读以下条款：
    </div>
    <div class="helpclause">
      <h2 class="title">一、本站服务条款的确认和接纳</h2>
      <div class="p">
        本站的各项电子服务的所有权和运作权归本站。本站提供的服务将完全按照其发布的服务条款和操作规则严格执行。用户必须完全同意所有服务条款并完成注册程序，才能成为本站的正式用户。用户确认：本协议条款是处理双方权利义务的当然约定依据，除非违反国家强制性法律，否则始终有效。在下订单的同时，您也同时承认了您拥有购买这些产品的权利能力和行为能力，并且将您对您在订单中提供的所有信息的真实性负责。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">二、服务简介</h2>
      <div class="p">
        本站运用自己的操作系统通过国际互联网络为用户提供网络服务。同时，用户必须：
      </div>
      <div class="p">
        1. 自行配备上网的所需设备，包括个人电脑、调制解调器或其他必备上网装置。
      </div>
      <div class="p">
        2. 自行负担个人上网所支付的与此服务有关的电话费用、网络费用。
      </div>
      <div class="p">3. 基于本站所提供的网络服务的重要性，用户应同意：</div>
      <div class="p">(1). 提供详尽、准确的个人资料。</div>
      <div class="p">(2). 不断更新注册资料，符合及时、详尽、准确的要求。</div>
      <div class="p">
        本站不公开用户的姓名、地址、电子邮箱和笔名， 除以下情况外：
      </div>
      <div class="p">1. 用户授权本站透露这些信息。</div>
      <div class="p">2. 相应的法律及程序要求本站提供用户的个人资料。</div>
      <div class="p">
        如果用户提供的资料包含有不正确的信息，本站保留结束用户使用网络服务资格的权利。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">三、价格和数量</h2>
      <div class="p">
        本站将尽最大努力保证您所购商品与网站上公布的价格一致，但价目表和声明并不构成要约。本站有权在发现了其网站上显现的产品及订单的明显错误或缺货的情况下，单方面撤回任何承诺。同时，本站保留对产品订购的数量的限制权。
        产品的价格和可获性都在本站上指明。这类信息将随时更改且不发任何通知。商品的价格都包含了增值税。如果发生了意外情况，在确认了您的订单后，由于供应商提价，税额变化引起的价格变化，或是由于网站的错误等造成商品价格变化，您有权取消您的订单，并希望您能及时通过电子邮件或电话通知本站客户服务部。
        您所订购的商品，如果发生缺货，您有权取消定单。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">四、送货及费用</h2>
      <div class="p">
        本站将会把产品送到您所指定的送货地址。所有在本站上列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。送货费用根据您选择的配送方式的不同而异。
      </div>
      <div class="p">
        请清楚准确地填写您的真实姓名、送货地址及联系方式。因如下情况造成
        订单延迟或无法配送等，本站将不承担责任：
      </div>
      <div class="p">1. 客户提供错误信息和不详细的地址；</div>
      <div class="p">
        2. 货物送达无人签收，由此造成的重复配送所产生的费用及相关的后果。
      </div>
      <div class="p">3. 不可抗力，例如：自然灾害、交通戒严、突发战争等。</div>
    </div>
    <div class="helpclause">
      <h2 class="title">五、服务条款的修改</h2>
      <div class="p">
        本站将可能不定期的修改本用户协议的有关条款，一旦条款及服务内容产生变动，本站将会在重要页面上提示修改内容。如果不同意本站对条款内容所做的修改，用户可以主动取消获得的网络服务。如果用户继续使用本站的服务，则视为接受服务条款的变动。
      </div>
      <div class="p">本站保留随时修改或中断服务而不需告知用户的权利。</div>
      <div class="p">
        本站行使修改或中断服务的权利，不需对用户或第三方负责。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">六、用户隐私制度</h2>
      <div class="p">
        尊重用户个人隐私是本站的一项基本政策。所以，作为对以上第二点人注册资料分析的补充，本站一定不会在未经合法用户授权时公开、编辑或透露其注册资料及保存在本站中的非公开内容，除非有法律许可要求或本站在诚信的基础上认为透露这些信息在以下四种情况是必要的：
      </div>
      <div class="p">1. 遵守有关法律规定，遵从本站合法服务程序。</div>
      <div class="p">2. 保持维护本站的商标所有权。</div>
      <div class="p">3. 在紧急情况下竭力维护用户个人和社会大众的隐私安全。</div>
      <div class="p">4. 符合其他相关的要求。</div>
    </div>
    <div class="helpclause">
      <h2 class="title">七、用户的帐号、密码和安全性</h2>
      <div class="p">
        用户一旦注册成功，成为本站的合法用户，将得到一个密码和用户名。您可随时根据指示改变您的密码。用户将对用户名和密码安全负全部责任。另外，每个用户都要对以其用户名进行的所有活动和事件负全责。用户若发现任何非法使用用户帐号或存在安全漏洞的情况，请立即通告本站。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">八、拒绝提供担保</h2>
      <div class="p">
        用户个人对网络服务的使用承担风险。本站对此不作任何类型的担保，不论是明确的或隐含的，但是不对商业性的隐含担保、特定目的和不违反规定的适当担保作限制。本站不担保服务一定能满足用户的要求，也不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保。本站对在本站上得到的任何商品购物服务或交易进程，不作担保。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">九、有限责任</h2>
      <div class="p">
        本站对任何直接、间接、偶然、特殊及继起的损害不负责任，这些损害可能来自：不正当使用网络服务，在网上购买商品或进行同类型服务，在网上进行交易，非法使用网络服务或用户传送的信息有所变动。这些行为都有可能会导致本站的形象受损，所以本站事先提出这种损害的可能性。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">十、对用户信息的存储和限制</h2>
      <div class="p">
        本站不对用户所发布信息的删除或储存失败负责。本站有判定用户的行为是否符合本站服务条款的要求和精神的保留权利，如果用户违背了服务条款的规定，本站有中断对其提供网络服务的权利。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">十一、用户管理</h2>
      <div class="p">
        用户单独承担发布内容的责任。用户对服务的使用是根据所有适用于本站的国家法律、地方法律和国际法律标准的。用户必须遵循：
      </div>
      <div class="p">
        1. 从中国境内向外传输技术性资料时必须符合中国有关法规。
      </div>
      <div class="p">2. 使用网络服务不作非法用途。</div>
      <div class="p">3. 不干扰或混乱网络服务。</div>
      <div class="p">
        4.
        遵守所有使用网络服务的网络协议、规定、程序和惯例。用户须承诺不传输任何非法的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽等信息资料。
        另外，用户也不能传输任何教唆他人构成犯罪行为的资料；不能传输助长国内不利条件和涉及国家安全的资料；不能传输任何不符合当地法规、国家法律和国际法律的资料。
        未经许可而非法进入其它电脑系统是禁止的。
      </div>
      <div class="p">
        若用户的行为不符合以上提到的服务条款，本站将作出独立判断立即取消用户服务帐号。用户需对自己在网上的行为承担法律责任。用户若在本站上散布和传播反动、色情或其他违反国家法律的信息，本站的系统记录有可能作为用户违反法律的证据。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">十二、保障用户</h2>
      <div class="p">
        同意保障和维护本站全体成员的利益，负责支付由用户使用超出业务范围引起的律师费用，违反服务条款的损害补偿费用等。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">十三、结束服务</h2>
      <div class="p">
        用户或本站可随时根据实际情况中断一项或多项网络服务。本站不需对任何个人或第三方负责而随时中断服务。用户对后来的条款修改有
        异议，或对本站的服务不满，可以行使如下权利：
      </div>
      <div class="p">1. 停止使用本站的网络服务。</div>
      <div class="p">2. 通告本站停止对该用户的服务。</div>
      <div class="p">
        结束用户服务后，用户使用网络服务的权利马上中止。从那时起，用户有权利，本站也没有义务传送任何未处理的信息或未完成的服务给户或第三方。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">十四、通告</h2>
      <div class="p">
        所有发给用户的通告都可通过重要页面的公告或电子邮件或常规的信件传送。用户协议条款的修改、服务变更、或其它重要事件的通告都会以此形式进行。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">十五、参与广告策划</h2>
      <div class="p">
        用户在他们发表的信息中加入宣传资料或参与广告策划，在本站的免费服务上展示他们的产品，任何这类促销方法，包括运输货物、付款、服务、商业条件、担保及
      </div>
      <div class="p">
        与广告有关的描述都只是在相应的用户和广告销售商之间发生。本站不承担任何责任，本站没有义务为这类广告销售负任何一部分的责任。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">十六、网络服务内容的所有权</h2>
      <div class="p">
        本站定义的网络服务内容包括：文字、软件、声音、图片、录象、图表、广告中的全部内容；电子邮件的全部内容；本站为用户提供的其他信息。所有这些内容受版权、商标、标签和其它财产所有权法律的保护。所以，用户只能在本站和广告商授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。本站所有的文章版权归原文作者和本站共同所有，任何人需要转载本站的文章，必须征得原文作者或本站授权。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">十七、责任限制</h2>
      <div class="p">
        如因不可抗力或其他本站无法控制的原因使本站销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，本站不承担责任。但是本站会尽可能合理地协助处理善后事宜，并努力使客户免受经济损失。
      </div>
      <div class="p">
        除了本站的使用条件中规定的其它限制和除外情况之外，在中国法律法规所允许的限度内，对于因交易而引起的或与之有关的任何直接的、间接的、特殊的、附带的、后果性的或惩罚性的损害，或任何其它性质的损害，本站、本站的董事、管理人员、雇员、代理或其它代表在任何情况下都不承担责任。本站的全部责任，不论是合同、保证、侵权(包括过失)项下的还是其它的责任，均不超过您所购买的与该索赔有关的商品价值额。
      </div>
    </div>
    <div class="helpclause">
      <h2 class="title">十八、法律管辖和适用</h2>
      <div class="p">
        本协议的订立、执行和解释及争议的解决均应适用中国法律。
      </div>
      <div class="p">
        如发生本站服务条款与中国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它合法条款则依旧保持对用户产生法律效力和影响。
      </div>
      <div class="p">
        本协议的规定是可分割的，如本协议任何规定被裁定为无效或不可执行，该规定可被删除而其余条款应予以执行。
      </div>
      <div class="p">
        如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向本站所在地的人民法院提起诉讼。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },

  methods: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
.clause {
  font-size: 20px;
  color: #00ccff;
  text-indent: 2em;
  line-height: 30px;
}
.title {
  color: #808080;
  margin-top: 5px;
}
.p {
  margin-top: 5px;
  text-indent: 2em;
  font-size: 16px;
  color: #808080;
  line-height: 1.5;
  // padding-bottom: 15px;
}
.helpclause {
  margin-bottom: 15px;
}
</style>
