<template>
	<div class="swipers">
		<div class="swipersimg">
			<img src="../../static/images/bg.jpg" alt="" width="100%">
		</div>
		<div class="swiper-img">
			<div class="img-box1">
				<el-carousel :interval="4000" type="card" height="562px">
					<el-carousel-item class="item"  v-for="(item,index) in imgList" :key="index">
						<img :src="item.url" width="100%" alt="">
						<!-- <el-image  :src="item.url" :fit="fill"></el-image> -->
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imgList: [
					//图片路径不能写为这种形式
					//需要使用require获取
					{
						id: '1',
						url: require('../../static/images/bannerone.png'),
					},
					{
						id: '2',
						url: require('../../static/images/bannertwo.png')
					},
					{
						id: '3',
						url: require('../../static/images/bannerthree.png')
					}
				]
			}
		}
	}
</script>

<style lang="scss" scoped>
	.swipers {
		position: relative;
		margin-top: 50px;
		width: 100%;

		.swipersimg {
			width: 100%;
		}

		.swiper-img {
			// width: 1520px;
			position: absolute;
			bottom: 50px;
			left: 50%;
			transform: translate(-50%, 0);

			.swiper-wrapper {
				width: 1520px;
				margin: 0 auto;

				.swiper-slide {}
			}
		}

		.img-box1 {
			width: 780px;
		}
		
	}
</style>
<style scoped>
	
</style>