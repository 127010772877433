<template>
  <div>
    <h2 class="title">公司地址：山东省青岛市海尔路1号创牌大楼</h2>
    <div class="p">服务热线：4009-998-888</div>
    <div class="p">
      注：工作时间为周一至周日。法定节假日期间，工作人员在线时间会有所调整，请以商城公告为准
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
.title {
  color: #808080;
  margin-top: 5px;
}
.p {
  /* margin-top: 5px; */
  /* text-indent: 2em; */
  font-size: 16px;
  color: #808080;
  line-height: 1.8;
}
</style>