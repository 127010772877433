<template>
  <div class="register">
    <h2 class="title">兑换实物礼品</h2>
    <div class="p">
      海尔海贝商城提供包括海量家具日用、电器数码、生活母婴、户外旅游、汽车周边产品供您挑选，并且支持您使用海贝支付购买您心宜的商品和服务。
    </div>
    <h2 class="title">兑换虚拟礼品</h2>
    <div class="p">海贝商城为您提供多种虚拟商品。购买虚拟商品时验证码会发送至您的注册手机号上，通过验证后即可获得。</div>
    <h2 class="title">增值服务</h2>
    <div class="p">海贝商城内还提供了可由海贝兑换的商品抽奖、秒杀，产品延保，会员专享，清洗保养等增值服务。</div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },

  methods: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
.title {
  color: #808080;
  margin-top: 5px;
}
.p{
  margin-top: 5px;
  text-indent: 2em;
  font-size: 16px;
  color: #808080;
  line-height: 1.5;
}
</style>
