<template>
<div>
    <div class="collection-main">
        <div class="collection" v-for="(item, index) in list" :key="index" @click="goDetail(item.productId)">
            <div>
                <img class="cationimg" :src="item.pictureUrl" alt="" width="100%" />
            </div>
            <div>
                <div class="cationtitle">{{ item.chineseName }}</div>
                <div class="cationtext">{{ item.subTitle }}</div>
                <div class="cationintegral">{{ item.integralNum }}积分</div>
            </div>
        </div>
    </div>
    <div class="page">
        <el-pagination  background layout="prev, pager, next" :page-sizes="[5, 15, 25, 30]" :total="total" :page-size="10" @current-change="changePage"></el-pagination>
    </div>
</div>
</template>

<script>
import {
    collectListPage,
} from "@/api/api";
export default {
    data() {
        return {
            list: [],
            total: 0
        };
    },

    methods: {
        async getCollectListPage(pageNum) {
            let params = {
                pageNum: pageNum,
                pageSize: 10
            };

            const res = await collectListPage(params);
            if (res.code == "0000") {
                console.log(res)
                this.list = res.data.list;
                this.total = parseInt(res.data.total)
            } else {
                this.$message({
                    message: res.msg,
                    type: 'warning'
                });
            }
        },
        changePage(index) {
            this.getCollectListPage(index);
        },
        goDetail(id) {
            let routeUrl = this.$router.resolve({
                path: "/products/productDetails?id=",
                query: {
                    id: id
                }
            });
            window.open(routeUrl.href, '_blank');
        }
    },

    mounted() {
        this.getCollectListPage(1);
    },
};
</script>

<style lang="scss" scoped>
/deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #AC8C4F;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #AC8C4F;
    color: #fff;

}
.collection-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.collection {
    width: 49%;
    display: flex;
    //   flex-direction: column;
    justify-content: space-between;
    //   flex-wrap: wrap;
    box-sizing: border-box;
    border: 1px solid #666;
    margin-bottom: 20px;
    cursor: pointer;
}

.cationtitle {
    padding: 10px;
    color: #333;
}

.cationimg {
    width: 100px;
    height: 100px;
    display: block;
}

.cationtext {
    color: #999;
    padding-left: 10px;
}

.cationintegral {
    color: red;
    font-size: 18px;
    text-align: right;
    padding-top: 7px;
    padding-right: 5px;
}

.page {
    text-align: center;
}
</style>
