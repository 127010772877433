import { Request } from "@/config/request";

const order = "/jic-order"; // 订单模块路径
const product = "/jic-product"; // 商品模块路径
const user = "/jic-member"; // 个人信息模块路径
const trade = "/jic-order"; // 订单模块路径
const conf = "/jic-manager"; // 配置中心模块路径
const market = "/jic-market"; // 商城中心模块路径
const point = "/jic-point"; // 积分中心模块
const interaction = "/jic-interaction";

// 登录接口
export function doLogin(data = {}) {
  return Request(user + "/api/common/internal/memberLogin", {
    methods: "POST",
    data,
  });
}

// 获取手机验证码
export function sendIdentifyingCode(data = {}) {
  return Request(user + "/apiMember/getMessageCode", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 个人中心接口
export function queryMember(data = {}) {
  return Request(user + "/api/common/myInformation", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 商品（店铺）收藏列表
export function getFavoriteList(data = {}) {
  return Request(user + "/apiMyFavorite/getFavoriteList", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 店铺/商品-收藏/取消收藏 v.2.0
export function favorite(data = {}) {
  return Request(user + "/apiMyFavorite/editMyFavorite", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 查询积分数据
export function integralInfo(data = {}) {
  return Request(user + "/apiMember/getMemberInfo", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 查询积分分页
export function integralList(data = {}) {
  return Request(user + "/integralManagelog/queryMemberBaseList", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 查询用户收货地址
export function getAddressList(data = {}) {
  return Request(user + "/apiMyAddress/getAddressList", {
    methods: "POST",
    data,
  });
}

// 查询省市区地址            （此项请求数据拿，还未做处理）
export function getNationalAddress(data = {}) {
  return Request(user + "/apiSysProvinceCity/getProvinceCityList", {
    methods: "POST",
    data,
  });
}

// 新增收货地址(编辑)
export function saveAddress(data = {}) {
  return Request(user + "/apiMyAddress/updateAddress", {
    methods: "POST",
    data,
  });
}

// 删除用户收货地址
export function deleteAddress(data = {}) {
  return Request(user + "/apiMyAddress/deleteAddress", {
    methods: "POST",
    data,
  });
}

//获取脱敏手机号
export function getDesensitizedPhone(data = {}) {
  return Request(user + "/apiMember/getMemberMobileByToken", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 获取短信验证码
export function sendVerificationCode(data = {}) {
  return Request(user + "/apiMember/getMessageCode", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 修改手机号
export function changePhoneNumber(data = {}) {
  return Request(user + "/apiMember/updateMemberMobileByToken", {
    methods: "POST",
    data,
    type: "json",
  });
}

//判断用户是否设置过密码
export function judgePassworded(data = {}) {
  return Request(user + "/apiMember/getMemberSettingPasswordByToken", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 验证用户输入密码
export function checkCountPassword(data = {}) {
  return Request(user + "/apiMember/updateMemberPassWordByToken", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 我的评价列表页面
export function evaluateList(data = {}) {
  return Request(order + "/order/getOrderSensitiveList", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 评价详情页面
export function evaluateList1(data = {}) {
  return Request(product + "/apiProductComment/getCommentInfo", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 发表评论/追评
export function updateProductComment(data = {}) {
  return Request(product + "/apiProductComment/updateProductComment", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 获取首页配置数据
export function getIndexConf(data = {}) {
  return Request(conf + "/apiCmsModule/getIndexCmsConf", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 获取购物车列表
export function getCartList(data = {}) {
  return Request(trade + "/shopping-cart/query-shopping-cart", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 删除购物车中的商品
export function deleteCart(data = {}) {
  return Request(trade + "/shopping-cart/delete-shopping-cart", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 编辑购物车的数量
export function editNumber(data = {}) {
  return Request(trade + "/shopping-cart/editNum-shopping-cart", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 购物车选中接口
export function selectCart(data = {}) {
  return Request(trade + "/shopping-cart/select-settle-shopping-cart", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 购物车取消选中接口
export function unSelectCart(data = {}) {
  return Request(trade + "/shopping-cart/cancel-settle-shopping-cart", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 获取订单列表接口
export function getOrderList(data = {}) {
  return Request(order + "/order/queryAllList", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 订单确认收货
export function confirmShip(data = {}) {
  return Request(order + "/order/confirmShip", {
    methods: "POST",
    type: "json",
    data,
  });
}

// 取消订单
export function cancelOrder(data = {}) {
  return Request(order + "/order/close", {
    methods: "POST",
    type: "json",
    data,
  });
}

// 获取物流数据
export function getLogistics(data = {}) {
  return Request(order + "/order/getDeliveryByOrderNo", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 订单详情接口
export function getOrderDetail(data = {}) {
  return Request(order + "/order/queryDetail", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 申请售后接口
export function apply(data = {}) {
  return Request(order + "/afterSale/apply", {
    methods: "POST",
    type: "json",
    data,
  });
}

// 售后列表
export function afterList(data = {}) {
  return Request(order + "/afterSale/queryPageList", {
    methods: "POST",
    type: "json",
    data,
  });
}

// 售后详情
export function afterDetail(data = {}) {
  return Request(order + "/afterSale/query", {
    methods: "POST",
    type: "json",
    data,
  });
}

// 取消售后
export function cancelAfter(data = {}) {
  return Request(order + "/afterSale/cancel", {
    methods: "POST",
    type: "json",
    data,
  });
}

// 获取物流公司数据
export function getLogisticsList(data = {}) {
  return Request(order + "/afterSale/queryLogisticsCompanyList", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 拼团活动活动列表
export function getGroupThemeList(data = {}) {
  return Request(market + "/rest/groupon/queryGroupProductList", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 查询当前活动已开团未成团的列表
export function getCurrentJoinedGroupList(data = {}) {
  return Request(market + "/rest/groupon/getCurrentJoinedGroupList", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 秒杀活动商品列表
export function querySeckillProductList(data = {}) {
  return Request(market + "/rest/seckill/query-processing-goods-list-page", {
    methods: "POST",
    data,
    type: "json",
  });
}
// 即将开始的秒杀商品列表
export function queryAboutStartGoodsListPage(data = {}) {
  return Request(market + "/rest/seckill/query-about-start-goods-list-page", {
    methods: "POST",
    data,
    type: "json",
  });
}
// 未开始的秒杀商品列表
export function queryHasNotStartGoodsListPage(data = {}) {
  return Request(market + "/rest/seckill/query-has-not-start-goods-list-page", {
    methods: "POST",
    data,
    type: "json",
  });
}
// 查询我的优惠券
export function queryMyCoupon(data = {}) {
  return Request(market + "/rest/coupon/query-coupon-person", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 查询优惠券中心
export function queryCouponCenter(data = {}) {
  return Request(market + "/rest/coupon/query-coupon-center", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 领取优惠券
export function receiveCoupon(data = {}) {
  return Request(market + "/rest/coupon/receive-coupon-person", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 查询商品优惠券
export function queryProductCoupon(data = {}) {
  return Request(market + "/rest/coupon/queryProductCoupon", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 获取商品详情页面 推荐商品 24小时热销
export function getRecommendedProductList(data = {}) {
  return Request(product + "/productRest/getRecommendedProductList", {
    methods: "POST",
    data,
  });
}

// 获取商品详情
export function getProductInfo(data = {}) {
  return Request(product + "/productRest/queryProductDetail", {
    methods: "POST",
    data,
  });
}

// 搜索接口（参照H5首页数据）
export function getSearchList(data = {}) {
  return Request(product + "/productRest/getHomeProducts", {
    methods: "POST",
    data,
  });
}
// 查询我的拼团列表
export function getMyGroupList(data = {}) {
  return Request(market + "/rest/groupon/groupon-list", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 查询拼团详情
export function getCurrentJoinedGroupInfo(data = {}) {
  return Request(market + "/rest/groupon/getCurrentJoinedGroupInfo", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 首页数据
export function getModuleCmsConf(data = {}) {
  return Request(conf + "/cmsPageModule/getModuleCmsConf", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 秒杀
export function queryTimeSkillGoodsList(data = {}) {
  return Request(market + "/rest/seckill/query-processing-goods-list-page", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 获取商品详情sku数据
export function getProductsProperty(data = {}) {
  return Request(product + "/productRest/getProductsProperty", {
    methods: "POST",
    data,
  });
}

// 获取商品sku详情 getSkuList
export function getPropertyDetail(data = {}) {
  return Request(product + "/productRest/getSkuList", {
    methods: "POST",
    data,
  });
}

// 加入购物车
export function addCart(data = {}) {
  return Request(trade + "/shopping-cart/add-shopping-cart", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 商品收藏切换
export function toggleCollect(data = {}) {
  return Request(user + "/apiMyFavorite/editMyFavorite", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 查询菜单配置
export function getMenuConfiguration(data = {}) {
  return Request(product + "/categoryRest/queryBeforeSecondSonCategoryForPc", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 根据三级菜单查询该类目的商品
export function getMenuProduct(data = {}) {
  return Request(product + "/productRest/queryRestPorduct", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 根据一级类目查询所有三级数据
export function getAllMenu(data = {}) {
  return Request(product + "/categoryRest/queryLastCategory", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 查询购物车数量
export function userCartNum(data = {}) {
  return Request(trade + "/shopping-cart/query-goods-num", {
    methods: "POST",
    type: "json",
    data,
  });
}

// 查询充值卡列表
export function rechargeCardList(data = {}) {
  return Request(user + "/apiRechargeCard/getRechargeCardPageList", {
    methods: "POST",
    data,
    type: "json",
  });
}

//查询充值卡明细
export function rechargeCardRecordList(data = {}) {
  return Request(user + "/apiRechargeCard/getRechargeCardRecordPageList", {
    methods: "POST",
    data,
    type: "json",
  });
}

//新增充值卡
export function bindRechargeCard(data = {}) {
  return Request(user + "/apiRechargeCard/bindRechargeCard", {
    methods: "POST",
    data,
    type: "json",
  });
}

//下单商品
export function directSettle(data = {}) {
  return Request(trade + "/trade-settle/direct-settle", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 选择收货地址
export function tradeSettleAddress(data = {}) {
  return Request(trade + "/trade-settle/address", {
    methods: "POST",
    type: "json",
    data,
  });
}

// 提交订单
export function tradeSettleOrder(data = {}) {
  return Request(trade + "/trade-settle/order", {
    methods: "POST",
    type: "json",
    data,
  });
}

// 付款接口
export function pay(data = {}) {
  return Request(order + "/order/pay", { methods: "POST", type: "json", data });
}

// 购物车结算接口
export function shoppingCartSettlement(data = {}) {
  return Request(trade + "/trade-settle/settle", {
    methods: "POST",
    type: "json",
    data,
  });
}

// 购物车结算接口
export function addFeedback(data = {}) {
  return Request(user + "/feedback/editFeedBackInfo", {
    methods: "POST",
    type: "json",
    data,
  });
}

// 查询分销员基本信息
export function getDistributionUserInfo(data = {}) {
  return Request(user + "/apiDistribution/getDistributionUserInfo", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 累计明细
export function getCommissionListPage(data = {}) {
  return Request(user + "/apiDistribution/getCommissionListPage", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 累计明细
export function getWithdrawalListPage(data = {}) {
  return Request(user + "/apiDistribution/getWithdrawalListPage", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 我的团队
export function getMyTeam(data = {}) {
  return Request(user + "/apiDistribution/getMyTeam", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 我的推广二维码
export function queryDistributorShare(data = {}) {
  return Request(user + "/apiDistribution/queryDistributorShare", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 添加上级或者下级
export function addHigherOrLower(data = {}) {
  return Request(user + "/apiDistribution/addHigherOrLower", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 根据品牌id查
export function getClassProduct(data = {}) {
  return Request(product + "/mProduct/listPointsProduct", {
    methods: "POST",
    data,
    type: "json",
  });
}

//根据积分查
export function getIntegral(data = {}) {
  return Request(product + "/mProduct/listPointsProduct", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 积分卡充值
export function topPoint(data = {}) {
  return Request(point + "/api/point/query/activateCardPoint", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 热销商品列表
export function hotProduct(data = {}) {
  return Request(product + "/mHotProduct/listTopTenProduct", {
    methods: "POST",
    data,
    type: "json",
  });
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//海尔商城

//商品列表
export function getproductlist(data = {}) {
  return Request(
    product + "/productPcApi/listCommoditySpecificationParameters",
    { methods: "POST", data, type: "json" }
  );
}

//用户总积分查询
export function totalPoint(data = {}) {
  return Request(point + "/shoppingMall/user/userTotalPoint", {
    methods: "GET",
    data,
    type: "json",
  });
}

//用户积分明细
export function upperFindDataPoint(data = {}) {
  return Request(point + "/publMemberLog/getUserPointLog", {
    methods: "POST",
    data,
    type: "json",
  });
}
//订单分页列表
export function getOrdersList(data = {}) {
  return Request(trade + "/shoppingMall/order/orderList", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 物流信息
export function getLogisticsDetails(data = {}) {
  return Request(order + "/shoppingMall/order/selctHzyGigtExpressV2", {
    methods: "POST",
    data,
    type: "json",
  });
}

//商品评论
export function getProductCommentApi(data = {}) {
  return Request(product + "/mProductComment/queryByProductIdComment", {
    methods: "POST",
    data,
    type: "json",
  });
}

//商品详情
export function getProductDetails(data = {}) {
  return Request(product + "/mProduct/getByProductId", {
    methods: "POST",
    data,
    type: "json",
  });
}

//订单快照
export function orderSnapshotByOrderNo(data = {}) {
  return Request(order + "/feign/orderSnapshotByOrderNo", {
    methods: "GET",
    data,
    type: "json",
  });
}

//商品描述 2021-3-23 11:01:48 tzc
export function queryProductDescribe(data = {}) {
  return Request(product + "/productDescribe/queryProductDescribe", {
    methods: "POST",
    data,
    type: "json",
  });
}

//商品列表分页
export function queryPageHaierApi(data = {}) {
  return Request(product + "/productManagement/queryPageHaierByPointDesc", {
    methods: "POST",
    data,
    type: "json",
  });
}

//区域有无货
export function queryByProductIdAndSaleAreaIdApi(data = {}) {
  return Request(
    product + "/productSaleAreaRelation/queryByProductIdAndSaleAreaId",
    { methods: "POST", data, type: "json" }
  );
}

// 查询积分卡是否存在
export function getPointCardExit(data = {}) {
  return Request(point + "/api/point/query/getPointCardExit", {
    methods: "GET",
    data,
    type: "json",
  });
}

// 激活积分卡
export function activateCardPoint(data = {}) {
  return Request(point + "/api/point/query/activateCardPoint", {
    methods: "POST",
    data,
    type: "json",
  });
}

// 获取用户收货地址
export function searchUserAddrList(data = {}) {
  return Request(interaction + "/userAddress/casarte/searchUserAddrList", {
    methods: "GET",
    data,
    type: "json",
  });
}

// 获取用户默认收货地址
export function searchUserAddrDefault(data = {}) {
  return Request(interaction + "/userAddress/searchUserAddrDefault", {
    methods: "GET",
    data,
    type: "json",
  });
}

// 删除地址
export function deleteUserAddr(data = {}) {
  return Request(interaction + "/userAddress/casarte/deleteUserAddr", {
    methods: "POST",
    data,
    type: "json",
  });
}

//添加用户地址
export function addUserAddr(data = {}) {
  return Request(interaction + "/userAddress/casarte/addUserAddr", {
    methods: "POST",
    data,
    type: "json",
  });
}

//修改用户地址
export function updateUserAddr(data = {}) {
  return Request(interaction + "/userAddress/casarte/updateUserAddr", {
    methods: "POST",
    data,
    type: "json",
  });
}

//用户信息
export function getUserInfo(data = {}) {
  return Request(interaction + "/userRegister/casarte/getUserInfo", {
    methods: "GET",
    data,
    type: "json",
  });
}

//客服-用户问题新增
export function saveOrUpdateSysMemDialog(data) {
  return Request(conf + "/memDialogue/saveOrUpdate", {
    methods: "POST",
    data,
    type: "json",
  });
}

//首页菜单
export function queryBeforeCategory(data) {
  return Request(product + "/category/queryBeforeCategory", {
    methods: "POST",
    data,
    type: "json",
  });
}

//下单
export function haierCreateOrder(data) {
  return Request(order + "/feign/haierCreateOrderForeign", {
    methods: "POST",
    data,
    type: "json",
  });
}

//下单校验自然月短信验证码
export function haierOrderCount(data) {
  return Request(order + "/feign/haierOrderCount", {
    methods: "POST",
    data,
    type: "json",
  });
}
//审核资料

export function addToExamineImages(data) {
  return Request(order + "/feign/addToExamineImages", {
    methods: "POST",
    data,
    type: "json",
  });
}

//退换货
export function orderAfterAaleComplaintByPage(data) {
  return Request(order + "/feign/orderAfterAaleComplaintByPage", {
    methods: "POST",
    data,
    type: "json",
  });
}

//下单校验地区
export function haierOrderCheckArea(data) {
  return Request(order + "/feign/haierOrderCheckArea", {
    methods: "POST",
    data,
    type: "json",
  });
}

//下单校验库存
export function haierOrderCheckQuantity(data) {
  return Request(order + "/feign/haierOrderCheckQuantity", {
    methods: "POST",
    data,
    type: "json",
  });
}

//下单校验库存2
export function productStok(data) {
  return Request(interaction + "/productStock/productStock", {
    methods: "POST",
    data,
    type: "json",
  });
}

//获取首页cms配置数据
export function haierGetModuleCmsConf(data) {
  return Request(conf + "/apiCmsModule/getIndexCmsConf", {
    methods: "POST",
    data,
    type: "json",
  });
}

//海尔付款
export function haierOrderPay(data) {
  return Request(order + "/feign/haierOrderPay", {
    methods: "POST",
    data,
    type: "json",
  });
}

//查询省市区表
export function sysUserProvince(data) {
  return Request(conf + "/sysUserProvince/query", {
    methods: "POST",
    data,
    type: "json",
  });
}

//根据id查找商品详情
export function queryByProductId(data) {
  return Request(product + "/mProduct/queryByProductId", {
    methods: "POST",
    data,
    type: "json",
  });
}

//查询用户定制卡列表
export function getUserPointCard(data) {
  return Request(point + "/confPointCard/getUserPointCard", {
    methods: "POST",
    data,
    type: "json",
  });
}

//实物卡下单
export function placeAnOrder(data) {
  return Request(point + "/cardAndOrder/placeAnOrder", {
    methods: "POST",
    data,
    type: "json",
  });
}

//用户收藏分页列表
export function collectListPage(data) {
  return Request(user + "/mMemberCollect/listPage", {
    methods: "POST",
    data,
    type: "json",
  });
}

//添加收藏商品
export function collectSave(data) {
  return Request(user + "/mMemberCollect/saveOrUpdate", {
    methods: "POST",
    data,
    type: "json",
  });
}

//取消收藏商品
export function collectDelete(data) {
  return Request(user + "/mMemberCollect/delete", {
    methods: "POST",
    data,
    type: "json",
  });
}

//分类id查询列表
export function listBrandName(data) {
  return Request(product + "/productPcApi/listBrandName", {
    methods: "POST",
    data,
    type: "json",
  });
}

//确认收货
export function confirmReceipt(data) {
  return Request(order + "/feign/confirmReceipt", {
    methods: "POST",
    data,
    type: "json",
  });
}

//通知公告
export function sysNotice(data) {
  return Request(conf + "/sysNotice/queryList", {
    methods: "POST",
    data,
    type: "json",
  });
}

//订单到期事件
export function selHaierOrderOverdue(data) {
  return Request(order + "/feign/selHaierOrderOverdue", {
    methods: "POST",
    data,
    type: "json",
  });
}

//发表评论
export function mProductComment(data) {
  return Request(product + "/mProductComment/saveOrUpdate", {
    methods: "POST",
    data,
    type: "json",
  });
}

//获取token
export function getUserToken(data) {
  return Request(interaction + "/byTokenUserRegisterApi/casarte/getUserToken", {
    methods: "POST",
    data,
    type: "json",
  });
}

//加入购物车
export function haierOrderShoppingCart(data) {
  return Request(order + "/feign/haierOrderShoppingCart", {
    methods: "POST",
    data,
    type: "json",
  });
}
//购物车列表
export function selHaierShoppingCart(data) {
  return Request(order + "/feign/selHaierShoppingCart", {
    methods: "POST",
    data,
    type: "json",
  });
}
//购物车删除
export function haierDelShoppingCart(data) {
  return Request(order + "/feign/haierDelShoppingCart", {
    methods: "POST",
    data,
    type: "json",
  });
}

//短信发送接口
export function sendMsg(data) {
  return Request(interaction + "/public/communication/message/sendMsg", {
    methods: "POST",
    data,
    type: "json",
  });
}
//短信验证
export function verifyCodeMemberReceived(data) {
  return Request(
    interaction + "/public/communication/message/verifyCodeMemberReceived",
    { methods: "POST", data, type: "json" }
  );
}
export function signOut(data) {
  return Request(interaction + "/userAddress/signout", {
    methods: "GET",
    data,
    type: "json",
  });
}

// 校验单笔下单最大额度
export function getByRiskRule(data = {}) {
  return Request(point + "/moniRiskRule/getByRiskRule", {
    methods: "POST",
    data,
    type: "json",
  });
}
// 规格sku
export function AndProperty(data = {}) {
  return Request(product + '/mProduct/getSkuProductByProductIdAndProperty', { methods: 'POST', data, type: 'json' })
}
// 添加会员
export function addMember(data = {}) {
  return Request(user + "/api/user/addMemberForApp", {
    methods: "POST",
    data,
    type: "json",
  });
}
// 商品全局公告
export function queryNoticeByTime(data = {}) {
  return Request(conf + '/sysNotice/queryNoticeByTime', {
    methods: "POST",
    data,
    type: "json",
  });
}