<template>
	<div class="index-wrap">
		<div class="index-container">
			<top></top>
			<banner></banner>
			<Popular></Popular>
			<swipers></swipers>
			<codes></codes>
			<Sidebar></Sidebar>
			<!-- banner图 -->
			<!-- <div class="wrap" v-for="(item, index) in sortList" :key="index + '-1'" :style="{ order: item.sort }">
				<banner v-if="item.code == 'PC_CYCLE_PICTURE'"></banner>
			</div> -->
			
			<!-- 热门兑换 -->
			

			<!-- 轮播广告 促销信息轮播图 -->
			<!-- <div class="wrap" v-for="(item, index) in sortList" :key="index + '-2'" :style="{ order: item.sort }">
				<Popular v-if="item.code == 'PC_CYCLE_AD'" :variablesObject="item.variablesObject"></Popular>
			</div> -->


			<!-- 主题品牌 母婴保健 -->
			
		</div>
		<!-- 左侧分类 -->
		<!-- <classify></classify> -->
		<!-- 右侧贴士 -->
		<!-- <tip></tip> -->
	</div>
</template>

<script>
	import {
		getIndexConf,
		queryTimeSkillGoodsList,
		getModuleCmsConf,
	} from "@/api/api";
	
	import banner from "@/components/banner.vue";
	import classify from "@/components/classify";
	import banners from "@/components/indexComponents/banner";
	import infoSwipers from "@/components/indexComponents/infoSwiper";
	import mothers from "@/components/indexComponents/mother";
	// import makeups from "@/components/indexComponents/makeups";
	import favorites from "@/components/indexComponents/favorite";
	import protections from "@/components/indexComponents/protection";
	import kellys from "@/components/indexComponents/kelly";
	import seckills from "@/components/indexComponents/seckill";
	import imgs from "@/components/indexComponents/img";
	import tip from "@/components/tips";
	import top from "@/components/top";
	import Sidebar from "@/components/Sidebar";
	
	import codes from "@/components/codes";
	import swipers from "@/components/swipers";
	import Popular from "@/components/Popular";
	import json from "@/style/json/index2.json";
	import swiper from "@/components/haierIndexComponents/swiper.vue";
	import leftClassify from "@/components/leftClassify.vue";
	import score from "@/components/score.vue";

	export default {
		name: "index",
		components: {
			codes,
			Sidebar,
			// leftClassify,
			// classify,
			// banners,
			// mothers,
			// makeups,
			// favorites,
			// protections,
			// kellys,
			// seckills,
			// imgs,
			// tip,
			banner,
			// swiper,
			top,
			Popular,
			swipers,
		},

		data() {
			return {
				sortList: [],
				timeList: [],
				week: [{
						msg: "00:00-04:00"
					},
					{
						msg: "04:00-08:00"
					},
					{
						msg: "8:00-12:00"
					},
					{
						msg: "12:00-16:00"
					},
					{
						msg: "16:00-20:00"
					},
					{
						msg: "20:00-24:00"
					},
				],
			};
		},

		methods: {
			async init() {
				let sendData = {
					platform: 2,
				};
				const res = await getIndexConf(sendData);
				if (res.code === "0000") {
					this.getList(res.data.pageId);
				} else {
					this.$toast(res.msg);
				}
			},
			async getList(pageId) {
				let sendData = {
					platform: 2,
					pageId: pageId,
				};
				const res = await getModuleCmsConf(sendData);
				if (res.code === "0000") {
					let list = res.data.pageModuleResponseList;
					this.sortList = list;
				} else {
					this.$toast(res.msg);
				}
			},
			async datePicker() {
				let time = new Date().getHours(),
					startTime = "",
					endTime = "";
				this.week.forEach((e, index) => {
					let start = e.msg.split("-")[0].split(":")[0],
						end = e.msg.split("-")[1].split(":")[0];
					if (time < end && time >= start) {
						startTime = e.msg.split("-")[0];
						endTime = e.msg.split("-")[1];
					}
				});
				let year = new Date().getFullYear();
				let month = new Date().getMonth();
				let day = new Date().getDate();
				let sendData = {
					startTime: year +
						"-" +
						month.toString().padStart(2, "0") +
						"-" +
						day.toString().padStart(2, "0") +
						" " +
						startTime,
					endTime: year +
						"-" +
						month.toString().padStart(2, "0") +
						"-" +
						day.toString().padStart(2, "0") +
						" " +
						endTime,
					start: 1,
					limit: 10,
				};
				const res = await queryTimeSkillGoodsList(sendData);
				if (res.code === "0000") {
					this.timeList = res.data;
				} else {
					this.$toast(res.msg);
				}
			},
		},

		mounted() {
			this.init();
			this.datePicker();
		},
	};
</script>

<style lang="scss" scoped>
	.testImg {
		height: 390px;
		width: 1000px;
	}

	.index-wrap {
		width: 100%;
		height: auto;

		.index-container {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			flex-flow: column;
		}
	}
</style>
