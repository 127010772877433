<template>
  <div class="feedback-wrap">
      积分商城
  </div>
</template>
<script>
export default {
  name: "feedback",
  components: {

  },

  data() {
    return {
      
    };
  },

  methods: {
   
  },
  mounted() {

  }
};
</script>
<style lang="scss" scoped>
 
</style>