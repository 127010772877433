<template>
  <div class="addEvaluate">
    <ul>
      <!-- 第一次评论的数据展示 -->
      <li>
        <div class="goods">
          <img :src="lists.productImg" alt='#' />
          <p class="name">{{ lists.productName }}</p>
          <p class="price">{{orderDetails.actualPayTotalAmount|formatMoney}}</p>
        </div>
        <div class="form">
          <div class="isAppend mb20" v-if="isAppend">
            <!-- 时间先留存，暂时未调。 -->
            <p class="mb20 time">{{ orderDetails.createTime }}</p>
            <stars class="mb20" :isDisabled="isAppend" :evaluateNum="evaluateNum"></stars>
            <p v-if="evaluateNum == 0">真的差评</p>
            <p v-if="evaluateNum == 1">差评</p>
            <p v-if="evaluateNum == 2">一般差评</p>
            <p v-if="evaluateNum == 3">一般</p>
            <p v-if="evaluateNum == 4">刚拿到手就用了，感觉很好用，好评！</p>
            <p v-if="evaluateNum == 5">感觉非常很好用，好评好评！</p>
          </div>
          <div class="item" v-else>
            <p class="s1">商品评分</p>
            <div class="stars">
              <stars></stars>
            </div>
          </div>
          <div class="item mb20">
            <p class="s1" v-if="!isAppend">评价晒单</p>
            <div class="textarea">
              <!-- <textarea v-if="isAppend"></textarea> -->
              <div class="text">{{ lists.content }}</div>
            </div>
          </div>
          <div class="item">
            <p class="s1" v-if="!isAppend">评价晒图</p>
            <van-uploader v-model="fileList" multiple v-if="!isAppend" />
            <!-- 此处是查看评价详情的评价图片  目前数据没有 -->
            <div class="viewImg">
              <img
                :src="imgUrl"
                alt="#"
              />
            </div>
          </div>
        </div>
      </li>
      <!-- 追加的评论数据展示 -->
      <li v-if='addComments.length>0'>
        <div class="form">
          <div class="item mb20">
            <p class="s1" v-if="!isAppend">评价晒单</p>
            <div class="textarea">
              <div class="text">{{ addComments[0].content }}</div>
            </div>
          </div>
          <div class="item">
            <p class="s1" v-if="!isAppend">评价晒图</p>
            <van-uploader v-model="fileList" multiple v-if="!isAppend" />
            <!-- 此处是查看评价详情的评价图片  目前数据没有  假图片占位 -->
            <div class="viewImg">
              <img
                :src="imgUrl1"
                alt="#"
              />
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="btns">
      <button v-if="addComments.length>=1?false:true" @click="addReview">追加评论</button>
    </div>
  </div>
</template>

<script>
import stars from "@/components/star";
import { evaluateList1 } from "@/api/api";
export default {
  components: { stars },
  data() {
    return {
      isAppend: true, //是否为展示评价
      evaluateNum: 0, //第一次评价分数,追评不能评价分数。
      orderStatus: 10, //已评价
      orderDetails: {}, //订单详情
      id: "", //订单号
      goodsId: "", //商品id
      productInfo: {}, //商品信息
      lists: {}, //第一条评论内容
      addComments: [], //追加评论内容
      pid: "", //判断是否追评价
      fileList: [],
      imgUrl:"http://yanxuan.nosdn.127.net/8319d7be7b6c4f1654535aa9c80931b5.png?imageView&quality=65&thumbnail=330x330",
      imgUrl1:"http://yanxuan.nosdn.127.net/8319d7be7b6c4f1654535aa9c80931b5.png?imageView&quality=65&thumbnail=330x330",
    };
  },
  methods: {
    // 获取当前订单详情
    getOrderNo() {
      this.id = this.$route.query.orderNo;
      this.goodsId = this.$route.query.goodsId;
    },

    //获取当前商品评论信息
    async getComment() {
      const params = {
        start: 1,
        limit: 10,
        orderStatus: 10,
        productId:this.$route.query.goodsId,
        orderNo:this.$route.query.orderNo,
        id:this.$route.query.id,
      };
      const { data } = await evaluateList1(params);  
      // 筛选当前订单
      let object =data;
      // data.forEach((item, index) => {
      //   if (item.orderNo == this.id) {
      //     object = data[index];
      //   }
      // });
      // 获取追加评论的第一条信息
      this.addComments = object.additionalReviewList;
       this.fileList = object.fileList;
       if(this.fileList.length>0){
         this.imgUrl = this.fileList[0].url;
       }
       if(this.addComments.length > 0){
         if(this.addComments[0].fileList.length > 0){
           this.imgUrl1 = this.addComments[0].fileList[0].url;
         }
       }
      if (this.addComments.length <= 0) {
        this.isAppend = true;
      }
      this.orderDetails = object;
      // 筛选当前商品
      let projectObject = {};
      // object.goodsInfoList.forEach((item, index) => {
      //   if (item.goodsId == this.goodsId) {
      //     projectObject = object.goodsInfoList[index];
      //   }
      // });
      this.productInfo = projectObject;
      this.lists = object;
      this.pid = this.lists.id;
      this.evaluateNum = parseInt(this.lists.serviceRating);
      // this.evaluateNum = this.lists.productRating;
    },

    // 追加评论页面跳转和传值
    addReview() {
      this.$router.push({
        path: `/user/addEvaluate?orderNo=${this.id}&goodsId=${this.goodsId}&pid=${this.pid}`
      });
    }
  },
  mounted() {
    this.getOrderNo();
    this.getComment();
  }
};
</script>

<style lang="scss" scoped>
.addEvaluate {
  ul {
    padding: 20px 0;
    border: 1px solid #eaeaea;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 25px;
    li {
      display: flex;
      &:nth-of-type(2) {
        padding-left: 322px;
        margin-top: 15px;
        .form{
          border-top: 1px dashed #ccc;
        }
      }
      .goods {
        width: 322px;
        padding: 50px;
        text-align: center;
        border-right: 1px dashed #eaeaea;
        border-bottom: 1px dashed #eaeaea;
        img {
          width: 100px;
          height: 100px;
        }
        .name {
          line-height: 20px;
          color: #666;
        }
        .price {
          font-weight: bold;
          margin-top: 10px;
        }
      }
      .form {
        padding: 30px;
        .item {
          display: flex;
          .viewImg {
            width: 100px;
            height: 100px;
            margin: 0 auto;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
        .s1 {
          width: 100px;
          margin-bottom: 30px;
        }
        .textarea {
          width: 500px;
          padding: 10px;
          border: 1px solid #eaeaea;
          textarea {
            width: 100%;
            height: 50px;
            resize: none;
          }
          .text {
            width: 100%;
            height: 50px;
            resize: none;
          }
        }
      }
    }
  }
  .btns {
    text-align: center;
    button {
      width: 220px;
      height: 48px;
      line-height: 48px;
      background: $themeColor;
      color: #fff;
      font-size: 18px;
      margin: 30px auto;
    }
  }
  .isAppend {
    .time {
      color: #999;
    }
  }
}
</style>