<template>
<div style="">
    <div class="personal-main">
        <div class="personal">
            <div class="personalimg">
                <img class="img" :src="headurl" alt="" />
            </div>
            <div>
                <div style="display:flex;padding-top: 10px;">
                    <img style="width:18px;margin-right:5px;" :src="require('@/assets/images/zhanghao.png')" alt="">
                    <div class="title">{{username}}</div>

                </div>
                <div class="integral">
                    <img style="width:20px;margin-right:5px;" :src="require('@/assets/images/ji.png')" alt="">

                    <span>{{totalPoint}}积分</span>
                </div>
            </div>

        </div>
        <!-- <div class="date">注册日期：<span>{{createTime}}</span></div> -->
        <div @click="haier()">
            <a style="color: #AC8C4F;" href="https://user.haier.com/HaierFramework/haier/appuser/vipUser/vipUserInfo.jsp" target="_blank">修改信息</a>
        </div>
    </div>
    <div class="orderlist-main">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="所有积分"></el-tab-pane>
            <el-tab-pane label="赚取积分" name="1"></el-tab-pane>
            <el-tab-pane label="使用积分" name="2"></el-tab-pane>
        </el-tabs>
        <el-table :data="List">
            <el-table-column prop="createTime" label="操作时间"></el-table-column>
            <!-- <el-table-column prop="pastDueTime" label="过期时间"></el-table-column> -->
            <el-table-column prop="amount" label="海贝数"></el-table-column>
            <el-table-column prop="description" label="备注"></el-table-column>
            <!-- <el-table-column prop="cardType" label="实体卡状态" width="180"></el-table-column> -->
            <!-- <el-table-column label="操作" width="180">
                    <template slot-scope="scope" v-if="type == '2'">
                        <el-button size="mini" @click="handleEditProduct(scope.$index)">下单</el-button>
                    </template>
                </el-table-column> -->
        </el-table>
        <div class="paging">
            <el-pagination background layout="prev, pager, next" :page-sizes="[5, 15, 25, 30]" :total="total" :page-size="10" @current-change="changePage"></el-pagination>
        </div>
    </div>
</div>
</template>

<script>
import {
    getUserInfo,
    totalPoint,
    upperFindDataPoint
} from '@/api/api';
export default {
    data() {
        return {
            activeName: "0",
            List: [],
            total: 0,
            type: "0",
            username: "",
            createTime: null,
            headurl: null,
            totalPoint: null
        };
    },

    methods: {
        orderListInit() {
            //获取所有账单明细
            this.upperFindDataPoint(this.type, 1);
        },
        handleClick(targetName, action) {
            this.type = targetName.paneName;
            this.upperFindDataPoint(targetName.paneName)
        },
        changePage(index) {
            this.upperFindDataPoint(this.type, index);
        },
        async getUserInfo() {
            let params = {}
            let userInfo = await getUserInfo(params);
            this.username = userInfo.data.nickname
            this.createTime = userInfo.data.createTime
            this.headurl = userInfo.data.headurl
        },
        async getTotalPoint() {
            let paramss = {}
            let totalRes = await totalPoint(paramss)
            if (totalRes.code == "0000") {
                this.totalPoint = totalRes.data
            }
        },
        async upperFindDataPoint(type, page) {
            let params = {
                // type:type,
                pageNum: page,
                pageSize: "10",
                thirdUserId: localStorage.getItem("memberId")
            }
            if (type != "0") {
                params.type = type
            }
            let res = await upperFindDataPoint(params)
            if (res.code == "0000") {
                this.List = res.data.list
                this.total = parseInt(res.data.total);

            }
        },
        // haier() {
        //     let routeUrl = this.$router.resolve({
        //         window.location.href = "https://user.haier.com/HaierFramework/haier/appuser/vipUser/vipUserInfo.jsp"
        //     });
        //     window.open(routeUrl.href, '_blank');
        // }
    },

    mounted() {
        this.getUserInfo();
        this.getTotalPoint();
        this.orderListInit()
    },
};
</script>

<style lang="scss" scoped>
/deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #AC8C4F;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #AC8C4F;
    color: #fff;

}

/deep/.el-tabs__active-bar {
    background-color: #AC8C4F;

}

/deep/.el-tabs__item.is-active {
    color: #AC8C4F;
}

/deep/.el-tabs__item:hover {

    color: #AC8C4F;
}
.paging {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.personal-main {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;

    .personal {
        display: flex;

        .personalimg {
            margin-right: 10px;

            .img {
                width: 78px;
                height: 78px;
                border-radius: 78px;
            }
        }
    }
}

.title {
    color: #333;
    font-size: 14px;
}

.integral {
    display: flex;
    padding-top: 10px;

    span {
        color: #F63140;
        font-size: 14px;
    }
}

.date {
    color: #999;
    padding-top: 10px;
    padding-bottom: 10px;
}
</style>
