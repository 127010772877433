<template>
  <div class="kelly" v-if="variablesObject">
    <titles :title="variablesObject"></titles>
    <div class="carousel">
      <swipers :list="list" class="kelly-btn"></swipers>
    </div>
  </div>
</template>
<script>
import titles from "@/components/indexComponents/title";
import swipers from "@/components/detailSwiper.vue";
export default {
  components: { titles, swipers },
  props: {
    variablesObject: {
      type: Object,
      default: () => {
        return {};
      }
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
.kelly {
  width: 1200px;
  margin: 0 auto 30px;
  .carousel {
    border: 3px solid #2283E2;
    border-radius: 10px;
  }
}
</style>