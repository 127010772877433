<template>
    <div class="selective-main">
        <div class="main">
            <div style="display:flex;">
                <div class="main-title">居家生活</div>
                <div class="sub-title"><img src="@/assets/images/zan.png" alt="" />最新爆款推荐</div>
            </div>
            <div style="display:flex;align-items: center;">
                <div class="menu-list">
                    <span class="menu-item" :class="menuIndex == index ? 'menu-item-selected' : ''" @click="menuIndex = index" v-for="(item, index) in cmsData" :key="index">{{ item.name }}</span>
                </div>
                <!-- <div class="more">
                    <span>更多</span>
                    <img src="@/assets/images/gengduo.png" alt="" />
                </div> -->
            </div>
            <!-- <div style="color:#999999;padding-bottom: 2px;">更多 ></div> -->
        </div>

        <div class="selective-list">
            <div class="selective-item" v-for="(item,index) in productList[menuIndex]" :key="index" @click="detail(item.id)">
                <el-image :src="item.pictureUrl"></el-image>
                <!-- <img class="item-img" :src="require('@/assets/images/youxuan.png')" alt=""> -->

                <div class="item">
                    <span class="item-title">{{ item.chineseName }}</span>
                    <div class="item-price">
                        <span class="price-fav">{{ item.integralNum }} 海贝</span>
                        <!-- <span class="price-cost">99999 海贝</span> -->
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {
    queryByProductId
} from "@/api/api";
export default {
    components: {},
    props: ["cmsData"],
    data() {
        return {
            productList: [],
            menuIndex: 0,
        };
    },

    methods: {
        detail: function (id) {
            let routeUrl = this.$router.resolve({
                path: "/products/productDetails?id=",
                query: {
                    id: id
                }
            });
            window.open(routeUrl.href, '_blank');
        },
        async queryProduct() {
            for (let index = 0; index < this.cmsData.length; index++) {

                let tempList = [];
                this.cmsData[index].preferenceList.map(value => {
                    tempList.push({
                        id: value.id
                    })
                })
                let params = {
                    list: tempList
                }
                const res = await queryByProductId(params);
                this.productList.push(res.data);
            }
        }
    },

    mounted() {
        this.queryProduct();
    },
};
</script>

<style lang="scss" scoped>
/deep/.el-image{
    width: 150px;
    height:150px;
}
.main {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-end;
    justify-content: space-between;
    // border-bottom: 1px solid #2183E2;

    .main-title {
        width: 96px;
        height: 24px;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
        margin-right: 20px;
    }

    .sub-title {
        // width: 104px;
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 24px;

        img {
            margin-right: 8px;
        }
    }

    .more {
        display: flex;
        align-items: center;
        width: 54px;
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        float: right;
        cursor: pointer;

        img {
            margin-left: 4px;
            vertical-align: sub;
        }
    }
}

.item-img {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 0;
    left: 0;
}

.item {
    width: 100%;
    padding: 8px 0;
    // background: #eff7ff;

    .item-title {
        font-size: 14px;
        // text-align: center;
        display: block;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 5px;
        margin-bottom: 5px;
    }

    .item-price {
        flex: 1;
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        // justify-content: space-evenly;

        .price-fav {
            // border: 1px solid #ed2856;
            color: #4B89F8;
            font-size: 15px;
            padding: 2px 5px;
            border-radius: 20px;
        }

        .price-cost {
            text-decoration: line-through;
        }
    }
}

.selective-main {
    background: #fff;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding:  20px;

    .title-text {
        display: block;
        // line-height: 50px;
        padding: 10px 20px 20px 0;
        font-size: 26px;
        font-weight: bold;
    }

    .menu-list {
        // width: 100%;
        // height: 50px;
        flex-direction: row;

        .menu-item {
            display: inline-block;
            margin-right: 40px;
            font-size: 16px;
            color: #666666;
            // height: 50px;
            // line-height: 50px;
            text-align: center;
        }

        .menu-item:hover {
            cursor: pointer;
            color: #4B89F8;
        }

        .menu-item-selected {
            // border-bottom: 2px solid #2283e2;
            color: #4B89F8;
        }
    }

    .selective-list {
        margin-top: 20px;
        // height: 330px;
        display: flex;
        flex-direction: row;

        .selective-item {
            width: 16%;
            margin: 0 20px;
            transition: box-shadow 0.3s linear;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;


        }

        .selective-item:hover {
            box-shadow: 0 3px 8px rgba($color: #000000, $alpha: 0.3);
            transition: box-shadow 0.3s linear;
        }
    }
}
</style>
