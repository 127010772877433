<template>
<div class="submit-main">
    <div style="padding:20px;width: 1200px;
    margin: 0 auto;">
        <!-- 地址选择 -->
        <div class="submit-address">
            <span class="address-title">选择收货地址</span>
            <!-- 地址列表 -->
            <div class="address-list">
                <div class="address-item-border" @click="addressIndex = index" v-for="(item, index) in address" :key="index">
                    <div class="address-item" :class="index == addressIndex ? 'address-item-selected' : ''">
                        <div class="address-name">
                            <span>{{ item.receiver_name }}</span>
                            <span>{{ item.receiver_mobile }}</span>
                            <span class="address-default" v-if="item.is_default == '1'">默认地址</span>
                        </div>
                        <div class="address-address">
                            {{
                item.address.province +
                "  " +
                item.address.city +
                "  " +
                item.address.district +
                "  " +
                item.address.line1
              }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="address-manager">
                <div class="manager-new" @click="openAddAddressDialog()">使用新地址</div>
                <div class="manager-edit" @click="goAddressEdit()">地址管理</div>
            </div>
        </div>
        <!-- 订单明细 -->
        <div class="submit-details">
            <span class="address-title">确认订单信息</span>
            <el-table :data="productList" style="width: 100%">
                <el-table-column prop="cardNo" label="卡号"> </el-table-column>
                <el-table-column prop="productName" label="商品名称"> </el-table-column>
                <el-table-column prop="num" label="数量" width="190px">
                </el-table-column>
                <el-table-column prop="point" label="小记" width="140px">
                </el-table-column>
            </el-table>
            <div class="details-remarks">
                <span>给商家留言:</span>
                <el-input class="remarks-textarea" type="textarea" placeholder="请输入给商家的留言..." v-model="mark" maxlength="200" show-word-limit:autosize="{ minRows: 1, maxRows: 2}">
                </el-input>
            </div>
            <div class="details-remarks" v-if="this.OrderCount< 1">
                <span>请输入验证码</span>
                <div><input class="tui-input" type="number" v-model="code"></div>
                <el-button :disabled="disabled" @click="sendcode" class="huoqu">{{vocode}}</el-button>
            </div>
            <div class="details-total">
                <span>总计:</span>
                <span class="details-total-point">定制卡支付</span>
            </div>
        </div>
        <!-- 汇总提交 -->
        <div class="order-submit">
            <div class="submit-details">
                <div class="submit-point">
                    <div>
                        <span class="submit-point-title">我的海贝：</span>
                        <span class="submit-point-my">{{userPoint}}</span>
                    </div>
                    <div>
                        <span class="submit-point-title">订单合计：</span>
                        <span class="submit-point-number">定制卡支付</span>
                    </div>
                </div>
                <div class="order-submit-address" v-if="address.length!=0">
                    寄送至：{{ address[addressIndex].address.province }}
                    {{ address[addressIndex].address.city }}
                    {{ address[addressIndex].address.district }}
                    {{ address[addressIndex].address.line1 }}
                </div>
            </div>
            <div class="submit-button" @click="commitOrder()">提交订单</div>
        </div>

        <!-- 新增地址 -->
        <el-dialog title="收货地址" :visible.sync="dialogFormVisible">
            <div class="add-main">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="地址信息:" prop="addressList">
                        <div class="add-item">
                            <!-- <span class="add-title"></span> -->
                            <el-cascader ref="cascaderAddr" size="large" :options="options" v-model="ruleForm.addressList">
                            </el-cascader>
                        </div>
                    </el-form-item>
                    <el-form-item label="详细地址:" prop="addressDetails">
                        <div class="add-item">
                            <!-- <span class="add-title"></span> -->
                            <el-input validate-event type="textarea" :rows="2" placeholder="请输入详细地址" v-model="ruleForm.addressDetails" style="width: 300px">
                            </el-input>
                        </div>
                    </el-form-item>
<!-- 
                    <el-form-item label="邮政编码:" prop="postalCode">
                        <div class="add-item">
                            <el-input v-model="ruleForm.postalCode" placeholder="请输入邮政编码" style="width: 300px"></el-input>
                        </div>
                    </el-form-item> -->
                    <el-form-item label="姓名:" prop="name">
                        <div class="add-item">
                            <!-- <span class="add-title"></span> -->
                            <el-input v-model="ruleForm.name" placeholder="请输入姓名" style="width: 300px"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="手机号:" prop="phone">
                        <div class="add-item">
                            <!-- <span class="add-title"></span> -->
                            <el-input v-model="ruleForm.phone" type="number" placeholder="请输入手机号" style="width: 300px"></el-input>
                        </div>
                    </el-form-item>
                    <!-- <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item> -->
                </el-form>

            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog()">取 消</el-button>
                <el-button type="primary" @click="addAddressHandel('ruleForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>

</div>
</template>

<script>
import {
    sysUserProvince,
    haierCreateOrder,
    haierOrderCheckArea,
    haierOrderCheckQuantity,
    placeAnOrder,
    searchUserAddrList,
    searchUserAddrDefault,
    addUserAddr,
    sendMsg,
    verifyCodeMemberReceived,
    haierOrderCount,
    totalPoint
} from "@/api/api";
export default {
    data() {
        return {
            disabled: false,
            OrderCount: "",
            vocode: "发送验证码",
            code: null,
            options: "", //省市区数据
            productList: [], //商品列表
            userPoint: 0,
            mark: "",
            address: [],
            addressIndex: 0,
            dialogFormVisible: false, //新增地址弹窗标识
            ruleForm: {
                addressDetails: "", //详细地址
                // postalCode: "",
                name: "",
                phone: "",
                addressList: [],
            },
            rules: {
                name: [{
                    required: true,
                    message: '请输入名字',
                    trigger: 'blur'
                }],
                addressDetails: [{
                    required: true,
                    message: '请输入详细地址',
                    trigger: 'blur'
                }],
                phone: [{
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur"
                    },
                    {
                        min: 11,
                        max: 11,
                        message: "请输入11位正确手机号码",
                        trigger: "blur"
                    },
                    {
                        pattern: /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/,
                        message: "请输入正确的手机号码",
                        trigger: "blur"
                    }
                ],
                // postalCode: [{
                //     required: true,
                //     message: '请输入邮政编码',
                //     trigger: 'blur'
                // }, {
                //     pattern: /^[0-8][0-7]\d{4}$/,
                //     message: "请输入正确的邮政编码",
                //     trigger: "blur"
                // }, ],
                addressList: [{
                    required: true,
                    message: '请选择地址',
                    // trigger: 'blur'
                }]
            },
        };
    },

    methods: {
        sendcode() {
            this.totaltime = 60;
            this.timer();
            this.sendMsg();
        },
        timer() {
            if (this.totaltime > 0) {
                this.disabled = true;
                this.totaltime--
                this.vocode = this.totaltime + 's后重新发送'
                setTimeout(this.timer, 1000);
            } else {
                this.totaltime = 0;
                this.vocode = "重新发送验证码";
                this.disabled = false;
                console.log("1212")
            }

        },
        orderInit() {
            this.productList = this.$route.query.productList; //获取路由参数
            this.productList[0].num = "1";
            this.productList[0].point = "定制卡支付";
        },

        //发送验证码
        async sendMsg() {
            const param = {
                memberId: localStorage.getItem("memberId"),
                msgParameter: {
                    code: this.code
                },
                phoneNo: localStorage.getItem("memberPhone"),
                sysMessageTemplateId: 1,
            };
            const res = await sendMsg(param);
            if (res.code == "0000") {
                this.$toast("发送成功");
            }
        },

        //自然月短信校验
        async haierOrderCount() {
            let params = {
                buyerId: localStorage.getItem("memberId")
            };
            let res = await haierOrderCount(params);
            if (res.code == "0000") {
                this.OrderCount = res.data;
                console.log(this.OrderCount)
            }
        },
        async upperFindTotalPoint() {
            //获取总积分
            let params = {}
            let totalRes = await totalPoint(params)
            if (totalRes.code == "0000") {
                this.userPoint = totalRes.data;
            }
        },

        //查询用户地址列表
        async searchUserAddrList() {
            let params = {};
            let res = await searchUserAddrList(params);
            if (res.code == "0000") {
                if (res.data[0] == null) {
                    this.address = [];

                } else {
                    this.address = res.data;
                }
            }
        },
        //查询用户默认地址列表
        async searchUserAddrDefaults() {
            let params = {};
            let pointCard = await searchUserAddrDefault(params);
            // let list = pointCard.data;
            // this.address = list;
        },

        //提交订单
        async commitOrder() {
            //检验地区
            let params = {
          
                productId: this.productList[0].id!=null?this.productList[0].id:this.productList[0].productId, //商品ID
                provinceId: this.address[this.addressIndex].address.province_id, //省份id
                cityId: this.address[this.addressIndex].address.city_id, //市级id
                areaId: this.address[this.addressIndex].address.district_id, //区县id
            };
            // if(this.productList[0].id==null){
            //     params.productId = this.productList[0].productId
            // }
            let res = await haierOrderCheckArea(params);

            if (res.code != "0000") {
                this.$message(res.msg);
                return;
            }
            if (this.address.length == 0||this.address[this.addressIndex].address.province_id==null||this.address[this.addressIndex].address.district_id==null||this.address[this.addressIndex].address.city_id==null) {
        this.$toast("地址信息不完整，请重新录入");
        return false;
      }

            //验证验证码
            if (this.OrderCount < 1) {
                params = {
                    key: localStorage.getItem("memberPhone") + "-" + 1 + "-" + this.code,
                    code: this.code,
                };
                res = await verifyCodeMemberReceived(params);
                if (res.code == "0000") {
                    // this.$toast("验证码正确");
                } else if (this.code == "") {
                        this.$toast("验证码为空");
                        return false;

                    } else {
                        this.$toast("验证码错误");
                        return false;

                    }
            }

            params = {
                // cardPassword: this.productList[0].cardPassword,
                memberId: localStorage.getItem("memberId"),
                cardNo: this.productList[0].cardNo, //积分卡号， 必
                //type: "", //订单类型
                productId: this.productList[0].productId, //兑换产品ID， 必
                //orderTerminal: "", //下单终端
                orderRemark: this.mark, //订单留言， 必
                cardPassword: this.productList[0].cardPassword, //订单留言， 必

                //userId: "", //下单用户ID
                num: "1", //购买数量，1,必
                productName: this.productList[0].productName, //商品名称， 必
                //userName: "", //用户名称
                recipientsName: this.address[this.addressIndex].receiver_name, //收货人姓名， 必
                recipientsAddr: this.address[this.addressIndex].address.line1, //收货详细地址， 必
                cityCode: this.address[this.addressIndex].address.city_id, //市区的编码， 必
                city: this.address[this.addressIndex].address.city, //市区名称， 必
                province: this.address[this.addressIndex].address.province, //省名称， 必
                provinceCode: this.address[this.addressIndex].address.province_id, //省编码， 必
                areaCode: this.address[this.addressIndex].address.district_id, //区/县编码， 必
                area: this.address[this.addressIndex].address.district, //区/县名称， 必
                recipientsPhone: this.address[this.addressIndex].receiver_mobile, //收货人电话， 必
            };

            res = await placeAnOrder(params);
            if (res.code == "0000") {
                this.$message("下单成功！");
                this.$router.push({
                    path: "/centre/ordersList",
                });
            } else {
                this.$message(res.msg);
            }
        },
        goAddressEdit() {
            const routerUrl = this.$router.resolve({
                path: "/centre/addressManager"
            });
            window.open(routerUrl.href, "_blank");
        },
        cleanData() {
            this.ruleForm.addressDetails = ""
            // this.ruleForm.postalCode = "";
            this.ruleForm.name = "";
            this.ruleForm.phone = "";
            this.ruleForm.addressList = [];
        },
        //添加地址
        async addAddressHandel(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.AddAddress();
                } else {
                    console.log('error submit!!');

                    // return false;
                }
            });
        },
        async AddAddress() {
            let labelList = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels;
            let addressObj = {
                receiver_name: this.ruleForm.name, //用户名
                receiver_mobile: this.ruleForm.phone, //手机号
                address: {
                    line1: this.ruleForm.addressDetails,
                    province_id: this.ruleForm.addressList[0],
                    province: labelList[0],
                    city_id: this.ruleForm.addressList[1],
                    city: labelList[1],
                    district_id: this.ruleForm.addressList[2],
                    district: labelList[2],
                    // postcode: this.ruleForm.postalCode,
                },
                is_default: 0,
                source: 1,
                is_service: 0,
            };

            let params = {
                param: JSON.stringify(addressObj),
            };
            let res = await addUserAddr(params);
            if (res.code == "0000") {
                this.dialogFormVisible = false;
                this.searchUserAddrList();
            }
        },
        //取消添加
        cancelDialog() {
            this.dialogFormVisible = false;
            this.cleanData();
            this.searchUserAddrList();
            this.$router.go(0)
        },
        //获取省市区列表
        async sysUserProvince() {
            let params = {};
            const res = await sysUserProvince(params);
            if (res.code === "0000") {
                this.options = res.data;
            } else {
                this.$toast(res.msg);
            }
        },
        openAddAddressDialog() {
            this.dialogFormVisible = true;
        }
    },

    mounted() {
        this.sysUserProvince();
        this.orderInit();
        this.searchUserAddrList();
        this.haierOrderCount();
        this.upperFindTotalPoint();

    },
};
</script>

<style lang="scss" scoped>
/deep/.el-button--primary:focus, .el-button--primary:hover{
    background-color: #AC8C4F;
    border-color: #AC8C4F;
}
/deep/.el-button--primary {
    background-color: #AC8C4F;
    border-color: #AC8C4F;
}

.tui-input {
    border: 1px solid #999;
    width: 100px;
    height: 30px;
    margin-right: 20px;
}

.submit-main {
    height: auto;
    width: 100%;
    background:#fff;
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    .address-title {
        font-size: 14px;
        font-weight: bold;
    }
}

.submit-address {
    display: flex;
    flex-direction: column;

    .address-list {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 7px;

        .address-item-border {
            width: 25%;
            height: 120px;

            .address-item {
                margin: 0 7px;
                height: 100%;
                border: 6px solid #eee;
                cursor: pointer;

                .address-name {
                    height: 30px;
                    border-bottom: 1px solid #f3f3f3;

                    span {
                        line-height: 30px;
                        margin-left: 10px;
                        font-weight: bold;
                    }

                    .address-default {
                        float: right;
                        color: #AC8C4F;
                        margin-right: 10px;
                    }
                }

                .address-address {
                    padding: 10px;
                }
            }

            .address-item-selected {
                border: 6px solid #AC8C4F;
            }
        }
    }

    .address-manager {
        width: 100%;
        height: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;

        .manager-new {
            height: 30px;
            width: 95px;
            border: 1px solid #c7c9d2;
            border-radius: 4px;
            line-height: 30px;
            text-align: center;
            cursor: pointer;
        }

        .manager-edit {
            color: #d6b777;
            font-weight: bold;
            cursor: pointer;
        }
    }
}

.submit-details {
    margin-top: 40px;

    .details-remarks {
        margin-top: 20px;
        margin-left: 10px;
        display: flex;
        flex-direction: row;

        span {
            margin-right: 12px;
            font-size: 14px;
        }

        .remarks-textarea {
            width: 300px;
        }
    }

    .details-total {
        width: 100%;
        height: 50px;
        line-height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 20px;
        font-size: 14px;

        .details-total-point {
            color: #AC8C4F;
            font-weight: bold;
            margin-left: 6px;
        }
    }
}

.order-submit {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .submit-details {
        width: 400px;
        height: 125px;
        padding: 10px;
        border: 2px solid #AC8C4F;
        display: flex;
        flex-direction: column;

        .submit-point {
            height: 30px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            div {
                margin-left: 40px;

                .submit-point-title {
                    font-weight: bold;
                }

                .submit-point-number {
                    font-weight: bold;
                    font-size: 20px;
                    color: #AC8C4F;
                }

                .submit-point-my {
                    font-weight: bold;
                    font-size: 24px;
                    color: #ed2856;
                }
            }
        }

        .order-submit-address {
            flex: 1;
            display: flex;
            color: #333;
            font-weight: bold;
            align-items: center;
        }
    }

    .submit-button {
        width: 180px;
        height: 40px;
        background-color: #AC8C4F;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
    }
}
</style>
