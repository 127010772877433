<template>
  <div class="listIndex main-container">
    <div class="tabSearch">
      <groups :list="list"></groups>
    </div>
  </div>
</template>
<script>
import groups from "@/components/group";
export default {
  name: "listIndex",
  components: { groups },
  data() {
    return {
      active: 0, //当前显示tab索引
      //当前tab数组
      tabsArray: [
        {
          title: "已结束",
        },

        {
          title: "进行中",
        },
        {
          title: "未开始",
        },
      ],
      //数据列表
      list: [
        {
          //图片
          img:
            "http://yanxuan.nosdn.127.net/8319d7be7b6c4f1654535aa9c80931b5.png?imageView&quality=65&thumbnail=330x330",
          title: "多功能私享按摩椅", //标题
          desc: "选款师力荐 专属私人按摩师", //描述
          nowPrice: "1699", //当前价格
          oldPrice: "2099" //之前价格价格
        },
        {
          img:
            "//img13.360buyimg.com/n7/jfs/t1/106014/23/19054/371173/5e9990a8E7282c4e9/8fce999353feeb18.jpg",
          title: "美的MJ-PB80Easy215",
          desc:
            "美的（Midea）京品家电破壁机家用多功能榨汁机果汁机辅食机绞肉馅机3.6万转可预约破壁料理机MJ-PB80Easy215",
          nowPrice: "599",
          oldPrice: "2099"
        },
        {
          img:
            "http://yanxuan.nosdn.127.net/3692d2c8a87b549238219ef454fcf39f.png?imageView&thumbnail=430x430&quality=95",
          title: "克拉斯克系列装饰画 6件装",
          desc: "提升格调，展现独特抽象美学",
          nowPrice: "99",
          oldPrice: "199"
        },
        {
          img:
            "http://yanxuan.nosdn.127.net/67dfbb78dbf56ae38841314ad33274e8.png?imageView&thumbnail=430x430&quality=95",
          title: "东阿阿胶片 30克*8片",
          desc: "传统工艺 质地细密",
          nowPrice: "1400",
          oldPrice: "1499"
        },
        {
          img:
            "http://yanxuan.nosdn.127.net/5325d6170b0fb885c8f5f1b3528811e2.png?imageView&quality=65&thumbnail=330x330",
          title: "女式优雅羊毛连衣裙",
          desc: "全羊毛针织呢面，繁复精工给你流畅垂繁复精工给你流畅垂",
          nowPrice: "999",
          oldPrice: "1099"
        },
        {
          img:
            "https://yanxuan.nosdn.127.net/542658e715c61a14fc09596ea08edf4f.png?imageView&quality=95&thumbnail=245x245",
          title: "法国雨露棉麻合体版男式衬...",
          desc: "天然棉麻的触感与心跳",
          nowPrice: "55",
          oldPrice: "66"
        },
        {
          img:
            "http://yanxuan.nosdn.127.net/3dfa21af78edea60e53d65d160ad7134.jpg?imageView&thumbnail=430x430&quality=95",
          title: "山雪新疆长绒棉加厚毛巾",
          desc: "厚长毛圈如花瓣，新疆好棉似山雪厚长毛圈如花瓣，新疆好棉似山雪",
          nowPrice: "75",
          oldPrice: "79"
        }
      ]
    };
  },
  methods: {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
.listIndex {
  width: 1200px;
  margin: 0 auto;
  font-size: 14px;
  color: #333;
  .tabSearch {
    margin-top: 20px;
    text-align: center;
    /deep/ .van-tabs {
      .van-tabs__wrap {
        font-size: 16px;
        height: 50px;
        border: 1px solid #e6eaf0;
        border-right: 0 none;
        .van-tabs__nav {
          width: 100%;
          justify-content: flex-start;
          .van-tab {
            line-height: 50px;
            padding: 0;
            border-right: 1px solid #e6eaf0;
            .van-tab__text {
              font-size: 16px;
            }
          }
          .van-tab:last-child {
            border-right: 0 none;
          }
          .van-tab--active {
            background: $themeColor;
            color: #fff;
          }
        }
      }
      .van-tabs__content {
        .van-tab__pane {
          .tabSearchType {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            cursor: pointer;
            span {
              width: 184px;
              color: #868e96;
              font-size: 14px;
              &.on {
                color: $themeColor;
              }
            }
          }
        }
      }
      .van-tabs__line {
        display: none;
      }
    }
  }
}
</style>