<template>
<div>
    <div>
        <el-steps :active="2" align-center>
            <el-step title="步骤1" description="登录海贝商城"></el-step>
            <el-step title="步骤2" description="输入卡号"></el-step>
            <el-step title="步骤3" description="输入卡密"></el-step>
            <el-step title="步骤4" description="激活成功"></el-step>
        </el-steps>
    </div>
    <div class="vation">
        <div class="item">
            <div class="label">姓名：</div>
            <div>
                <input type="text" placeholder="请输入内容" v-model="memberName" />
            </div>
        </div>
        <div class="item">
            <div class="label">联系电话：</div>
            <div>
                <input type="text" placeholder="请输入内容" v-model="mobileNo" maxlength="11" @blur="validPhone()" />
            </div>
        </div>
        <div class="item">
            <div class="label">卡号：</div>
            <div>
                <input type="text" placeholder="请输入内容" v-model="cardNo" />
            </div>
        </div>
        <div class="item">
            <div class="label">卡密：</div>
            <div>
                <input type="text" placeholder="请输入内容" v-model="cardPassword" />
            </div>
        </div>
        <div class="item" v-if="this.$route.query.cardSort==2 || this.$route.query.cardSort==3">
            <div class="label">上传发票：</div>
            <div>
                <el-upload action="http://tpoint.haier.net/api/jic-filecenter/api/ali/upload" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-success="success" :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
            </div>
        </div>
        <!-- <div class="item">
        <div class="label">验证码：</div>
        <div>
          <input type="text" placeholder="请输入内容" />
        </div>
        <div class="Verification">
          <img src="" alt="" />
          <span>看不清？</span>
        </div>
      </div> -->
    </div>
    <div class="btn" @click="submit()">下一步</div>
</div>
</template>

<script>
import {
    activateCardPoint
} from '@/api/api'
export default {
    components: {},
    props: {},
    data() {
        return {
            memberName: null,
            mobileNo: null,
            dialogImageUrl: "",
            dialogVisible: false,
            cardPassword: null,
            cardNo: null,
            imgUrl: null,
            cardSort: null
        };
    },
    watch: {},
    computed: {},
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.imgUrl = file.url.data;
        },
        success(file) {
            console.log(file)
        },
        validPhone() {
            if (!/^1[3456789]\d{9}$/.test(this.mobileNo)) {
                this.$toast("请输入正确的手机号");
                return;
            }
        },
        async submit() {
            if (!this.mobileNo) {
                this.$toast("请输入手机号");
                return;
            }
            if (!/^1[3456789]\d{9}$/.test(this.mobileNo)) {
                this.$toast("请输入正确的手机号");
                return;
            }

            if (this.imgUrl == null && (this.cardSort == 2 || this.cardSort == 4)) {
                this.$toast("请上传发票图片");
            }

            let params = {
                "memberName": this.memberName,
                "cardNo": this.cardNo,
                "mobileNo": this.mobileNo,
                "imageUrl": this.imgUrl,
                // "token": "39a160aadfa04deabec9674012ff00db",
                "cardPassword": this.cardPassword
            };
            let pointCard = await activateCardPoint(params)
            if (pointCard.code == '0000') {
                this.$router.push({
                    path: 'Activationsuccessful',
                    query: {
                        msg: pointCard.msg,
                    }
                });
            } else {
                this.$message({
                    message: pointCard.msg,
                    type: 'warning'
                });
            }

            this.showPicker = true;
        },
    },
    created() {},
    mounted() {
        this.cardNo = this.$route.query.cardNo;
        this.cardSort = this.$route.query.cardSort;
    },
};
</script>

<style lang="scss" scoped>
/deep/.el-step__title.is-finish{
  color: #AC8C4F;
}
/deep/.el-step__head.is-finish {
    color: #AC8C4F;
    border-color: #AC8C4F;
}

/deep/.el-step__description.is-finish {
    color: #AC8C4F;
}
.vation {
    margin-top: 50px;
    width: 800px;
    margin-left: 80px;

    .item {
        margin: 20px 0;
        display: flex;
        align-items: center;

        .label {
            width: 130px;
            font-size: 14px;
        }

        input {
            width: 160px;
            height: 40px;
            color: #999;
            padding-left: 10px;
            margin-right: 50px;
            border: 1px solid rgb(187, 185, 185);
        }
    }
}

.Verification {
    width: 155px;
    height: 40px;
    background: rgb(202, 199, 199);
    display: flex;
    align-items: center;

    span {
        color: #2283e2;
    }
}

.btn {
    width: 110px;
    height: 45px;
    margin: 0 auto;
    line-height: 45px;
    font-size: 16px;
    color: #fff;
    border-radius: 5px;
    background: #AC8C4F;
    text-align: center;
    margin-top: 50px;
}

/deep/.el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 110px;
}
</style>
