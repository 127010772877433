<template>
  <div class="no-data">
    <img src="../../static/images/no-data.png" alt="" class="no">
    <p class="title">暂无相关数据</p>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
  .no-data {
    width: 100%;
    height: 100%;
    margin: 1rem auto;
    text-align: center;
    color: #888;
    .no {
      display: block;
      width: 150px;
      height: 100px;
      margin: 100px auto 20px auto;
    }
    .title {
      font-size: 20px;
    }
  }
</style>


