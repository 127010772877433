<template>
  <div class="evaluate">
    <div class="tabsMain">
      
      <div class="tabsItem">
        <p v-for="(item,index) in tabs"
          :key="index"
          :class="[{'on':tabIndex==index},'item']"
          @click="tabFun(index)"
        >{{item}}</p>
      </div>
      
      <div class="tabsList" v-if="evaluateList.length > 0">
        <table v-for="(item1,index) in evaluateList" :key="index">
          <thead>
            <tr>
              <th align="left" class="th">
                <p>{{item1.createTime}}</p>
                <p>订单号：{{item1.orderNo}}</p>
                <p v-if="item1.orderStatus == 10">已评价</p>
                <p v-else>待评价</p>
              </th>
              <th></th>
            </tr>
          </thead>
          
          <tbody>
            <tr v-for="(item2,index) in item1.goodsInfoList" :key="index">
              <td align="center" class="detail">
                <img :src="item2.goodsImgUrl" alt />
                <p class="title">{{item2.goodsName}}</p>
                <p>X{{item2.goodsNum}}</p>
              </td>
              
              <td align="center" class="btn">
                <button v-if="item2.productComment != null" @click="evaluateDetail(item1, item2.goodsId,item2.productComment)">查看详情</button>
                <button v-else @click="addEvaluate(item1, item2.goodsId)">待评价</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <!-- 没有信息情况下的提醒 -->
      <no-data v-else></no-data>
    </div>
  </div>
</template>

<script>
import { evaluateList } from "@/api/api";
import noData from "@/components/noData";
export default {
  components: {
    noData
  },
  data() {
    return {
      tabIndex: 0,                           // 索引
      tabs: ["全部", "已评价", "未评价"],
      evaluateList: [],
      orderStatus: 11,                       // 拼团请求状态 （ 11全部 6待评价 10已评价）
      start: 1,                              // 当前页数
      limit: 100                             // 当前1页数据条数
    };
  },
  created() {
    this.getEvaluate();
  },
  methods: {
    tabFun(ind) {
      //tab切换
      this.tabIndex = ind;
      if (this.tabIndex == 0) {
        this.orderStatus = 11;
        this.getEvaluate();
      }
      if (this.tabIndex == 1) {
        this.orderStatus = 10;
        this.getEvaluate();
      }
      if (this.tabIndex == 2) {
        this.orderStatus = 6;
        this.getEvaluate();
      }
    },
  
    evaluateDetail(item, id,item2) {
      this.$router.push({
        path: `/user/checkEvaluate?orderNo=${item.orderNo}&goodsId=${id}&id=${item2.id}`
      })
    },
  
    addEvaluate(item, id) {
      this.$router.push({
        path: `/user/addEvaluate?orderNo=${item.orderNo}&goodsId=${id}`
      })
      
    },
    
    // 获取评价数据
    async getEvaluate() {
      let sendData = {
        start: this.start,
        limit: this.limit,
        orderStatus: this.orderStatus
      };
      const res = await evaluateList(sendData);
      if (res.code == "0000") {
        this.evaluateList = res.data;
      } else {
        this.$toast(res.msg);
      }
    }
  },
  
  mounted() {}
};
</script>
<style lang="scss" scoped>
.evaluate {
  .tabsList {
    table {
      margin-bottom: 20px;
      .th {
        padding: 0 20px;
        display: flex;
        color: #aaa;
        p {
          margin-right: 20px;
          &:nth-of-type(3) {
            margin-left: 250px;
            color: black;
          }
        }
      }
      th,
      td {
        border: 0;
      }
    }
    tbody {
      font-size: 12px;
      tr {
        padding: 0;
      }
    }
    .detail {
      padding: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: auto;
      line-height: 1.4;
      border: 0;

      p {
        text-align: left;
        padding: 0 20px;
      }
      .title {
        width: 400px;
      }
      img {
        width: 62px;
        height: 62px;
        border: 1px solid #e8e8e8;
      }
    }
    .btn {
      button {
        width: 90px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #e2e2e2;
      }
    }
  }
}
</style>