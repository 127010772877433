<template>
	<div class="coupon main-container">
		<ul>
			<li v-for="(item,index) in list" :key="index">
				<div class="info">
					<div class="price">
						<p>{{item.couponAmount}}</p>
						<p>{{item.chineseDesc}}</p>
					</div>
					<p class="des">{{item.promName}}</p>
				</div>
				<button  @click="applyCoupon(item.promotionId, index)"><span>立即领取</span></button>
<!--				<button v-if="item.status === 1"><span>已领取</span></button>-->
<!--				<button v-if="item.status === 2"><span>已使用</span></button>-->
<!--				<button v-if="item.status === 3"><span>已过期</span></button>-->
<!--				<button v-if="item.status === 4"><span>已作废</span></button>-->
			</li>
		</ul>
	</div>
</template>

<script>
import {queryCouponCenter, receiveCoupon} from '@/api/api'

export default {
	data() {
		return {
			disabled: false,
			list: []
		}
	},

	methods: {
		// 查询优惠券信息
		async queryCouponCenter() {
			const res = await queryCouponCenter({start: 1,limit:100})
			if (res.code === '0000') {
				this.list = res.data.rows
			} else {
				this.$toast(res.msg)
			}
		},

		// 立即领取优惠券api
		async applyCoupon(params, index) {
			this.disabled = true
			const res = await receiveCoupon({promotionId: params})
			if (res.code === '0000') {
				this.list[index].status = 1
				this.$toast("成功")
			} else {
				this.$toast(res.msg)
			}
		},
	},
	mounted() {
		this.queryCouponCenter()
	}
}
</script>

<style lang="scss" scoped>
	.coupon {
		margin-top: 50px;
		font-size: 14px;

		ul {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;

			li {
				width: 30%;
				height: 110px;
				border: 1px solid #eaeaea;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-radius: 5px;
				margin-bottom: 20px;

				.info {
					width: 310px;
					padding: 20px;

					.price {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 10px;

						p {
							&:nth-child(1) {
								font-size: 34px;
								color: $themeColor;
								margin-right: 10px;
							}

							&:nth-child(2) {
								max-width: 180px;
								color: #ff615e;
								background: #fff1f1;
								padding: 2px 8px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								text-align: center;
							}
						}
					}

					.des {
						max-width: 266px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}

				.time {

				}

			}

			button {
				width: 50px;
				height: 100%;
				font-size: 16px;
				line-height: 1.6;
				background: $themeColor;
				color: #fff;
				text-align: center;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				position: relative;

				&::before,
				&::after {
					content: "";
					width: 16px;
					height: 16px;
					background: #fff;
					border-radius: 16px;
					position: absolute;
					right: 42px;
					z-index: 999;
				}

				&::before {
					top: -8px;
					border-bottom: 1px solid #eaeaea;
				}

				&::after {
					bottom: -8px;
					border-top: 1px solid #eaeaea;
				}

				span {
					width: 20px;
					margin: 0 auto;
					display: block;
				}
			}
		}
	}
</style>
