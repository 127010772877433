<template>
  <div class="login-wrap">
    <div class="login-content">
      <!-- 短信登录  账号密码登录 -->
      <img src="../../static/images/cancel.png" class="cancel" alt @click="close" />

      <div class="toggle">
        <span @click="toggle(1)" class="common-login msg" :class="{'active':loginType ==1}">短信登录</span>
        <span @click="toggle(2)" class="common-login" :class="{'active':loginType ==2}">账号登录</span>
      </div>
      <!-- 手机号输入框 -->
      <div class="common-input-wrap">
        <i class="iconfont icon-shouji"></i>
        <input
          type="tel"
          maxlength="11"
          class="mobile"
          v-model="mobile"
          placeholder="请输入手机号"
          @blur="getPhone"
        />
      </div>

      <!-- 验证码输入框 -->
      <div class="common-input-wrap" v-if="loginType == 1">
        <input
          type="tel"
          maxlength="4"
          class="mobile code"
          v-model="code"
          placeholder="请输入短信验证码"
          @blur="getCodes"
        />
        <span class="send-code" @click="getCode">获取验证码</span>
      </div>

      <!-- 密码输入框 -->
      <div class="common-input-wrap" v-else>
        <i class="iconfont icon-icon-test"></i>
        <input
          type="password"
          class="mobile"
          v-model="password"
          placeholder="请输入密码"
          @blur="getPassword"
        />
      </div>
      <!-- 登录按钮 -->
      <div class="login" @click="loginOn()">登录</div>
      <div class="tips">首次短信登录即注册</div>
    </div>
  </div>
</template>
<script>
import { sendIdentifyingCode, doLogin } from "@/api/api";
import store from '@/store/store';

export default {
  name: "login",
  components: {},
  data() {
    return {
      loginType: 1, // 登录方式， 1---短信登录  2-----账号密码登录
      mobile: "", // 手机号
      code: "", // 验证码
      password: "", // 密码
      arguments: {}, // 接收跳转页面的带来的参数，登陆成功后原路返回
    };
  },

  methods: {
    // 切换登录方式
    toggle(value) {
      this.loginType = value;
      this.code = '';
      this.password = ''
    },

    // 隐藏登录窗
    close() {
      this.$store.commit("showLoginModal", false);
    },

    // 获取手机号
    getPhone(e) {    
      let value = e.target.value;
      if (value && /^1\d{10}$/.test(value)) {
        this.mobile = value;
      }     
    },

    // 获取登录密码
    getPassword(e) {
      let value = e.target.value;
      if (value !== "") {
        this.password = value;
      }
    },

    // 获取验证码
    async getCode() {
      if (!this.mobile || !/^1\d{10}$/.test(this.mobile)) {
        return false;
      }
      let sendData = {
        mobile: this.mobile,
        codeType: 0
      };
      const res = await sendIdentifyingCode(sendData);
      if (res.code === "0000") {
        // 提示短信发送成功
      } else {
        //  提示短信发送失败信息res.msg
      }
    },

    // 验证码验证
    getCodes(e) {
      let value = e.target.value;
      if (!value || value.length < 4) {
        //  提示信息验证码不能小于4位
      } else {
        this.code = value;
      }
    },

    // 登录
    async loginOn() {    
      if (!this.mobile || !/^1\d{10}$/.test(this.mobile)) {
        return false;
      }
      if (this.loginType === 2) {
        if (!this.password || this.password.length < 6) {
          return;
        }
      } else {
        if (!this.code || this.code.length < 4) {
          return;
        }
      }
      let sendData = { mobile: this.mobile };
      //登录类型type 0:账号登录 1: 验证码登录
      if (this.loginType === 2) {
        sendData.password = this.password;
        sendData.type = "0";
      } else {
        sendData.code = this.code;
        sendData.type = "1";
      }
      const res = await doLogin(sendData);
      if (res.code == "0000") {
        store.storeToken(res.data);
        this.$store.commit("showLoginModal", false);
        this.$store.commit("changeLoginStatus", true);
      }
    }
  },

  mounted() {}
};
</script>
<style lang="scss" scoped>
.login-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999999;
  .login-content {
    width: 400px;
    height: 420px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #fff;
    padding: 0 70px;
    .cancel {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
    .toggle {
      margin: 50px 0;
      font-size: 18px;
      color: #333;
      cursor: pointer;
      .common-login.msg {
        border-right: 1px solid #999;
        margin-right: 55px;
        padding-right: 55px;
      }
      .common-login.active {
        color: #999;
      }
    }
    .common-input-wrap {
      width: 255px !important;
      height: 36px;
      border: 1px solid #e8e8e8;
      line-height: 36px;
      border-radius: 2px;
      margin-bottom: 30px;
      position: relative;
      .iconfont {
        font-size: 22px;
        color: #ccc;
        margin-left: 5px;
        float: left;
        position: relative;
      }
      .iconfont::after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 16px;
        border-left: 1px;
        background-color: #ccc;
        margin-left: 2px;
      }
      .mobile {
        position: relative;
        float: left;
        height: 34px;
        line-height: 34px;
        padding-left: 8px;
        width: 214px;
        font-size: 14px;
        font-weight: normal;
        color: #999;
      }
      .mobile.code {
        width: 150px;
      }
      .send-code {
        display: inline-block;
        vertical-align: middle;
        height: 34px;
        line-height: 36px;
        background-color: #eee;
        width: 100px;
        text-align: center;
        float: right;
        color: #333;
        cursor: pointer;
        font-size: 14px;
      }
    }
    /* 登录按钮 */
    .login {
      width: 100%;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background-color: $themeColor;
      margin: 50px auto;
      color: #fff;
      font-size: 16px;
      letter-spacing: 2px;
    }
    .tips {
      text-align: center;
    }
  }
}

</style>


