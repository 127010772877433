<template>
<div class="details-main">
    <div style="padding:20px;background:#fff;">
        <div style="width:1200px;margin:0 auto;display:flex;cursor: pointer; align-items: center;">您所在的位置：
            <div style="padding:10px 5px">首页</div>>
            <div style="padding:10px 5px" @click="golist('0')"> {{cateList[0]}}</div>>
            <div style="padding:10px 5px" @click="golist('1')"> {{cateList[1]}} </div>>
            <div style="padding:10px 5px">{{ productDetails.chineseName }}</div>
        </div>
        <!-- 商品详情 -->
        <div class="product-details">
            <!-- 图片预览 -->
            <div class="details-pic">
                <el-image fit="fill" class="pic-img" :src="exhibitionList[0]"></el-image>
                <div class="pic-preview">
                    <i class="el-icon-arrow-left"></i>
                    <div class="preview-list">
                        <div class="preview-position">
                            <div class="preview-item" :class="index == previewIndex ? 'preview-hover' : ''" v-for="(item, index) in previewList" :key="index" @mouseenter="chosePreview(index)">
                                <el-image :src="item.pictureUrl"></el-image>
                            </div>
                        </div>
                    </div>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <!-- 规格下单 -->
            <div class="details-place">
                <div class="place-titles">
                    <span class="place-title" v-if="!ischineseName">{{ productDetails.chineseName }}</span>
                    <span class="place-title" v-if="ischineseName">{{ chineseName }}</span>
                    <span class="place-subtitle">{{ productDetails.subtitle }}</span>
                </div>
                <!-- <div class="place-type">
                <span class="place-name">兑换方式:</span>
                <div>
                    <el-radio v-model="radio" label="1" border size="mini">积分支付</el-radio>
                    <el-radio v-model="radio" label="2" border size="mini">卡兑换</el-radio>
                </div>
            </div> -->
            <div class="notice" v-show="notice!=''">{{notice}}</div>

                <div class="place-point">
                    <span class="place-name">积分价:</span>
                    <span class="point-price">{{ productDetails.integralNum }} 积分</span>
                </div>
                <div class="place-volumn">
                    <span class="place-name">已兑出:</span>
                    <span class="volumn-number">{{ productDetails.saleNum }}件</span>
                </div>
                <div class="place-opinion">
                    <span class="place-name">好评率:</span>
                    <span class="opinion-number">100%</span>
                </div>
                <div class="place-volumn" v-if="availabl==false">
                    <span class="place-name">库存量:</span>
                    <span class="volumn-number">{{ productDetails.available>0?productDetails.available:0 }}件</span>
                </div>
                <div class="place-distribution" v-if="availabl==true">
                    <span class="place-name">配送至:</span>
                    <el-cascader size="large" ref="cascaderMallCatergory" :options="options" v-model="selectedOptions" @change="haierOrderCheckArea" @click="addressIndex = index">
                    </el-cascader>
                    <span class="distribution-result" v-if="Product==true">库存:{{ hasProduct>0?hasProduct:0 }}件</span>
                </div>
                <div class="product-specs chose-ck" v-for="(item, index2) in codeList" :key="index2">
                    <span class="place-name">{{ item.name }}:</span>
                    <dt :class="{ check: index === iac[index2] }" v-for="(it, index) in item.productPropertyValues" :key="item.code + index" @click="choseSpecs(index2, index, $event)">
                        {{ it.valueName }}
                    </dt>

                </div>
                <div class="specs-list" v-if="this.codeList.length==0">
                    <span class="place-name">规格:</span>
                    <span class="specs-item" @click="choseSpecs(index)">默认规格</span>
                </div>

                <div class="place-buy">
                    <div class="place-edit">
                        <span class="place-name">数量:</span>
                        <div class="number-edit">
                            <div class="edit-icon" @click="minusCount()">
                                <i class="el-icon-minus"></i>
                            </div>
                            <input type="number" v-model="count" @blur="checkCount()" />
                            <div class="edit-icon" @click="plusCount()">
                                <i class="el-icon-plus"></i>
                            </div>
                        </div>
                    </div>
                    <div class="place-button" v-show="bitConceal != 1">
                        <div class="button-buy" @click="submitOrder()">立即兑换</div>
                        <div class="button-cart" @click="gocard()">加入购物车</div>
                    </div>
                </div>
                <div class="place-collection">
                    <i class="el-icon-s-goods"></i>
                    <span @click="collectSave()" v-if="isCollected == false">收藏此商品</span>
                    <span @click="collectSave()" v-if="isCollected == true">取消收藏</span>
                </div>
            </div>
        </div>
        <!-- 商品下方 -->
        <div class="product-bottom">
            <!-- 类似商品 -->
            <!-- <div class="product-same">
            <div class="title">类似产品</div>
        </div> -->
            <!-- 规格详情评论 -->
            <div class="product-specification">
                <div class="product-guess">
                    <div class="title">猜你喜欢</div>
                    <div class="product-guesslist">
                        <div class="product-item" v-for="(item,index) in guesslist" :key="index">
                            <div class="item-img"><img width="100%" :src="item.mainPictureUrl" alt=""></div>
                            <div class="item-title">{{item.chineseName}}</div>
                            <div class="item-jifen">
                                <div style="font-size:13px;color:#AC8C4F;margin-right:3px;">{{item.integralNum}}</div>积分
                            </div>
                        </div>
                    </div>

                </div>
                <el-tabs v-model="activeName" type="card" style="width:100%;">
                    <el-tab-pane label="商品详情" name="first">
                        <!-- <div class="specification-list">
                        <div class="specification-item" v-for="item in specificationList" :key="item">
                            {{ item.title }}: {{ item.value }}
                        </div>
                    </div> -->
                        <!-- <el-image class="product-images" v-for="(item, index) in imgList" :key="index" :src="item"></el-image> -->
                        <div class="text" style="margin-top: 20px;text-align: center;">
                            <p class="productDescribleContent" style="width:750px;margin:0 auto" v-html="productDescribleContent"></p>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="'累计评价(' + this.total + ')'" name="second">
                        <div class="comment-list">
                            <div class="comment-item" v-for="(item, index) in commentDetails" :key="index">
                                <div class="comment-userinfo">
                                    <el-image class="comment-avatar" :src="require('@/assets/images/portrait.jpg')"></el-image>
                                    <span class="comment-nickname">{{ item.userName }}</span>
                                </div>
                                <span class="comment-time">{{ item.createTime }}</span>
                                <div class="star-list">
                                    <i class="el-icon-star-on" v-for="(star, index) in item.startNum" :key="index"></i>
                                </div>
                                <div class="comment-content">
                                    <span class="comment-text">{{ item.content }}</span>
                                    <!-- <div class="flag-list">
                                    <span class="flag-item">质量很好</span>
                                    <span class="flag-item">价格便宜</span>
                                </div> -->
                                </div>
                                <!-- <div class="comment-add">
                                <span class="add-title">[用户追评]</span>
                                <span>很好使实用性和颜值都非常的赞，我很喜欢，还会再来买的是正品，信赖商家，宝贝质感很好，价格也适中，无限回购！本来还很担心东西不好，拿到手发现完全多虑了，五星好评很好的购买平台，质量不错，特别是有品牌的，值得信赖大品牌，用起来很放心，质量非常好，赶上活动购买非常的划算!</span>
                            </div>
                            <div class="comment-shop">
                                <span class="shop-title">[客服回复]</span>
                                <span>很好使实用性和颜值都非常的赞，我很喜欢，还会再来买的是正品，信赖商家，宝贝质感很好，价格也适中，无限回购！本来还很担心东西不好，拿到手发现完全多虑了，五星好评很好的购买平台，质量不错，特别是有品牌的，值得信赖大品牌，用起来很放心，质量非常好，赶上活动购买非常的划算!</span>
                            </div> -->
                            </div>
                            <div class="page">
                                <el-pagination background layout="prev, pager, next" :page-sizes="[5, 15, 25, 30]" :total="total" :page-size="10" @current-change="changePage"></el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="售后方式" name="third">
                        <p v-html="productDetails.afterSaleContent"></p>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    getProductCommentApi,
    queryByProductIdAndSaleAreaIdApi,
    getProductDetails,
    sysUserProvince,
    productStok,
    collectSave,
    collectDelete,
    haierOrderShoppingCart,
    AndProperty,
    getByRiskRule,
    queryProductDescribe,
    getUserInfo,
    queryNoticeByTime

} from "@/api/api";
import {
    regionData,
    CodeToText
} from "element-china-area-data";
export default {
    name: "App",
    data() {
        return {
            total: 0,
      bitConceal: null,
            productcode: "",
            cateList: [],
            canSale: "",
            codeid: "",
            status: "",
            Product: false,
            guesslist: [{
                    url: "http://jfopr.haier.com/preview/jfmall/categories/kitchenUtensils/tableware/cutlerySet/202102/P020210227382401021610.jpg",
                    title: "食联生态 KONKA蒸煮饭盒 KGZZ-2185",
                    jifen: "4000"
                },
                {
                    url: "http://jfopr.haier.com/preview/jfmall/categories/kitchenUtensils/tableware/cutlerySet/202102/P020210227382401021610.jpg",
                    title: "食联生态 KONKA蒸煮饭盒 KGZZ-2185",
                    jifen: "4000"
                },
                {
                    url: "http://jfopr.haier.com/preview/jfmall/categories/kitchenUtensils/tableware/cutlerySet/202102/P020210227382401021610.jpg",
                    title: "食联生态 KONKA蒸煮饭盒 KGZZ-2185",
                    jifen: "4000"
                }
            ],
            ischineseName: false,
            iscodelist: false,
            banners: "",
            iac: [],
            num: "",
            isCollected: false,
            availabl: false,
            addressIndex: 0,
            productDetails: {},
            commentDetails: {},
            hasProduct: "", //区域是否有货
            radio: "1", //兑换方式
            activeName: "first", //商品详情tab
            options: null, //地区三级数据
            selectedOptions: [], //选中的地区
            specsIndex: 0, //默认规格
            count: 1, //商品购买数量
            previewIndex: 0,
            previewList: [], //图片列表
            exhibitionList: [], //图片列表
            //展示列表
            imgList: [],
            codeList: [],
            productDescribleContent: "",
            notice:''
            // specificationList: [{
            //         title: "产品名称",
            //         value: "HONOR/荣耀9X麒麟810芯片4800万超清双摄全面屏智能手机",
            //     },
            //     {
            //         title: "规格",
            //         value: "麒麟810旗舰级芯片4800万超清双摄",
            //     },
            //     {
            //         title: "产地",
            //         value: "上海",
            //     },
            //     {
            //         title: "毛重",
            //         value: "12kg",
            //     },
            //     {
            //         title: "品牌",
            //         value: "荣耀",
            //     },
            //     {
            //         title: "重量",
            //         value: "13kg",
            //     },
            //     {
            //         title: "高度",
            //         value: "30cm",
            //     },
            //     {
            //         title: "宽度",
            //         value: "10cm",
            //     },
            // ],
        };
    },

    methods: {
        // 获取公告
        async getNotice(){
            let res = await queryNoticeByTime();
            if (res.code === "0000") {
                if (res.data!=null) {
                    this.notice = res.data.context
                }
            }
        },
        //初始化
        productDetailsInit() {
            this.getProductComment(1);
            this.getProduct();
            this.sysUserProvince();
            
            this.queryProductDescribe();
            //   this.haierOrderCheckArea();
        },
        golist(value) {
            console.log(value)

            this.$router.push({
                path: "/products/productList",
                query: {
                    categoryName: value == 0 ? this.cateList[0] : this.cateList[1],
                },
            });
        },
        changePage(index) {
            this.getProductComment(index);
        },
        //请求商品评论
        async getProductComment(pageNum) {
            let params = {
                productId: this.$route.query.id,
                pageNum: pageNum,
                pageSize: 10
            };
            let commentRes = await getProductCommentApi(params);

            if (commentRes.code == "0000") {
                this.commentDetails = commentRes.data.list;
                this.total = parseInt(commentRes.data.total)
            }
        },
        //区域有无货
        async handleChange() {
            let params = {
                productId: 1,
                saleAreaCityId: 1,
            };
            let res = await queryByProductIdAndSaleAreaIdApi(params);
            this.hasProduct = res.data;
        },

        async haierOrderCheckArea() {
            let arr = this.$refs['cascaderMallCatergory'].getCheckedNodes()[0].pathLabels;
            let arrs = arr[0] + ' ' + arr[1] + ' ' + arr[2];
            console.log(arrs);
            //检验地区
            let params = {
                area: arrs,
                thirdProductCode: this.productcode,

            };

            console.log(params);
            let res = await productStok(params);

            console.log(res);
            if (res.code == "0000") {
                this.Product = true;
                this.hasProduct = res.data;
            } else {
                this.Product = true;
                this.hasProduct = 0;
            }
        },

        //商品详情
        // 获取数据
        async getProduct() {
            var id = this.$route.query.id;
            let params = {
                id: id,
                userId: localStorage.getItem("memberId"),
                // promotionId: 1,
                // productType:1
            };
            let res = await getProductDetails(params);
            if (res.code === "0000") {
                this.productDetails = res.data;
        this.bitConceal = res.data.bitConceal;

                this.previewList = res.data.pictureUrl;
                this.exhibitionList[0] = res.data.pictureUrl[0].pictureUrl;
                this.imgList = res.data.detailUrl.split(",");
                this.codeList = res.data.productPropertyList;this.num = res.data.quotaNum
                //默认设置第一个规格
                this.iac[0] = 0;
                this.iac = this.iac.concat([]);
                if (this.codeList.length == this.iac.length) {
                    this.AndProperty();
                }
                this.memberCollectFlag = res.data.memberCollectFlag;

                if (this.memberCollectFlag == 1) {
                    this.isCollected = true;
                    // this.$toast("收藏成功");
                } else {
                    this.isCollected = false;
                    // this.$toast("取消收藏成功");
                }
                this.cateList = res.data.categoryList;
                this.guesslist = res.data.guessLikeProductList;
                this.canSale = res.data.canSale;
                this.productcode = res.data.thirdProductCode;
                console.log(this.canSale)

                if (res.data.canSale == 0) {
                    this.$toast("该宝贝已经下架~");
                }
            } else {
                this.$toast(res.msg);
            }
        },
        //库存2

        /**
         *获取商品描述信息
         *tzc
         *2021-3-23 10:42:18
         */
        async queryProductDescribe() {
            var id = this.$route.query.id;
            let params = {
                productId: id,
                // type: 1,
                // promotionId: 1,
                // productType:1
            };
            let res = await queryProductDescribe(params);
            if (res.code === "0000") {
                // ;
                console.log("详情查询" + res);

                this.productDescribleContent = res.data[0].content;
                // if (res.data.code == "") {
                //     this.codeList = ["默认规格"]
                // } else {
                //     this.codeList = res.data.code.split(",");
                // }
            } else {
                this.$toast(res.msg);
            }
        },
        //选择规格
        choseSpecs(index2, index, $event) {

            this.iac[index2] = index;
            this.iac = this.iac.concat([]);
            if (this.codeList.length == this.iac.length) {
                this.AndProperty();
            }
            // this.specsIndex = index;
        },
        //规格详情
        //这里的接口
        async AndProperty() {
            let relationRequests = [];
            let codelist = [];
            for (let i = 0; i < this.iac.length; i++) {
                let ll = this.codeList[i].productPropertyValues[this.iac[i]];
                let tt = this.codeList[i].productPropertyValues[this.iac[i]].valueName;

                let lldetail = this.codeList[i];

                ll.attNameId = lldetail.attNameId;
                // console.log(ll);
                ll.code = lldetail.code;
                ll.name = lldetail.name;
                ll.nameDeleteFlag = lldetail.nameDeleteFlag;
                ll.productId = lldetail.productId;
                ll.productParentId = lldetail.productParentId;
                relationRequests.push(ll);
                codelist.push(tt);
            } //

            let params = {
                productId: this.$route.query.id,
                relationRequests: relationRequests,
            };
            // console.log(relationRequests)

            let res = await AndProperty(params);
            if (res.code == "0000") {
                this.exhibitionList = [];
                this.exhibitionList[0] =
                    res.data.pictureUrl[0].pictureUrl == "" ?
                    this.previewList[0].pictureUrl :
                    res.data.pictureUrl[0].pictureUrl;

                this.productDetails.chineseName = res.data.chineseName == "" ? this.productDetails.chineseName : res.data.chineseName;
                this.productDetails.subtitle = res.data.subtitle == "" ? this.productDetails.subtitle : res.data.subtitle;
                this.productDetails.integralNum = res.data.integralNum == "" ? this.productDetails.integralNum : res.data.integralNum;
                this.productDetails.available = res.data.available === "" ? this.productDetails.available : res.data.available;

                this.codeid = res.data.id;
                this.parentId = res.data.parentId;

                if (res.data.availableType == 1) {
                    this.availabl = false;
                } else {
                    this.availabl = true;
                }
            }
            this.code = "";
            relationRequests.forEach((wl) => {
                this.code += wl.name + ":" + wl.valueName + ",";
            });
            this.code = this.code.substring(0, this.code.length - 1);
        },
        //减少数量
        minusCount() {
            this.count--;
            if (this.count <= 0) {
                this.count = 1;
            }
        },
        //增加数量
        plusCount() {
            if (this.count >= this.num) {
                this.$toast("根据商城规定，一个用户一次只能购买"+this.num+"件同种商品~");
            } else {
                this.count++;
                // console.log(this.num)
            }
        },
        // 校验下单数量
        async getByRiskRule() {
            let params = {};
            let res = await getByRiskRule(params);
            if (res.code == "0000") {
                this.num = res.data.frequency;
            }
            console.log(this.num);
        },
        //失焦校验数量
        checkCount() {
            if (this.count <= 0) {
                this.count = 1;
            }
        },
        //点击预览产看大图
        chosePreview(index) {
            this.exhibitionList = [];
            this.exhibitionList[0] = this.previewList[index].pictureUrl;
            this.previewIndex = index;
        },
        //用户信息
        async getUserInfo() {
            let params = {
                accessTokenPri: localStorage.getItem("hbscToken"),
            };

            let userInfo = await getUserInfo(params);
            this.status = userInfo.data.status;
        },
        //去下单
        submitOrder() {
            this.getUserInfo();
            if (this.status == 1) {
                // this.popupShow = false;
                this.$toast("立即兑换异常，请联系管理员")
                return
            }
            if (this.codeList.length != this.iac.length) {
                this.$toast("请选择商品规格~");
                return;
            }
            if (this.hasProduct == "0") {
                this.$toast("库存不足~");
                return;
            }
            if (this.hasProduct != "" && this.hasProduct <= this.value) {
                // debugger
                this.$toast("库存不足~");
                return;
            }
            if (this.canSale == "0") {
                this.$toast("商品已经下架~");
                return;
            }
            if (
                this.productDetails.available === 0 &&
                this.productDetails.available == "1"
            ) {
                this.$toast("库存不足~");

                if (this.productDetails.available <= this.value) {

                    this.$toast("库存不足~");
                    return;
                }
                return
            }
            if (this.codeList.length == 0) {
                this.code = "默认规格";
                // return;
            }
            if (this.availabl == true) {
                if (this.Product == false) {
                    this.$toast("请选择配送地址~");
                    return;
                }

            }
            this.goodid = this.codeid;
            if (this.codeid == "") {
                this.goodid = this.$route.query.id;
            }
            this.$router.push({
                path: "/orders/submitOrders",
                query: {
                    productList: encodeURIComponent(
                        JSON.stringify([{
                            thirdProductCode: this.productcode,

                            id: this.goodid,
                            name: this.productDetails.chineseName,
                            code: this.code,
                            point: this.productDetails.integralNum,
                            number: this.count,
                            supplierCode: this.productDetails.supplierCode,
                            supplierName: this.productDetails.supplierName,
                            supplierV: this.productDetails.supplierVCode,
                            goodsImgUrl: this.exhibitionList[0],
                        }, ])
                    ),
                },
            });
        },
        //加入购物车
        async gocard() {
            if (this.availabl == true) {
                if (this.Product == false) {
                    this.$toast("请选择配送地址~");
                    return;
                }

            }
            if (this.codeList.length != this.iac.length) {
                this.$toast("请选择商品规格~");
                return;
            }
            if (this.hasProduct == "0") {
                this.$toast("库存不足~");
                return;
            }
            if (this.hasProduct != "" && this.hasProduct <= this.value) {
                // debugger
                this.$toast("库存不足~");
                return;
            }
            if (this.canSale == "0") {
                this.$toast("商品已经下架~");
                return;
            }
            if (
                this.productDetails.available === 0 &&
                this.productDetails.available == "1"
            ) {
                this.$toast("库存不足~");

                if (this.productDetails.available <= this.value) {

                    this.$toast("库存不足~");
                    return;
                }
                return
            }
            if (this.codeList.length == 0) {
                this.code = "默认规格";
                // return;
            }
            this.goodid = this.codeid;
            if (this.codeid == "") {
                this.goodid = this.$route.query.id;
            }
            let params = {
                userId: localStorage.getItem("memberId"),
                productId: this.parentId,
                skuId: this.goodid,
                code: this.code,
                amount: this.count,
                thirdProductCode: this.productcode,

            };

            const res = await haierOrderShoppingCart(params);
            if (res.code == "0000") {
                this.popupShow = false;

                this.$toast("加入成功，在购物车等亲~");
            }
        },
        //获取省市区列表
        async sysUserProvince() {
            let params = {};
            const res = await sysUserProvince(params);
            if (res.code === "0000") {
                this.options = res.data;
                // console.log(this.options);
                // console.log(regionData);
            } else {
                this.$toast(res.msg);
            }
        },
        //收藏商品
        async collectSave() {
            let params = {
                memberId: localStorage.getItem("memberId"),
                productId: this.$route.query.id,
            };

            const res = await collectSave(params);
            if (res.code == "0000") {
                this.memberCollectFlag = res.data.memberCollectFlag;
                if (this.memberCollectFlag == 1) {
                    this.isCollected = true;
                    this.$message({
                        message: "收藏成功！",
                    });
                } else {
                    this.isCollected = false;
                    this.$message({
                        message: "取消收藏成功！",
                    });
                }
            }
        },
        //取消商品收藏
        async collectDelete() {
            let params = {
                id: this.productDetails.memberCollectId,
            };

            const res = await collectDelete(params);
            if (res.code == "0000") {
                this.$message({
                    message: "取消收藏成功！",
                });
                this.isCollected = false;
            } else {
                this.$message({
                    message: res.msg,
                    type: "warning",
                });
            }
        },
    },
    mounted() {

        this.productDetailsInit();
        this.getNotice()
    },
};
</script>

<style>
.productDescribleContent img {
    width: 100%;
}
</style><style lang="scss" scoped>
.page {
    padding-top: 10px;
    text-align: center;
}

/deep/.el-tabs__item:hover {
    color: #AC8C4F;
}

/deep/.el-tabs__item.is-active {
    color: #AC8C4F;
}

.details-main {
    padding-top: 20px;
}

/deep/.el-tabs__header {
    margin: 0;
}

.chose-ck {
    overflow: hidden;
}

.chose-ck dt {
    padding: 7px 12px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    float: left;
    margin-right: 10px;
    font-size: 0.28rem;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.chose-ck dt.check {
    border: 1px solid #ed2856;
    color: #ed2856;
}

.chose-ck p {
    color: rgba(0, 0, 0, 0.87);
    line-height: 0.8rem;
}

.product-details {
    width: 1200px;
    margin: 0 auto;
    height: auto;
    // padding-top: 20px;
    display: flex;
    flex-direction: row;

    .details-pic {
        width: 340px;
        height: auto;
        display: flex;
        flex-direction: column;

        .pic-img {
            width: 100%;
            height: 340px;
            border: 1px solid #e1e1e1;
        }

        .pic-preview {
            margin-top: 10px;
            height: 52px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            i {
                font-size: 23px;
                color: #6d6d6d;
                cursor: pointer;
            }

            .preview-list {
                width: 292px;
                height: 100%;
                overflow: hidden;
                position: relative;

                .preview-position {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: auto;
                    display: flex;
                    flex-direction: row;

                    .preview-item {
                        padding: 4px;
                        width: 52px;
                        height: 52px;
                        border: 1px solid #e1e1e1;
                        display: inline-block;
                    }

                    .preview-hover {
                        border: 1px solid #2283e2;
                    }

                    .preview-item:not(:last-child) {
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    .details-place {
        flex: 1;
        height: 420px;
        margin-left: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .place-name {
            display: inline-block;
            font-size: 14px;
            color: #333;
            font-weight: bold;
            width: 70px;
        }

        .place-titles {
            display: flex;
            flex-direction: column;

            .place-title {
                color: #333;
                font-size: 18px;
                font-weight: bold;
            }

            .place-subtitle {
                color: #ed2856;
                font-size: 16px;
                font-weight: bold;
            }
        }

        .place-type {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .place-point {
            .point-price {
                color: #AC8C4F;
                font-size: 16px;
                font-weight: bold;
            }
        }

        .place-opinion {
            .opinion-number {
                color: #ed2856;
                font-size: 14px;
            }
        }

        .place-distribution {
            .distribution-result {
                margin-left: 12px;
                color: #AC8C4F;
                font-weight: bold;
            }
        }

        .product-specs {
            align-items: center;
            display: flex;
            flex-direction: row;

            .specs-list {
                width: 300px;

                .specs-item {
                    height: 30px;
                    border: 1px solid #dcdfe6;
                    padding: 0 12px;
                    cursor: pointer;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: #333;
                    border-radius: 4px;
                    margin-right: 8px;
                }

                .specs-item-selected {
                    border: 2px solid #2283e2;
                    color: #2283e2;
                }
            }
        }

        .place-buy {
            align-items: center;
            display: flex;
            flex-direction: row;
            // align-items: flex-end;
            border-bottom: 1px dashed #dedede;
            padding-bottom: 10px;

            .place-edit {
                display: flex;
                flex-direction: row;

                .number-edit {
                    display: flex;
                    flex-direction: row;
                    height: 24px;
                    align-items: center;

                    .edit-icon {
                        background-color: #f1f8ff;
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                        text-align: center;
                        color: #616161;
                        cursor: pointer;
                        border: 1px solid #f1f3f6;
                    }

                    input {
                        border: none;
                        border-top: 1px solid #f1f3f6;
                        border-bottom: 1px solid #f1f3f6;
                        height: 100%;
                        width: 45px;
                        text-align: center;
                    }
                }
            }

            .place-button {
                margin-left: 120px;
                width: 100%;
                height: 55px;
                display: flex;
                flex-direction: row;

                .button-buy,
                .button-cart {
                    width: 155px;
                    line-height: 55px;
                    text-align: center;
                    margin-right: 20px;
                    cursor: pointer;
                    border-radius: 4px;
                }

                .button-cart {
                    color: #999;
                    border: 1px solid #999;
                    font-size: 18px;
                    font-weight: bold;
                }

                .button-buy {
                    color: #fff;
                    background-color: #AC8C4F;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }

        .place-collection {
            height: 30px;
            display: flex;
            align-items: center;

            i {
                font-size: 26px;
                color: #ed2856;
                margin-right: 6px;
            }

            span {
                font-weight: bold;
                color: #333;
            }

            span:hover {
                cursor: pointer;
                color: #2283e2;
            }
        }
    }
}

.product-bottom {
    width: 1200px;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 40px auto;

    .product-same {
        width: 220px;
        height: auto;
    }

    .product-specification {
        display: flex;
        margin-left: 20px;
        flex: 1;
        height: auto;

        .product-guess {
            width: 300px;
            height: auto;
            margin-right: 20px;

            .title {
                text-align: center;
                background: #ececec;
                width: 100%;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
            }

            .product-guesslist {
                border: 1px #ececec solid;

                .product-item {
                    cursor: pointer;

                    .item-img {
                        img {
                            display: block;
                            margin: 10px auto;
                            width: 148px;
                            height: 148px;
                        }
                    }

                    .item-title {
                        width: 180px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        margin: 0 auto;
                        padding-bottom: 10px;

                    }

                    .item-jifen {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                    }
                }
            }
        }

        .specification-list {
            width: 100%;
            height: auto;
            border: 1px solid #e6e6e6;
            margin-bottom: 20px;
            padding: 20px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .specification-item {
                padding: 10px 20px;
                width: 370px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .product-images {
            width: 100%;
            height: auto;
        }

        .comment-list {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;

            .comment-item {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid #ddd;
                padding: 20px 0;

                .comment-userinfo {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .comment-avatar {
                        width: 30px;
                        height: 30px;
                        margin-right: 12px;
                    }

                    .comment-nickname {
                        font-size: 14px;
                        color: #333;
                    }
                }

                .comment-time {
                    color: #999;
                    margin-top: 6px;
                }

                .star-list {
                    margin-top: 10px;

                    i {
                        font-size: 18px;
                        color: #ed2856;
                    }
                }

                .comment-content {
                    margin-top: 12px;
                    display: flex;
                    flex-direction: column;

                    .comment-text {
                        font-size: 14px;
                        line-height: 26px;
                    }
                }

                .flag-list {
                    margin-top: 10px;
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: row;

                    .flag-item {
                        width: auto;
                        height: 30px;
                        padding: 0 12px;
                        border: 1px solid #2283e2;
                        color: #2283e2;
                        text-align: center;
                        line-height: 30px;
                        margin-right: 10px;
                        border-radius: 2px;
                        cursor: pointer;
                    }

                    .flag-item:hover {
                        color: #fff;
                        background-color: #2283e2;
                    }
                }

                .comment-add {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    border-top: 1px solid #f4f4f4;
                    font-size: 14px;
                    color: #999999;
                    padding-top: 10px;
                    margin-top: 10px;
                    line-height: 30px;

                    .add-title {
                        color: #2283e2;
                    }
                }

                .comment-shop {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    border-top: 1px solid #f4f4f4;
                    font-size: 14px;
                    color: #f5a623;
                    padding-top: 10px;
                    margin-top: 10px;
                    line-height: 30px;
                }
            }
        }
    }
}
.notice{
    color: #ed2856;
    font-size: 14px;
    font-weight: bold;
}
</style>
