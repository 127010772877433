<template>
  <div class="userCenter-wrap">
    <div class="integralTop mb20">
      <div class="integralLeft">
        <p>邀请有奖</p>
        <p>拉新用户赚提成</p>
        <p>躺着也能致富</p>
      </div>
      <router-link to class="integralRight"></router-link>
    </div>
    <div class="info-wrap">
      <!-- 基础信息 -->
      <div class="info">
        <img :src="init.headImage" alt class="user-image" width="60" height="60" />
        <div>
          <span class="id ml10">用户名：{{init.nickName}}</span>
          <br />
          <span class="id ml10">Id：{{init.id}}</span>
        </div>
      </div>
      <!-- 邀请码 -->
      <div class="growth-value">
        <div>邀请码</div>
        <div class="title">{{init.invitationCode}}</div>
      </div>
    </div>
    <!-- <div class="user-detail">
      <img :src="myInformation.code" alt class="user-image" width="160" height="160" />
    </div> -->
    <div class="codeImg"  id="qrcode" ref="qrcode"></div>
  </div>
</template>
<script>
import { queryDistributorShare } from "@/api/api";
import QRCode from "qrcodejs2";
export default {
  components: {},
  data() {
    return {
      init: {},
      qr: "",
    };
  },

  methods: {
    async initFun() {
      let res = await queryDistributorShare();
      if (res.code == "0000") {
        this.init = res.data;
      } else {
        this.$toast(res.msg);
      }
    },
    qrcodeFun() {
      this.qr = new QRCode("qrcode", {
        width: 180,
        height: 180,
        text: this.location + "?inviteCode=" + this.init.invitationCode,
      });
    },
  },
  created() {
    this.initFun();
    this.$nextTick(() => {
      this.qrcodeFun();
    });
  },
};
</script>
<style lang="scss" scoped>
.userCenter-wrap {
  display: block;
  width: 100%;
  height: 100%;
  .integralTop {
    width: 892px;
    height: 265px;
    padding: 34px 60px;
    background: url(http://yanxuan.nosdn.127.net/054ae764bcee8cc6d6eee75366e5d83d.png?imageView&quality=95)
      no-repeat center;
    display: flex;
    justify-content: space-between;
    .integralLeft {
      width: 390px;
      height: 195px;
      text-align: center;
      color: $themeColor;
      padding: 34px 55px;
      font-size: 16px;
      p {
        &:nth-child(2) {
          font-size: 25px;
          margin-top: 15px;
          text-align: left;
        }
        &:nth-child(3) {
          font-size: 25px;
          margin-top: 10px;
          text-align: right;
        }
      }
    }
    .integralRight {
      width: 490px;
      height: 195px;
      right: 0;
      cursor: pointer;
    }
  }
  .info-wrap {
    background: rgba(134, 142, 150, 0.05);
    border-right: none;
    border: 1px solid #e6eaf0;
    width: auto;
    padding: 15px 20px;
    display: flex;
    height: 100px;
    /* 基础信息 */
    .info {
      display: flex;
      width: 35%;
      height: 100%;
      padding-right: 50px;
      border-right: 1px dashed #dde0e5;
      .icon-touxiang {
        color: #ccc;
        font-size: 66px;
        margin: 0 auto;
        vertical-align: middle;
      }
      .id {
        display: inline-block;
        vertical-align: middle;
        color: #868e96;
        font-size: 16px;
      }
    }
    /* 成长值 */
    .growth-value {
      display: inline-block;
      width: 500px;
      height: 100%;
      padding: 12px 36px;
      .level {
        display: block;
        width: 100%;
      }
      .wrap {
        display: inline-block;
        width: 200px;
        border: 1px solid $themeColor;
        border-radius: 10px;
        height: 10px;
        margin: 10px 0 30px 0;
        position: relative;
        .inner {
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          background-color: $themeColor;
          // width: 50%;
          height: 100%;
        }
      }
      .value {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 60px;
        margin-left: 20px;
        color: $themeColor;
        font-size: 16px;
      }
    }
  }
  /* 余额 优惠券 积分 充值卡部分 */
  .user-detail {
    display: block;
    width: 100%;
    height: auto;
    border: 1px solid #e6eaf0;
    border-top: 0 none;
    display: flex;
    padding: 30px;
  }

  .codeImg{
      margin-top: 20px;
  }
}
</style>

