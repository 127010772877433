<template>
<div class="card-wrap">
    <!-- 余额信息 -->
    <div class="balance-wrap">
        <span class="title">充值卡余额：</span>
        <span class="title themeColor">￥{{ total }}</span>
        <span class="primaryColor fr cursor" @click="showAdd = true">+添加充值卡</span>
    </div>

    <!-- 收支明细 -->
    <div class="tabsMain">
        <div class="tabsItem">
            <p v-for="(item,index) in tabs" :key="index" :class="[{'on':tabIndex==index},'item']" @click="tabFun(index)">{{item}}</p>
        </div>
        <!-- 概览部分 -->
        <div class="overview-wrap" v-if="tabIndex ==0">
            <div class="table-title">
                <div class="table-item value themeColor">面值(￥)</div>
                <div class="table-item number themeColor">余额(￥)</div>
                <div class="table-item status themeColor">状态</div>
            </div>

            <div class="wrap" v-if="detailList.length > 0">
                <div class="table-content" v-for="(item,index) in detailList" :key="index">
                    <div class="table-item">{{ item.rechargeAmount }}</div>
                    <div class="table-item">{{ item.rechargeBalance }}</div>
                    <div v-if="item.status == 1" class="table-item">已绑定</div>
                    <div v-else-if="tem.status == 2" class="table-item">未绑定</div>
                    <div v-else-if="tem.status == 3" class="table-item">已冻结-</div>
                </div>
            </div>
            <nodata v-else></nodata>

        </div>

        <!-- 明细部分 -->
        <div class="detail-wrap" v-else>
            <div class="table-title">
                <div class="table-item value themeColor">交易时间</div>
                <div class="table-item number themeColor">订单号</div>
                <div class="table-item status themeColor">变动金额</div>
                <div class="table-item status themeColor">余额</div>
            </div>

            <div class="wrap" v-if="list.length > 0">
                <div class="table-content" v-for="(item,index) in list" :key="index">
                    <div class="table-item">{{ item.createTime }}</div>
                    <div class="table-item">{{ item.orderNo }}</div>
                    <div class="table-item">{{ item.amountUsed }}</div>
                    <div class="table-item">{{ item.rechargeBalance }}</div>
                </div>
            </div>
            <nodata v-else></nodata>

        </div>
    </div>

    <!-- 充值弹窗 -->
    <div class="login-wrap" v-if="showAdd">
        <div class="login-content">
            <!-- 短信登录  账号密码登录 -->
            <img src="../../../static/images/cancel.png" class="cancel" alt="" @click="showAdd = false">
            <div class="common-input-wrap">
                <input type="tel" maxlength="11" class="mobile" v-model="number" placeholder="请输入充值卡号码">
            </div>
            <div class="common-input-wrap">
                <input type="text" class="mobile" v-model="password" placeholder="请输入充值卡密码">
            </div>
            <div class="login" @click="addPoint">添加</div>
        </div>
    </div>

</div>
</template>

<script>
import {
    rechargeCardList,
    rechargeCardRecordList,
    bindRechargeCard,
    topPoint
} from '@/api/api';
import nodata from '@/components/noData'
export default {
    name: 'card',
    components: {
        nodata
    },
    data() {
        return {
            tabIndex: 0, // 默认选中
            tabs: ["概览", "收支明细"], // tab数据
            detailList: [], // 充值卡列表数据
            list: [], // 消费明细数据列表
            number: '', // 充值卡号码
            password: '', // 充值卡密码
            showAdd: false, // 显示添加弹框
            total: 0, // 积分总额
        }
    },
    methods: {
        // tab事件切换
        tabFun(ind) {
            this.tabIndex = ind;
        },

        // 添加充值卡
        async addPoint() {
            var this_ = this;
            const res = await topPoint({
                busiCode: "ac",
                busiType: "MEMBER",
                cardNo: "123",
                cardPassword: "123",
                memberName: "封瑞",
                mobileNo: "19118112648",
            });
            if (res.code === "0000") {
                this.$toast("充值成功！")
            }
        },

        // 获取充值卡列表
        async getCardList() {
            let params = {
                start: 1,
                limit: 100
            };
            const res = await rechargeCardList(params);
            if (res.code == '0000') {
                this.detailList = res.data.rows;
            } else {
                this.$toast(res.msg);
            }
        },

        // 获取充值卡明细
        async getCardDetailList() {
            let params = {
                start: 1,
                limit: 100
            };
            const res = await rechargeCardRecordList(params);
            if (res.code == '0000') {
                this.list = res.data.rows;
            } else {
                this.$toast(res.msg);
            }
        },

    },
    mounted() {
        this.total = this.$route.query.number;
        this.getCardList();
        this.getCardDetailList();
    },
}
</script>

<style lang="scss" scoped>
.balance-wrap {
    border: 1px solid #ccc;
    padding: 20px;

    .title {
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
    }
}

/* 收支明细部分 */
.tabsMain {
    margin-top: 30px;
    border: 1px solid #ccc;

    .overview-wrap,
    .detail-wrap {
        padding: 20px;

        .table-title {
            display: flex;
            border-bottom: 1px dashed #ccc;
            padding-bottom: 20px;

            .table-item {
                flex: 1;
                text-align: center;
            }
        }

        .table-content {
            display: flex;
            padding: 15px 0;

            .table-item {
                flex: 1;
                text-align: center;
            }
        }
    }
}

/* 充值弹窗 */
.login-wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 9999999;

    .login-content {
        width: 400px;
        height: 420px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: #fff;
        padding: 70px;

        .cancel {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 50px;
            height: 50px;
            cursor: pointer;
        }

        .common-input-wrap {
            width: 255px !important;
            height: 36px;
            border: 1px solid #e8e8e8;
            line-height: 36px;
            border-radius: 2px;
            margin-bottom: 30px;
            position: relative;

            .mobile {
                position: relative;
                float: left;
                height: 34px;
                line-height: 34px;
                padding-left: 8px;
                width: 214px;
                font-size: 14px;
                font-weight: normal;
                color: #999;
            }

            .mobile.code {
                width: 150px;
            }
        }

        /* 登录按钮 */
        .login {
            width: 100%;
            height: 48px;
            line-height: 48px;
            text-align: center;
            background-color: $themeColor;
            margin: 50px auto;
            color: #fff;
            font-size: 16px;
            letter-spacing: 2px;
            cursor: pointer;
        }
    }
}
</style>
