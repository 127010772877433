<template>
	<div class="list-wrap">
		<!-- tab部分 -->
		<div class="tap-wrap">
			<span class="common-tab" :class="{active:active == 0}" @click="tab(0)">全部</span>
			<span class="common-tab" :class="{active:active == 2}" @click="tab(2)">拼团中</span>
			<span class="common-tab" :class="{active:active == 3}" @click="tab(3)">拼团成团</span>
			<span class="common-tab" :class="{active:active == 4}" @click="tab(4)">拼团失败</span>
		</div>
		
		<!-- 订单列表部分 -->
		<div v-if="orderList.length > 0">
			<div class="list" v-for="(item,index) in orderList" :key="index">
				<p class="title">
					<span class="time fl ml20">拼团时间: {{item.startTime}}</span>
					<span class="order fl ml20">订单编码：{{item.groupCode}}</span>
				</p>
				<div class="goods-wrap">
					<div class="common-style goods">
						<div class="image-wrap">
							<img  :src="item.promPicUrl" class="image" alt="">
							<img  src="https://yanxuan-item.nosdn.127.net/93bd4b19b04c15ec9f3b48e4100f5c0a.png?type=webp&imageView&thumbnail=100x100&quality=95" class="image" alt="">
						</div>
						<span class="name" >{{item.mpName}}</span>
						<span class="name">{{item.groupMembers === null ? 1 : item.groupMembers}}人团</span>
					</div>
					<div class="common-style cost" >
						<p class="price">{{item.marketPrice}}元</p>
					</div>
					<div class="common-style cost" >
						<p class="price">0元</p>
					</div>
					<div class="common-style operation">
						<span class="tip" @click="goDetail(item.groupInstCode)">查看详情</span>
					</div>
				</div>
			</div>
		</div>
	<nodata v-else></nodata>
	</div>
</template>

<script>
import {getMyGroupList} from '@/api/api'
import nodata from '@/components/noData'
export default {
	name: 'list',

	components: {
		nodata
	},

	data() {
		return {
			active: 0,               // tab选中状态下标
			searchValue: '',         // 搜索订单号
			groupInstStatus: 9,      // 拼团状态 9:全部 2：组团中 3：已成团 4：组团失败
			start: 1,                // 开始页数
			limit: 100,              // 每次请求条数
			orderList: []            // 列表
		}
	},

	methods: {
		// 获取拼团列表数据
		async getList(groupInstStatus) {
			const params = {
				start: this.start,
				limit: this.limit,
				groupInstStatus: groupInstStatus,
			}
			const res = await getMyGroupList(params)
			if (res.code === '0000') {

				this.orderList = res.data.rows
			} else {
				this.$toast(res.msg)
			}
		},

		// tab切换
		tab(value) {
			this.active = value
			this.getList(value)
		},

		// 搜索订单
		search() {
			console.log('do')
		},

		// 跳转到订单详情
		goDetail(groupInstCode) {
			this.$router.push({
				path: `/order/groupDetail?groupInstCode=${groupInstCode}`
			})
		},
	},

	mounted() {
		this.getList(0)
	},
}
</script>

<style lang="scss" scoped>
	.list-wrap {
		width: 100%;
		height: auto;
		
		.tap-wrap {
			display: inline-block;
			border-bottom: 2px solid #eaeaea;
			
			.common-tab {
				display: inline-block;
				width: 110px;
				padding: 10px 0;
				font-size: 18px;
				color: #333;
				margin-bottom: -2px;
				text-align: center;
				cursor: pointer;
				font-size: 16px;
				position: relative;
			}
			
			.common-tab::after {
				content: '|';
				position: absolute;
				right: 0;
				top: 12px;
				color: #ccc;
			}
			
			.common-tab:last-child::after {
				content: '';
			}
			
			.common-tab.active {
				color: $themeColor;
				border-bottom: 2px solid $themeColor;
			}
		}
		
		.search-wrap {
			width: 290px;
			float: right;
			margin-top: 10px;
			font-size: 14px;
			
			.order-input {
				width: 240px;
				height: 28px;
				border: 1px solid #ddd;
				float: left;
				padding-left: 12px;
			}
			
			.order-button {
				width: 51px;
				height: 28px;
				line-height: 28px;
				margin-left: -1px;
				border: 1px solid #ddd;
				border-radius: 0;
				float: left;
				text-align: center;
				background-color: #f5f5f5;
				cursor: pointer;
			}
			
		}
		
		/* 订单数据 */
		.list {
			border: 1px solid #e8e8e8;
			margin-top: 20px;
			
			.title {
				background-color: #f5f5f5;
				height: 43px;
				line-height: 43px;
				text-align: left;
				font-weight: 400;
				padding-left: 10px;
			}
			
			/* 商品部分 */
			.goods-wrap {
				border-top: 1px solid #e8e8e8;
				display: flex;
				
				.common-style {
					border-right: 1px solid #e8e8e8;
					padding: 20px 0;
					
					.image-wrap {
						float: left;
						position: relative;
						width: 100px;
						height: 100px;
						margin: 0 10px;
						
						.image {
							background-color: #f4f4f4;
							display: block;
							width: 100px;
							height: 100px;
						}
					}
					
					.name {
						float: left;
						width: 220px;
						margin-top: 40px;
						text-align: center;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-size: 14px;
					}
				}
				
				.common-style:last-child {
					border-right: 0 none;
				}
			}
			
			/* 价格 运费部分 */
			.cost {
				width: 200px;
				text-align: center;
				
				.price {
					font-size: 16px;
					font-weight: 700;
					margin-top: 20px;
				}
			}
			
			/* 操作部分 */
			.operation {
				width: 215px;
				text-align: center;
				
				.common-button {
					display: block;
					width: 100px;
					height: 30px;
					line-height: 30px;
					// background-color: #ccc;
					background-color: $themeColor;
					color: #fff;
					margin: 10px auto;
					border-radius: 5px;
				}
				
				.tip {
					display: inline-block;
					color: #69c;
					cursor: pointer;
					margin-top: 40px;
				}
			}
		}
	}
</style>


