<template>
  <div class="groupList main-container">
    <ul v-if="list.length > 0">
      <li v-for="(item,index) in list"
        :key="index"
        :style="{'background-image': 'url('+item.mpUrl+')'}">
        <router-link :to="{path:'/list/detail',query:{id:item.mpId,promotionId:item.groupCode}}" class="btn">去开团</router-link>
        <div class="coverInfo">
          <div class="cover">
            <p class="name">{{item.mpName}}</p>
            <div class="flex">
              <p>
                <span class="themeColor">{{item.groupMembers}}</span>人团
              </p>
              <p class="themeColor">{{item.grouponPrice}}</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <nodata v-else></nodata>
  </div>
</template>
<script>
import { getGroupThemeList } from "@/api/api";
import nodata from '@/components/noData'
export default {
  name: "groupList",
  components: {
    nodata
  },
  data() {
    return {
      start: 1,     // 请求页数
      limit: 100,   // 每次请求条数
      list: [],     // 拼团数据列表
    };
  },
  methods: {
    // 获取拼团列表数据
    async getList() {
      const params = {
        start: this.start,
        limit: this.limit,
        type: 0
      };
      const res = await getGroupThemeList(params);
      if (res.code === "0000") {
        this.list=res.data.rows;
      } else {
        this.$toast(res.msg);
      }
    },
  },
  mounted() {
    this.getList()
  }
};
</script>
<style lang="scss" scoped>
.groupList {
  margin-top: 50px;
  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  li {
    width: 586px;
    height: 295px;
    background-size: 100%;
    position: relative;
    margin: 0 28px 28px 0;
    &:nth-child(2n) {
      margin: 0;
    }
    .btn {
      text-align: center;
      position: absolute;
      top: 10px;
      right: 10px;
      background: $themeColor;
      color: #fff;
      width: 120px;
      height: 40px;
      line-height: 40px;
      border-radius: 40px;
      font-size: 16px;
      &::before {
        content: " ";
        width: 8px;
        height: 8px;
        border-top: 2px solid #fff;
        border-left: 2px solid #fff;
        position: absolute;
        right: 20px;
        top: 15px;
        transform: rotate(135deg);
      }
    }
    .coverInfo {
      width: 254px;
      height: 148px;
      position: absolute;
      top: 72px;
      left: 24px;
      border: 2px solid #fff;
      border-radius: 12px 34px;
      margin: 10px;
      .cover {
        width: 244px;
        height: 138px;
        margin: 3px 0 0 3px;
        border-radius: 10px 30px;
        background: #fff;
        text-align: center;
        padding: 30px;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        p {
          margin-bottom: 20px;
        }
        .name {
          font-size: 20px;
          font-weight: bold;
        }
        .flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
</style>
