<template>
  <div class="changeAccount">
    <p>更换绑定手机</p>
    <!-- 更换账户流程 -->
    <div class="process">
      <!-- 流程图 -->
      <div class="processImgs">
        <span
          class="spanOne color999"
          :class="{bgRed:1===controlColor,colorWhite:1===controlColor}"
        >1</span>
        <i class="bgGray" :class="{bgRed:1===controlColor,colorWhite:1===controlColor}"></i>
        <i class="bgGray" :class="{bgRed:2===controlColor,colorWhite:2===controlColor}"></i>
        <span
          class="spanTwo color999"
          :class="{bgRed:2===controlColor,colorWhite:2===controlColor}"
        >2</span>
        <i class="bgGray" :class="{bgRed:2===controlColor,colorWhite:2===controlColor}"></i>
        <i class="bgGray" :class="{bgRed:3===controlColor,colorWhite:3===controlColor}"></i>
        <span
          class="spanThree color999"
          :class="{bgRed:3===controlColor,colorWhite:3===controlColor}"
        >3</span>
        <!-- 提示信息 -->
        <div class="processContent">
          <label>身份验证</label>
          <label>新手机号码</label>
          <label>完成</label>
        </div>
        <!--1. 绑定手机获取验证码 -->
        <div class="getCode" v-if="1==controlColor">
          <div class="bindPhone">
            <span>已绑定的手机：</span>
            <span>{{ userPhone }}</span>
          </div>
          <div class="codePhone">
            <input
              type="text"
              v-model.trim="inputText"
              placeholder="请输入验证码"
              @input="getNextStatus(1)"
            />
            <button
              @click="getCodes"
              class="bgGold"
              :class="{bgCcc:btnBgc}"
              :disabled="flag"
            >{{ btnText }}</button>
          </div>
        </div>
        <!-- 2. 点击更换手机号码 -->
        <div class="againCode" v-if="2==controlColor">
          <div class="codePhone">
            <input type="text" placeholder="请输入手机号码" class="getNewNumber" v-model.trim="newPhone" />
            <input
              type="text"
              v-model.trim="newInputText"
              placeholder="请输入验证码"
              @input="getNextStatus(2)"
            />
            <button
              @click="getNewCodes"
              class="bgGold"
              :class="{bgCcc:btnBgc}"
              :disabled="flag"
            >{{ btnText }}</button>
          </div>
        </div>
        <!-- 点击下一步 -->
        <div class="btnNext">
          <button @click="nextOperate" :disabled="!isNextStep" :class="{bgGold:isNextStep}">下一步</button>
        </div>
      </div>
    </div>
    <!-- 底部温馨提示栏 -->
    <div class="warmTip">
      <p>温馨提示</p>
      <ul>
        <li>• 为保障您的帐号安全，变更重要信息需要身份验证</li>
      </ul>
    </div>
  </div>
</template>
<script>
import {
  getDesensitizedPhone,
  sendVerificationCode,
  changePhoneNumber
} from "@/api/api";
export default {
  data() {
    return {
      controlColor: 1,
      userPhone: "", //旧手机号码
      inputText: "", //获取旧手机验证码
      oldCode: "", //用来存储旧手机号码验证码
      newPhone: "", //用户新的手机号码
      newInputText: "", //获取新手机验证码
      newCode: "", //用来存储新手机验证码
      btnText: "获取验证码",
      timeNumber: 60, //倒计时
      btnBgc: false, //控制按钮背景颜色
      flag: false, //控制按钮是否禁用
      clock: "", //定时器
      isNextStep: false, //下一步操作是否禁用
      code: 1 //判断用户短信类型（短信登录/修改手机号/修改登录密码）
    };
  },
  created() {
    this.getPhone();
  },
  methods: {
    // 获取手机号码
    async getPhone() {
      const res = await getDesensitizedPhone();
      if (res.code == "0000") {
        this.userPhone = res.data.mobile;
      } else {
        this.$toast(res.msg);
      }
    },
    //定时器方法用函数调用
    setInterval() {
      this.clock = window.setInterval(() => {
        this.timeNumber--;
        this.btnText = this.timeNumber + "s后重新发送";
        if (this.timeNumber <= 0) {
          window.clearInterval(this.clock);
          this.btnText = "重新发验证码";
          this.timeNumber = 60;
          this.btnBgc = false;
          this.flag = false;
        }
      }, 1000);
    },
    // 发送旧验证码请求
    async sendCode() {
      const params = {
        codeType: this.code,
        mobile:this.userPhone
      };
      const res = await sendVerificationCode(params);
      if (res.code == "0000") {
        this.oldCode = res.data.code;
        console.log("这是旧的" + this.oldCode);
      } else {
        this.$toast(res.msg);
      }
    },
    //  点击获取旧验证码按钮,执行sendCode函数
    getCodes() {
      this.btnBgc = true;
      this.flag = true;
      this.sendCode();
      this.$toast("验证码已发送，注意查收");
      this.setInterval();
    },
    // 发送新手机验证码请求
    async sendNewCode() {
      const params = {
        codeType: 3,
        mobile: this.newPhone
      };
      const res = await sendVerificationCode(params);
      if (res.code == "0000") {
        this.newCode = res.data.code;
        this.setInterval();
        this.btnBgc = true;
        this.flag = true;
        console.log("这是新的" + this.newCode);
      } else {
        this.$toast(res.msg);
      }
    },
    // 点击获取新手机验证码按钮，执行sendNewCode函数
    getNewCodes() {
      const mobile = /^1\d{10}$/;
      if (!mobile.test(this.newPhone)) {
        this.$toast("请输入正确的手机号");
        return;
      }
      this.sendNewCode();
    },
    // 获取下一步按钮的状态
    getNextStatus(value) {
      const mobile = /^1\d{10}$/;
      if (value == 1) {
        if (this.inputText != "") {
          this.isNextStep = true;
        } else {
          this.isNextStep = false;
        }
      } else {
        if (mobile.test(this.newPhone)) {
          if (this.newInputText != "") {
            this.isNextStep = true;
          } else {
            this.isNextStep = false;
          }
        }
      }
    },
    // 执行第二次下一步操作函数调出来
    async scondSendData() {
      const params = {
        codeType: 3,
        code: this.newCode,
        mobile: this.newPhone
      };
      const res = await changePhoneNumber(params);
      console.log(res);
      if (res.code == "0000") {
        this.controlColor = 3;
        this.$toast("修改成功");
      } else {
        this.$toast(res.msg);
      }
    },
    // 下一步操作
    async nextOperate() {
      // 第一次点击判断条件
      if (this.controlColor == 1) {
        if (this.inputText == this.oldCode) {
          this.controlColor = 2;
          this.btnText = "获取验证码";
          this.timeNumber = 60;
          this.btnBgc = false;
          this.flag = false;
          window.clearInterval(this.clock);
          this.clock = "";
          this.inputText = "";
          this.isNextStep = false;
        } else {
          this.$toast("请输入正确的验证码");
        }
      }
      // 第二次点击判断条件
      else if (this.controlColor == 2) {
        const mobile = /^1\d{10}$/;
        if (!mobile.test(this.newPhone)) {
          this.$toast("请输入正确手机号码");
          return;
        }
        if (this.newPhone == this.userPhone) {
          this.$toast("两次手机号码不能相同");
          return;
        }
        if (this.newInputText != this.newCode) {
          this.$toast("请输入正确验证码");
          return;
        }
        this.scondSendData();
      }
      // 如果修改成功
      else if (this.controlColor == 3) {
        this.$router.push("/user/accountSecurity");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.changeAccount {
  width: 890px;
  padding-top: 40px;
  height: auto;
  border: 1px solid #e8e8e8;
  p {
    padding-left: 30px;
    font-weight: 700;
    font-size: 20px;
  }
  .process {
    padding-top: 20px;
    .processImgs {
      text-align: center;
      span {
        text-align: center;
        height: 30px;
        width: 30px;
        line-height: 30px;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid #999;
      }
      i {
        height: 5px;
        width: 100px;
        display: inline-block;
        position: relative;
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        top: 0px;
      }
      .processContent {
        width: 550px;
        margin: 0 auto;
        padding-top: 10px;
        label {
          display: inline-block;
          font-size: 16px;
          &:nth-child(2) {
            padding: 0 170px;
            padding-left: 145px;
          }
        }
      }
      // 第一步操作的样式
      .getCode {
        padding-top: 50px;
        .bindPhone {
          width: 400px;
          margin: 0 auto;
          text-align: center;
          span {
            border: none;
            display: inline;
            font-size: 16px;
          }
        }
        .codePhone {
          padding-top: 30px;
          input {
            border: 1px solid #e8e8e8;
            height: 40px;
            width: 200px;
            border-radius: 5px;
            padding-left: 20px;
            font-size: 16px;
          }
          button {
            width: 150px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: white;
            font-size: 16px;
            cursor: pointer;
            padding: 0 20px;
            background-color: #b4a078;
          }
        }
      }
      // 第二步操作的样式
      .againCode {
        .codePhone {
          padding-top: 30px;
          .getNewNumber {
            display: block;
            width: 350px;
            margin: 0 auto;
            margin-bottom: 10px;
          }
          input {
            border: 1px solid #e8e8e8;
            height: 50px;
            width: 200px;
            border-radius: 5px;
            padding-left: 20px;
            font-size: 16px;
          }
          button {
            width: 150px;
            height: 50px;
            line-height: 40px;
            text-align: center;
            color: white;
            font-size: 16px;
            cursor: pointer;
            padding: 0 20px;
          }
        }
      }
      // 每次请求的提示信息
      .getMessageBox {
        color: red;
        height: 20px;
        line-height: 20px;
        padding-top: 20px;
        font-size: 18px;
      }
      .btnNext {
        padding-top: 50px;
        button {
          width: 120px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          font-size: 16px;
          background-color: #ccc;
        }
      }
    }
  }
  .warmTip {
    width: 890px;
    margin-top: 80px;
    padding-left: 30px;
    background-color: #f5f5f5;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    p {
      padding-left: 0;
      font-size: 18px;
      line-height: 30px;
    }
    ul {
      li {
        line-height: 25px;
        font-size: 15px;
        color: #999;
      }
    }
  }
}
.bgRed {
  background-color: #ff2441 !important;
}
.colorWhite {
  color: white;
}
.bgGold {
  background-color: #b4a078 !important;
}
.bgCcc {
  background-color: #ccc;
}
.bgGray {
  background-color: #999;
}
</style>