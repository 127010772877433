<template>
	<div class="tips-wrap">
		<ul class="list">

			<li>
				<router-link to=''>
					<i class="iconfont icon-kefu"></i>
					<p>4006999999</p>
				</router-link>
			</li>

		</ul>
	</div>
</template>
<script>
	export default {
		name: 'tips',
		components: {

		},
		props: {

		},
		data() {
			return {

			}
		},
		methods: {
			// 回到顶部
			top() {
				document.body.scrollTop = 0
				document.documentElement.scrollTop = 0
			},
		},
		mounted() {

		},
	}
</script>
<style lang="scss" scoped>
	.tips-wrap {
		width: 140px;
		position: fixed;
		top: 300px;
		right: 0px;
		z-index: 999;

		.list {
			
			// border: 1px solid #E6EAF0;
			border-bottom: none;
			font-size: 10px;
			color: #868E96;

			// text-align: center;
			li {
				// border-bottom: 1px solid #E6EAF0;
				padding: 10px 0;
				padding-left: 10px;
				background-color: #fac22f;
				border-radius: 20px 0 0 20px;
				a{
					display: flex;
					.icon-kefu{
						color: #111;
					}
					p{
						color: #111;
						padding-left:10px ;
						font-size: 16px;
					}
				}

			}
		}
	}
</style>
