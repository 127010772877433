<template>
  <div class="sort-right">
    <div class="scale">
      <div
        class="imgSrc"
        v-if="variablesObject && variablesObject.bigImgList && variablesObject.bigImgList[0]"
      >
        <router-link to>
          <img
            :src="variablesObject.bigImgList[0].imgUrl"
            alt
            @click="goOut(variablesObject.bigImgList[0].backUrl)"
          />
        </router-link>
      </div>
      <lists :list="list1" class="scaleImg"></lists>
    </div>
    <div>
      <lists :list="list2" class="smallImg2"></lists>
    </div>
  </div>
</template>
<script>
import lists from "@/components/list";
export default {
  props: {
    variablesObject: {
      type: Object,
      default: function() {
        return {};
      }
    },
    list: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  components: {
    lists
  },
  data() {
    return {
      list1: [],
      list2: []
    };
  },
  methods: {
    goOut(url) {
      window.open(url, "_blank");
    }
  },
  mounted() {
    this.list1 = this.list.slice(0, 2);
    this.list2 = this.list.slice(2, 6);
  }
};
</script>
<style lang="scss" scoped>
.sort-right {
  width: 930px;
  overflow: hidden;
}
.scale {
  height: 300px;
  display: flex;
}
.imgSrc {
  width: 465px;
  height: 300px;
  img {
    width: 465px;
    height: 300px;
  }
}
</style>