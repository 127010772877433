<!-- title -->
<template>
<div class="brand-main">
    <img src="@/assets/images/brandbg.png" alt="" class="bg">
    <div class="brand-content">
        <div class="brand-title">品牌专区</div>
        <div class="radius">
            <div class="zjimg"><a target="_blank" :href="cmsData.bannerList[0].bannerId"><img :src="cmsData.bannerList[0].bannerImg" alt=""></a></div>
            <div class="box-img">
                <a target="_blank" :href="cmsData.bannerList[1].bannerId"> <img :src="cmsData.bannerList[1].bannerImg" alt=""></a>
                <a target="_blank" :href="cmsData.bannerList[2].bannerId"> <img :src="cmsData.bannerList[2].bannerImg" alt=""></a>
                <a target="_blank" :href="cmsData.bannerList[3].bannerId"> <img :src="cmsData.bannerList[3].bannerImg" alt=""></a>
                <a target="_blank" :href="cmsData.bannerList[4].bannerId"> <img :src="cmsData.bannerList[4].bannerImg" alt=""></a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    components: {},
    props: ["cmsData"],
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
.brand-main {
    .bg {
        z-index: -1;
        position: absolute;
        left: 0;
        width: 100%;
    }
}

.brand-content {
    height: auto;
    margin: 20px auto;

    .brand-title {
        font-size: 20px;
        color: #fff;
        padding: 30px 0 10px 20px;
    }

    .radius {
        border-radius: 20px;
        background-color: #fff;
        display: flex;
        padding: 20px;

        img {
            border-radius: 20px;
        }

        .zjimg {
            width: 44%;

            img {
                width: 100%;
            }
        }

        .box-img {
            display: flex;
            width: 56%;
            flex-wrap: wrap;

            a {
                width: 46%;
                margin-left: 25px;
                margin-bottom: 20px;

                img {
                    width: 100%;
                    height: 243px;
                }
            }
        }
    }

}
</style>
