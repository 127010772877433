import { render, staticRenderFns } from "./inteShopping.vue?vue&type=template&id=5a359344&scoped=true"
import script from "./inteShopping.vue?vue&type=script&lang=js"
export * from "./inteShopping.vue?vue&type=script&lang=js"
import style0 from "./inteShopping.vue?vue&type=style&index=0&id=5a359344&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a359344",
  null
  
)

export default component.exports