<template>
  <div class="list listImgs">
    <ul>
      <li class="goods-wrap" v-for="(item,index) in list" :key="index" @click="goDetail(item)">
        <div class="img">
          <img :src="item.picUrl" alt class="image" :class="{'imageBorder':!item.picUrl}" />
        </div>
        <div class="info">
          <p class="title">{{item.mpName}}</p>
          <p class="price">
            <span class="nowPrice">{{item.promPrice|formatMoney}}</span>
            <span class="oldPrice">原价{{item.salePriceWithTax|formatMoney}}</span>
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 是不是秒杀商品
    isSeckill: {
      default: false
    }
  },
  data() {
    return {};
  },

  methods: {
    // 新页面跳转到商品详情
    goDetail(item) {
      if (this.isSeckill) {
        const routerUrl = this.$router.resolve({
          path: `/list/detail?id=${item.mpId}&type=sckill`
        });
        window.open(routerUrl.href, "_blank");
      } else {
        const routerUrl = this.$router.resolve({
          path: `/list/detail?id=${item.id}`
        });
        window.open(routerUrl.href, "_blank");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list {
  width: 1200px;
  margin: 20px auto 0;
  ul {
    width: 1220px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      width: 230px;
      border: 1px solid #e6eaf0;
      img {
        display: block;
        width: 228px;
        height: 228px;
        margin: 0 auto;
        .image {
          width: 100%;
          height: 100%;
        }
      }
      .imageBorder {
        border: 1px solid #e6eaf0;
        box-sizing: border-box;
      }
      .shopcar {
        display: none;
      }
    }
  }
  &.scaleImg {
    width: 930px;
    margin: 0;
    ul {
      width: 465px;
      height: 300px;
      overflow: hidden;
      li {
        position: relative;
        width: 232.5px;
        height: 300px;
        margin: 0;
        padding-top: 20px;
        &:last-child {
          border-left: 0;
        }
        img {
          width: 150px;
          height: 150px;
          margin: 0 auto;
        }
        &:hover {
          border-color: $themeColor;
          &::before,
          &::after {
            content: "";

            background: $themeColor;
            position: absolute;
            left: 0;
            z-index: 99;
          }
          &::before {
            height: 100%;
            width: 1px;
            top: 0;
          }
          &::after {
            height: 1px;
            width: 100%;
            bottom: 0;
          }
        }
      }
    }
  }
  &.smallImg2 {
    width: 930px;
    margin: 0;
    ul {
      width: 930px;
      height: 181px;
      overflow: hidden;
      li {
        position: relative;
        width: 232.5px;
        height: 181px;
        margin: 0;
        border-left: 0;
        padding: 31px 0 30px 20px;
        clear: both;
        &:nth-last-child(1),
        &:nth-last-child(2) {
          border-top: 0;
        }
        img {
          width: 126px;
          height: 126px;
          float: right;
        }
        .info {
          width: 80px;
          padding: 0;
          .title {
            height: 43px;
            white-space: normal;
            text-align: left;
            margin: 0;
          }
          .desc {
            display: none;
          }
          .price {
            margin-top: 20px;
            white-space: normal;
            .oldPrice {
              margin-top: 6px;
              display: block;
            }
          }
        }
        &:hover {
          border-color: $themeColor;
          &::before {
            content: "";
            height: 100%;
            width: 1px;
            background: $themeColor;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 99;
          }
        }
      }
    }
  }
  &.smallImg3 {
    margin: 10px auto 0;
    ul {
      width: 1220px;
      height: 305px;
      overflow: hidden;
      li {
        border: 0;
        position: relative;
        background: #fff;
        .img {
          width: 230px;
          height: 230px;
          border: 1px solid #e6eaf0;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .desc {
          display: none;
        }
      }
    }
  }
  &.smallImg4 {
    width: 595px;
    height: 500px;
    margin: 0;
    border: 1px solid #e6eaf0;
    box-sizing: border-box;
    border-left: 0;
    border-right: 0;
    ul {
      width: auto;
      padding: 10px 20px;
      overflow: hidden;
      li {
        width: 33.33%;
        height: 250px;
        border: 0;
        margin: 0;
        img {
          width: 160px;
          height: 160px;
        }
        .desc {
          display: none;
        }
        .price {
          .nowPrice {
            font-size: 14px;
            color: #333;
          }
        }
      }
    }
  }
  &.smallImg5 {
    width: 309px;
    margin: 0;
    ul {
      width: 309px;
      max-height: 436px;
      overflow: hidden;
      li {
        width: 100%;
        clear: both;
        margin: 0;
        padding: 15px;
        border-top: 0;
        display: flex;
        align-items: center;
        border-left: 0;
        border-right: 0;
        img {
          width: 80px;
          height: 80px;
          float: left;
        }
        .info {
          width: 170px;
          padding: 0;
          .title {
            max-height: 80px;
            padding-left: 15px;
            padding-top: 18px;
            margin: 0;
            text-align: left;
          }
          .desc {
            display: none;
          }
          .price {
            padding-left: 15px;
            text-align: left;
          }
        }
        .shopcar {
          display: block;
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  &.smallImg6 {
    width: 930px;
    overflow: hidden;
    margin: 0;
    ul {
      width: 930px;
      height: 340px;
      overflow: hidden;
      position: relative;
      li {
        width: 232.5px;
        height: 100%;
        margin: 0;
        border: 1px solid #e6eaf0;
        box-sizing: border-box;
      }
    }
  }
}
</style>