<template>
  <div class="register">
    <h2 class="title">1、任何人都可以注册成为海尔海贝商城会员吗？</h2>
    <div class="p">是的 ，只需注册即可成为海尔海贝商城会员。</div>
    <h2 class="title">2、注册成为海尔海贝商城会员收费吗?</h2>
    <div class="p">注册成为海尔海贝商城会员是免费的。</div>
    <h2 class="title">3、如何登录海尔海贝商城?</h2>
    <div class="p">
      进入jf.haier.com
      ，并使用以下3种方式：用户名+密码；email地址+密码；手机号+密码。
    </div>
    <h2 class="title">4、如何找回密码?</h2>
    <div class="p">在网上登录页面点击“忘记密码”，按提示操作。</div>
    <h2 class="title">5、多个账户可以合并吗？</h2>
    <div class="p">不可以，不同账户海贝不可合并使用。</div>
    <h2 class="title">6、为什么系统提示我的邮箱地址已经被注册？</h2>
    <div class="p">
      出现此系统提示，您可通过手机号或email注册新用户，您的手机号、email或用户名均无法与已有的其他账户重复。
    </div>
    <h2 class="title">7、我是企业用户，我能注册成为海尔海贝商城会员吗？</h2>
    <div class="p">海尔海贝商城会员目前只针对个人用户。</div>
    <h2 class="title">8、用户名是几位的，允许中文吗？</h2>
    <div class="p">
      用户名为4-16个字符，一个汉字为2个字符，不能包含符号等特殊字符。
    </div>
    <h2 class="title">9 、海尔海贝商城会员热线是多少？</h2>
    <div class="p">海尔海贝商城会员服务热线号码：4006999999</div>
    <h2 class="title">10 “账户设置”中的“个人信息”页面将用于什么用途？</h2>
    <div class="p">
      “个人信息”分为必填项和选填项。为了给您更优质的服务请您填写并完善个人信息。当信息变更时，请您及时更新个人信息。手机号和邮箱需激活验证后才有效。同时也保证您兑换的产品第一时间的准确送达您手中。
    </div>
    <h2 class="title">11、我填写的个人信息是否是保密处理？</h2>
    <div class="p">
      所有的个人资料将做高度保密处理。当海尔举行各种互动活动时，您提供的联系方式将被用于客户关怀和优惠信息的通知。此外，如果当您忘记了账号或者密码，您提供的资料将用于身份的核对。
    </div>
    
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },

  methods: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
.title {
  color: #808080;
  margin-top: 5px;
}
.p {
  margin-top: 5px;
  text-indent: 2em;
  font-size: 16px;
  color: #808080;
  line-height: 1.5;
  padding-bottom: 15px;
}
</style>
