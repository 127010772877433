<template>
  <div class="haier-header">
    <div class="banner-notive">
      <div class="marquee">
        <div class="marquee_title">
          <span><img :src="require('@/assets/images/tumpet.png')" alt="" /></span>
          <span>公告：</span>
        </div>
        <div class="marquee_box">
          <ul class="marquee_list" :class="{ marquee_top: animate }">
            <li v-for="(item, index) in list" :key="index">
              <router-link target="_blank" :to="{ path: '/centre/noticeDetail', query: { notice: JSON.stringify(item) } }">
                {{ item.title }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="lkmore" @click="gonovite">查看更多 ></div>
      </div>

      <!-- <div>
              <div class="text" v-for="(item,index) in list" :key="index">
                  <div @click="noticedetail(item)">
                      {{item.title}}
                  </div>
              </div>
          </div> -->
    </div>
    <!-- 顶部菜单 -->
    <div class="header-menu">
      <div class="header-menu-main">
        <div class="haier-login">
          <span>欢迎来到卡萨帝积分商城! </span>
        </div>
        <div class="menu-main">
          <div class="pinpai">
            <div class="pinpaititle">品牌专区</div>
            <div class="pin">
              <div class="menu-pinpai">
                <a href="https://jf.haier.com">海尔积分商城</a>
              </div>
            </div>
          </div>
          <a v-for="(item, index) in topMunu" :key="index" @click="goMenu(item.path)">{{ item.title }}</a>
        </div>
      </div>
    </div>

    <div class="header-main">
      <img @click="goMenu('/')" class="haier-logo" src="../../../../static/images/casartelogo.png" alt="" />
      <!-- 搜索 -->
      <div class="search">
        <div class="search-drop">
          <el-dropdown class="dropdown" @command="handleCommand">
            <span class="dropdown-text"> {{ pointArea }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1,10000,1～1w">1～1w</el-dropdown-item>
              <el-dropdown-item command="10001,30000,1w～3w">1w～3w</el-dropdown-item>
              <el-dropdown-item command="30001,50000,3w～5w">3w～5w</el-dropdown-item>
              <el-dropdown-item command="50000,100000,5w～10w">5w～10w</el-dropdown-item>
              <el-dropdown-item command="100000,200000,10w～20w">10w～20w</el-dropdown-item>
              <el-dropdown-item command="200000,10000000000000000000,20w以上">20w以上</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="search-input">
          <input type="text" class="search-input-text" v-model="cName" placeholder="请输品牌或商品进行搜索" />
        </div>
        <div class="search-button" @click="searchList()">
          <div class="s-b-block">
            <img src="../../../../static/images/sousuo.png" alt="" />
          </div>
        </div>
      </div>
      <div>
        <ul class="herder-ul">
          <li>
            <span class="login-button" @click="login()" v-if="!isToken">登录 / </span>
            <span class="login-button" @click="register()" v-if="!isToken">注册</span>
            <span class="login-button" @click="signout()" v-if="isToken">退出</span>
          </li>
          <li>
            <div class="search-userinfo">
              <img :src="headurl" v-if="isToken" />
              <img :src="require('@/assets/images/haier_avatar.png')" v-if="!isToken" />
              <div class="userinfo-main">
                <span class="userinfo-nickname">
                  <span v-if="!isToken">欢迎来到卡萨帝积分商城！</span>
                  <span v-if="isToken">欢迎！{{ nickname }}</span></span
                >
                <span class="userinfo-point" v-if="isToken">
                  <span><img @click="goMenu('/centre/personalCenter')" :src="require('@/assets/images/jifen2.png')" alt="" /></span>
                  <span>{{ totalPoint }}积分</span>
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// 导包区域
import { totalPoint, getUserInfo, signOut, sysNotice } from '@/api/api';
import storage from '@/store/store';

// JS输出到页面的区域
export default {
  name: 'headers',
  components: {},

  props: {},

  /**
   *数据区域
   */
  data() {
    return {
      cName: '',
      isToken: false,
      nickname: '未登录',
      headurl: '',
      totalPoint: '0',
      animate: false,
      list: [],
      topMunu: [
        {
          title: '我的订单',
          path: '/centre/ordersList'
        },
        {
          title: '我的购物车',
          path: '/products/cart'
        },
        {
          title: '积分卡激活',
          path: '/centre/pointInvest'
        },
        {
          title: '会员中心',
          path: '/centre/personalCenter'
        },
        {
          title: '我的收藏',
          path: '/centre/myCollection'
        }
      ],
      bottomMunu: [
        {
          title: '通知公告',
          path: '/centre/notice'
        },

        {
          title: '我能兑换',
          path: '/products/productList?minPoint=1&maxPoint=',
          name: 2
        },

        {
          title: '我的海贝',
          path: '/centre/personalCenter'
        },
        {
          title: '获取海贝',
          path: '/helpCenter/helpGet'
        }
      ],
      chineseName: '未登录',
      pointArea: '积分范围',
      maxPoint: '',
      minPoint: '',
      brandList: [
        {
          name: '海尔',
          path: 'https://www.haier.com/cn/'
        },
        {
          name: '卡萨帝',
          path: 'http://www.casarte.com/'
        },
        {
          name: '统帅',
          path: 'http://www.tongshuai.com/'
        }
      ]
    };
  },

  /**
   * 方法区
   */
  methods: {
    /**
     * 页头初始化数据方法
     */
    headerDataInit() {
      //初始化页面
      if (this.nickname == '未登录') {
        this.isToken = true;
      }
      console.log(this.nickname);
      if (localStorage.getItem('hbscToken') == null) {
        this.isToken = false;
      } else {
        this.upperFindTotalPoint();
        this.getUserInfo();
        this.isToken = true;
      }
    },
    /**
     * 获取用户总结分
     */
    async upperFindTotalPoint() {
      //获取总积分
      let params = {};
      let totalRes = await totalPoint(params);
      if (totalRes.code == '0000') {
        this.totalPoint = totalRes.data;
        this.bottomMunu[1].path += this.totalPoint;
      }
    },

    /**
     * 登录
     */
    login() {
      // debugger;
      //跳转用户中心登录
      var radom = Math.random();
      localStorage.setItem('checkState', radom);

      let Url = 'https://jf.casarte.com';
      var Url2 = encodeURIComponent(Url);

      //测试环境地址
      // window.location.href =
      //     "https://taccount.haier.com/oauth/authorize?client_id=casartepointtest&response_type=code&state=xyz&redirect_uri=" +
      //     Url2;

      // var returnUrl =
      window.location.href =
        'http://account.haier.com/oauth/authorize?client_id=casartepoint&response_type=code&state=' + radom + '&redirect_uri=' + Url2;
    },

    /**
     * 异步退出登录
     */
    async signout() {
      if (localStorage.getItem('hbscToken') != null) {
        let params = {
          token: localStorage.getItem('hbscToken')
        };
        let userInfo = await signOut(params);
        // debugger;
        if (userInfo != null && userInfo.code == '0000') {
          console.log('远程注销成功！');
        } else {
          console.log('远程注销失败');
        }
        console.log(userInfo);
      } else {
        console.log('无token退出');
      }

      storage.removeToken();
      this.$store.commit('change', true);
      this.$store.commit('changeLoginStatus', false);
      localStorage.removeItem('hbscToken');
      localStorage.removeItem('memberId');
      localStorage.removeItem('checkState');
      localStorage.removeItem('memberPhone');
      localStorage.removeItem('memberName');

      this.$cookies.remove('NTKF_T2D_CLIENTID');
      this.$cookies.remove('gr_user_id');
      this.$cookies.remove('_trs_uv');
      this.$cookies.remove('nTalk_CACHE_DATA');
      this.$cookies.remove('INGRESSCOOKIE');
      this.$cookies.remove('hbscToken');

      this.delCookie('NTKF_T2D_CLIENTID');
      this.delCookie('gr_user_id');
      this.delCookie('_trs_uv');
      this.delCookie('nTalk_CACHE_DATA');
      this.delCookie('INGRESSCOOKIE');
      this.delCookie('hbscToken');
      this.$router.go(0);
      window.location.href = 'https://jf.casarte.com';
    },

    /**
     * 获取cookies
     */
    getCookie(name) {
      var arr,
        reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
      if ((arr = document.cookie.match(reg))) {
        return arr[2];
      } else {
        return false;
      }
    },

    /**
     * 删除cookies
     */
    delCookie(name) {
      var exp = new Date();
      exp.setTime(exp.getTime() - 1);
      var cval = this.getCookie(name);
      if (cval) {
        document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString();
      }
    },

    /**
     * 跳转到菜单页面
     */
    goMenu(path) {
      this.$router.push({
        path: path
      });
    },

    /**
     * 跳转到购物车页面
     */
    gocard() {
      this.$router.push({
        path: '/products/cart'
      });
    },

    /**
     * 异步获取用户信息
     * 2021-3-20 15:06:01
     */
    async getUserInfo() {
      let params = {};
      let userInfo = await getUserInfo(params);

      if (userInfo != null) {
        if (userInfo.data != null) {
          this.nickname = userInfo.data.nickname;
          this.createTime = userInfo.data.createTime;
          this.headurl = userInfo.data.headurl;
        }
      }
    },
    searchList() {
      this.$router.push({
        path: '/products/productList',
        query: {
          cName: this.cName,
          maxPoint: this.maxPoint,
          minPoint: this.minPoint
        }
      });
    },
    handleCommand(command) {
      let strList = command.split(',');
      this.maxPoint = strList[1];
      this.minPoint = strList[0];
      this.pointArea = strList[2];
    },
    register() {
      window.location.href = 'http://account.haier.com/register';
    },
    goBrand(path) {
      this.$router.push({
        path: path
      });
    },
    async getNotice() {
      let params = {
        type: 2
      };
      const res = await sysNotice(params);
      if (res.code == '0000') {
        this.list = res.data.rows;
      }
    },
    gonovite() {
      const { href } = this.$router.resolve({
        path: '/centre/notice'
      });
      window.open(href, '_blank');
    },
    showMarquee: function () {
      this.animate = true;
      setTimeout(() => {
        this.list.push(this.list[0]);
        this.list.shift();
        this.animate = false;
      }, 500);
    }
  },

  mounted() {
    this.getNotice();
    this.headerDataInit();
  },
  created: function () {
    // 页面显示
    setInterval(this.showMarquee, 2000);
  }
};
</script>

<style lang="scss" scoped>
.marquee {
  width: 1200px;
  margin: 0 auto;
  // height: 50px;
  align-items: center;
  color: #999;
  background-color: #fff;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;

  .marquee_title {
    // width: 90px;
    padding-left: 20px;
    height: 21px;
    font-size: 14px;
    // border-right: 1px solid #d8d8d8;
    display: flex;
    color: #333333;

    span {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .marquee_box {
    display: block;
    position: relative;
    width: 85%;
    height: 30px;
    overflow: hidden;

    a {
      color: #999;
    }
  }

  .marquee_list {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .marquee_top {
    transition: all 0.5s;
    margin-top: -30px;
  }

  .marquee_list li {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    padding-left: 20px;
    color: #999999;
  }

  .marquee_list li span {
    padding: 0 2px;
  }
}

.pinpai {
  position: relative;

  .pinpaititle {
    cursor: pointer;
  }

  .pin {
    display: none;

    .menu-pinpai {
      width: 180px;
      display: flex;
      align-items: center;
      background: #fff;
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 100;

      a {
        text-align: center;
        width: 50%;
        margin-left: 0 !important;
      }
    }
  }
}

.pinpai:hover {
  .pinpaititle {
    color: #ac8c4f;
  }

  .pin {
    display: block;
  }
}

.herder-ul {
  display: flex;
  font-size: 14px;
  color: #9a9a9a;
}

.herder-ul li {
  display: flex;
  align-items: center;
  margin: 0 20px;

  img {
    margin-right: 5px;
  }
}

.login-button {
  color: #9a9a9a;
  cursor: pointer;
  font-size: 14px;
}

.haier-header {
  // height: 205px;
  width: 100%;
  margin: 0 auto;
  background-color: #f3f4f5;

  .header-menu {
    width: 100%;
    height: 40px;
    background-color: #f3f4f5;
    border-bottom: 1px #d5d5d5 solid;

    .login-button:hover {
      color: #ac8c4f;
    }

    .header-menu-main {
      margin: 0 auto;
      display: flex;
      width: 1200px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .menu-main {
        width: auto;
        height: 100%;
        line-height: 40px;
        display: flex;
        justify-content: flex-end;

        a {
          margin-left: 55px;
          color: #333;
          cursor: pointer;
        }

        a:hover {
          color: #ac8c4f;
        }
      }
    }
  }

  .header-main {
    width: 1200px;
    margin: 0 auto;
    height: 115px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .haier-logo {
      width: 154px;
      // height: 32px;
      cursor: pointer;
    }

    .search {
      width: auto;
      height: 50px;
      display: flex;
      flex-direction: row;
      background: #fff;
      border-radius: 26px;

      .search-drop {
        width: 105px;
        height: 100%;
        background: #fff;
        border-left: 2px solid #d5d5d5;
        border-top: 2px solid #d5d5d5;
        border-bottom: 2px solid #d5d5d5;
        border-top-left-radius: 26px;
        border-bottom-left-radius: 26px;
        display: flex;
        align-items: center;
        justify-content: center;

        .dropdown-text {
          color: #ac8c4f;
        }
      }

      .search-input {
        width: 365px;
        height: 100%;
        border: 2px solid #d5d5d5;
        border-left: none;
        border-right: none;

        .search-input-text {
          width: 90%;
          height: 100%;
          text-indent: 1em;
          font-size: 14px;
          color: #999999;
        }
      }

      .search-button {
        background: #fff;
        // width: 85px;
        height: 100%;
        // border-radius: 5px;
        // background-color: #71a2f9;
        margin-left: -4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 26px;
        border-bottom-right-radius: 26px;
        border-top: 2px solid #d5d5d5;
        border-bottom: 2px solid #d5d5d5;

        .s-b-block {
          width: 48px;
          height: 48px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .search-cart {
      opacity: 1;
      width: 128px;
      height: 40px;
      border: 1px solid #2283e2;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      span {
        font-size: 12px;
        font-weight: 550;
        text-align: left;
        color: #4b89f8;
        margin-left: 10px;
      }

      .cart-icon {
        width: 24px;
        height: 20px;
        position: relative;
        margin-left: 17px;

        img {
          width: 24px;
          height: 20px;
        }

        .cart-count {
          width: 16px;
          height: 16px;
          background-color: #ff0135;
          border-radius: 50%;
          line-height: 16px;
          text-align: center;
          color: #fff;
          position: absolute;
          right: -6px;
          top: -6px;
        }
      }
    }

    .search-userinfo {
      width: auto;
      height: 50px;
      display: flex;
      flex-direction: row;
      cursor: pointer;

      img {
        height: 48px;
        width: 48px;
        border-radius: 50%;
        margin-right: 12px;
      }

      .userinfo-main {
        height: 100%;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .userinfo-nickname {
          color: #333;
          font-size: 14px;
          margin-bottom: 5px;
        }

        .userinfo-login {
          display: flex;
          flex-direction: row;
          align-items: center;

          span:hover {
            color: #2283e2;
            cursor: pointer;
          }
        }

        .userinfo-point {
          color: #ffb100;
          font-size: 14px;

          span {
            img {
              width: 20px;
              height: 20px;
              border-radius: 0;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .banner-notive {
    width: 100%;
    height: 30px;
    background: #fff;
    display: flex;
    align-items: center;
    color: #333333;
    border-bottom: 1px solid #eee;
    margin: auto;

    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    .lkmore {
      font-size: 14px;
      color: #ac8c4f;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .header-entrance {
    height: 50px;
    width: 100%;
    // background-color: #2283e2;

    .entrance-main {
      width: 1200px;
      margin: 0 auto;
      display: flex;

      .all {
        width: 190px;
        background: #71a2f9;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        line-height: 52px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        // font-weight: bold;
        cursor: pointer;

        img {
          width: 16px;
          height: 16px;
          display: block;
          margin-right: 5px;
        }
      }

      .brand {
        position: relative;

        .brand-list {
          display: none;
          position: absolute;
          bottom: -10;
          left: 0;
          z-index: 3;
          width: 150px;
          height: auto;
          background-color: #fff;

          li {
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            cursor: pointer;

            a {
              width: 80%;
              border-bottom: 1px solid #666;
            }
          }

          li:hover {
            background-color: #71a2f9;

            a {
              color: white;
            }
          }
        }
      }

      .brand:hover {
        .brand-list {
          display: block;
        }
      }

      a {
        display: inline-block;
        height: 100%;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        color: #666;
        // font-weight: bold;
        cursor: pointer;
        padding: 0 25px;
      }

      .item {
        display: inline-block;
        height: 100%;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        color: #666;
        // font-weight: bold;
        cursor: pointer;
        padding: 0 25px;
      }

      .item:hover {
        color: #2283e2;
      }

      .router-link-active {
        color: #2283e2;
      }
    }
  }
}
</style>
