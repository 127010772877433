<template>
	<div class="container">
		<div class="title">热门兑换</div>

		<div class="swiper-container" @mouseenter="comtainer_enter" @mouseleave="comtainer_leave">
			<!-- 这边的两个方法是鼠标移入和移出 -->
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item,index) in arr" :key="index">
					<img :src="item.img_url" alt="" width="100%">
					<div class="swiper">
						<div class="swiper-item titles">{{item.title}}</div>
						<div class="swiper-item integral">{{item.integral}}积分</div>
						<div class="swiper-item xian"></div>
						<div class="btn"><span>立即兑换</span></div>
					</div>
				</div>
			</div>
			<!-- 如果需要导航按钮 -->
			<!-- <div class="swiper-button-prev"></div>
			<div class="swiper-button-next"></div> -->
			<!-- 指示点 -->
			<div class="swiper-pagination"></div>
			<!--分页器。如果放置在swiper-container外面，需要自定义样式。-->
		</div>


	</div>
</template>

<script>
	import Swiper from 'swiper';

	export default {
		data() {
			return {
				swiper: '',
				index: '',
				arr: [
					{
						title:"Samsung无线耳机",
						integral:"10000",
						img_url: require('../../static/images/banner1.png'),
						
					},
					{
						title:"Samsung无线耳机",
						integral:"10500",
						img_url: require('../../static/images/banner1.png')
					},
					{
						title:"Samsung无线耳机",
						integral:"10000",
						img_url: require('../../static/images/banner1.png')
					},
					{
						title:"Samsung无线耳机",
						integral:"10000",
						img_url: require('../../static/images/banner1.png')
					},
					{
						title:"Samsung无线耳机",
						integral:"10700",
						img_url: require('../../static/images/banner1.png')
					},
					{
						title:"Samsung无线耳机",
						integral:"10900",
						img_url: require('../../static/images/banner1.png')
					},
				]

			}

		},
		mounted() {
			this.swiper = new Swiper(".swiper-container", {
				autoplay: { //自动轮播
					disableOnInteraction: false, //当设置为false时，用户操作之后（swipes,arrow以及pagination 点击）autoplay不会被禁掉，用户操作之后每次都会重新启动autoplay。
				},
				pagination: { //指示点
					el: '.swiper-pagination',
				},
				grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
				loop: true,
				// 如果需要前进后退按钮
				// navigation: {
				// 	nextEl: '.swiper-button-next',
				// 	prevEl: '.swiper-button-prev',
				// },
				breakpoints: {
					1520: {
						slidesPerView: 6,
						spaceBetween: 30
					},
				},
			})
		}
	}
</script>

<style scoped lang="scss">
	.container {
		width: 100%;
		padding-top: 30px;

		.title {
			width: 1520px;
			height: 80px;
			line-height: 80px;
			margin: 0 auto;
			text-align: center;
			font-size: 18px;
			background-image: url(../../static/images/xian.png);
			background-size: 100% 100%;
		}
		.swiper-slide{
			position: relative;
		}
		.swiper{
			width: 100%;
			text-align: center;
			position: absolute;
			bottom: 80px;
			left: 50%;
			transform: translate(-50%,0);
			color: #fff;
			font-size: 18px;
			.swiper-item{
				margin-bottom: 8px;
			}
			.xian{
				width: 20px;
				margin: 0 auto;
				height: 1px;
				border-bottom: 1px solid #fff;
				margin-bottom: 10px;
			}
			.btn{
				span{
					display: block;
					margin: 0 auto;
					width: 90px;
					height: 20px;
					line-height: 20px;
					font-size: 13px;
					border: 1px solid #fff;
					border-radius: 20px;
				}
				
			}
		}

		.swiper-container {
			width: 1520px;
			height: 350px;
			margin: 20px auto;
		}
	}
</style>
