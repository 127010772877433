<template>
  <div class="addEvaluate">
    <ul>
      <li>
        <div class="goods">
          <img :src="productInfo.goodsImgUrl" alt />
          <p class="name">{{productInfo.goodsName}}</p>
          <p class="price">{{productInfo.saleUnitPrice|formatMoney}}</p>
        </div>

        <div class="form">
          <div class="isAppend mb20" v-if="isAppend">
            <p class="mb20 time">2020-04-29 10:51</p>

            <stars class="mb20" :isDisabled="isAppend"></stars>
            <p>刚拿到手就用了，感觉很好用，好评好评！</p>
          </div>
          
          <div class="item" v-else>
            <p class="s1" v-if = 'pid? false: true'>商品评分</p>
            <div class="stars" v-if = 'pid? false: true'>
              <stars @getStart="starNum"></stars>
            </div>
          </div>

          <div class="item mb20">
            <p class="s1" v-if="!isAppend">评价晒单</p>
            <div class="textarea">
              <textarea v-model="content"></textarea>
            </div>
          </div>
          <div class="item">
            <p class="s1" v-if="!isAppend">评价晒图</p>
            <van-uploader v-model="fileList" multiple :before-read="beforeRead" />
          </div>
        </div>
      </li>
    </ul>

    <div class="btns">
      <button @click="updateProductComment">{{ pid ? '追加评价' : '发表评价' }}</button>
    </div>
  </div>
</template>

<script>
import stars from "@/components/star";
import { updateProductComment, evaluateList, getOrderDetail } from "@/api/api";
export default {
  components: { stars },

  data() {
    return {
      isAppend: false, //是否为追加评价
      id: "", //订单号
      storeId: "", //店铺id
      merchantId: "", //所属商家
      goodsId: "", //商品id
      productInfo: {}, //商品信息
      pid: "", //是否是追评
      content: "", //评价的内容
      cur: "", //评价的星数
      fileList: [] //评价图片信息
    };
  },

  methods: {
    // 获取当前订单详情
    getOrderNo() {
      this.id = this.$route.query.orderNo;
      this.goodsId = this.$route.query.goodsId;
      this.pid = this.$route.query.pid;
    },

    // 获取商品的信息
    async getComment() {
      const params = {
        start: 1,
        limit: 10,
        orderStatus: 11
      };
      const { data } = await evaluateList(params);
      // 筛选当前订单
      let object = {};
      data.forEach((item, index) => {
        if (item.orderNo == this.id) {
          object = data[index];
        }
      });
      this.storeId = object.storeId;
      this.merchantId = object.merchantId;
      // 筛选当前商品
      let projectObject = {};
      object.goodsInfoList.forEach((item, index) => {
        if (item.goodsId == this.goodsId) {
          projectObject = object.goodsInfoList[index];
        }
      });
      this.productInfo = projectObject;
    },

    // 获取评价的星数
    starNum(value) {
      this.cur = value;
    },

    // 上传图片之前判断的文件类型
    beforeRead(file) {
      if (file.type !== "image/jpeg") {
        this.$toast("请上传 jpg 格式图片");
        return false;
      }
      return true;
    },

    // 返回 Promise
    asyncBeforeRead(file) {
      return new Promise((resolve, reject) => {
        if (file.type !== "image/jpeg") {
          this.$toast("请上传 jpg 格式图片");
          reject();
        } else {
          resolve();
        }
      });
    },

    // 追加评论或者发表评论
    async updateProductComment() {
      let sendData = {
        channel: "H5",
        merchantId: this.merchantId,
        orderNo: this.id,
        storeId: this.storeId,
        productCommentGoodsRequests: {
          productId: this.productInfo.goodsId,
          productImg: this.productInfo.goodsImgUrl,
          productName: this.productInfo.goodsName,
          anonymousFlag: 0, //0:不匿名 1:匿名
          content: this.content,
          fileList: [],
          pid: this.pid
        }
      };
      // 这是追加评论,不能对星级评价
      if (this.pid) {
        sendData.type = 2    //type 2是追评论
        const res = await updateProductComment(sendData);
        if(res.code == '0000'){
            this.$toast('评价成功')
            this.$router.push({
            path:"/user/evaluate"
        })
        }else{
            this.$toast(res.msg)
        }
      } else {
        // 这是新评论
        sendData.type = 1    //type 1是评论
        sendData.productCommentGoodsRequests.productRating = this.cur  //商品评分
        sendData.productCommentGoodsRequests.serviceRating = 5   //页面无此演示，暂时写死
        sendData.productCommentGoodsRequests.overallRating = 5
        sendData.productCommentGoodsRequests.logisticsScore = 5
        const res = await updateProductComment(sendData);
        if (res.code == "0000") {
          this.$toast("评价成功");
             this.$router.push({
            path:"/user/evaluate"
        })
        } else {
          this.$toast(res.msg);
        }
      }
    }
  },

  mounted() {
    this.getOrderNo();
    this.getComment();
  }
};
</script>

<style lang="scss" scoped>
.addEvaluate {
  ul {
    padding: 20px 0;
    border: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;
    li {
      display: flex;
      .goods {
        width: 322px;
        padding: 50px;
        text-align: center;
        border-right: 1px dashed #eaeaea;
        img {
          width: 100px;
          height: 100px;
        }
        .name {
          line-height: 20px;
          color: #666;
        }
        .price {
          font-weight: bold;
          margin-top: 10px;
        }
      }
      .form {
        padding: 30px;
        .item {
          display: flex;
        }
        .s1 {
          width: 100px;
          margin-bottom: 30px;
        }
        .textarea {
          width: 500px;
          padding: 10px;
          border: 1px solid #eaeaea;
          textarea {
            width: 100%;
            height: 50px;
            resize: none;
          }
        }
      }
    }
  }
  .btns {
    text-align: center;
    button {
      width: 220px;
      height: 48px;
      line-height: 48px;
      background: $themeColor;
      color: #fff;
      font-size: 18px;
      margin: 30px auto;
    }
  }
  .isAppend {
    .time {
      color: #999;
    }
  }
}
</style>