<template>
  <div class="commstar">
    <div
      v-for="(n,ni) in 5"
      @mouseenter="enter(n)"
      @mouseleave="leave()"
      @click="Selected(n)"
      :class="[{'on':cur>=n},'star',{'isDisabled':isDisabled}]"
      :key="ni"
    ></div>
    <p>{{cur>0?cur:0}}分</p>
  </div>
</template>
<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    evaluateNum: {
      type: Number,
      default: 0
    }
  },
  components: {},
  data() {
    return {
      cur: 5,
      flag: false
    };
  },
  methods: {
    enter(idx) {
      if (this.isDisabled) {
        return false;
      }
      if (!this.flag) {
        this.cur = idx;
      }
    },
    leave() {
      if (this.isDisabled) {
        return false;
      }
      if (!this.flag) {
        this.cur = 1;
      }
    },
    Selected(n) {
      if (this.isDisabled) {
        return false;
      }
      this.flag = true;
      this.cur = n;
      this.$emit("getStart", this.cur);
    },
    // 获取星星状态
    getCur() {
      if (this.isDisabled) {
          this.cur = this.evaluateNum;
      }
    }
  },
  mounted() {
    this.getCur();
  },
};
</script>

<style lang="scss" scoped>
.commstar {
  display: flex;
  justify-content: flex-start;
  .star {
    width: 30px;
    height: 30px;
    float: left;
    cursor: pointer;
    background: url("../../static/images/off.png") no-repeat;
    background-size: 100%;
    &.isDisabled {
      cursor: default;
    }
    &.on {
      background: url("../../static/images/on.png") no-repeat;
      background-size: 100%;
    }
  }
  p {
    line-height: 30px;
    margin-left: 10px;
    font-size: 16px;
    color: $themeColor;
  }
}
</style>