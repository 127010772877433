<template>
<div>
    <div>
        <el-steps :active="3" align-center>
            <el-step title="步骤1" description="登录海贝商城"></el-step>
            <el-step title="步骤2" description="输入卡号"></el-step>
            <el-step title="步骤3" description="输入卡密"></el-step>
            <el-step title="步骤4" description="激活成功"></el-step>
        </el-steps>
    </div>
    <div class="success">
        <i class="el-icon-success"  size="30px"></i>
        <div class="title">{{this.$route.query.msg}}</div>
    </div>
    <div class="timedown-outside" style="display: flex; align-items: flex-end">
        <div class="btn" @click="clickTimeDown">返回商城首页</div>
        <!-- <h3>
            <i>{{ countDown }}</i> 秒后跳转到首页
        </h3> -->
    </div>
</div>
</template>

<script>
export default {
    name: "test",
    data() {
        return {
            countDown: 5,
            timer: "",
        };
    },
    methods: {
        //*** 利用计时器，控制用于倒计时的变量countDown（1秒减1），countDown为 0 时 清除该定时器，并跳转到首页。
        timeDown() {
            if (!this.timer) {
                this.timer = setInterval(() => {
                    if (this.countDown > 0) {
                        this.countDown--;
                    } else {
                        clearInterval(this.timer);
                        this.$router.push({
                            path: "/"
                        });
                    }
                }, 1000);
            }
        },
        clickTimeDown() {
            this.$router.push({
                path: "/"
            });
        }
    },
    mounted() {
        // this.timeDown();
    }
};
</script>

<style lang="scss" scoped>
/deep/.el-step__title.is-finish{
  color: #AC8C4F;
}
/deep/.el-step__head.is-finish {
    color: #AC8C4F;
    border-color: #AC8C4F;
}

/deep/.el-step__description.is-finish {
    color: #AC8C4F;
}
.timedown-outside {
    width: 300px;
    //   height: 150px;
    padding-top: 50px;
    margin: auto;

    //   background: lightyellow;
    i {
        font-size: 24px;
        color: #f40;
    }

    .btn {
        padding: 10px 20px;
        background: #AC8C4F;
        color: #fff;
        border-radius: 5px;
        margin-right: 20px;
        font-size: 18px;
        cursor: pointer;
    }
}

.success {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.title {
    font-size: 30px;
    padding-left: 10px;
    color: #333;
}

/deep/.el-icon-success {
    font-size: 50px;
    color: #AC8C4F;
}
</style>
