<template>
  <div class="settingAccount">
    <p>{{ isLoginPassword?'设置新密码':'设置密码' }}</p>
    <!-- 更换账户流程 -->
    <div class="process">
      <!-- 流程图 -->
      <div class="processImgs">
        <span
          class="spanOne color999"
          :class="{bgRed:1===controlColor,colorWhite:1===controlColor}"
        >1</span>
        <i class="bgGray" :class="{bgRed:1===controlColor,colorWhite:1===controlColor}"></i>
        <i class="bgGray" :class="{bgRed:2===controlColor,colorWhite:2===controlColor}"></i>
        <span
          class="spanTwo color999"
          :class="{bgRed:2===controlColor,colorWhite:2===controlColor}"
        >2</span>
        <i class="bgGray" :class="{bgRed:2===controlColor,colorWhite:2===controlColor}"></i>
        <i class="bgGray" :class="{bgRed:3===controlColor,colorWhite:3===controlColor}"></i>
        <span
          class="spanThree color999"
          :class="{bgRed:3===controlColor,colorWhite:3===controlColor}"
        >3</span>
        <!-- 提示信息 -->
        <div class="processContent">
          <label>身份验证</label>
          <label>{{ isLoginPassword?'设置新密码':'设置密码' }}</label>
          <label>完成</label>
        </div>
        <!--1. 绑定手机获取验证码 -->
        <div class="getCode" v-if="1==controlColor">
          <div class="bindPhone">
            <span>已绑定的手机：</span>
            <span>{{ userPhone }}</span>
          </div>
          <div class="codePhone">
            <input
              type="text"
              v-model.trim="inputText"
              placeholder="请输入验证码"
              @input="getNextStatus(1)"
            />
            <button
              @click="getCodes"
              class="bgGold"
              :class="{bgCcc:btnBgc}"
              :disabled="flag"
            >{{ btnText }}</button>
          </div>
        </div>
        <!-- 2. 设置新的密码 -->
        <div class="setNewPassword" v-if="2==controlColor">
          <ul class="codePhone">
            <li>
              <input
                type="password"
                v-model.trim="oldPassword"
                placeholder="请输入旧密码"
                v-if="isLoginPassword"
                @input="getNextStatus(2)"
              />
            </li>
            <li>
              <input
                type="password"
                v-model.trim="newPassword"
                placeholder="请输入密码"
                @blur="showCheckPassword"
                @input="getNextStatus(2)"
              />
              <div class="messageBox" v-if="checkPassword">密码不能为空</div>
            </li>
            <li>
              <input
                type="password"
                v-model.trim="againNewPassword"
                placeholder="请再次输入密码"
                @blur="showComparePassword"
                @input="getNextStatus(2)"
              />
              <div class="messageBox" v-if="comparePassword">两次输入的密码不一样</div>
            </li>
          </ul>
        </div>
        <!-- 点击下一步 -->
        <div class="btnNext">
          <button @click="nextOperate" :class="{bgGold:isNextStep}" :disabled="!isNextStep">下一步</button>
        </div>
      </div>
    </div>
    <!-- 底部温馨提示栏 -->
    <div class="warmTip">
      <p>温馨提示</p>
      <ul>
        <li>• 为保障您的帐号安全，变更重要信息需要身份验证</li>
      </ul>
    </div>
  </div>
</template>
<script>
import {
  getDesensitizedPhone,
  sendVerificationCode,
  judgePassworded,
  checkCountPassword
} from "@/api/api";
export default {
  data() {
    return {
      controlColor: 1,
      userPhone: "", //手机号码
      inputText: "", //获取手机验证码
      verifyCode: "", //存储用户的验证码
      isLoginPassword: false, //判断用户是否有登录密码
      oldPassword: "", //用户旧密码
      newPassword: "", //用户新密码
      againNewPassword: "", //用户重新输入新密码
      checkPassword: false, //密码校验提示信息
      comparePassword: false, //俩次输入的密码校验提示信息
      btnText: "获取验证码",
      timeNumber: 60, //倒计时
      btnBgc: false, //控制按钮背景颜色
      flag: false, //控制按钮是否禁用
      clock: "", //定时器
      isNextStep: false, //下一步操作是否禁用
      code: 1 //判断用户短信类型（短信登录/修改手机号/修改登录密码
    };
  },
  created() {
    this.getPhone();
    this.checkPassworded();
  },
  methods: {
    //定时器方法用函数调用
    setInterval() {
      this.clock = window.setInterval(() => {
        this.timeNumber--;
        this.btnText = this.timeNumber + "s后重新发送";
        if (this.timeNumber <= 0) {
          window.clearInterval(this.clock);
          this.btnText = "重新发验证码";
          this.timeNumber = 60;
          this.btnBgc = false;
          this.flag = false;
        }
      }, 1000);
    },

    // 判断用户是否设置过密码
    async checkPassworded() {
      const res = await judgePassworded();
      if (res.code == "0000") {
        this.isLoginPassword = res.data;
      } else {
        this.$toast(res.msg);
      }
    },
    // 1 验证用户身份
    // 1.1获取手机号码
    async getPhone() {
      const res = await getDesensitizedPhone();
      if (res.code == "0000") {
        this.userPhone = res.data.mobile;
      } else {
        this.$toast(res.msg);
      }
    },
    // 1.2发送验证码请求
    async sendCode() {
      const params = {
        codeType: this.code
      };
      const res = await sendVerificationCode(params);
      if (res.code == "0000") {
        this.verifyCode = res.data.code;
        console.log("验证码是" + this.verifyCode);
      } else {
        this.$toast(res.msg);
      }
    },
    //  1.3点击获取验证码按钮
    getCodes() {
      this.btnBgc = true;
      this.flag = true;
      this.sendCode();
      this.setInterval();
    },
    // 2如果之前有（设置新密码）
    async setNewPass() {
      let sendData = {
        confirmPassword: this.againNewPassword,
        newPassword: this.newPassword,
        oldPassword: this.oldPassword,
        settingPassword: this.isLoginPassword
      };
      const res = await checkCountPassword(sendData);
      console.log(res);
      if (res.code == "0000") {
        this.$toast("修改成功");
        this.controlColor = 3;
      } else {
        this.$toast(res.msg);
        this.controlColor = 2;
      }
    },
    // 3如果之前没有（设置密码）
    async setPass() {
      if (this.newPassword != this.againNewPassword) {
        this.$toast("俩次输入的密码不一样");
        return;
      }
      let sendData = {
        confirmPassword: this.againNewPassword,
        newPassword: this.newPassword,
        settingPassword: this.isLoginPassword
      };
      const res = await checkCountPassword(sendData);
      if (res.code == "0000") {
        this.$toast("修改成功");
        this.controlColor = 3;
      } else {
        this.$toast(res.msg);
        this.controlColor = 2;
      }
    },
    // 获取下一步按钮的状态
    getNextStatus(value) {
      if (value == 1) {
        if (this.inputText) {
          this.isNextStep = true;
        } else {
          this.isNextStep = false;
        }
      }
      if (value == 2) {
        if (this.isLoginPassword) {
          if (this.oldPassword && this.newPassword && this.againNewPassword) {
            this.isNextStep = true;
          } else {
            this.isNextStep = false;
          }
        } else {
          if (this.newPassword && this.againNewPassword) {
            this.isNextStep = true;
          } else {
            this.isNextStep = false;
          }
        }
      }
    },
    // 校验密码格式
    showCheckPassword() {
      if (this.newPassword == "") {
        this.checkPassword = true;
      } else {
        this.checkPassword = false;
      }
    },
    //  俩次密码是否相同校验
    showComparePassword() {
      if (this.newPassword !== this.againNewPassword) {
        this.comparePassword = true;
      } else {
        this.comparePassword = false;
      }
    },
    // 下一步操作
    nextOperate() {
      // 第一步
      if (this.controlColor == 1) {
        if (this.inputText == this.verifyCode) {
          this.controlColor = 2;
          // 重新修改获取按钮状态并清除定时器
          this.btnText = "获取验证码";
          this.timeNumber = 60;
          this.btnBgc = false;
          this.flag = false;
          window.clearInterval(this.clock);
          this.clock = "";
          this.isNextStep = false;
        } else {
          this.$toast("请输入正确的验证码");
        }
      }
      // 第二步
      else if (this.controlColor == 2) {
        if (this.isLoginPassword) {
          if (this.oldPassword == this.newPassword) {
            this.$toast("旧密码不能与新密码相同");
            return;
          }
          this.setNewPass();
        } else {
          this.setPass();
        }
      }
      // 第三步 如果修改成功
      else if (this.controlColor === 3) {
        this.$router.push("/user/accountSecurity");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.settingAccount {
  width: 890px;
  padding-top: 40px;
  height: auto;
  border: 1px solid #e8e8e8;
  p {
    padding-left: 30px;
    font-weight: 700;
    font-size: 20px;
  }
  .process {
    padding-top: 20px;
    .processImgs {
      text-align: center;
      span {
        text-align: center;
        height: 30px;
        width: 30px;
        line-height: 30px;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid #999;
      }
      i {
        height: 5px;
        width: 100px;
        display: inline-block;
        position: relative;
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        top: 0px;
      }
      .processContent {
        width: 550px;
        margin: 0 auto;
        padding-top: 10px;
        label {
          display: inline-block;
          font-size: 16px;
          &:nth-child(2) {
            padding: 0 170px;
            padding-left: 145px;
          }
        }
      }
      // 第一步操作的样式
      .getCode {
        padding-top: 50px;
        .bindPhone {
          width: 400px;
          margin: 0 auto;
          text-align: center;
          span {
            border: none;
            display: inline;
            font-size: 16px;
          }
        }
        .codePhone {
          padding-top: 30px;
          input {
            border: 1px solid #e8e8e8;
            height: 40px;
            width: 200px;
            border-radius: 5px;
            padding-left: 20px;
            font-size: 16px;
          }
          button {
            width: 150px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: white;
            font-size: 16px;
            cursor: pointer;
            padding: 0 20px;
            background-color: #ccc;
          }
        }
      }
      // 第二步操作的样式
      .setNewPassword {
        ul {
          padding-top: 30px;
          li {
            margin-bottom: 15px;
            input {
              border: 1px solid #e8e8e8;
              height: 50px;
              width: 370px;
              border-radius: 5px;
              padding-left: 20px;
              font-size: 16px;
            }
            .messageBox {
              width: 370px;
              margin: 0 auto;
              text-align: left;
              padding-left: 5px;
              font-size: 16px;
              color: #2283E2;
            }
          }
        }
      }
      // 每次请求的提示信息
      .getMessageBox {
        color: #2283E2;
        height: 20px;
        line-height: 20px;
        padding-top: 20px;
        font-size: 18px;
      }
      // 底部按钮
      .btnNext {
        padding-top: 50px;
        button {
          width: 120px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          font-size: 16px;
        }
      }
    }
  }
  .warmTip {
    width: 890px;
    margin-top: 80px;
    padding-left: 30px;
    background-color: #f5f5f5;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    p {
      padding-left: 0;
      font-size: 18px;
      line-height: 30px;
    }
    ul {
      li {
        line-height: 25px;
        font-size: 15px;
        color: #999;
      }
    }
  }
}
.bgRed {
  background-color: #2283E2 !important;
}
.colorWhite {
  color: white;
}
.bgGold {
  background-color: #b4a078 !important;
}
.bgCcc {
  background-color: #ccc;
}
.bgGray {
  background-color: #999;
}
</style>