<template>
  <div class="order-detail-wrap">
    <!-- 订单信息 -->
    <div class="order-detail">

      <div class="order-header">
        <span class="order-number">订单号：{{orderInfo.parentOrderNo}}</span>
        <span class="order-time ml30">下单时间：{{orderInfo.createTime}}</span>

         
        <!-- 付款状态 -->
        <span v-if="orderInfo.orderStatus == '1'"      class="name themeColor fr">待支付</span>
        <span v-else-if="orderInfo.orderStatus == '2'" class="name themeColor fr">已支付</span>
        <span v-else-if="orderInfo.orderStatus == '3'" class="name themeColor fr">待发货</span>
        <span v-else-if="orderInfo.orderStatus == '4'" class="name themeColor fr">已发货</span>
        <span v-else-if="orderInfo.orderStatus == '5'" class="name themeColor fr">已签收</span>
        <span v-else-if="orderInfo.orderStatus == '6'" class="name themeColor fr">待评价</span>
        <span v-else class="name themeColor">已关闭</span>

      </div>

      <!-- 收货地址 -->
      <div class="content">
        <div class="address fl">
          <p class="comon-address">
            <span class="key">收件人：</span>
            <span class="value">{{orderInfo.receiverName}}</span>
          </p>
          <p class="comon-address">
            <span class="key">联系方式：</span>
            <span class="value">{{orderInfo.receiverPhone}}</span>
          </p>
          <p class="comon-address">
            <span class="key">收货地址：</span>
            <span class="value">{{orderInfo.receiverCityName}}-{{orderInfo.receiverProvinceName}}-{{orderInfo.receiverRegionName}}-{{orderInfo.receiverAddress}}</span>
          </p>
        </div>
        <div class="detail fr">
          <p class="comon-detail">
            <span class="key">商品合计：</span>
            <span class="value">￥{{orderInfo.actualPayTotalAmount}}元</span>
          </p>
          <p class="comon-detail">
            <span class="key">运费：</span>
            <span class="value">￥5.00</span>
          </p>
          <p class="comon-detail">
            <span class="key">活动优惠：</span>
            <span class="value">-￥5.00</span>
          </p>
          <p class="comon-detail">
            <span class="key">优惠券：</span>
            <span class="value">-￥5.00</span>
          </p>
        </div>
      </div>

    </div>

    <!-- 商品信息 -->
    <div class="goods-detail" v-for="(item,index) in orderInfo.orderList" :key="index">
      <p class="header">
        <img :src="item.storeLogoUrl" class="store-image fl " alt="">
        <span class="store-name fl ml20">{{item.storeName}}</span>
      </p>
      <div class="content">
        <div class="common-style">
          <div class="message">商品信息</div>
          <div class="price">单价</div>
          <div class="number">数量</div>
        </div>

        <div class="common-content" v-for="(itm,idx) in item.goodsInfoList" :key="idx">
          <div class="message">
            <img :src="itm.goodsImgUrl" class="img" alt="">
            <div class="goods-info">
              <p class="name">{{ itm.goodsName }}</p>
              <!-- <p class="sku">清新型 M 200ml</p> -->
            </div>
          </div>
          <div class="price">￥{{ itm.saleUnitPrice }}</div>
          <div class="number">{{ itm.goodsNum }}</div>
          <!-- 申请退货 -->
          <div class="apply" @click="apply(item.orderNo,itm.goodsId)">申请售后</div>
        </div>
      </div>
    </div>

    <!-- 订单操作按钮部分 -->
    <div class="operation-wrap">
      <blockquote v-if="orderInfo.orderStatus == '1'">
        <van-button  color="#2283E2" size="small">去付款</van-button>
        <van-button  color="#2283E2" size="small" @click.prevent="cancer(orderInfo.parentOrderNo)">取消订单</van-button>
      </blockquote>
      <blockquote v-else-if="orderInfo.orderStatus == '4'">
        <van-button  color="#2283E2" size="small" @click.prevent="confirm(orderInfo.parentOrderNo)">确认收货</van-button>
        <van-button  color="#2283E2" size="small" @click.prevent="toLogistics(orderInfo.parentOrderNo)">查看物流</van-button>
      </blockquote>
      <blockquote v-else-if="orderInfo.orderStatus == '5'">
        <van-button  color="#2283E2" size="small" @click.prevent="toLogistics(orderInfo.parentOrderNo)">查看物流</van-button>
      </blockquote>
      <blockquote v-else-if="orderInfo.orderStatus == '6'">
        <van-button  color="#2283E2" size="small">去评价</van-button>
      </blockquote>
    </div>
    <!-- 物流弹窗 -->
    <logistics v-if="showLogistics" :info="logisticsInfo" @closeLogistics="closeLogistics"></logistics>
  </div>
</template>
<script>
import { getOrderDetail, confirmShip,cancelOrder,getLogistics } from '@/api/api';
import logistics from '@/components/logistics';
export default {
  name: 'detail',
  components: {
    logistics
  },
  data() {
    return {
      orderNumber: '',                      // 订单编号
      orderInfo: {},                        // 订单数据
      showLogistics: false,                             // 是否显示物流弹窗
    }
  },

  methods: {

    // 获取订单详情
    async getDetail() {
      let params = {
        orderNo: this.orderNumber,
      }
      const res = await getOrderDetail(params);
      if( res.code == '0000' ) {
        this.orderInfo = res.data;
        console.log(this.orderInfo, '----------this.orderInfo--------------');
      }else {
        this.$toast(res.msg);
      }
    },

    // 取消订单
    cancer(number) {
      this.$dialog.confirm({
        title: '提示',
        message: '确定要取消该订单？'
      }).then(() => {
        this.cancers(number);
      }).catch(() => {
      });
    },

    // 确认取消接口
    async cancers (number) {
      let params = {
        parentOrderNo : number
      }
      let id = number;
      let lists = this.orderList;
      const res =await cancelOrder(params);
      if(res.code === '0000'){
        this.$toast("订单已取消");
        lists.forEach((item)=>{
          if(item.parentOrderNo === id) {
            item.orderStatus = '7'
          }
        })
      }else{
        this.$toast(res.message);
      }
    },

    // 申请售后
    apply(number,id) {
      this.$router.push({
        path: `/afterSale/apply?number=${number}&id=${id}`
      });
    },

    // 确认收货
    async confirm(number){
      let params = {
        orderNo : number
      }
      let id = number;
      let lists = this.orderList;
      const res =await confirmShip(params);
      if(res.code === '0000'){
        this.$toast("确认收货成功");
        lists.forEach((item)=>{
          if(item.parentOrderNo === id) {
            item.orderStatus = '5'
          }
        })
      }else{
        this.$toast(res.message);
      }
    },

    // 显示物流弹窗
    async toLogistics(number) {
      let params = {
        orderNo: number,
      };
      const res = await getLogistics(params);
      if(res.code === '0000') {
        this.logisticsInfo = res.data;
        this.showLogistics = true;
      }else {
        this.$toast(res.msg);
      }
    },

    // 关闭物流弹窗
    closeLogistics() {
      this.logisticsInfo = {};
      this.showLogistics = false;
    },

  },

  mounted() {
    this.orderNumber = this.$route.query.id;
    this.getDetail();
  },

}
</script>
<style lang="scss" scoped>
.order-detail-wrap {
  width: 100%;
  height: auto;
  .order-detail {
    width: 100%;
    border: 1px solid #eaeaea;
    margin-bottom: 20px;
    font-size: 14px;
    .order-header {
      height: 52px;
      line-height: 52px;
      padding: 0 20px;
      background: #f5f5f5;
      .common-operation {
        color: #69c;
        cursor: pointer;
      }
    }
    /* 收货地址信息 */ 
    .content {
      padding: 23px 20px 30px;
      overflow: hidden;
      .address {
        width: 560px;
        .comon-address {
          height: 30px;
          line-height: 30px;
          .key {
            color: #999;
          }
        }
      }
      .detail {
        width: 280px;
        .comon-detail {
          height: 30px;
          line-height: 30px;
          .key {
            display: inline-block;
            color: #999;
            width: 100px;
            text-align: right;
          }
        }
      }
    }
  }
  /* 商品列表 */ 
  .goods-detail {
    font-size: 14px;
    margin-bottom: 20px;
    border: 1px solid #eaeaea;
    .header {
      padding: 10px 20px;
      background: #f5f5f5;
      overflow: hidden;
      .store-image  {
        display: inline-block;
        width: 30px;
        height: 30px;
        vertical-align: middle;
      }
      .store-name {
        display: inline-block;
        vertical-align: middle;
        margin-top: 5px;
      }
    }
    .content {
      padding: 10px 20px 0;
      .common-style,.common-content {
        display: flex;
        height: 30px;
        line-height: 40px;
        .message {
          text-align: left;
          width: 60%;
        }
        .price,.number {
          width: 20%;
          text-align: center;
        }
      }
      .common-content {
        line-height: 0;
        height: auto;
        border-bottom: 0 none;
        position: relative;
        border-top: 1px dashed #ccc;
        margin-top: 20px;
        padding: 20px 0;
        .message {
          display: flex;
          .img {
            display: block;
            width: 100px;
            height: 100px;
          }
          .goods-info {
            margin-left: 20px;
            .name {
              line-height: 24px;
              max-width: 400px;
              margin-top: 10px;
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .sku {
              margin-top: 10px;
              color: #999;
              font-size: 12px;
            }
          }
        }
        .price,.number {
          margin-top: 20px;
        }
        .apply {
          position: absolute;
          right: 10px;
          bottom: 10px;
          z-index: 9;
          display: inline-block;
          padding: 12px 10px;
          background-color: $themeColor;
          color: #fff;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
  /* 按钮部分 */ 
  .operation-wrap {
    width: 100%;
    height: auto;
    text-align: right;
    blockquote {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 40px;
      margin-inline-end: 30px;
  }
    button {
      margin-left: 20px;
      display: inline-block;
      background-color: #2283E2;
      color: #fff;
      border-radius: 4px;
      width: 100px;
    }
  }
}
</style>


