<template>
<div class="register">
    <div class="title">
        <h2 >海尔海贝商城会员统一在海尔官网会员登录界面注册</h2>
        <a href="https://account.haier.com/register">https://account.haier.com/register</a>
    </div>

    <div>
        <img :src="require('@/assets/images/register.png')" width="100%" alt="">
    </div>
</div>
</template>

<script>
export default {
    name: "App",
    data() {
        return {};
    },

    methods: {},

    mounted() {

    }
};
</script>

<style lang="scss" scoped>
.title {

    margin-bottom: 25px;

    h2 {
        color: #808080;
    margin-bottom: 15px;

    }
    a{
        font-size: 13px;
    }
}
</style>
