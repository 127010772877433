<template>
<div class="quality-main">
    <div class="q-box">
        <img src="@/assets/images/qua.png" alt="" />
        <div class="q-content">
            <div>品类齐全，大牌保障</div>
            <p>从几百款商品任意兑换，品质护航，兑换无忧</p>
        </div>
    </div>
    <div class="q-box">
        <img src="@/assets/images/tui.png" alt="" />
        <div class="q-content">
            <div>退换货规则</div>
            <p>平台特殊性，已兑换商品，不予退换货</p>
        </div>
    </div>
    <div class="q-box">
        <img src="@/assets/images/lxkf.png" alt="" />
        <div class="q-content">
            <div>在线客服，售后保障</div>
            <p>在线快速解决您遇到的问题</p>
        </div>
    </div>
    <div class="q-box ewm">
        <img src="../../../../../static/images/qrcode.png" alt="" class="ewm" />
        <div class="q-content">
            <div>会员专属福利</div>
            <p>关注“海尔智家”微信公众号，享受更多会员权益</p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    components: {},
    props: ["cmsData"],
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
.quality-main {
    background-color: #fff;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 40px auto;

    .q-box {
        display: flex;
        flex-direction: row;
        width: 22%;

        img {
            width: 50px;
            height: 50px;
            margin-right: 12px;
            margin-top: 10px;
        }

        .ewm {
            width: 70px;
            height: 70px;
            margin-top: 0px;

        }

        .q-content {
            div {
                font-size: 1.6rem;
                font-weight: 600;
            }

            p {
                color: #888;
                font-size: 1.2rem;
                margin-top: 6px;
                font-weight: 600;
            }
        }
    }
}
</style>
