<template>
  <div>
    <div class="p">
      “海尔海贝商城”是海尔面向所有用户的海贝兑换奖励品的网上平台，海尔海贝商城海贝由海知友、海尔社区、海尔营销活动等海尔各交互渠道奖励的海贝组成。海尔用户可以使用海贝兑换实物礼品或海尔产品的清洗保养、延保服务、以及其它各种增值服务。
    </div>
    <div class="p">
      为了回馈海尔用户的支持，方便海尔所有用户使用海贝兑换奖励礼品，同时提高"海尔海贝商城"的服务质量，创造更好的和用户交互平台。"海尔海贝商城"推出了网上兑换产品和门到门的配送服务，足不出户就可以享受和海尔品牌的互动交流，还可以为您购买海尔产品选择更多的优质售后服务。
    </div>
    <div class="p">
      我们希望在海尔和所有用户的共同努力下，海尔海贝商城目标成为深受会员喜爱的沟通、交互、奖励平台。同时为了可以满足不同会员的兑换需求，海尔海贝商将不断的完善和丰富兑换奖励品的方式，并随时听取所有用户的意见和建议能更好有效的服务所有海尔用户。
    </div>
    <div class="p">
      未来的日子里，海尔海贝商城会一如既往的为您提供各种各样的精彩奖励和妙趣横生的活动，使您的生活变得更丰盛！如有疑问请及时与客户服务部联系，我们会尽快为您解决。
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
.p {
  margin-top: 5px;
  text-indent: 2em;
  font-size: 16px;
  color: #808080;
  line-height: 1.8;
}
</style>