<template>
<div class="billing-main">

</div>
</template>

<script>
export default {
    data() {
        return {

        };
    },

    methods: {},

    mounted() {},
};
</script>

<style lang="scss" scoped>

</style>