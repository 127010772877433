<template>
<div class="orderlist-main">
    <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="已激活" name="2"></el-tab-pane>
        <el-tab-pane label="已过期" name="4"></el-tab-pane>
        <el-tab-pane label="已使用" name="6"></el-tab-pane>
    </el-tabs>
    <el-table :data="cardList">
        <el-table-column prop="cardNo" label="实体卡id"></el-table-column>
        <el-table-column prop="goodsName" label="兑换商品"></el-table-column>
        <el-table-column prop="scoreSrice" label="商品积分"></el-table-column>
        <el-table-column prop="activeTime" label="激活日期" width="180"></el-table-column>
        <!-- <el-table-column prop="cardType" label="实体卡状态" width="180"></el-table-column> -->
        <el-table-column label="操作" width="180">
            <template slot-scope="scope" v-if="type == '2'">
                <el-button size="mini" @click="handleEditProduct(scope.$index)">下单</el-button>
            </template>
        </el-table-column>
    </el-table>
    <div class="paging">
        <el-pagination background layout="prev, pager, next" :page-sizes="[5, 15, 25, 30]" :total="total" :page-size="10" @current-change="changePage"></el-pagination>
    </div>
</div>
</template>

<script>
import {
    getUserPointCard
} from '@/api/api'
export default {
    data() {
        return {
            totalCount:[],
            activeName: "2",
            cardList: [],
            total: 0,
            type: "2"
        };
    },

    methods: {
        orderListInit() {
            //获取所有订单
            this.getCardList(2, 1);
        },
        async getCardList(type, page) {
            let params = {
                type: 2,
                sts: type,
                pageNum: page,
                pageSize: "10"
            }

            let res = await getUserPointCard(params)
            if (res.code == "0000") {
                if (res.data.list == null) {
                    this.cardList = [];
                    this.total = 0;
                } else {
                    this.cardList = res.data.list;
                    this.total = parseInt(res.data.total);
                    this.totalCount = res.data.navigatepageNums;
                    console.log(res.data.navigatepageNums)
                }
            }
        },
        handleClick(targetName, action) {
            this.type = targetName.paneName;
            this.getCardList(targetName.paneName)
        },
        changePage(index) {
            this.getCardList(this.type, index);
        },
        handleEditProduct(index) {
            this.$router.push({
                path: '/orders/submitCardOrders',
                query: {
                    productList: [{
                        cardNo: this.cardList[index].cardNo, //积分卡号， 必
                        cardPassword: this.cardList[index].cardPassword,
                        productId: this.cardList[index].goodsId, //兑换产品ID， 必
                        productName: this.cardList[index].goodsName, //商品名称， 必
                    }]
                }
            })
        },
    },
    mounted() {
        this.orderListInit()
    },
};
</script>

<style lang="scss" scoped>
/deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #AC8C4F;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #AC8C4F;
    color: #fff;

}

/deep/.el-tabs__active-bar {
    background-color: #AC8C4F;

}

/deep/.el-tabs__item.is-active {
    color: #AC8C4F;
}

/deep/.el-tabs__item:hover {

    color: #AC8C4F;
}
.paging {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}
</style>
