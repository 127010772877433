<template>
  <div class="listIndex">
    <div class="tabSearch">
      <!-- 所有三级类目 -->
      <div class="menu-list-wrap">
        <div class="left-title">分类：</div>
        <div class="right-menu">
          <span class="mune" :class="{'active' : item.id==sonId}" v-for="(item,index) in menuList" :key="index" @click="searchProduct(item.id)">{{item.name}}</span>
        </div>
      </div>
      <!-- 排序搜索 -->
      <!-- <sorts></sorts> -->

      <!-- 商品数据 -->
      <lists :list="list" v-if="list.length > 0"></lists>
      <nodata v-else></nodata>
    </div>
  </div>
</template>
<script>
import nodata from '@/components/noData'
import sorts from "@/components/sort";
import lists from "@/components/list";
import { getAllMenu, getMenuProduct } from '@/api/api';
export default {
  name: "listIndex",
  components: { lists, nodata },
  data() {
    return {
      list: [],         // 商品数据列表
      menuList: [],     // 三级类目数据
      fatherId: '',     // 一级类目ID
      sonId: '',        // 三级类目ID
    };
  },
  methods: {

    // 根据一级类目ID获取所有三级类目
    async getMuneList() {
      let params = {
        id: 12,
      };
      const res = await getAllMenu(params);
      if(res.code == '0000') {
        this.menuList = res.data;
        this.searchProduct(this.sonId == undefined ? this.menuList[0].id : this.sonId);

      }else {
        this.$toast(res.msg);
      }
    },

    // 获取类目对应的商品
    async searchProduct(id) {
      this.sonId = id;
      let params = {
          categoryId: id,
      };
      const res = await getMenuProduct(params);
      if(res.code == '0000') {
        this.list = res.data.rows;
      }else {
        this.$toast(res.msg);
      }
    },
  },
  mounted() {
    this.getMuneList();
  }
};
</script>
<style lang="scss" scoped>
  /*改变引入组件的宽度*/
  .listIndex .tabSearch /deep/ .list {
    width: 1000px;
    margin: 0px;
    ul {
      width: 1000px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
.listIndex {
  width: 980px;
  margin: 0 auto;
  font-size: 14px;
  color: #333;
  .tabSearch {
    /*margin-top: 20px;*/
    text-align: center;
    text-align: left;
    .menu-list-wrap {
      border: 1px solid #e6eaf0;
      margin-bottom: 10px;
      padding: 20px;
      .left-title {
        display: inline-block;
        width: 10%;
        text-align: left;
        line-height: 1;
      }
      .right-menu {
        display: inline-block;
        width: 90%;
        text-align: left;
        .mune {
          display: inline-block;
          cursor: pointer;
          margin-right: 25px;
          line-height: 30px;
          &:hover {
            color: #2283E2;
          }
          &.active {
            color: #2283E2;
          }
        }
      }
    }
  }
}
</style>
