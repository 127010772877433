<template>
  <div class="userSafe">
    <div class="contanier">
      <ul>
        <li>
          <span class="spanOne">您当前的帐号</span>
          <span class="ml">{{ user.mobile }}</span>
        </li>
        <li>
          <span class="spanOne">绑定手机</span>
          <span class="color999 ml">绑定手机：</span>
          <span>{{ user.mobile }}</span>
          <span class="color999">， 用于支付安全和收货提醒等，若手机丢失或停用，请及时更换</span>
          <router-link to="/user/changeAccount">更换</router-link>
        </li>
        <li>
          <span class="spanOne">登录密码</span>
          <span class="color999 ml">修改登录密码，设置新登录密码</span>
          <router-link to="/user/settingAccount">立即修改</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { getDesensitizedPhone } from  '@/api/api'
export default {
  data() {
    return {
      userPhone: "17352922912",
      user:{}
    };
  },
  created (){
      this.getPhone()
  },
  methods:{
    async getPhone (){
      const res = await getDesensitizedPhone()     
      if(res.code == '0000'){
        this.user = res.data      
      }else {
        this.$toast(res.msg)
      }  
    }
  },
  computed:{
  }
};
</script>
<style lang="scss" scoped>
.userSafe {
  .contanier {
    ul {
      padding: 30px 30px 0;
      li {
        border-bottom: 1px solid #e8e8e8;
        height: 80px;
        line-height: 80px;
        .spanOne{
          width: 120px;
          display: inline-block;
        }
      }
    }
  }
}
a{
  font-size: 16px;
  color: #69c;
  float: right;
}
span{
  font-size: 16px;
}
.ml{
  margin-left: 50px;
}
</style>