import { render, staticRenderFns } from "./accountSecurity.vue?vue&type=template&id=6ebf1cbd&scoped=true"
import script from "./accountSecurity.vue?vue&type=script&lang=js"
export * from "./accountSecurity.vue?vue&type=script&lang=js"
import style0 from "./accountSecurity.vue?vue&type=style&index=0&id=6ebf1cbd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ebf1cbd",
  null
  
)

export default component.exports