<template>
<div class="member-main">
    <div class="mem-con">
        <div class="member-con">
            <div class="mem-title">
                <span class="main-title">秒杀专区</span>
            </div>
            <div class="men-content">
                <div class="mem" v-for="(item, index) in seckillists" :key="index" @click="detail(item.id)">
                    <!-- <el-image class="item-img" src=""></el-image> -->
                    <img class="item-img" :src="item.pictureUrl" />
                    <img class="shandian" src="@/assets/images/img_shopping.png" alt="" />
                    <div class="mem-r">
                        <div class="con-titles">{{ item.chineseName }}</div>
                        <div class="con-point">
                            {{ item.integralNum }} <span>积分</span>
                        </div>
                    </div>
                    <!-- <div class="deltxt">￥111</div> -->
                </div>
                <!-- <div class="more">more></div> -->
            </div>
        </div>
        <div class="member-con">
            <div class="mem-title">
                <span class="main-title">特价专区</span>
            </div>
            <div class="men-content">
                <div class="mem" v-for="(item, index) in speciallist" :key="index" @click="detail(item.id)">
                    <!-- <el-image class="item-img" src=""></el-image> -->
                    <img class="item-img" :src="item.pictureUrl" />
                    <img class="shandian" src="@/assets/images/img_shopping.png" alt="" />
                    <div class="mem-r">
                        <div class="con-titles">{{ item.chineseName }}</div>
                        <div class="con-point">
                            {{ item.integralNum }} <span>积分</span>
                        </div>
                    </div>
                    <!-- <div class="deltxt">￥111</div> -->
                </div>
            </div>
            <!-- <div class="more">more></div> -->
        </div>
        <div class="member-con">
            <div class="mem-title" style="display:flex;justify-content: space-between;">
                <span class="main-title">优选专区</span>
                <div class="main-select">
                    <div class="mores">more > </div>
                    <div class="main-more">
                        <div class="main-pref" @click="menuIndex = index" v-for="(item, index) in cmsData.preference" :key="index">
                            <span>{{item.name}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="men-content">
                <div class="mem" v-for="(item, index) in productList[menuIndex]" :key="index" @click="detail(item.id)">
                    <!-- <el-image class="item-img" src=""></el-image> -->
                    <img class="item-img" :src="item.pictureUrl" />
                    <img class="shandian" src="@/assets/images/img_shopping.png" alt="" />
                    <div class="mem-r">
                        <div class="con-titles">{{ item.chineseName }}</div>
                        <div class="con-point">
                            {{ item.integralNum }} <span>积分</span>
                        </div>
                    </div>
                    <!-- <div class="deltxt">￥111</div> -->
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import {
    queryByProductId
} from "@/api/api";
export default {
    components: {},
    props: ["cmsData"],
    data() {
        return {
            value: this.menuIndex,
            seckillists: [],
            speciallist: [],
            productList: [],
            preference: [],
            menuIndex: 0,
        };
    },

    methods: {
        detail: function (id) {
            let routeUrl = this.$router.resolve({
                path: "/products/productDetails?id=",
                query: {
                    id: id,
                },
            });
            window.open(routeUrl.href, "_blank");
        },
        async queryProduct() {
            let tempList = [];

            //秒殺排行
            this.cmsData.seckillist.map((value) => {
                tempList.push({
                    id: value.id,
                });
            });
            let params = {
                list: tempList,
            };
            let res = await queryByProductId(params);
            this.seckillists = res.data;

            //特價排行
            tempList = [];
            this.cmsData.speciallist.map((value) => {
                tempList.push({
                    id: value.id,
                });
            });
            params = {
                list: tempList,
            };
            res = await queryByProductId(params);
            this.speciallist = res.data;

            let preference = this.cmsData.preference;
            console.log(preference)

            for (let index = 0; index < preference.length; index++) {

                let tempList = [];
                this.cmsData.preference[index].preferenceList.map(value => {
                    tempList.push({
                        id: value.id
                    })
                })
                console.log(tempList)
                let params = {
                    list: tempList
                }
                const res = await queryByProductId(params);
                this.productList.push(res.data);
            }
        },
    },

    mounted() {
        this.queryProduct();
    },
};
</script>

<style lang="scss" scoped>
.main-pref:hover{
  background: #fff;
  color: #ac8c4f;
}
.main-select {
    position: relative;

    .mores {
        width: 100px;
        height: 25px;
        opacity: 1;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ac8c4f;
        line-height: 16px;
        // margin-top: 30px;
        cursor: pointer;

    }

    .main-more {
        width: 100px;
        position: absolute;
        z-index: 100;
        background: #f5f5f5;

        .main-pref {

            text-align: center;
            padding: 10px 0;

            cursor: pointer;
            display: none;

            span {
                font-size: 16px;
            }
        }
    }

}

.main-select:hover {
    .main-pref {
        display: block;
    }
}

.member-main {
    margin-bottom: 10px;
    border-top: 1px solid #e2e2e2;
    width: 1200px;
    margin: auto;
    margin-bottom: 40px;
}

.mem-con {
    display: flex;
    justify-content: space-between;
}

.member-con {
    width: 32%;

    .mem-title {
        margin-top: 40px;

        .main-title {
            width: 96px;
            font-size: 24px;
            font-weight: 700;
            color: #333333;
            font-family: PingFang SC, PingFang SC-Bold;
            line-height: 25px;
            display: inline-block;
        }

        .main-title::after {
            content: "";
            display: inline-block;
            width: 66px;
            height: 1px;
            background: #bca06b;
            margin-top: 16px;
        }

        .yz {
            line-height: 24px;
            margin-left: 220px;

            img {
                cursor: pointer;
                margin-left: 8px;
                vertical-align: sub;
                height: 24px;
            }
        }
    }

    .more {
        width: 59px;
        height: 25px;
        opacity: 1;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: #ac8c4f;
        line-height: 16px;
        margin-top: 30px;
        cursor: pointer;

        img {
            margin-left: 4px;
            vertical-align: sub;
        }
    }

    .men-content {
        // display: flex;
        // justify-content: space-around;

        .mem {
            //   width: 31%;
            //   text-align: center;
            cursor: pointer;
            //   padding: 4px;
            position: relative;
            margin-top: 20px;

            .con-titles {
                text-align: left;
                font-size: 14px;
                width: 265px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #333333;
                height: 25px;
            }

            img.item-img {
                width: 80px;
                height: 80px;
            }

            .mem-r {
                float: right;
                padding: 8px;
                width: 290px;
            }

            .sub-title {
                width: 104px;
                height: 14px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                line-height: 24px;

                img {
                    margin-right: 8px;
                }
            }

            .con-point {
                height: 40px;
                font-size: 28px;
                // font-weight: 700;
                text-align: left;
                color: #AC8C4F;
                margin-top: 8px;

                span {
                    color: #333;
                    font-size: 14px;
                }
            }

            .deltxt {
                text-decoration: line-through;
                color: #999999;
                text-align: left;
            }

            .shandian {
                position: absolute;
                bottom: 0px;
                right: 0;
                width: 38px;
                display: none;
            }

            .qianggou {
                position: absolute;
                top: 0px;
                right: 0;
            }
        }

        .mem:hover {
            box-shadow: 0 3px 8px rgba($color: #000000, $alpha: 0.3);
            //   transition: box-shadow 0.3s linear;
            background-color: #fff;

            .shandian {
                display: inline;
            }
        }
    }
}

// .main-select {
    // vertical-align: top;
    // border: none;
    // width: 120px;
    // float: right;

    // .el-input__inner {
    //     color: #ac8c4f;
    //     font-size: 18px;
    //     border: none;
    //     background-color: transparent;
    // }

    // .el-select__caret {
    //     color: #000;
    // }
// }
</style>
