<template>
  <div class="integral">
      我的砍价
  </div>
</template>

<script>
export default {
  components: {
    
  },
  data() {
    return {
      
    };
  },
  created() {
    
  },
  methods: {
   
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>

</style>