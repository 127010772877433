<template>
  <div class="mother" v-if="variablesObject">
    <titles :title="variablesObject"></titles>
    <div class="sort">
      <sort-left :sortLeft="variablesObject"></sort-left>
      <sort-right :list="list" :variablesObject="variablesObject"></sort-right>
    </div>
  </div>
</template>
<script>
import titles from "@/components/indexComponents/title";
import sortLeft from "@/components/indexComponents/sortLeft";
import sortRight from "@/components/indexComponents/sortRight";

export default {
  components: {
    titles,
    sortLeft,
    sortRight
  },
  props: {
    variablesObject: {
      type: Object,
      default: () => {
        return {};
      }
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  data() {
    return {};
  },
  mounted() {
  }
};
</script>
<style lang="scss" scoped>
.mother {
  width: 1200px;
  margin: 0 auto 30px;

  .sort {
    display: flex;
  }
}
</style>