<template>
<div class="cart-main">
    <div style="padding:20px;background:#fff;">

        <span class="cart-title">购物车</span>
        <div class="cart-bar">
            <div style="flex:1" class="bar-checkbox">
                <!-- <input class="tui-checkbox" type="checkbox" v-model="checkAll" /> -->
            </div>
            <span style="flex:1">商品图片</span>
            <span style="flex:2">商品名称</span>
            <span style="flex:1">规格</span>
            <span style="flex:1">积分</span>
            <span style="flex:1">数量</span>
            <!-- <span style="flex:1">合计</span> -->
            <span style="flex:1">操作</span>
        </div>
        <div class="cart-list">
            <div class="fruit" v-for="(item, index) of product" :key="index">
                <div style="flex:1" class="bar-checkbox">
                    <input class="tui-checkbox" type="checkbox" :value="item.skuId" v-model="model" />
                </div>
                <span style="flex:1"><img width="100%" :src="item.pictureUrl[0].pictureUrl" /></span>
                <span style="flex:2">{{ item.chineseName }}</span>
                <span style="flex:1">{{ item.code }}</span>
                <span style="flex:1">{{ item.integralNum }}海贝</span>
                <span style="flex:1;display:flex;align-items: center;justify-content: center;">
                    <button class="cut" @click="cuts(item,index)">━</button>
                    <div class="num">{{item.amount}}</div>
                    <button class="add" @click="add(item,index)">✚</button>
                </span>
                <!-- <span style="flex:1">1999</span> -->
                <span class="item-delete" @click="delate(item)" style="flex:1">删除</span>
            </div>
        </div>
        <div class="bottom-bar">
            <div class="bottom-tool">
                <input class="tui-checkbox" type="checkbox" v-model="checkAll" />
                <span>全选</span>
            </div>
            <div class="commit-main">
                <!-- <div class="commit-count">已选商品<span class="count-text">{{amount}}</span>件</div> -->
                <div class="commit-total">总计:<span class="total-text">{{total}}海贝</span></div>
                <div @click="orderSettlement()" class="commit-button">结算</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import utils from "@/utils/utils";
import {
    selHaierShoppingCart,
    getProductDetails,
    haierDelShoppingCart,
    haierOrderShoppingCart,
    getByRiskRule,
    AndProperty
} from "@/api/api";
export default {
    data() {
        return {
            fruits: [],
            model: [],
            product: [],
            productId: "",
            amount: "",
            deleteList: {},
            num: "",
        };
    },
    computed: {
        checkAll: {
            get() {
                // return this.model.length === this.product.length;
                return this.model.length === this.product.length && this.model.length != 0;
            },
            set(val) {
                if (val) {
                    this.model = [];
                    this.product.forEach((fruit) => {
                        this.model.push(fruit.skuId);
                    });
                } else {
                    this.model = [];
                }
            },
        },
        total() {
            let t = 0;
            this.model.forEach((item) => {
                let index = this.product.findIndex((fruit) => fruit.skuId === item);
                t += this.product[index].integralNum * this.product[index].amount;
            });
            return t;
        },
    },

    methods: {
        //购物车删除
        delate: function (item) {
            let this_ = this;
            let tt = this.product.filter(it => it.skuId == item.skuId);
            this_.deleteList.userId = localStorage.getItem("memberId");
            this_.deleteList.productId = tt[0].productId;
            this_.deleteList.skuId = tt[0].skuId;
            this.delateProduct(item);
        },
        async delateProduct(item) {
            let params = this.deleteList;
            let this_ = this;
            const res = await haierDelShoppingCart(params);
            if (res.code === "0000") {
                this.$toast("删除成功");
                //let fruitsOld = this.fruits.filter(it => it.productId != item.id);
                let productOld = this.product.filter(it => it.skuId != item.skuId); //购物车列表
                if (this.model.length > 0) { //选择列表
                    let modelOld = this.model.filter(it => it != item.skuId); //获取不与当前项删除的数据
                    this.model = [];
                    modelOld.forEach(p => {
                        this.model.push(p);
                    });
                }
                //this.fruits = [];
                this.product = [];
                if (productOld.length > 0) {
                    productOld.forEach(p => {
                        this.product.push(p);

                    });
                }

            }
        },
        //购物车列表
        async selHaierShoppingCart() {
            let params = {
                userId: localStorage.getItem("memberId"),
                amount: 0,
            };
            const res = await haierOrderShoppingCart(params);
            if (res.code == "0000") {
                console.log(res.data.length)
                let pp1 = [];
                for (let i = 0; i < res.data.length; i++) {
                    if(utils.isNull(res.data[i].productId) || utils.isNull(res.data[i].skuId)){
                        let p = {};
                        p.amount = res.data[i].amount;
                        p.code = res.data[i].code;
                        p.banners = res.data[i].banners;
                        p.skuId = res.data[i].skuId;
                        p.productId = res.data[i].productId;
                        p.thirdProductCode = res.data[i].thirdProductCode;
                        pp1[i] = p
                        continue
                    }
                    let params1 = {
                        // id:data[i].productId,
                        productId:res.data[i].productId,
                        relationRequests:[
                          {
                            attValueId:res.data[i].skuId,
                            productParentId:res.data[i].productId
                          }
                        ]
                    };
                    const res1 = await AndProperty(params1);
                    if (res1.code === "0000") {
                        // this.productId = res.data[i].productId;
                        let pp = res1.data;
                        pp.amount = res.data[i].amount;
                        pp.code = res.data[i].code;
                        pp.skuId = res.data[i].skuId;
                        pp.banners = res.data[i].banners;
                        pp.productId = res.data[i].productId;
                        pp.thirdProductCode = res.data[i].thirdProductCode;

                        pp1[i] = pp;
                        // this.product.push(pp);
                    }
                }
                this.product = pp1;
                //this.getProduct();
            }
        },
        async commCart(data) {
            let pp1 = [];
            for (let i = 0; i < data.length; i++) {
                if(utils.isNull(data[i].productId) || utils.isNull(data[i].skuId)){
                    let p = {};
                    p.amount = data[i].amount;
                    p.code = data[i].code;
                    p.banners = data[i].banners;
                    p.skuId = data[i].skuId;
                    p.productId = data[i].productId;
                    p.thirdProductCode = data[i].thirdProductCode;
                    pp1[i] = p
                    continue
                }
                let params1 = {
                    // id:data[i].productId,
                    productId:data[i].productId,
                    relationRequests:[
                      {
                        attValueId:data[i].skuId,
                        productParentId:data[i].productId
                      }
                    ]
                };
                const res1 = await AndProperty(params1);
                if (res1.code === "0000") {
                    let pp = res1.data;
                    pp.amount = data[i].amount;
                    pp.code = data[i].code;
                    pp.banners = data[i].banners;
                    pp.skuId = data[i].skuId;
                    pp.productId = data[i].productId;
                    pp.thirdProductCode = data[i].thirdProductCode;

                    pp1[i] = pp;
                }
            }
            this.product = pp1;
        },
        //获取数据
        async getProduct() {
            let params = {
                id: this.productId,

            };
            // console.log(this.productId)
            let res = await getProductDetails(params);
            if (res.code === "0000") {
                // this.fruits = res.data;
                // this.banner = res.data.pictureUrl[0].pictureUrl.split(",");
                // this.imgList = res.data.detailUrl.split(",");
                // this.codeList = res.data.code.split(",");
                if (res.data.code == "") {
                    this.codeList = ["默认规格"];
                } else {
                    this.codeList = res.data.code.split(",");
                }
            }
        },
        inverse() {
            this.product.forEach((fruit) => {
                let index = this.model.findIndex((item) => item === fruit.value);
                if (index > -1) {
                    this.model.splice(index, 1);
                } else {
                    this.model.push(fruit.value);
                }
            });
        },
        // 校验下单数量
        async getByRiskRule() {
            let params = {};
            let res = await getByRiskRule(params);
            if (res.code == "0000") {
                this.num = res.data.frequency
            }
        },
        async cuts(item, index) {
            // if (this.product[index].amount < 2) {
            //     return
            // }
            // this.product[index].amount--;
            let cgv = this.product.filter(it => it.skuId == item.skuId);
            let params = {
                userId: localStorage.getItem("memberId"),
                productId: cgv[0].productId,
                amount: -1,
                skuId: cgv[0].skuId
            };

            const res = await haierOrderShoppingCart(params);
            if (res.code == "0000") {
                this.commCart(res.data);
            }
        },
        async add(item, index) {
            // this.product[index].amount++;
            if (this.product[index].amount >= this.num) {
                this.$toast("根据商城规定，一个用户一次只能购买5件同种商品~");
                return false;
            }
            let cgv = this.product.filter(it => it.skuId == item.skuId);

            // this.deleteList.userId = cgv[0].userId;
            // this.deleteList.productId = cgv[0].productId;
            // this.deleteList.skuId = cgv[0].skuId;

            let params = {
                userId: localStorage.getItem("memberId"),
                productId: cgv[0].productId,
                amount: 1,
                skuId: cgv[0].skuId

            };
            const res = await haierOrderShoppingCart(params);
            if (res.code == "0000") {
                this.commCart(res.data);
            }
        },
        orderSettlement: function () {
            if (this.model.length < 1) {
                this.$toast("请先选择商品~")
                return
            }
            let s = [];
            this.model.forEach(i => {
                let ss = this.product.filter(it => it.skuId == i);
                let sss = {};
                sss.id = ss[0].skuId;
                sss.name = ss[0].chineseName;
                sss.code = ss[0].code;
                sss.point = ss[0].integralNum;
                sss.number = ss[0].amount;
                sss.supplierCode = ss[0].supplierCode;
                sss.supplierName = ss[0].supplierName;
                sss.thirdProductCode = ss[0].thirdProductCode;

                sss.supplierV = ss[0].supplierVCode;
                sss.goodsImgUrl = ss[0].pictureUrl[0].pictureUrl;
                s.push(sss);
            });
            this.$router.push({
                path: "/orders/submitOrders",
                query: {
                    productList: encodeURIComponent(
                        JSON.stringify(s)
                    ),
                    crat: 1
                }
            });
        },
    },

    mounted() {
        this.selHaierShoppingCart();
        this.getByRiskRule();
    },
}
</script>

<style lang="scss" scoped>
.cart-main {
    padding-top: 20px;
}

.tui-checkbox:checked {
    background: #1e53a4;
    border: solid 1px #1e53a4;
}

.tui-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #ffffff;
    border: solid 1px #c9c9c9;
    font-size: 0.8rem;
    outline: none;
    display: inline-block;
    vertical-align: top;
    cursor: default;
    -webkit-appearance: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-transition: background-color ease 0.6s;
    transition: background-color ease 0.6s;
}

.tui-checkbox:checked::after {
    content: '';
    top: 3px;
    left: 1px;
    position: absolute;
    background: transparent;
    border: #fff solid 1px;
    border-top: none;
    border-right: none;
    height: 3px;
    width: 9px;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.num {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    margin: 0 3px;
    background: rgb(239, 239, 239);
}

.add,
.cut {
    width: 25px;
    height: 25px;
    border: none;
}

.cart-title {
    display: inline-block;
    color: 16px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0;
}

.cart-bar {
    width: 100%;
    height: 40px;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: row;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .bar-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span {
        text-align: center;
        font-weight: bold;
    }
}

.cart-list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    .fruit {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        min-height: 130px;
        margin-top: 20px;
        border: 1px solid #e6e8ed;
        align-items: center;
        transition: background-color 0.1s linear;

        span {
            text-align: center;
            font-weight: bold;
        }

        .item-delete {
            cursor: pointer;
        }

        .item-delete:hover {
            color: #ED2856;
        }

        .bar-checkbox {
            display: flex;
            align-items: center;
            justify-content: center;

        }
    }

    .fruit:hover {
        transition: background-color 0.1s linear;
        background-color: #e6e8ed;
    }
}

.bottom-bar {
    width: 100%;
    height: 50px;
    background-color: #e5e5e5;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .bottom-tool {
        display: flex;
        align-items: center;
        width: 100px;
        margin-left: 20px;

        span:nth-child(2) {
            margin-left: 6px;
            color: #333;
            font-weight: bold;
        }

        span:last-child {
            margin-left: 10px;
            font-weight: bold;
            cursor: pointer;
            color: #333;
        }

        span:last-child:hover {
            color: #ED2856;
        }
    }

    .commit-main {
        display: flex;
        flex-direction: row;
        align-items: center;

        .commit-count {
            margin-right: 40px;

            .count-text {
                color: #ED2856;
                font-size: 18px;
                padding: 0 4px;
            }
        }

        .commit-total {
            display: flex;
            align-items: center;

            .total-text {
                margin-left: 10px;
                margin-right: 40px;
                color: #ED2856;
                font-size: 22px;
                font-weight: bold;
            }
        }

        .commit-button {
            width: 120px;
            height: 50px;
            background-color: #ED2856;
            color: #fff;
            line-height: 50px;
            text-align: center;
            font-size: 20px;
            cursor: pointer;
        }
    }
}
</style>
