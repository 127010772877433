<template>
<div class="container">
    <!--情况一：没有历史地址-->
    <div class="noAddress" v-if="false">
        <div class="text">
            <p>您还没有收货地址</p>
            <span>新建地址</span>
        </div>
    </div>
    <!--情况二：有历史地址-->
    <div class="haveAddress">
        <div class="toolbar">
            <span class="saveAddressNum">已保存收货地址（地址最多20条，还能保存{{ addressNum }}条）</span>
            <span class="addAddress" @click="showAddAdress=!showAddAdress">新建地址</span>
        </div>
        <!--地址表格-->
        <div class="addressTable">
            <div class="tableHead">
                <div style="width:15%">收货人</div>
                <div style="width:30%">地址</div>
                <div style="width:30%">联系方式</div>
                <div style="width:25%">操作</div>
            </div>
            <div v-if="addressList.length>0">
                <div class="tableBody" v-for="item in addressList" :key="item.id">
                    <div style="width:15%">{{ item.consigneeName }}</div>
                    <div style="width:30%">{{ item.addressDetail }}</div>
                    <div style="width:30%">{{ item.phone }}</div>
                    <div style="width:25%" class="opreate">
                        <span @click="edit(item)">编辑</span>
                        <span @click="delte(item.id)">删除</span>
                        <span class="defaultAddress" :class="{default:item.isDefault==1}" @click="defaultAddress(item)">{{ item.isDefault==1?'默认地址':'设置默认地址' }}</span>
                    </div>
                </div>
            </div>
            <!-- 没有信息情况下的提醒 -->
            <no-data v-else></no-data>
        </div>
    </div>
    <!-- 新建地址弹窗 -->
    <div class="setNewAdress" v-if="showAddAdress">
        <div class="newAdress">
            <p>新建地址</p>
            <div class="close" @click="showAddAdress=!showAddAdress"></div>
            <div class="chooseAdress">
                <h4>所在地区：</h4>
                <div class="divwrap">
                    <vdistpicker @province="onChangeProvince" @city="onChangeCity" @area="onChangeArea"></vdistpicker>
                </div>
            </div>
            <div class="chooseAdress">
                <h4>详细地址：</h4>
                <div class="divwrap">
                    <input type="text" class="detailedAddress" v-model="sendData.addressDetail" placeholder="详细地址，街道、门牌号等" />
                </div>
            </div>
            <div class="chooseAdress">
                <h4 class="pd-l15">收货人：</h4>
                <div class="divwrap">
                    <input type="text" class="inputCommon" v-model="sendData.consigneeName" />
                </div>
                <h4 class="pd-l20">手机号码：</h4>
                <div class="divwrap">
                    <input type="text" class="inputCommon" v-model="sendData.phone" />
                </div>
            </div>
            <div class="bottomContent">
                <form>
                    <label>
                        <van-checkbox v-model="sendData.isDefault">默认地址</van-checkbox>
                    </label>
                </form>
                <button @click="addAdress(1)">确定</button>
                <button @click="addAdress(2)">取消</button>
            </div>
        </div>
    </div>
    <!-- 编辑地址弹窗 -->
    <div class="setNewAdress" v-if="showEditAdress">
        <div class="newAdress">
            <p>编辑地址</p>
            <div class="close" @click="showEditAdress=!showEditAdress"></div>
            <div class="chooseAdress">
                <h4>所在地区：</h4>
                <div class="divwrap">
                    <vdistpicker @province="onChangeProvince" @city="onChangeCity" @area="onChangeArea" :province="dataPlayback.addressProvinceId" :city="dataPlayback.addressCityId" :area="dataPlayback.addressRegionId"></vdistpicker>
                </div>
            </div>
            <div class="chooseAdress">
                <h4>详细地址：</h4>
                <div class="divwrap">
                    <input type="text" class="detailedAddress" v-model="dataPlayback.addressDetail" placeholder="详细地址，街道、门牌号等" />
                </div>
            </div>
            <div class="chooseAdress">
                <h4 class="pd-l15">收货人：</h4>
                <div class="divwrap">
                    <input type="text" class="inputCommon" v-model="dataPlayback.consigneeName" />
                </div>
                <h4 class="pd-l20">手机号码：</h4>
                <div class="divwrap">
                    <input type="text" class="inputCommon" v-model="dataPlayback.phone" maxlength="11" />
                </div>
            </div>
            <div class="bottomContent">
                <form>
                    <label>
                        <van-checkbox v-model="dataPlayback.isDefault">设置默认值</van-checkbox>
                    </label>
                </form>
                <button @click="editAdress(1)">确定</button>
                <button @click="editAdress(2)">取消</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    getAddressList,
    saveAddress,
    deleteAddress,
    getNationalAddress
} from "@/api/api";
import vdistpicker from "v-distpicker";
import noData from "@/components/noData";
export default {
    components: {
        vdistpicker,
        noData
    },
    data() {
        return {
            addressList: [], //用来双向绑定并且渲染的数据
            region: [], //地区数据
            dataPlayback: {}, //数据回显
            addressNum: 0, //还能保存地址条数
            showAddAdress: false, //控制是否展示新建地址
            showEditAdress: false, //控制是否展开编辑弹窗
            id: "", //控制编辑对应项
            sendData: {
                //添加的sendData
                provinceId: "",
                addressProvinceId: "",
                cityId: "",
                addressCityId: "",
                regionId: "",
                addressRegionId: "",
                recieverAddress: "", //详细地址
                reciever: "", //收件人
                recieverTel: "", //用户手机号码
                isDefault: true //设置默认地址状态
            },
            query: {
                start: 1,
                limit: 10,
            }

        };
    },
    created() {
        this.getAddressList();
    },
    methods: {
        //查询用户收货地址
        async getAddressList() {
            let data = {};
            data.start = 1;
            data.limit = 10;
            const res = await getAddressList(data);
            if (res.code == '0000') {
                this.addressList = res.data.rows;
                this.addressNum = 20 - this.addressList.length;
            } else {
                this.$toast(res.msg);
            }
        },
        // 获取省，市，区具体信息e
        onChangeProvince(a) {
            this.sendData.provinceId = a.code;
            this.sendData.addressProvinceId = a.value;
            this.dataPlayback.provinceId = a.code;
            this.dataPlayback.addressProvinceId = a.value;
        },
        onChangeCity(a) {
            this.sendData.cityId = a.code;
            this.sendData.addressCityId = a.value;
            this.dataPlayback.cityId = a.code;
            this.dataPlayback.addressCityId = a.value;
        },
        onChangeArea(a) {
            this.sendData.regionId = a.code;
            this.sendData.addressRegionId = a.value;
            this.dataPlayback.regionId = a.code;
            this.dataPlayback.addressRegionId = a.value;
        },
        // 新增地址
        async addAdress(value) {
            if (value == 1) {
                if (!this.sendData.addressProvinceId || this.sendData.addressProvinceId == "省") {
                    this.$toast("请选择省");
                    return;
                }
                if (!this.sendData.addressCityId || this.sendData.addressCityId == "市") {
                    this.$toast("请选择市");
                    return;
                }
                if (!this.sendData.addressRegionId || this.sendData.addressRegionId == "区") {
                    this.$toast("请选择区");
                    return;
                }
                if (!this.sendData.addressDetail) {
                    this.$toast("街道门牌、楼层房间号等信息");
                    return;
                }
                if (!this.sendData.consigneeName) {
                    this.$toast("请输入用户名");
                    return;
                }
                if (!this.sendData.phone) {
                    this.$toast("请输入手机号");
                    return;
                }
                if (!/^1[3456789]\d{9}$/.test(this.sendData.phone)) {
                    this.$toast("请输入正确手机号");
                    return;
                }
                if (this.addressNum < 0) {
                    this.$toast("保存的地址已经上限");
                    return;
                }
                this.sendData.isDefault = this.sendData.isDefault ? 1 : 0;
                const res = await saveAddress(this.sendData);
                if (res.code == "0000") {
                    this.showAddAdress = !this.showAddAdress;
                    this.getAddressList();
                    this.sendData = {
                        provinceId: "",
                        addressProvinceId: "",
                        cityId: "",
                        addressCityId: "",
                        regionId: "",
                        addressRegionId: "",
                        recieverAddress: "",
                        reciever: "",
                        recieverTel: "",
                        isDefault: true
                    };
                    this.$toast("添加成功");
                } else {
                    this.$toast(res.msg);
                }
            } else {
                this.showAddAdress = !this.showAddAdress;
            }
        },
        // 编辑地址
        async edit(item) {
            this.showEditAdress = !this.showEditAdress;
            this.id = item.id;
            this.query.id = item.id;
            const res = await getAddressList(this.query);
            let list = res.data.rows.filter(item => item.id == this.id);
            this.dataPlayback = list[0];
        },
        async editAdress(value) {
            if (value == 1) {
                if (
                    !this.dataPlayback.addressProvinceId ||
                    this.dataPlayback.addressProvinceId == "省"
                ) {
                    this.$toast("请选择省");
                    return;
                }
                if (!this.dataPlayback.addressCityId || this.dataPlayback.addressCityId == "市") {
                    this.$toast("请选择市");
                    return;
                }
                if (
                    !this.dataPlayback.addressRegionId ||
                    this.dataPlayback.addressRegionId == "区"
                ) {
                    this.$toast("请选择区");
                    return;
                }
                if (!this.dataPlayback.addressDetail) {
                    this.$toast("街道门牌、楼层房间号等信息");
                    return;
                }
                if (!this.dataPlayback.consigneeName) {
                    this.$toast("请输入用户名");
                    return;
                }
                if (!this.dataPlayback.phone) {
                    this.$toast("请输入手机号");
                    return;
                }
                if (!/^1[3456789]\d{9}$/.test(this.dataPlayback.phone)) {
                    this.$toast("请输入正确手机号");
                    return;
                }
                let sendData = {
                    id: this.id,
                    provinceId: this.dataPlayback.provinceId,
                    addressProvinceId: this.dataPlayback.addressProvinceId,
                    cityId: this.dataPlayback.cityId,
                    addressCityId: this.dataPlayback.addressCityId,
                    regionId: this.dataPlayback.regionId,
                    addressRegionId: this.dataPlayback.addressRegionId,
                    addressDetail: this.dataPlayback.addressDetail,
                    consigneeName: this.dataPlayback.consigneeName,
                    phone: this.dataPlayback.phone,
                    isDefault: this.dataPlayback.isDefault
                };
                sendData.isDefault = sendData.isDefault ? 1 : 0;
                const res = await saveAddress(sendData);
                if (res.code == "0000") {
                    this.getAddressList();
                    this.showEditAdress = !this.showEditAdress;
                    this.$toast("修改成功");
                } else {
                    this.$toast(res.msg);
                }
            } else {
                this.showEditAdress = !this.showEditAdress;
            }
        },
        // 删除当前的地址
        async delte(id) {
            this.$dialog
                .confirm({
                    title: "提示",
                    message: "确定要删除该地址？"
                })
                .then(() => {
                    this.delteSite(id);
                })
                .catch(() => {
                    this.$toast("取消成功");
                });
        },
        async delteSite(id) {
            let sendData = {};
            sendData.id = id;
            const res = await deleteAddress(sendData);
            if (res.code === "0000") {
                this.addressList.forEach((item, index) => {
                    if (item.id == id) {
                        this.addressList.splice(index, 1);
                    }
                });
            } else {
                this.$toast(res.msg);
            }
        },
        // 设置默认地址
        async defaultAddress(item) {
            const id = item.id;
            let status = 0;
            if (item.isDefault == 0) {
                status = 1;
            } else if (item.isDefault == 1) {
                status = 1;
            }
            let sendData = {
                id: item.id,
                provinceId: item.provinceId,
                addressProvinceId: item.addressProvinceId,
                cityId: item.cityId,
                addressCityId: item.addressCityId,
                regionId: item.regionId,
                addressRegionId: item.addressRegionId,
                recieverAddress: item.recieverAddress,
                reciever: item.reciever,
                recieverTel: item.recieverTel,
                isDefault: status
            };
            const res = await saveAddress(sendData);
            this.addressList.forEach((item, index) => {
                if (item.id == id) {
                    this.addressList[index].isDefault = 1;
                } else {
                    this.addressList[index].isDefault = 0;
                }
            });
        }
    }
    // computed:{
    //   getLength(){
    //     return this.getAddressList.length>0
    //   }
    // }
};
</script>

<style lang="scss" scoped>
.container {
    .noAddress {
        width: 100%;
        height: 480px;
        border: 1px solid #eaeaea;
        text-align: center;
        color: grey;
        position: relative;

        .text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            p {
                font-size: 25px;
                margin-bottom: 10px;
            }

            span {
                color: $themeColor;
                cursor: pointer;
            }
        }
    }

    .haveAddress {
        .toolbar {
            overflow: hidden;
            margin-bottom: 20px;

            .saveAddressNum {
                float: left;
            }

            .addAddress {
                float: right;
                color: $themeColor;
                cursor: pointer;
            }
        }

        .addressTable {
            width: 100%;
            border: 1px solid #eaeaea;

            .tableHead {
                display: flex;
                justify-content: space-around;
                height: 42px;
                background-color: #f5f5f5;

                div {
                    border-bottom: 1px solid #eaeaea;
                    text-align: center;
                    padding: 10px 0;
                }
            }

            .tableBody {
                display: flex;
                justify-content: space-around;
                height: 70px;

                div {
                    text-align: center;
                    padding: 10px 0;
                    line-height: 50px;
                    font-size: 14px;
                }

                .opreate {
                    display: flex;
                    justify-content: space-around;

                    span {
                        color: $themeColor;
                        cursor: pointer;
                        display: inline-block;
                        text-align: center;

                        &:nth-child(3) {
                            width: 100px;
                            height: 30px;
                            line-height: 30px;
                            margin-top: 10px;
                        }
                    }

                    .defaultAddress {
                        color: grey;
                    }
                }
            }
        }
    }

    .setNewAdress {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        position: fixed;
        z-index: 99;

        .newAdress {
            height: 500px;
            width: 750px;
            border: 1px solid #ccc;
            box-sizing: border-box;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
            margin: 0 auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding: 20px;
            padding-top: 30px;
            background-color: white;

            p {
                font-size: 20px;
                color: black;
            }

            .close {
                color: #ccc;
                border-radius: 50%;
                line-height: 30px;
                text-align: center;
                height: 30px;
                width: 30px;
                font-size: 24px;
                padding: 1px;
                right: 30px;
                top: 20px;
                position: absolute;

                &::before {
                    content: "\2716";
                }
            }

            .chooseAdress {
                display: flex;
                padding-top: 30px;

                h4 {
                    font-size: 16px;
                    padding-top: 9px;
                    margin-right: 10px;
                }

                .divwrap {
                    /deep/.distpicker-address-wrapper select {
                        width: 200px;
                        font-size: 16px;
                    }

                    .detailedAddress {
                        width: 600px;
                        height: 40px;
                        border: 1px solid #ccc;
                        line-height: 22px;
                        padding-left: 20px;
                        font-size: 16px;
                        box-sizing: border-box;
                    }

                    .inputCommon {
                        height: 40px;
                        width: 242px;
                        border: 1px solid #ccc;
                        padding-left: 20px;
                        font-size: 16px;
                        box-sizing: border-box;
                    }
                }
            }

            .bottomContent {
                padding-top: 40px;
                display: flex;

                label {
                    display: inline-block;
                    height: 32px;

                    input {
                        width: 30px;
                        height: 30px;
                        border: 1px solid #ccc;
                        margin-left: 88px;
                        vertical-align: top;
                        box-sizing: border-box;
                    }

                    span {
                        padding-top: 2px;
                        line-height: 30px;
                        display: inline-block;
                        padding-left: 10px;
                    }
                }

                button {
                    width: 80px;
                    height: 30px;
                    border-radius: 5px;
                    font-size: 16px;
                    color: white;

                    &:nth-of-type(1) {
                        margin-left: 350px;
                        background-color: #2283E2;
                    }

                    &:nth-of-type(2) {
                        margin-left: 50px;
                        background-color: #ccc;
                    }
                }
            }
        }
    }
}

.pd-l20 {
    padding-left: 30px;
}

.pd-l15 {
    padding-left: 15px;
}

.default {
    border-radius: 5px;
    color: #b4a078;
    border: 1px solid #b4a078;
    background-color: #f5f3ef;
}

.no-data {
    height: 400px;
}

/deep/ .van-checkbox__icon--checked .van-icon {
    color: #fff;
    background-color: #2283E2;
    border-color: #2283E2;
}
</style>
