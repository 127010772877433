<template>
<div class="haier-footer">
    <div class="title">您可能需要</div>
    <div class="quality-main">
        <div class="q-box">
            <img src="@/assets/images/icon_foot_01.png" alt="" />
            <div class="q-content">
                <div>优惠信息</div>
                <p>立即前往 ></p>
            </div>
        </div>
        <div class="q-box">
            <img src="@/assets/images/icon_foot_02.png" alt="" />
            <div class="q-content">
                <div>服务与帮助</div>
                <p>立即前往 ></p>
            </div>
        </div>
        <div class="q-box">
            <img src="@/assets/images/icon_foot_03.png" alt="" />
            <div class="q-content">
                <div>在线咨询</div>
                <p>立即前往 ></p>
            </div>
        </div>
        <div class="q-box ewm">
            <img src="@/assets/images/icon_foot_04.png" alt="" />
            <div class="q-content">
                <div>客服热线</div>
                <p>4006-999-999</p>
            </div>
        </div>
    </div>
    <div class="footer-footer">
        <div>
            <a>联系我们</a>
            <a>服务条款</a>
            <a target="_blank"  href="http://www.casarte.com/">回到官网</a>
            <a>隐私权政策</a>
        </div>
        <div class="footer-icp">
            鲁ICP备09096283Copyright © 2016 海尔集团 版权所有
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: "haierFooter",
    components: {

    },

    props: {},

    data() {
        return {

        };
    },
    methods: {
        goMenu(path) {
            this.$router.push({
                path: path
            })
            document.documentElement.scrollTop = 0;

        },
        gohelpRule() {
            this.$router.push({
                path: "/helpCenter/helpRule"
            })
            document.documentElement.scrollTop = 350;
        }
    },

    mounted() {

    }
};
</script>

<style lang="scss" scoped>
.footer-footer {
    width: 1200px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    display: flex;

    a {
        color: #999;
        padding-right: 20px;
    }
}

.title {
    text-align: center;
    color: #333333;
    font-size: 23px;
}

.haier-footer {
    padding: 38px 0;
    // margin-top: 50px;
    height: auto;
    width: 100%;
    background-color: #fff;
}

.footer-main {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.quality-main {
    width: 1045px;
    background-color: #fff;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 40px auto;

    .q-box {
        display: flex;
        flex-direction: row;
        width: 22%;

        img {
            width: 50px;
            height: 50px;
            margin-right: 12px;
            margin-top: 10px;
        }

        .ewm {
            width: 70px;
            height: 70px;
            margin-top: 0px;

        }

        .q-content {
            div {
                color: #999;
                font-size: 1.6rem;
                // font-weight: 600;
            }

            p {
                color: #AC8C4F;
                font-size: 1.2rem;
                margin-top: 16px;
                cursor: pointer;
                // font-weight: 600;
            }
        }
    }
}

.footer-icp {
    // border-top: 1px solid rgba($color: #999, $alpha: .5);
    color: #999;
}
</style>
