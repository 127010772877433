<template>
<div class="orderdetails-main">
    <div class="details-title">审核资料</div>
    <div class="details-content">
        <div class="content-left">
            <div class="left-item">
                <span>您好，请您提交审核资料！</span>
                <!-- <span>{{details.receiverName}}</span> -->
            </div>
            <div class="left-item">
                <span>审核资料：</span>
                <div>
                    <el-upload action="https://jsonplaceholder.typicode.com/posts/" ref="upload" multiple :limit="10" list-type="picture-card" :on-change="handleChange" :on-remove="handleRemove" :on-exceed="handleExceed" :file-list="fileList" :http-request="uploadFile" :auto-upload="false">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </div>
            </div>
            <div class="left-item">
                <el-button type="primary" @click="submit">提交</el-button>
            </div>

        </div>

    </div>
</div>
</template>

<script>
import {
    addToExamineImages
} from "@/api/api";
export default {
    data() {
        return {
            fileData: '', // 文件上传数据（多文件合一）
            fileList: [], // upload多文件数组
            uploadData: {
                fieldData: {
                    id: '', // 机构id,
                }
            },
            dialogVisible: false,
            dialogImageUrl: "",
            imgurl: []

        };
    },

    methods: {
        // 上传文件
        uploadFile(file, fileList) {
            this.fileData.append('files', file.file); // append增加数据
            console.log(this.fileList)
        },

        //移除
        handleRemove(file, fileList) {
            this.fileList = fileList;
            // return this.$confirm(`确定移除 ${ file.name }？`);
        },

        // 选取文件超过数量提示
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },

        //监控上传文件列表
        handleChange(file, fileList) {
            let existFile = fileList.slice(0, fileList.length - 1).find(f => f.name === file.name);
            if (existFile) {
                this.$message.error('当前文件已经存在!');
                fileList.pop();
            }
            this.fileList = fileList;
        },
        async submit() {
            let fieldData = this.uploadData.fieldData; // 缓存，注意，fieldData不要与fileData看混
            if (this.fileList.length === 0) {
                this.$message({
                    message: '请先选择图片',
                    type: 'warning'
                })
                return
            } else {
                const isLt100M = this.fileList.every(file => file.size / 1024 / 1024 < 100);
                if (!isLt100M) {
                    this.$message.error('请检查，上传文件大小不能超过100MB!');
                } else {
                    this.fileData = new FormData(); // new formData对象
                    this.$refs.upload.submit(); // 提交调用uploadFile函数
                }
            }
            // let fileList = [];
            // let imgurl = [];
            // for (let i = 0; i < fileList.length; i++) {
            //     let getlist = fileList[i].url;
            //     imgurl.push(getlist)
            // }
            // console.log(imgurl)

            const fileLists = this.fileList;
            const imgurl = fileLists.map(item => item.url)

            var avalues = new Array(imgurl);
            var color = avalues.toString();

            console.log(color);

            let params = {
                buyerId: localStorage.getItem("memberId"),
                orderNo: this.$route.query.orderNo,
                examineImages: color,
                parentOrderNo: this.$route.query.parentOrderNo
            }

            const res = await addToExamineImages(params);
            if (res.code == "0000") {
                this.$message({
                    message: "提交审核成功",
                });
                this.$router.push({
                    path: '/centre/ordersList',
                });
            }
        }

    },

    mounted() {

    },
};
</script>

<style lang="scss" scoped>
/deep/.el-button--primary:focus, .el-button--primary:hover{
    background-color: #AC8C4F;
    border-color: #AC8C4F;
}
/deep/.el-button--primary {
    background-color: #AC8C4F;
    border-color: #AC8C4F;
}

.orderdetails-main {
    width: 100%;
    height: auto;
    border: 1px solid #e1e1e1;

    .details-title {
        width: 100%;
        height: 30px;
        background-color: #f3f3f3;
        color: #333;
        text-indent: 20px;
        line-height: 30px;
        font-weight: bold;
        border-bottom: 1px solid #e5e5e5;
        font-size: 13px;

    }

    .details-content {
        min-height: 235px;
        display: flex;
        flex-direction: row;

        .content-left {
            flex: 1;
            padding: 20px;
            background-color: #fbfbfb;
            border-right: 1px solid #e5e5e5;

            .left-item {
                display: flex;
                flex-direction: row;
                margin-bottom: 16px;

                span:first-child {
                    font-size: 13px;
                    // width: 65px;
                    line-height: 26px;
                }

                span:last-child {
                    flex: 1;
                    line-height: 26px;
                }
            }
        }

        .content-right {
            flex: 2;
            padding: 20px;
        }
    }
}
</style>
