<template>
  <div class="swiper">
    <swiper :options="swiperOption">
      <swiper-slide class="listImgs" v-for="(l,li) in list" :key="li">
        <div class="li" @click="goDetail(l.productId)">
          <img :src="l.pictureUrl" alt :class="{'imageBorder':!l.pictureUrl}" />
          <div class="info">
            <p class="title">{{l.chineseName}}</p>
            <p class="price">
              <span class="nowPrice">{{l.salePriceWithTax|formatMoney}}</span>
              <span class="oldPrice">原价{{l.marketPrice|formatMoney}}</span>
            </p>
          </div>
        </div>
      </swiper-slide>
      <!-- 分页器 -->
      <div class="swiper-pagination" slot="pagination"></div>
      <!-- 左右箭头 -->
    </swiper>
    <div class="swiper-button-prev btns" slot="button-prev">
      <img src="../../static/images/left-arrow.svg" alt />
    </div>
    <div class="swiper-button-next btns" slot="button-next">
      <img src="../../static/images/right-arrow.svg" alt />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      swiperOption: {
        //显示分页
        pagination: {
          el: ".swiper-pagination"
        },
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        //开启循环模式
        loop: true,
        slidesPerView: 5
      }
    };
  },
  methods: {
    // 新页面跳转到商品详情
    goDetail(id) {
      let routerUrl = this.$router.resolve({
        path: `/list/detail?id=${id}`
      });
      window.open(routerUrl.href, "_blank");
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.swiper {
  position: relative;
  padding: 0 80px;
  .swiper-slide {
    width: 1050px;
    margin: 20px auto 0;
    padding: 18px 0;
    /deep/ .van-swipe-item {
      text-align: center;
    }
    .li {
      width: 210px;
      display: block;
      img {
        display: block;
        width: 150px;
        height: 150px;
        margin: 0 auto;
      }
      .imageBorder {
        border: 1px solid #e6eaf0;
        box-sizing: border-box;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
    outline: none;
    img {
      width: 100%;
      height: 100%;
    }
    &::after {
      display: none;
    }
    &.swiper-button-prev {
      left: 24px;
    }
    &.swiper-button-next {
      right: 24px;
    }
  }
  &.kelly-btn {
    padding: 0 10px;
    position: relative;
    .btns {
      width: 30px;
      height: 120px;
      line-height: 120px;
      background: #2283E2;
      border-radius: 15px;
      color: #fff;
      font-size: 20px;
      text-align: center;
      top: 110px;
      outline: none;
      position: absolute;
      img {
        display: none;
      }
      &::before {
        content: " ";
        width: 10px;
        height: 10px;
        transform: rotate(135deg);
      }
      &.swiper-button-prev {
        left: -16px;
        &::before {
          margin-left: 6px;
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
        }
      }
      &.swiper-button-next {
        right: -16px;
        &::before {
          margin-right: 6px;
          border-left: 2px solid #fff;
          border-top: 2px solid #fff;
        }
      }
    }
  }
}
</style>