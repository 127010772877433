<template>
  <div class="favorite" v-if="variablesObject">
    <titles :title="variablesObject"></titles>
    <div class="goods">
      <div class="bd">
        <div class="msg">
          <p class="s1">时尚潮流</p>
          <p class="s2">20款时尚流行搭配</p>
        </div>
        <div
          class="imgSrc"
          v-if="variablesObject && variablesObject.bigImgList && variablesObject.bigImgList[0]"
        >
          <router-link to>
            <img
              :src="variablesObject.bigImgList[0].imgUrl"
              alt
              @click="goOut(variablesObject.bigImgList[0].backUrl)"
            />
          </router-link>
        </div>
      </div>
      <ul>
        <li v-for="(item,index) in list.slice(0,6)" :key="index" @click="goDetail(item.productId)">
          <div class="info" v-if="index == 2|| index == 5">
            <h4>{{item.chineseName}}</h4>
            <p class="desc">甄选大牌尖货</p>
            <p class="price">{{item.marketPrice|formatMoney}}起</p>
          </div>
          <img :src="item.pictureUrl" alt />
          <p v-if="index == 0 || index == 1 || index == 3 || index == 4">{{item.chineseName}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import titles from "@/components/indexComponents/title";
export default {
  props: {
    variablesObject: {
      type: Object,
      default: () => {
        return {};
      }
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    titles
  },
  data() {
    return {};
  },
  methods: {
    goOut(url) {
      window.open(url, "_blank");
    },
    goDetail(id) {
      let routerUrl = this.$router.resolve({
        path: `/list/detail?id=${id}`
      });
      window.open(routerUrl.href, "_blank");
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.favorite {
  width: 1200px;
  margin: 0 auto 30px;
  .goods {
    height: 400px;
    display: flex;
    .bd {
      width: 400px;
      height: 400px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .msg {
        position: absolute;
        display: block;
        width: 280px;
        height: 150px;
        margin: 0 auto;
        border: 5px solid #ffffff;
        text-align: center;
        background: rgba(0, 0, 0, 0.2);
        .s1 {
          font-size: 36px;
          line-height: 48px;
          color: #ffffff;
          margin-top: 25px;
          padding-bottom: 26px;
          position: relative;
          &::before {
            content: " ";
            width: 60px;
            height: 4px;
            background: #fff;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 14px;
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .s2 {
          font-size: 16px;
          color: #ffffff;
          line-height: 22px;
        }
      }
    }
    ul {
      width: 798px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      li {
        width: 200px;
        height: 200px;
        padding: 20px 0;
        text-align: center;
        border: 1px solid #e6eaf0;
        border-left: 0;
        box-sizing: border-box;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        cursor: pointer;
        &:nth-child(3n) {
          width: 396px;
          border-top: 0;
        }
        &:nth-child(4n),
        &:nth-child(5n) {
          border-top: 0;
        }
        img {
          width: 140px;
          height: 140px;
          margin: 0 auto;
        }
        p {
          width: 100%;
          font-size: 14px;
          margin-top: 8px;
          padding: 0 10px;
        }
        .info {
          padding-top: 12px;
          padding-left: 40px;
          text-align: left;
          h4 {
            max-width: 200px;
            padding-right: 10px;
            font-size: 30px;
            line-height: 40px;
            color: #212529;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .desc {
            font-size: 18px;
            line-height: 24px;
            color: #868e96;
            margin: 15px 0 20px 0;
          }
          .price {
            font-size: 16px;
            color: $themeColor;
          }
        }
      }
    }
  }
  .imgSrc {
    width: 400px;
    height: 400px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>