<template>
<div class="banner-main">

    <div class="banner-title">
        <div class="title-right">
            <div class="title-main">
                <a>
                    <router-link target="_blank" :to="{path: '/products/productList',query: {  }, }">
                        <div>
                            <img src="@/assets/images/qbsp.png" class="ml20" />
                        </div>
                        全部商品
                        <div></div>
                    </router-link>
                </a>
                <a>
                    <router-link target="_blank" :to="'/products/productList?minPoint=1&maxPoint=' + totalPoint">
                        <div><img src="@/assets/images/wndh.png" class="ml20" /></div>
                        我能兑换
                        <div></div>
                    </router-link>
                </a>
                <a>
                    <router-link target="_blank" to="/centre/ordersList">
                        <div><img src="@/assets/images/wddd.png" class="ml20" /></div>
                        我的订单
                        <div></div>
                    </router-link>
                </a>

                <a>
                    <router-link target="_blank" to="/centre/pointInvest">
                        <div><img src="@/assets/images/jfkjh.png" class="ml20" /></div>
                        积分卡激活
                        <div></div>
                    </router-link>
                </a>
                <a>
                    <router-link target="_blank" to="/">
                        <div><img src="@/assets/images/lljl.png" class="ml20" /></div>
                        浏览记录
                        <div></div>
                    </router-link>
                </a>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    queryBeforeCategory,
    sysNotice,
    totalPoint
} from "@/api/api";
export default {
    props: ["cmsData"],
    data() {
        return {
            totalPoint: "",
        };
    },

    methods: {
        async upperFindTotalPoint() {
            //获取总积分
            let params = {
                memberId: localStorage.getItem("memberId"),
            };
            let totalRes = await totalPoint(params);
            if (totalRes.code == "0000") {
                this.totalPoint = totalRes.data;
            }
        },
    },

    mounted() {
        this.upperFindTotalPoint();
    },
    created: function () {
    },
};
</script>

<style lang="scss" scoped>
.banner-main {
    width: 100%;
    //   height: 580px;
    margin-bottom: 10px;

    .banner-top {
        height: 485px;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;

        .banner-menu {
            padding: 30px 0;
            width: 190px;
            height: 100%;
            display: flex;
            flex-direction: column;
            background: #fff;
            position: relative;

            .menu-item {
                width: 100%;
                flex: 1;
                color: #333;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-right: 12px;
                padding-left: 30px;
                justify-content: space-between;
                cursor: pointer;

                span {
                    width: 130px;
                    font-size: 16px;
                    // font-weight:bold ;
                    font-weight: 400;
                }
            }

            .menu-item:hover {
                background-color: #bddfff;
            }

            .menu-children {
                position: absolute;
                width: 700px;
                height: 485px;
                background-color: #fff;
                top: 0;
                right: -700px;
                z-index: 10;
                // padding:0 40px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: flex-start;
                border: 2px solid #2283e2;
            }
        }

        .banner-slider {
            height: 100%;
            width: 1000px;
            margin-left: 10px;
        }
    }

    .banner-point {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 28px 20px;
        background-color: #fff;

        .point-title {
            width: 96px;
            opacity: 1;
            font-size: 24px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            color: #333333;
            line-height: 38px;
        }

        .point-list {
            width: 1010px;
            //   height: 56px;
            //   background-color: #f3f9ff;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            span {
                font-size: 16px;
                font-weight: 400;
                text-align: center;
                color: #333333;
                line-height: 38px;
                width: 120px;
                height: 38px;
                background: #f4f4f9;
                border-radius: 19px;
                cursor: pointer;
            }

            span:hover {
                background: #71a2f9;
                color: #ffffff;
            }
        }

        .point-more {
            cursor: pointer;
            line-height: 38px;
            font-size: 14px;
            color: #999999;

            img {
                margin-left: 4px;
                vertical-align: sub;
            }
        }
    }

    .banner-notive {
        width: 98%;
        height: 50px;
        background: #fff;
        display: flex;
        align-items: center;
        color: #333333;
        border-bottom: 1px solid #eee;
        margin: auto;

        img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

        .lkmore {
            font-size: 14px;
            color: #4b89f8;
            cursor: pointer;
            margin-right: 10px;
        }
    }
}

.banner-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    background-color: #fff;

    .title-left {
        width: 190px;
        height: 200px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .title-right {
        width: 1200px;
        background-color: #fff;
        height: 154px;
        margin: auto;
    }
}

.title-main {
    background-color: #fff;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 40px;

    a {
        text-align: center;
        font-size: 16px;
        // font-weight: 600;
        color: #ac8c4f;

        img {
            width: 32px;
            margin: 0;
            margin-bottom: 20px;
        }
    }
}
</style>
