<template>
  <div class="anchor">
    <div class="title">
      <span>{{title.title}}</span>
      {{title.subTitle}}
    </div>
    <div class="anchorList">
      <router-link
        :to="item.url"
        v-for="(item,index) in title.secondTitleTableData"
        :key="index"
      >{{item.name}}</router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
.anchor {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}
.title {
  font-size: 16px;
  color: #868e96;
  font-weight: normal;
  span {
    line-height: 28px;
    font-size: 20px;
    color: #212529;
    font-weight: bold;
  }
}
.anchorList {
  display: flex;
  justify-content: flex-end;
  height: 16px;
  a {
    line-height: 16px;
    padding: 0px 5px;
    font-size: 12px;
    border-right: 1px solid #868e96;
    color: #868e96;
    display: block;
    &:last-child {
      border: 0;
    }
  }
}
</style>