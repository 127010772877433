<template>
  <div class="integral">
    <div class="integralTop mb20">
      <div class="integralLeft">
        <p>当前积分</p>
        <p>{{ integration }}</p>
        <div class="flex">
          <div>
            <p>可用积分</p>
            <p>{{ integration }}</p>
          </div>
          <div>
            <p>冻结积分</p>
            <p>{{ freezeIntegral }}</p>
          </div>
        </div>
      </div>
      <router-link to class="integralRight"></router-link>
    </div>
    <div class="tabsMain">
      <div class="tabsItem">
        <p
          v-for="(item,index) in tabs"
          :key="index"
          :class="[{'on':tabIndex==index},'item']"
          @click="tabFun(index)"
          @load="onLoad"
        >{{item}}</p>
      </div>
      <div class="tabsList">
        <table v-if="dataList.length>0">
          <thead>
            <tr>
              <th>名称</th>
              <th>时间</th>
              <th>积分数量</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in dataList" :key="index">
              <td align="center">{{item.actionTypeDes}}</td>
              <td align="center">{{item.createTime}}</td>
              <td align="center">{{item.freezeIntegral}}</td>
            </tr>
          </tbody>
        </table>
        <!-- 没有信息情况下的提醒 -->
        <no-data v-else></no-data>
      </div>
    </div>
  </div>
</template>

<script>
import { integralInfo, integralList } from "@/api/api";
import noData from "@/components/noData";
export default {
  components: {
    noData
  },
  data() {
    return {
      integration: "0",              // 可用积分(当前积分同一个字段)
      freezeIntegral: "0",           // 冻结积分
      tabIndex: 0,
      changeTypes: null,            // 请求积分类型（1--收入 2---支出   null---全部）
      start: 1,                      // 当前页数
      limit: 100,                    // 当前每页多少个
      dataList: [],                  // 积分列表
      tabs: ["全部", "收入", "支出"]
    };
  },
  created() {
    this.getIntegralInfo();
    this.onLoad();
  },
  methods: {
    //  查询积分数据
    async getIntegralInfo() {
      const res = await integralInfo();
      if (res.code == "0000") {
        this.integration = res.data.integration;
        this.freezeIntegral = res.data.freezeIntegral;
      } else {
        this.$toast(res.msg);
      }
    },
    // 查询全部，收入，支出数据
    tabFun(ind) {
      this.tabIndex = ind;
      if (this.tabIndex == 0) {
        this.changeTypes = null;
        this.onLoad();
      } else if (this.tabIndex == 1) {
        this.changeTypes = 1;
        this.onLoad();
      } else {
        this.changeTypes = 2;
        this.onLoad();
      }
    },
    onLoad() {
      this.getDataList();
    },
    async getDataList() {
      let params = {
        // 此条数据可以改，分页数据
        start: this.start,
        limit: this.limit,
        changeTypes: this.changeTypes
      };
      const res = await integralList(params);
      if (res.code == "0000") {
        this.dataList = res.data.rows;
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.integral {
  .integralTop {
    width: 892px;
    height: 265px;
    padding: 34px 60px;
    background: url(http://yanxuan.nosdn.127.net/054ae764bcee8cc6d6eee75366e5d83d.png?imageView&quality=95)
      no-repeat center;
    display: flex;
    justify-content: space-between;
    .integralLeft {
      width: 390px;
      height: 195px;
      text-align: center;
      color: $themeColor;
      padding: 34px 0;
      font-size: 16px;
      p {
        &:nth-child(2) {
          font-size: 44px;
          line-height: 1;
        }
      }
      a {
        width: 80px;
        height: 26px;
        border: 1px solid $themeColor;
        color: $themeColor;
        display: inline-block;
        text-align: center;
        line-height: 24px;
        border-radius: 2px;
        background: #fff;
      }
      .flex {
        margin-top: 10px;
        display: flex;
        justify-content: space-around;
        p {
          &:nth-child(2) {
            font-size: 24px;
            margin: 10px 0;
          }
        }
      }
    }
    .integralRight {
      width: 490px;
      height: 195px;
      right: 0;
      cursor: pointer;
    }
  }
  .tabsMain {
    .tabsList {
      th,
      td {
        width: 33.33%;
      }
    }
  }
}
.no-data .no{
  margin: 50px auto 20px auto;
}
</style>