<template>
  <div class="evaluate">
    <div class="tabsMain">
      <div class="tabsItem">
        <p
          v-for="(item,index) in tabs"
          :key="index"
          :class="[{'on':tabIndex==index},'item']"
          @click="tabFun(index)"
        >{{item}}</p>
      </div>

      <div class="tabsList">
        <ul class="withdraw-list">
          <li class="withdraw-list-item" v-for="(item,index) in dataList" :key="index" >
            <div class="withdraw-list-item-title">
              <span>提现编号：{{item.applyNo}}</span>
              <span>创建时间：{{item.createTime}}</span>
            </div>
            <div class="withdraw-list-item-value">{{item.applyAmount|formatMoney}}元</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getWithdrawalListPage } from "@/api/api";
export default {
  data() {
    return {
      tabIndex: 0,
      tabs: ["所有", "代付款", "已付款", "已完成"],
      dataList: [],
      limit: 10,                 // 每页请求条数
        start: 1,                  // 请求页数
    };
  },
  created(){
    this.getList()
    
  },
  methods: {
    tabFun(ind) {
      //tab切换
      this.tabIndex = ind;
      if (this.tabIndex == 0) {
        this.orderStatus = 11;

      }
      if (this.tabIndex == 1) {
        this.orderStatus = 10;

      }
      if (this.tabIndex == 2) {
        this.orderStatus = 6;

      }
      this.getList()
    },
    async getList() {
        let params = {
          start: this.start,
          limit: this.limit,
        };

        params.changeTypes=this.tabIndex
        const res = await getWithdrawalListPage(params);
        if (res.code == "0000") {
          this.dataList = res.data.rows;
          console.log(this.dataList)
        } else {
          this.$toast(res.msg);
        }
      },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.evaluate {
  .tabsList {
    table {
      margin-bottom: 20px;
      .th {
        padding: 0 20px;
        display: flex;
        color: #aaa;
        p {
          margin-right: 20px;
          &:nth-of-type(3) {
            margin-left: 250px;
            color: black;
          }
        }
      }
      th,
      td {
        border: 0;
      }
    }
    tbody {
      font-size: 12px;
      tr {
        padding: 0;
      }
    }
    .detail {
      padding: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: auto;
      line-height: 1.4;
      border: 0;

      p {
        text-align: left;
        padding: 0 20px;
      }
      .title {
        width: 400px;
      }
      img {
        width: 62px;
        height: 62px;
        border: 1px solid #e8e8e8;
      }
    }
    .btn {
      button {
        width: 90px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #e2e2e2;
      }
    }
  }
}

.withdraw-list{
    width:100%;
    height:auto;
}

.withdraw-list-item{
    padding:0 12px;
    height:70px;
    margin:0 12px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
}

.withdraw-list-item:hover{
    background-color: #F5F5F5;
}

.withdraw-list-item-title{
    float:left;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 50%;
    font-size: 12px;
}

.withdraw-list-item-value{
    float:right;
    height:100%;
    text-align: end;
    width: 50%;
    line-height: 70px;
    color:#818080;
    font-size: 16px;
}
</style>

