<template>
  <div class="user-container main-container">
    <!-- 面包屑部分 -->
    <div class="crumbs-wrap">
      <router-link to="/">首页</router-link>
      <span class="ml5 color999">/</span>
      <span class="ml5 color999">{{crumbs}}</span>
    </div>

    <!-- 主体部分 -->
    <div class="container clearfix">
      <div class="sub-menu">
        <!-- 账号管理 -->
        <div class="side-menu">
          <div class="side-title">账号管理</div>
          <div class="side-content">
            <ul>
              <li @click="change({title: '个人中心',index: 1})">
                <router-link class="side-item" :class="{'active':activeIndex == '1'}" to="/user/userCenter">个人中心</router-link>
              </li>
              <li @click="change({title: '地址管理',index: 3})">
                <router-link class="side-item" :class="{'active':activeIndex == '3'}" to="/user/addressManagement">地址管理</router-link>
              </li>
              <li @click="change({title: '账号安全',index: 4})">
                <router-link class="side-item" :class="{'active':activeIndex == '4'}" to="/user/accountSecurity">账号安全</router-link>
              </li>
              <!-- <li @click="change({title: '分销中心',index: 14})">
                <router-link class="side-item" :class="{'active':activeIndex == '14'}" to="/user/distribution">分销中心</router-link>
              </li> -->
              <li @click="change({title: '我的积分',index: 5})">
                <router-link class="side-item" :class="{'active':activeIndex == '5'}" to="/user/integral">我的积分</router-link>
              </li>
              <li @click="change({title: '我的收藏',index: 6})">
                <router-link class="side-item" :class="{'active':activeIndex == '6'}" to="/user/userCollection">我的收藏</router-link>
              </li>
              <li @click="change({title: '我的评价',index: 12})">
                <router-link class="side-item" :class="{'active':activeIndex == '12'}" to="/user/evaluate">我的评价</router-link>
              </li>
            </ul>
          </div>
        </div>

        <!-- 交易管理 -->
        <div class="side-menu">
          <div class="side-title">交易管理</div>
          <div class="side-content">
            <ul>
              <li @click="change({title: '订单管理',index: 7})">
                <router-link class="side-item" :class="{'active':activeIndex == '7'}" to="/user/orderList">订单管理</router-link>
              </li>
              <!-- <li @click="change({title: '我的砍价',index: 13})">
                <router-link class="side-item" :class="{'active':activeIndex == '13'}" to="/user/myBargain">我的砍价</router-link>
              </li> -->
              <!-- <li @click="change({title: '我的拼团',index: 2})">
                <router-link class="side-item" :class="{'active':activeIndex == '2'}" to="/user/myGroup">我的拼团</router-link>
              </li> -->
              <!-- <li @click="change({title: '我的优惠券',index: 9})">
                <router-link class="side-item" :class="{'active':activeIndex == '9'}" to="/user/discountCouponList">我的优惠券</router-link>
              </li> -->
              <li @click="change({title: '我的充值卡',index: 10})">
                <router-link class="side-item" :class="{'active':activeIndex == '10'}" to="/user/card">我的充值卡</router-link>
              </li>
              <!-- <li @click="change({title: '分销中心',index: 13})">
                <router-link class="side-item" :class="{'active':activeIndex == '13'}" to="/user/distribution">分销中心</router-link>
				</li> -->
              <li @click="change({title: '积分商城',index: 20})">
                <router-link class="side-item" :class="{'active':activeIndex == '20'}" to="/user/inteShopping">积分商城</router-link>
              </li>
            </ul>
          </div>
        </div>

        <!-- 帮助中心 -->
        <div class="side-menu">
          <div class="side-title">服务中心</div>
          <div class="side-content">
            <ul>
              <li @click="change({title: '售后管理',index: 8})">
                <router-link class="side-item" :class="{'active':activeIndex == '8'}" to="/afterSale/aftersaleList">售后管理</router-link>
              </li>
              <li  @click="change({title: '我要反馈',index: 11})">
                <router-link class="side-item" :class="{'active':activeIndex == '11'}" to="/market/feedBack">我要反馈</router-link>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <!-- 右侧占位符 -->
      <div class="sub-content">
        <router-view></router-view>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'user',
  components: {

  },

  data() {
    return{
      crumbs: '个人中心',                       // 面包屑
      activeIndex: '1',                        // 侧边菜单选中状态
    }
  },

  methods: {

    // 切换面包屑
    change(value) {
      this.crumbs = value.title;
      this.activeIndex = value.index;
    },

  },

  mounted() {

  },
}
</script>

<style lang="scss" scoped>
  .crumbs-wrap {
    padding: 30px 0;
    font-size: 14px;
    color: #0F1300;
    line-height: 19px;
  }
  /* 主题部分 */
  .container {
    width: 100%;
    height: auto;
    position: relative;
    /* 左侧菜单栏 */
    .sub-menu {
      position: relative;
      width: 200px;
      float: left;
      z-index: 99;
      border: 1px solid #eaeaea;
      background-color: #fff;
      .side-menu {
        text-align: left;
        .side-title {
          background: rgba(134, 142, 150, 0.05);
          padding: 16px 0 16px 43px;
          border-bottom: 1px solid #E6EAF0;
          font-weight: 700;
          font-size: 16px;
        }
        .side-content {
          padding: 11px 0;
          .side-item {
            display: block;
            font-size: 14px;
            color: #868E96;
            line-height: 19px;
            margin: 2.5px 0;
            padding-top: 5.5px;
            padding-bottom: 5.5px;
            padding-left: 41px;
            border-left: 4px solid #fff;
            border-right: 2px solid #fff;
          }
          .side-item.active {
            border-left: 4px $themeColor solid;
          }
        }
      }
    }

    /* 右侧展位部分 */
    .sub-content {
      position: relative;
      width: 980px;
      float: left;
      margin-left: 20px;
      z-index: 999;
    }
  }
</style>

