<template>
<div class="invest-main">
    <div>
        <el-steps :active="1" color="#AC8C4F" align-center>
            <el-step title="步骤1" description="登录海贝商城"></el-step>
            <el-step title="步骤2" description="输入卡号"></el-step>
            <el-step title="步骤3" description="输入卡密"></el-step>
            <el-step title="步骤4" description="激活成功"></el-step>
        </el-steps>
    </div>
    <div class="card">
        <div class="title">温馨提示：</div>
        <p>1.如果您激活的为海贝积分卡：</p>
        <p>(1) 您充值到账户里的海贝积分自激活之日起1年内有效，请尽快使用</p>
        <p>(2) 您的积分卡一旦被激活，将充值到<span>当前登录的账户内，请牢记并妥善保管您的账号和密码。</span> </p>
        <p>2.如果您激活的为海贝定制卡，您的定制卡一旦被激活，有效期为30天，只可兑换对应礼品，请根据提示进行兑换。</p>
        <p>3.无卡用户如果想要获取积分卡或者定制卡，请关注海尔各个销售渠道的营销活动，可现场了解获取详情。</p>
    </div>
    <div class="investinput">
        <div>请输入您的积分卡号：</div>
        <input type="text" class="input" v-model="cardNo">
        <div class="btn" @click="pointCardExit">下一步</div>

    </div>
</div>
</template>

<script>
import {
    getPointCardExit
} from '@/api/api'
export default {
    data() {
        return {
            cardNo: null
        };
    },

    methods: {
        async pointCardExit() {
            let params = {
                cardNo: this.cardNo
            }
            let pointCard = await getPointCardExit(params)
            if (pointCard.code != '0000') {
                this.$message({
                    message: pointCard.msg,
                    type: 'warning'
                });
            } else {
                this.$router.push({
                    path: '/centre/activation',
                    query: {
                        cardNo: pointCard.result.cardNo,
                        cardSort: pointCard.result.cardSort
                    }
                });
            }
        },
    },

    mounted() {},
};
</script>

<style lang="scss" scoped>
/deep/.el-step__title.is-finish{
  color: #AC8C4F;
}
/deep/.el-step__head.is-finish {
    color: #AC8C4F;
    border-color: #AC8C4F;
}

/deep/.el-step__description.is-finish {
    color: #AC8C4F;
}

.card {
    width: 800px;
    margin: 0 auto;
    margin-top: 50px;

    .title {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    p {
        font-size: 17px;
        line-height: 25px;

        span {
            color: red;
        }
    }
}

.investinput {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    .input {
        width: 230px;
        height: 40px;
        padding-left: 10px;
        border: 1px solid rgb(182, 174, 174);
        margin-left: 10px;
        margin-right: 15px;
    }

    .btn {
        width: 130px;
        height: 40px;
        line-height: 40px;
        background-color: #AC8C4F;
        text-align: center;
        color: white;
        cursor: pointer;
    }
}
</style>
