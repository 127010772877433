<template>
<div class="register">
    <h2 class="title">1.海贝的有效期：</h2>
    <div class="p">
        权益卡激活的海贝有效期为激活之日起一年内（365天），权益卡有效期详见卡面（电子卡请查看备注），社区交互获得的海贝有效期为1年。
    </div>

    <h2 class="title">2.商城配送范围：</h2>
    <div class="p">
        <a name="abc">
            海尔海贝商城配送范围仅限中国大陆地区（不包含港、澳、台）。国外及港、澳、台会员兑换商品收件请填写中国大陆地区地址。海尔海贝商城配城保留更换部分兑换产品或调整兑换产品所需海贝的权利，不再另行通知。

        </a>
    </div>
    <h2 class="title">3.缺货通知：</h2>
    <div class="p">
        当兑换商品发生缺货现象，海尔海贝商城会员可以选择等待供货或者由海尔海贝商城配调换相应兑换区间的产品，海尔海贝商城将与会员取得联系并协商解决。
    </div>
    <h2 class="title">4.换货处理：</h2>
    <div class="p">
        通过海尔海贝商城配兑换的商品在配送过程、货到验收中出现外观破损或者由于破损造成的产品质量问题由配送服务商负责解决。
        会员收货后，除产品自身存在的质量问题外，商品一概不予更换。所有产品的质量问题和售后服务由生产厂商或生产厂商指定的售后服务商负责解决，具体方式请参照产品保修手册中的相关售后说明。
    </div>
    <h2 class="title">5.商品质量说明：</h2>
    <div class="p">
        商城保证所兑换的商品为正规渠道进货，商品的来源经专业采购严格把关，均为正品。如会员在本商城购买到假冒伪类商品，商城将按照国家规定进行双倍赔偿。如商品有质量问题或者发错，商品供应商会为您采取换货的补救措施，其中所产生物流费用由商品供应商全部承担。
    </div>
    <h2 class="title">6.商品兑换说明：</h2>
    <div class="p">
        在会员累计和使用海贝的过程中，如出现违规行为，包括但不限于：作弊累计、恶意套现、虚假交易等，海尔将保留不另行通知而取消该会员参与海贝兑现的权利，并有权撤销因违规行为所累计的海贝，以及采取其他相应法律措施的权利。
    </div>
    <h2 class="title">7.特别提醒：</h2>
    <div class="p">
        海贝商城为会员福利礼品兑换类网站，由于平台特殊性，已经支付成功的订单，不支持订单取消操作，不予退货。同时针对已下单未支付订单，24小时后订单将自动关闭。
    </div>
</div>
</template>

<script>
export default {
    name: "App",
    data() {
        return {};
    },

    methods: {},

    mounted() {},
};
</script>

<style lang="scss" scoped>
.title {
    color: #808080;
    margin-top: 5px;
}

.p {
    margin-top: 5px;
    text-indent: 2em;
    font-size: 16px;
    color: #808080;
    line-height: 2;
    padding-bottom: 10px;
}
</style>
