<template>
<div class="address-main">
    <div class="address-button" @click="openDialog()">
        新增地址
    </div>
    <div class="address-list">
        <div class="address-header">
            <div style="flex: 1">收货人</div>
            <div style="flex: 2">详细地址</div>
            <div style="flex: 1">邮编</div>
            <div style="flex: 1">电话/手机</div>
            <div style="flex: 1">操作</div>
            <div style="flex: 1">默认</div>
        </div>
        <div class="address-item" v-for="(item, index) in address" :key="index">
            <div style="flex: 1">{{ item.receiver_name }}</div>
            <div style="flex: 2">
                {{
            item.address.province +
            "  " +
            item.address.city +
            "  " +
            item.address.district +
            "  " +
            item.address.line1
          }}
            </div>
            <div style="flex: 1">{{ item.address.postcode }}</div>
            <div style="flex: 1">{{ item.receiver_mobile }}</div>
            <div style="flex: 1" class="address-edit">
                <span @click="openUpdateDialog(item)">修改</span>
                <i>｜</i>
                <span @click="openTakeOverDialog(item)">删除</span>
            </div>
            <div style="flex: 1">
                <span class="set-default" v-if="item.is_default != 1" @click="setdefault(item)">设为默认</span>
                <!-- <div class="set-default" v-if="item.is_default != 1" @click="setdefault(item)">设为默认</div> -->
                <span class="default-address" v-if="item.is_default == 1">默认地址</span>
            </div>
        </div>
    </div>
    <el-dialog title="收货地址" :visible.sync="dialogFormVisible">
        <div class="add-main">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="地址信息:" prop="addressList">
                    <div class="add-item">
                        <!-- <span class="add-title"></span> -->
                        <el-cascader ref="cascaderAddr" size="large" :options="options" v-model="ruleForm.addressList"></el-cascader>
                    </div>
                </el-form-item>
                <el-form-item label="详细地址:" prop="addressDetails">
                    <div class="add-item">
                        <!-- <span class="add-title"></span> -->
                        <el-input validate-event type="textarea" :rows="2" placeholder="请输入详细地址" v-model="ruleForm.addressDetails" style="width: 300px">
                        </el-input>
                    </div>
                </el-form-item>

                <!-- <el-form-item label="邮政编码:" prop="postalCode">
                    <div class="add-item">
                        <el-input v-model="ruleForm.postalCode" placeholder="请输入邮政编码" style="width: 300px"></el-input>
                    </div>
                </el-form-item> -->
                <el-form-item label="姓名:" prop="name">
                    <div class="add-item">
                        <!-- <span class="add-title"></span> -->
                        <el-input v-model="ruleForm.name" placeholder="请输入姓名" style="width: 300px"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="手机号:" prop="phone">
                    <div class="add-item">
                        <!-- <span class="add-title"></span> -->
                        <el-input v-model="ruleForm.phone" type="number" placeholder="请输入手机号" style="width: 300px"></el-input>
                    </div>
                </el-form-item>
                <!-- <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item> -->
            </el-form>

        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelDialog()">取 消</el-button>
            <el-button type="primary" @click="addAddressHandel('ruleForm')" v-if="type == 1">确 定</el-button>
            <el-button type="primary" @click="updateAddress('ruleForm')" v-if="type == 2">修 改</el-button>
        </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确认删除？</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="deleteAddress()">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import {
    sysUserProvince
} from "@/api/api";
import {
    searchUserAddrDefault,
    deleteUserAddr,
    updateUserAddr,
    addUserAddr,
    searchUserAddrList,
} from "@/api/api";
import listVue from '../../../pages/afterSale/list.vue';
export default {
    data() {
        return {
            dialogVisible: false,
            ruleForm: {
                addressDetails: "", //详细地址
                // postalCode: "",
                name: "",
                phone: "",
                addressList: [],
            },
            rules: {
                name: [{
                    required: true,
                    message: '请输入名字',
                    trigger: 'blur'
                }],
                addressDetails: [{
                    required: true,
                    message: '请输入详细地址',
                    trigger: 'blur'
                }],
                phone: [{
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur"
                    },
                    {
                        min: 11,
                        max: 11,
                        message: "请输入11位正确手机号码",
                        trigger: "blur"
                    },
                    {
                        pattern: /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/,
                        message: "请输入正确的手机号码",
                        trigger: "blur"
                    }
                ],
                // postalCode: [{
                //     required: true,
                //     message: '请输入邮政编码',
                //     trigger: 'blur'
                // }, {
                //     pattern: /^[0-8][0-7]\d{4}$/,
                //     message: "请输入正确的邮政编码",
                //     trigger: "blur"
                // }, ],
                addressList: [{
                    required: true,
                    message: '请选择地址',
                    // trigger: 'blur'
                }]
            },
            id: null,
            options: null,
            dialogFormVisible: false,
            addressList: [], //省市区
            type: 0, //新增还是修改
            addressDetails: "", //详细地址
            // postalCode: "",
            name: "",
            phone: "",
            address: null,
        };
    },
    methods: {
        //查询用户地址列表
        async searchUserAddrList() {
            let params = {};
            let res = await searchUserAddrList(params);
            if (res.code == "0000") {
                if (res.data[0] == null) {
                    this.address = [];
                } else {
                    this.address = res.data.sort((a, b) => b.is_default - a.is_default);
                }
            }
        },
        //查询用户默认地址列表
        async searchUserAddrDefaults() {
            let params = {};
            let pointCard = await searchUserAddrDefault(params);
            // let list = pointCard.data;
            // this.address = list;
        },
        openTakeOverDialog(item) {
            this.dialogVisible = true;
            this.tempItem = item;
            console.log(this.tempItem)
        },
        //删除
        async deleteAddress() {
            let params = {
                id: this.tempItem.id,
            };
            let pointCard = await deleteUserAddr(params);
            if (pointCard.code == "0000") {
                this.searchUserAddrList()
                this.$message({
                    message: "删除成功",
                    type: "success",
                });
                this.dialogVisible = false;
            }
        },
        //新增
        async AddAddress() {
            let labelList = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels;
            let addressObj = {
                receiver_name: this.ruleForm.name, //用户名
                receiver_mobile: this.ruleForm.phone, //手机号
                address: {
                    line1: this.ruleForm.addressDetails,
                    province_id: this.ruleForm.addressList[0],
                    province: labelList[0],
                    city_id: this.ruleForm.addressList[1],
                    city: labelList[1],
                    district_id: this.ruleForm.addressList[2],
                    district: labelList[2],
                    // postcode: this.ruleForm.postalCode,
                },
                is_default: 1,
                source: 1,
                is_service: 0,
            };

            let params = {
                param: JSON.stringify(addressObj),
            };
            let res = await addUserAddr(params);
            if (res.code == "0000") {
                this.searchUserAddrList()
                this.cleanData();
                this.searchUserAddrList();
                this.$message({
                    message: "新增成功",
                    type: "success",
                });
                this.dialogFormVisible = false;
            } else {
                this.$message({
                    message: res.msg,
                    type: "warning",
                });
            }
        },
        openUpdateDialog(item) {
            this.id = item.id;
            this.dialogFormVisible = true;
            this.type = 2;
            //回显数据
            this.ruleForm.addressDetails = item.address.line1;
            // this.ruleForm.postalCode = item.address.postcode;
            this.ruleForm.name = item.receiver_name;
            this.ruleForm.phone = item.receiver_mobile;
            this.ruleForm.addressList = [item.address.province_id, item.address.city_id, item.address.district_id];
        },
        async setdefault(item) {
            let obj = {
                receiver_name: item.receiver_name, //用户名
                receiver_mobile: item.receiver_mobile, //手机号
                address: {
                    province_id: item.address.province_id,
                    province: item.address.province,
                    city_id: item.address.city_id,
                    city: item.address.city,
                    district_id: item.address.district_id,
                    district: item.address.district,
                    postcode: item.address.postcode,
                    line1: item.address.line1,
                },
                is_default: 1,
                is_service: 0,
            };

            let params = {
                param: JSON.stringify(obj),
                id: item.id,
            };

            let res = await updateUserAddr(params);
            if (res.code == "0000") {
                this.cleanData();
                this.searchUserAddrList();
                this.$message({
                    message: "设为默认成功",
                    type: "success",
                });
                this.dialogFormVisible = false;
            } else {
                this.$message({
                    message: res.msg,
                    type: "warning",
                });
            }
        },
        //修改
        async updateAddress(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log('submit!');
                } else {
                    console.log('error submit!!');
                    // return false;
                }
            });
            let labelList = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels;
            let obj = {
                receiver_name: this.ruleForm.name, //用户名
                receiver_mobile: this.ruleForm.phone, //手机号
                address: {
                    province_id: this.ruleForm.addressList[0],
                    province: labelList[0],
                    city_id: this.ruleForm.addressList[1],
                    city: labelList[1],
                    district_id: this.ruleForm.addressList[2],
                    district: labelList[2],
                    // postcode: this.ruleForm.postalCode,
                    line1: this.ruleForm.addressDetails,
                },
                is_default: 1,
                is_service: 0,
            };

            let params = {
                param: JSON.stringify(obj),
                id: this.id,
            };

            let res = await updateUserAddr(params);
            if (res.code == "0000") {
                this.searchUserAddrList()
                this.cleanData();
                this.searchUserAddrList();
                this.$message({
                    message: "修改成功",
                    type: "success",
                });
                this.dialogFormVisible = false;
            } else {
                this.$message({
                    message: res.msg,
                    type: "warning",
                });
            }
        },
        //获取省市区列表
        async sysUserProvince() {
            let params = {};
            const res = await sysUserProvince(params);
            if (res.code === "0000") {
                this.options = res.data;
            } else {
                this.$toast(res.msg);
            }
        },
        //取消添加
        cancelDialog() {
            this.dialogFormVisible = false;
            this.cleanData();
            this.searchUserAddrList();
            this.$router.go(0)
        },

        //添加/修改地址
        async addAddressHandel(formName) {
            if (this.type == 1) {

                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        this.AddAddress();
                    } else {
                        console.log('error submit!!');
                        // return false;
                    }
                });

            } else if (this.type == 2) {
                this.updateAddress();
            }
        },
        haierOrderCheckArea(list) {
            console.log(list)
        },
        //清除数据
        cleanData() {
            this.ruleForm.addressDetails = ""
            // this.ruleForm.postalCode = "";
            this.ruleForm.name = "";
            this.ruleForm.phone = "";
            this.ruleForm.addressList = [];
        },
        openDialog() {
            this.dialogFormVisible = true;
            this.type = 1
        }
    },

    mounted() {
        this.searchUserAddrList();
        this.sysUserProvince();
    },
};
</script>

<style lang="scss" scoped>
/deep/.el-button--primary:focus, .el-button--primary:hover{
    background-color: #AC8C4F;
    border-color: #AC8C4F;
}
/deep/.el-button--primary {
    background-color: #AC8C4F;
    border-color: #AC8C4F;
}

.address-main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .address-button {
        width: 76px;
        height: 32px;
        color: #fff;
        background-color: #AC8C4F;
        border-radius: 2px;
        font-size: 13px;
        line-height: 32px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
    }

    .address-list {
        margin-top: 20px;
        width: 100%;
        height: auto;
        border: 1px solid #eee;

        .address-header {
            height: 50px;
            width: 100%;
            background-color: #eee;
            display: flex;
            flex-direction: row;

            >div {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .address-item {
            width: 100%;
            min-height: 70px;
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: background-color 0.1s linear;

            >div {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .address-edit {
                cursor: pointer;

                span:first-child:hover {
                    color: #AC8C4F;
                }

                span:last-child:hover {
                    color: #ed2856;
                }
            }

            .default-address {
                color: #f5a623;
            }

            .set-default {
                cursor: pointer;
            }
        }

        .address-item:hover {
            background-color: #f2f3f7;
            transition: background-color 0.1s linear;
        }
    }
}

.add-main {
    .add-title {
        margin-right: 12px;
        width: 100px;
        display: inline-block;
    }

    .add-item {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
</style>
