<template>
  <div class="listDetail main-container" v-if="isRequestedFinished">
    <div class="bread">
      <!-- <router-link to="/">首页</router-link>
      <span></span>
      <router-link to>生活电器</router-link>
      <span></span>
      <router-link to>浙里</router-link>-->
    </div>

    <div class="product">
      <!-- 图 -->
      <div class="productLeft">
        <div class="bigImg">
          <img :src="bigImgSrc" alt />
        </div>
        <div class="imglist">
          <swiper :options="imgOption">
            <swiper-slide
              class="swiper-slide"
              v-for="(item,index) in goodsInfo.productMedia"
              :key="index"
              @click="swiperSlideFun(item)"
            >
              <img :src="item.img" />
            </swiper-slide>
          </swiper>
          <!-- 左右箭头 -->
          <div class="imglistPrev imgBtn" slot="button-prev"></div>
          <div class="imglistNext imgBtn" slot="button-next"></div>
        </div>
      </div>
      <!-- 价格 sku 按钮 -->
      <div class="productRight">
        <div class="priceInfo">
          <p class="product-name">{{ goodsInfo.productInfo.name }}</p>
          <div class="item">
            <p class="label">价格</p>
            <div class>
              <!-- 正常商品价格 -->
              <div class="priceDetail flex">
                <p
                  class="themeColor price"
                >{{ this.issSerial ? this.skuPrice : goodsInfo.productInfo.marketPrice }}积分</p>
                <p class="color86">市场价格：<span>{{ goodsInfo.productInfo.salePriceWithTax }}积分</span></p>
              </div>
              <!-- 秒杀商品价格 -->

              <!-- <div class="memberDetail">
                <span class="icon">超划算</span>
                <span class="member-price">
                  联盟会员专享价格
                  <span class="themeColor">55.3-55.3</span>
                </span>
                <router-link class="link" to>开通立享&gt;&gt;</router-link>
              </div>-->
            </div>
          </div>
          <div class="item">
            <p class="label">促销</p>
            <div class="flex complimentary">
              <span>满赠</span>
              <p class="themeColor">每满1件领取赠品</p>
            </div>
          </div>
          <div class="item">
            <p class="label">积分</p>
            <div>
              <p>购买最高可得79-79积分</p>
            </div>
          </div>
        </div>
        <div class="typeInfo">
          <div class="item" v-if="goodsInfo.productGuaranteeList.length > 0">
            <p class="label">服务</p>
            <div class="flex">
              <span
                v-for="(item,index) in goodsInfo.productGuaranteeList"
                :key="index"
              >･{{item.guaranteeName}}</span>
            </div>
          </div>

          <!-- 商品sku部分 -->
          <!-- <div class="item" v-for="(item,index) in skuList" :key="index">
            <p class="label">{{item.name}}</p>
            <div class="flex">
              <p v-for="(i,ix) in item.productPropertyValue" :key="ix" @click="switchFun(item,i)" :class="[i.state==1?'on':'','btn']" >{{i.valueName}}</p>
            </div>
          </div>-->
          <div class="item" v-for="(item,index) in allSkuList" :key="index">
            <span class="turnInlineBlock w67 lh26">规格：</span>
            <div
              class="sku-wrap"
              :class="{'active': skuSelectedIndex == index}"
              v-if="item.productPropertyValue.length > 0"
              @click="toggleSelected(index,item.id,item)"
            >
            
              <span
                class="mr10"
                v-for="(i,ix) in item.productPropertyValue"
                :key="ix"
              >{{i.valueName}}</span>
            </div>
          </div>

          <div class="item">
            <p class="label">购买数量：</p>
            <van-stepper v-model="quantity" integer @change="buyNumber()" />
          </div>
          <div class="btns flex">
            <button @click="buyNow(goodsInfo)">立即购买</button>
            <!-- <button @click="addCart(goodsInfo.productInfo)" class="joinCart">
              <van-icon name="cart-o" class="mr10"/>
              <span>加入购物车</span> 
            </button> -->
            <!-- <button @click="toggleProductCollect(goodsInfo.memStoreInfo.isFavoriteProduct,goodsInfo.productInfo.storeId)" class="follow">
              <van-icon name="like" class="mr10" :class="{followed:isFollow}" v-if="goodsInfo.memStoreInfo.isFavoriteProduct == 0"/>
              <van-icon name="like-o" class="mr10" v-else/>
              <i class="mr5" v-if="goodsInfo.memStoreInfo.isFavoriteProduct == 0">收藏</i>
              <i class="iconfont icon-xin mr5 themeColor" v-else>已收藏</i>
            </button> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 大家都在看 start-->
    <div class="everyone">
      <h4 class="title">大家都在看</h4>
      <div class="list">
        <swipers :list="recommentList"></swipers>
      </div>
    </div>

    <!-- 大家都在看 end-->
    <div class="flex">
      <!-- 24小时热销榜 start-->
      <div class="hot">
        <!-- <h4 class="title">浙里南京东路店</h4> -->
        <!-- <h4 class="title">{{goodsInfo.memStoreInfo.storeName}}</h4> -->

        <!-- <div class="goodsBtn">
          <p>
            <i class="iconfont icon-home mr5"></i>进店逛逛
          </p>
          <p @click=toggleStoreCollect(goodsInfo.memStoreInfo.isFavorite,goodsInfo.productInfo.storeId)>
            <i class="mr5" v-if="goodsInfo.memStoreInfo.isFavorite == 0">关注店铺</i>
            <i class="mr5 themeColor" v-else>已关注</i>
          </p>
        </div> -->
        <h4 class="title">24小时热销榜</h4>
        <ul class="listImgs">
          <li v-for="(l,li) in hotSaleList" :key="li" @click="goDetail(l.id)">
            <img :src="l.pictureUrl" alt :class="{'imageBorder':!l.pictureUrl}" />
            <div class="info">
              <p class="title">{{l.chineseName}}</p>
              <p class="price">
                <span class="nowPrice">{{l.salePriceWithTax|formatMoney}}</span>
                <span class="oldPrice">原价{{l.marketPrice|formatMoney}}</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
      <!-- 24小时热销榜 end-->

      <!-- 商品详情 常见问题 start-->
      <div class="details">
        <div class="tabs">
          <p :class="{on:tabsFlag}" @click="tabsFlag=true">商品详情</p>
          <p :class="{on:!tabsFlag}" @click="tabsFlag=false">常见问题</p>
        </div>
        <div class="detailsContainer detContainer" v-show="tabsFlag">
          <ul class="infos">
            <li>形状：长方形</li>
            <li>适用季节：四季通用</li>
            <li>材质：纯棉</li>
            <li>规格：35cmx80cm</li>
            <li>类别：A类</li>
          </ul>
        </div>
        <div class="detailsContainer queContainer" v-show="!tabsFlag">常见问题</div>
      </div>
      <!-- 商品详情 常见问题 end-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import swipers from "@/components/detailSwiper.vue";
import {
  getProductInfo,
  getRecommendedProductList,
  getProductsProperty,
  getPropertyDetail,
  addCart,
  toggleCollect,
  userCartNum
} from "@/api/api";
import utils from "@/utils/utils";
export default {
  name: "listDetail",

  components: { swipers },

  data() {
    let self = this;
    return {
      storeInfo:{
        collectIds:[],
        storeId:null,
      },
      memStoreInfo:[],
      num: 1, //数量
      bigImgSrc: "", //大图
      tabsFlag: true, //显示商品详情或者常见问题
      recommentList: [], // 大家都在看数据
      hotSaleList: [], // 24小时热销
      imgOption: {
        spaceBetween: 10,
        slideToClickedSlide: true,
        //设置点击箭头
        navigation: {
          nextEl: ".imglistNext",
          prevEl: ".imglistPrev"
        },
        //开启循环模式
        loop: true,
        slidesPerView: 3,
        observeParents: true,
        on: {
          slideChange() {
            self.bigImgSrc = this.imagesToLoad[this.activeIndex].src;
          }
        }
      },
      goodsInfo: {}, // 商品信息部分
      quantity: "1", // 购买数量
      isRequestedFinished: false, // 是否请求结束
      goodsId: "", // 商品ID
      allSkuList: [], // 后台已经组合好的sku数据
      issSerial: false, // 该商品是不是系列品
      serialProductId: "", // 系列品id
      skuSelectedIndex: "0", // sku选中下标
      skuPrice: "", // sku价格
      isSckillProduct: false, // 改商品是不是秒杀商品
      isFollow:true,       //商品是否已收藏
    };
  },
  computed: {
    ...mapState({
      cartNum: state => state.number
    })
  },
  methods: {
    // sku选中切换
    toggleSelected(index, id, item) {
      this.skuSelectedIndex = index;
      this.serialProductId = id;
      this.skuPrice = item.marketPrice;
    },

    // 获取商品详情
    async getProduct() {
      let params = {
        id: this.goodsId
      };
      const res = await getProductInfo(params);
      if (res.code == "0000") {
        this.goodsInfo = res.data;
        this.memStoreInfo = res.data.memStoreInfo;
        // this.memStoreInfo.isFavoriteProduct = 0;
        // 处理商品大图数据
        this.goodsInfo.productMedia = utils.changeKey(
          this.goodsInfo.productMedia,
          ["img", "videoUrl"]
        );
        this.isRequestedFinished = true;
      } else {
        this.$toast(res.msg);
      }
    },

    // 获取商品sku数据
    async getProperty() {
      let params = {
        id: this.goodsId
      };
      const res = await getProductsProperty(params);
      if (res.code == "0000") {
        // 如果该商品时系列品
        if (res.data[0].typeOfProduct==2) {
          this.issSerial = true;
        }
          this.getPropertyDetail();
      } else {
        this.$toast(res.msg);
      }
    },

    // 获取系列品明细
    async getPropertyDetail() {
      let params = {
        productId: this.goodsId
      };
      const res = await getPropertyDetail(params);
      if (res.code == "0000") {
        console.log(res.data)
        this.allSkuList = res.data;
        this.serialProductId = this.allSkuList[0].id;
        this.skuPrice = this.allSkuList[0].marketPrice;
      } else {
        this.$toast(res.msg);
      }
    },

    // 商品收藏切换
    async toggleProductCollect(flag,storeId) {
      console.log(this.isFollow)
      const login = this.$store.state.isLogin;
      if (!login) {
        return this.$store.commit("showLoginModal", true);
      }
      this.storeInfo.collectIds.push(this.goodsId);
      this.storeInfo.storeId=this.memStoreInfo.id;
      let params = {
        // storeId: storeId,
        // entityId: this.goodsId,
        type: flag == 0 ? 1 : 0,          // 0--取消收藏  --1收藏
        entityType: 1,                    // 1--商品   2--店铺
        storeInfo:this.storeInfo,   
        collectIds:[]             
      };
      if(flag == 1){
        params.collectIds.push(this.goodsId)
      }
      const res = await toggleCollect(params);
      if(res.code == '0000') {
        flag == 0 ? this.$toast('收藏成功') : this.$toast('取消成功');
        // this.goodsInfo.memStoreInfo.isFavoriteProduct = flag == 0 ? 1 : 0;
        this.isFollow = !this.isFollow;
      }else {
        this.$toast(res.msg);
      }
    },

    // 店铺收藏切换
    async toggleStoreCollect(flag, storeId) {
      const login = this.$store.state.isLogin
      if (!login) {
        return this.$store.commit('showLoginModal', true)
      }
      this.storeInfo.collectIds.push(this.memStoreInfo.id);
      this.storeInfo.storeId=this.memStoreInfo.id;
      let params = {
        // storeId: storeId,
        // entityId: storeId,
        type: flag == 0 ? 1 : 0,          // 0--取消收藏  --1收藏
        entityType: 2,                    // 1--商品   2--店铺
        storeInfo:this.storeInfo                     
      }
      if(flag == 1){
        params.collectIds.push(this.memStoreInfo.id)
      }
      const res = await toggleCollect(params)
      if (res.code == '0000') {
        flag == 0 ? this.$toast('关注成功') : this.$toast('取消成功')
        this.goodsInfo.memStoreInfo.isFavorite = flag == 0 ? 1 : 0
      }else {
        this.$toast(res.msg)
      }
    },

    // 新页面打开商品详情
    goDetail(id) {
      let routerUrl = this.$router.resolve({
        path: `/list/detail?id=${id}`
      });
      window.open(routerUrl.href, "_blank");
    },

    // 购买数量
    buyNumber() {},

    // 大家都在看prev按钮
    prevBtn() {
      this.$refs.everyoneSwipe.prev();
    },
    // 大家都在看next按钮
    nextBtn() {
      this.$refs.everyoneSwipe.next();
    },
    //实现点击加class
    switchFun(item, i) {
      console.log(item, "------ITEM-----");
      console.log(this.skuList, "------this.skuList-----");
      this.skuList = this.skuList.map(value => {
        if (value.attNameId == item.attNameId) {
          value.productPropertyValue.forEach(f => {
            f.state = 0;
          });
          var items = value.productPropertyValue.filter(f => {
            return f.valueId == i.valueId;
          })[0];
          if (items) {
            items.state = items.state == 1 ? 0 : 1;
          }
        }
        return value;
      });
    },

    // 立即购买
    buyNow(info) {
      const login = this.$store.state.isLogin;
      if (!login) {
        return this.$store.commit("showLoginModal", true);
      }
      this.$router.push({
        path: "/order/index",
        query: {
          goodsId: this.issSerial ? this.serialProductId : this.goodsId,
          goodsNum: this.quantity
        }
      });
    },

    // 加入购物车 addCart
    async addCart(info) {
      const login = this.$store.state.isLogin;
      if (!login) {
        return this.$store.commit("showLoginModal", true);
      }
      let params = {
        goodsId: this.issSerial ? this.serialProductId : this.goodsId,
        goodsNum: this.quantity
      };
      const res = await addCart(params);
      if (res.code == "0000") {
        this.getCartNum();
        this.$toast("添加成功");
      } else {
        this.$toast(res.msg);
      }
    },

    // 获取推荐数据
    async getRecommended() {
      let params = {
        recommendType: 1
      };
      const res = await getRecommendedProductList(params);
      if (res.code == "0000") {
        this.recommentList = res.data;
      } else {
        this.$toast(res.msg);
      }
    },

    // 获取热销产品
    async getHotList() {
      let params = {
        recommendType: 2
      };
      const res = await getRecommendedProductList(params);
      if (res.code == "0000") {
        this.hotSaleList = res.data;
      } else {
        this.$toast(res.msg);
      }
    },
    // 商品数量
    async getCartNum() {
      const res = await userCartNum();
      if (res.code == "0000") {
        this.$store.commit("setCartNum", res.data);
      }
    },
  },

  mounted() {
    this.goodsId = this.$route.query.id;
    if (this.$route.query.type == "sckill") {
      this.isSckillProduct = true;
    }
    this.getRecommended();
    this.getHotList();
    this.getProduct();
    this.getProperty();
  }
};
</script>

<style lang="scss" scoped>
.listDetail {
  .flex {
    display: flex;
    justify-content: center;
  }
  .everyone {
    margin-bottom: 20px;
    border: 1px solid #e6eaf0;
  }
  .hot {
    border: 1px solid #e6eaf0;
    width: 250px;
    margin-right: 20px;
    h4 {
      height: 50px;
    }
    .goodsBtn {
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      text-align: center;
      p {
        border: 1px solid #ddd;
        background-color: #f8f8f8;
        color: #666;
        width: 88px;
        height: 34px;
        line-height: 34px;
        padding: 0;
        font-size: 12px;
        cursor: pointer;
      }
    }
    .listImgs {
      li {
        width: 248px;
        margin-bottom: 0;
        padding: 10px 0 20px 0;
        border-bottom: 1px solid #e6eaf0;
        img {
          display: block;
          width: 190px;
          height: 190px;
          margin: 0 auto;
        }
        .imageBorder {
          border: 1px solid #e6eaf0;
          box-sizing: border-box;
        }
      }
    }
  }
  .details {
    border: 1px solid #e6eaf0;
    width: 930px;
    .tabs {
      border-bottom: 1px solid #e6eaf0;
      height: 50px;
      line-height: 50px;
      display: flex;
      p {
        width: 140px;
        font-size: 18px;
        text-align: center;
        color: #212529;
        border-right: 1px solid #e6eaf0;
        cursor: pointer;
        &.on {
          background: $themeColor;
          color: #fff;
          border-right-color: $themeColor;
        }
      }
    }
    .detailsContainer {
      padding: 15px 30px;
      &.detContainer {
        .infos {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            width: 420px;
            height: 30px;
            line-height: 30px;
            border-bottom: 1px dashed #e6eaf0;
          }
        }
      }
    }
  }
  .product {
    display: flex;
    margin-bottom: 30px;
    .productLeft {
      width: 430px;
      margin-right: 40px;
      .bigImg {
        width: 430px;
        height: 430px;
        margin-bottom: 15px;
        border: 1px solid #e6eaf0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .imglist {
        height: 100px;
        padding: 0 55px;
        position: relative;
        .swiper-slide {
          width: 100px;
          height: 100px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .swiper-slide-active {
          width: 96px;
          height: 96px;
          border: 2px solid $themeColor;
          box-sizing: border-box;
        }
      }
      .imgBtn {
        cursor: pointer;
        position: absolute;
        width: 40px;
        height: 100px;
        border: 1px solid #e6eaf0;
        background: none;
        color: #e6eaf0;
        font-size: 24px;
        line-height: 100px;
        text-align: center;
        top: 0;
        margin-top: 0;
        outline: none;
        &::before {
          margin: 0 8px 0 4px;
          content: "";
          width: 10px;
          height: 10px;
          display: block;
          top: 50%;
          transform: rotate(135deg) translateY(-50%);
          position: absolute;
          margin-top: -5px;
        }
      }
      .imglistPrev {
        left: 0;
        &::before {
          left: 8px;
          border-bottom: 1px solid #e6eaf0;
          border-right: 1px solid #e6eaf0;
        }
      }
      .imglistNext {
        right: 0;
        &::before {
          right: 12px;
          border-top: 1px solid #e6eaf0;
          border-left: 1px solid #e6eaf0;
        }
      }
    }
    .productRight {
      width: 730px;
      .priceInfo {
        padding: 12px 20px;
        background: rgba(134, 142, 150, 0.05);
        border-top: 1px dashed #e6eaf0;
        border-bottom: 1px dashed #e6eaf0;
        .product-name {
          font-size: 18px;
        }
      }
      .typeInfo {
        padding: 12px 20px;
      }
      .label {
        width: 67px;
      }
      .item {
        width: 100%;
        display: flex;
        margin: 12.5px 0;
      }
      .btn {
        min-width: 40px;
        height: 28px;
        line-height: 28px;
        padding: 0 5px;
        color: #212529;
        font-size: 12px;
        text-align: center;
        border: 1px solid #e6eaf0;
        display: inline-block;
        margin-right: 15px;
        cursor: pointer;
        &.on {
          background: $themeColor;
          color: #fff;
        }
      }
      .num {
        div {
          text-align: center;
          border: 1px solid #e6eaf0;
          width: 28px;
          height: 28px;
          line-height: 28px;
          cursor: pointer;
        }
        input {
          width: 60px;
          height: 28px;
          border-top: 1px solid #e6eaf0;
          border-bottom: 1px solid #e6eaf0;
          text-align: center;
        }
      }
      .price {
        font-size: 24px;
        margin-right: 14px;
      }
      .color86 {
        color: #868e96;
      }
      .priceDetail {
        margin-top: -8px;
        margin-bottom: 2px;
        line-height: 34px;
        justify-content: flex-start;
        .color86{
            span{text-decoration: line-through;}
        }
      }
      .memberDetail {
        .icon {
          display: inline-block;
          line-height: 20px;
          width: 60px;
          height: 20px;
          border-radius: 10px;
          background: #212529;
          text-align: center;
          color: #fff;
          font-size: 10px;
          margin-right: 8px;
        }
        .member-price {
          margin-right: 18px;
        }
        .themeColor {
          font-size: 16px;
        }
        .link {
          color: #212529;
          text-decoration: underline;
        }
      }
      .complimentary {
        font-size: 16px;
        span {
          background: #eb646a;
          display: inline-block;
          color: #fff;
          height: 20px;
          padding: 0 6px;
          font-size: 12px;
          line-height: 20px;
          margin-right: 12px;
        }
      }
      .btns {
        justify-content: flex-start;
        button {
          margin-right: 20px;
          width: 150px;
          height: 46px;
          line-height: 46px;
          text-align: center;
          border-radius: 2px;
          font-size: 16px;
          cursor: pointer;
          &:nth-child(1) {
            background: $themeColor;
            color: #fff;
            cursor: pointer;
          }
          &:nth-child(2) {
            border: 1px solid $themeColor;
          }
        }
      }
    }
  }

  /* sku数据选择样式部分 */
  .sku-wrap {
    display: block;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    &.active {
      border: 1px solid #2283E2;
      background-color: #2283E2;
      color: #fff;
    }
  }
}
.turnInlineBlock{
  display: inline-block;
}
.w67{
  width: 67px;
}
.lh26{
  line-height: 26px;
}
/deep/ .joinCart{
   border:1px solid #2283E2;
   color: #2283E2;
   background-color: #fff;
}
.follow{
  background-color: #fff;
  border: 1px solid #E6EAF0;
}
.followed{
  color: #2283E2;
}
</style>