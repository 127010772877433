<template>
<div class="member-main">
    <div class="model-title">兑换排行</div>
    <div class="m-main-block">
        <!-- <div class="m-m-b-block1">
            <img :src="cmsData.bannerImg" alt="" />
        </div> -->

        <div v-for="(item, index) in recommendList" :key="index" @click="detail(item.id)">
            <!-- <div class="m-m-b-block2" :class="index == 3 ? 'm-m-b-b2-active ' : ''"> -->
            <div class="m-m-b-block2">
                <div class="m-m-b-b2-img">
                    <img width="100%" class="top5-imgs" :src="require('@/assets/images/NO1.png')" alt="" v-if="index==0">
                    <img width="100%" class="top5-imgs" :src="require('@/assets/images/NO2.png')" alt="" v-if="index==1">
                    <img width="100%" class="top5-imgs" :src="require('@/assets/images/NO3.png')" alt="" v-if="index==2">
                    <img :src="item.pictureUrl" alt="" />
                </div>
                <div class="m-m-b-b2-bottom">
                    <div class="m-m-b-b2-b-top">
                        <span>{{ item.chineseName }}</span>
                    </div>
                    <div class="pro-price">{{item.integralNum}} <span>积分</span></div>

                </div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
import {
    queryByProductId
} from "@/api/api";
export default {
    components: {},
    props: ["cmsData"],
    data() {
        return {
            recommendList: [],
        };
    },

    methods: {
        detail: function (id) {
            let routeUrl = this.$router.resolve({
                path: "/products/productDetails?id=",
                query: {
                    id: id,
                },
            });
            window.open(routeUrl.href, "_blank");
        },
        async queryProduct() {
            let tempList = [];

            //周排行
            this.cmsData.recommendList.map((value) => {
                tempList.push({
                    id: value.id,
                });
            });
            let params = {
                list: tempList,
            };
            let res = await queryByProductId(params);
            this.recommendList = res.data;
        },
        golist() {

            let routeUrl = this.$router.resolve({
                path: "/products/productList",
                query: {
                    cName: "海尔",
                },
            });
            window.open(routeUrl.href, "_blank");
        }

    },

    mounted() {
        this.queryProduct();
    },
};
</script>

<style lang="scss" scoped>
.model-title {
    font-size: 24px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
}

.model-title:before,
.model-title:after {
    content: "";
    display: inline-block;
    width: 4px;
    height: 24px;
    background: #bca06b;
    margin: auto 12px;
    vertical-align: sub;
}

// .m-m-b-block2:hover {
//     box-shadow: 0 3px 8px rgba($color: #000000, $alpha: 0.3);
//     transition: box-shadow 0.3s linear;
// }

.member-main {
    width: 1200px;
    margin: 0 auto;
    // height: 712px;
    opacity: 1;
    // background: #ffffff;

    .m-main-title {
        width: 100%;
        height: 62px;
        display: flex;

        .m-m-t-left {
            width: 60%;
            height: 100%;
            display: flex;
            align-items: center;

            .text1 {
                font-size: 24px;
                font-weight: 500;
                color: #333333;
                margin-left: 20px;
            }

            img {
                width: 18px;
                height: 19px;
                margin-left: 10px;
                margin-right: 9px;
            }

            .text2 {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
            }
        }

        .m-m-t-right {
            width: 40%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;

            img {
                width: 14px;
                height: 14px;
                margin-right: 20px;
                margin-left: 6px;
            }

            span {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
            }
        }
    }

    .m-main-block {
        width: 100%;
        // height: 650px;
        display: flex;
        background: #fff;
        justify-content: space-between;
        flex-wrap: wrap;

        .m-m-b-block1 {
            width: 458px;
            // height: 295px;
            opacity: 1;
            margin-left: 20px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .m-m-b-block2:hover {
            box-shadow: 0 3px 8px rgba($color: #000000, $alpha: 0.3);
            //   transition: box-shadow 0.3s linear;
            background-color: #fff;

            .shandian {
                display: inline;
            }
        }

        .m-m-b-block2 {
            background: #fff;
            width: 350px;
            // height: 295px;
            opacity: 1;
            margin-bottom: 10px;

            .m-m-b-b2-img {
                width: 100%;
                // height: 210px;
                display: flex;
                align-items: flex-end;
                position: relative;
                padding: 10px 0;

                img {
                    width: 200px;
                    height: 200px;
                    margin: 0 auto;
                }

                .top5-imgs {
                    z-index: 2;
                    top: 20px;
                    left: 20px;
                    position: absolute;
                    width: 48px;
                    height: 42px;
                }
            }

            .m-m-b-b2-bottom {
                width: 100%;
                // height: 96px;

                .m-m-b-b2-b-top {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    display: flex;
                    align-items: flex-end;
                    text-align: center;
                    justify-content: center;

                    span {
                        font-size: 14px;
                        font-weight: 500;
                        color: #333333;
                        margin: 0 30px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                .pro-price {
                    font-size: 28px;
                    // font-weight: 700;
                    text-align: center;
                    color: #ac8c4f;
                    padding-bottom: 10px;

                    span {
                        height: 20px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }
        }

        .m-m-b-b2-active {
            margin-left: 20px !important;
        }
    }
}
</style><style lang="scss" scoped>
.main {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #ee6836;
}

.cation-item {
    width: 100%;
    background: #fff4f0;
    padding-bottom: 15px;
}

.tab_con {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.advertisement {
    width: 48%;
    margin-bottom: 10px;
    height: 340px;

    .advertisement-img {
        width: 100%;
        height: 100%;
    }
}

.classification {
    width: 22%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
    // padding: 10px;
    margin-bottom: 20px;
    transition: box-shadow 0.3s linear;
    cursor: pointer;
}

.classification:hover {
    box-shadow: 0 3px 8px rgba($color: #000000, $alpha: 0.3);
    transition: box-shadow 0.3s linear;
}

.cation {
    width: 100%;
    margin: 0 auto;
}

.cationtitle {
    font-size: 14px;
    margin: 0 auto;
    padding-top: 15px;
    color: #333333;
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cationtext {
    color: #999;
    padding-top: 5px;
}

.cationintegral {
    font-size: 15px;
    margin: 0 auto;
    color: #ee365e;
    margin-top: 6px;
    width: fit-content;
    padding: 0 12px;
    // border: 1px solid #ed2856;
    border-radius: 20px;
}

.cationimg {
    width: 264px;
    height: 264px;
}

.member-title {
    display: block;
    line-height: 50px;
    padding: 10px 20px 20px 0;
    font-size: 26px;
    font-weight: bold;
}
</style>
