<template>
  <div class="logisticsBg">
    <!-- 物流组件 -->
    <div class="logistics">
      <!-- 物流头部提示信息 -->
      <div class="logisticsTitle">
        <p>追踪物流</p>
        <span class="logisticsClose" @click="close"></span>
        <div class="titleInformation">
          <div class="InformationIcon">
            <img :src="info.distributorLogo" alt="" class="logo">
          </div>
          <div class="InformationRight">
            <ul>
              <li>
                物流公司：
                <span>{{ info.distributorName }}</span>
              </li>
              <li>
                快递单号：
                <span>{{ info.expressNo }}</span>
              </li>
              <li>
                收货人：
                <span>{{ info.receiverName }}</span>
              </li>
              <li>
                手机号：
                <span>{{ info.receiverPhone }}</span>
              </li>
              <li>
                收获地址：
                <span>{{info.receiverProvinceName}}-{{info.receiverCityName}}-{{info.receiverRegionName}}-{{info.receiverAddress}}-{{ info.receiverAddress }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 物流过程信息 -->
      <div class="logisticsContent">
        <div class="contentScroll">
          <!-- 此处里面的li可根据后端数据动态去生成 -->
          <ul v-if="info.orderBaleDeliveryResponseList.length > 0">
            <li v-for="(itm,inx) in info.orderBaleDeliveryResponseList" :key="inx">
              <div class="content-left">
                <div >{{itm.createTime}}</div>
              </div>
              <p class="content-right">{{itm.remark}}</p>
            </li>
          </ul>
        </div>
      </div>
      <!-- 底部确认按钮 -->
      <div class="logisticsButton">
        <button @click="close">确定</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'logistics',

  props: {
    info: {
      type: Object
    }
  },
  data() {
    return {
      
    };
  },
  methods: {
    // 关闭吴瑞弹窗
    close() {
      this.$emit('closeLogistics');
    }
  },
  mounted() {

  },
};
</script>
<style lang="scss" scoped>
.logisticsBg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  .logistics {
    width: 600px;
    height: 600px;
    padding: 20px 40px 0;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    .logisticsTitle {
      position: relative;
      p {
        font-weight: 400;
        font-size: 22px;
      }
      .logisticsClose {
        background: white;
        color: #ccc;
        border-radius: 20px;
        line-height: 30px;
        text-align: center;
        height: 30px;
        width: 30px;
        font-size: 28px;
        padding: 1px;
        display: inline-block;
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 0;
      }
      .logisticsClose::before {
        content: "\2716";
      }
      .titleInformation {
        padding: 10px 0;
        box-sizing: border-box;
        display: flex;
        .InformationIcon {
          width: 25%;
          height: 100px;
          padding-top: 20px;
          .logo {
            display: inline-block;
            width: 70px;
            height: 70px;
            border: 1px solid #CCC;
          }
        }
        .InformationRight {
          width: 100%;
          ul {
            padding-top: 5px;
            li {
              height: 30%;
              font-size: 16px;
              line-height: 26px;
              span {
                color: #666;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
    .logisticsContent {
      height: 50%;
      overflow: scroll;
      overflow-x: hidden;
      border-bottom: 1px solid #e8e8e8;
      border-top: 1px solid #e8e8e8;
      .contentScroll {
        height: auto;
        ul {
          padding-top: 20px;
          li {
            display: flex;
            .content-left {
              width: 30%;
              padding-bottom: 20px;
              position: relative;
              border-right: 1px dashed #ccc;
              p {
                font-size: 16px;
                &:nth-child(1) {
                  color: black;
                  padding-bottom: 5px;
                }
                &:nth-child(2) {
                  color: #999;
                }
              }
              span {
                position: absolute;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: #999;
                top: 0;
                left: 142px;
                z-index: 1;
              }
            }
            .content-right {
              width: 70%;
              padding-left: 30px;
              padding-right: 20px;
              color: black;
              font-size: 14px;
            }
          }
        }
      }
    }
    .logisticsButton {
      height: 70px;
      text-align: center;
      padding-top: 20px;
      button {
        height: 40px;
        width: 100px;
        font-size: 16px;
        text-align: center;
        line-height: 30px;
        border-radius: 5px;
        cursor: pointer;
        margin: 0 auto;
        background-color: #2283E2;
        color: #fff;
      }
    }
  }
}
</style>