<template>
  <div class="listIndex main-container">
    <div class="tabSearch">
      <van-tabs v-model="active" @click="changeStatus">
        <van-tab :title="item.title" v-for="(item,index) in tabsArray" :key="index"></van-tab>
        <lists v-if="list.length > 0" :list="list" :isSeckill='isSeckill'></lists>
        <nodata v-else></nodata>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import lists from "@/components/skillList";
import {querySeckillProductList,queryAboutStartGoodsListPage,queryHasNotStartGoodsListPage} from "@/api/api";
import nodata from '@/components/noData'
export default {
  name: "listIndex",
  components: {lists,nodata},
  data() {
    return {
      filter: {
        start: 1,                 // 请求页数
        limit: 100,               // 每次请求条数
        status: 0                 // 秒杀状态
      },
      isSeckill: true,            // 是秒杀品
      active: 0,                  // 当前显示tab索引
      //当前tab数组
      tabsArray: [
        {
          title: "正在秒杀",
        },
        {
          title: "即将开抢",
        },
        {
          title: "更多预告",
        },
      ],
      //数据列表
      list: [],
    };
  },
  methods: {
    //状态切换
    changeStatus: function (param) {
      if (param == 0) {
        this.filter = {
          start: 1, 
          limit: 100, 
          status: 0, 
        };
        this.getList();
      } else if (param == 1) {
        this.filter = {
          start: 1, 
          limit: 100, 
          status: 1,  
        };
        this.getAboutStartList();
      } else {
        this.filter = {
          start: 1, 
          limit: 100, 
          status: 2,
        };
        this.queryHasNotStartGoodsListPage();
      }
    },
    // 获取秒杀商品数据
    async getList() {
      const res = await querySeckillProductList(this.filter);

      if (res.code === "0000") {
        this.list = res.data.rows;
      } else {
        this.$toast(res.msg);
      }
    },
    // 获取即将开始
    async getAboutStartList() {
      const res = await queryAboutStartGoodsListPage(this.filter);
      if (res.code === "0000") {
        this.list = res.data.rows;
      } else {
        this.$toast(res.msg);
      }
    },
    // 获取秒杀商品数据
    async queryHasNotStartGoodsListPage() {
      const res = await queryHasNotStartGoodsListPage(this.filter);

      if (res.code === "0000") {
        this.list = res.data.rows;
      } else {
        this.$toast(res.msg);
      }
    },
  },
  mounted() {
    this.getList()
  }
};
</script>
<style lang="scss" scoped>
    .listIndex {
        width: 1200px;
        margin: 0 auto;
        font-size: 14px;
        color: #333;
        .tabSearch {
            margin-top: 20px;
            text-align: center;
            /deep/ .van-tabs {
                .van-tabs__wrap {
                    font-size: 16px;
                    height: 50px;
                    border: 1px solid #e6eaf0;
                    border-right: 0 none;
                    .van-tabs__nav {
                        width: 100%;
                        justify-content: flex-start;
                        .van-tab {
                            line-height: 50px;
                            padding: 0;
                            border-right: 1px solid #e6eaf0;
                            .van-tab__text {
                                font-size: 16px;
                            }
                        }
                        .van-tab:last-child {
                            border-right: 0 none;
                        }
                        .van-tab--active {
                            background: $themeColor;
                            color: #fff;
                        }
                    }
                }
                .van-tabs__content {
                    .van-tab__pane {
                        .tabSearchType {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            cursor: pointer;
                            span {
                                width: 184px;
                                color: #868e96;
                                font-size: 14px;
                                &.on {
                                    color: $themeColor;
                                }
                            }
                        }
                    }
                }
                .van-tabs__line {
                    display: none;
                }
            }
        }
    }
</style>