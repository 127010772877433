<template>
  <div class="list-wrap">
    <!-- tab部分 -->
    <div class="tap-wrap">
      <span class="common-tab" :class="{active:active == 0}" @click="tab(0)">全部订单</span>
      <span class="common-tab" :class="{active:active == 1}" @click="tab(1)">待付款</span>
      <span class="common-tab" :class="{active:active == 2}" @click="tab(2)">待发货</span>
      <span class="common-tab" :class="{active:active == 4}" @click="tab(4)">已发货</span>
      <span class="common-tab" :class="{active:active == 6}" @click="tab(6)">待评价</span>
    </div>
    <div class="search-wrap">
      <input type="text" v-model="searchValue" placeholder="输入订单号搜索" class="order-input">
      <span class="order-button" @click="search">搜索</span>
    </div>

    <!-- 订单列表部分 -->
    <div v-if="orderList.length > 0">
      <div class="list" v-for="(item,index) in orderList" :key="index">
      <p class="title">
        <img :src="item.orderList[0].storeLogoUrl" class="store-image fl" alt="">
        <span class="store-name fl ml20">{{ item.orderList[0].storeName }}</span>
        <span class="time fl ml20">下单时间：{{ item.createTime }}</span>
        <span class="order fl ml20">订单号：{{ item.parentOrderNo }}</span>
      </p>
      <div class="goods-wrap">
        <div class="common-style goods">
          <div class="image-wrap">
            <img :src="item.orderList[0].goodsInfoList[0].goodsImgUrl" class="image" alt="">
          </div>
          <span class="name">共{{ item.orderList[0].goodsInfoList[0].goodsNum }}件商品</span>

          <!-- 付款状态 -->
          <span v-if="item.orderStatus == '1'" class="name themeColor">待支付</span>
          <span v-else-if="item.orderStatus == '2'"  class="name themeColor">已支付</span>
          <span v-else-if="item.orderStatus == '3'" class="name themeColor">待发货</span>
          <span v-else-if="item.orderStatus == '4'" class="name themeColor">已发货</span>
          <span v-else-if="item.orderStatus == '5'" class="name themeColor">已签收</span>
          <span v-else-if="item.orderStatus == '6'" class="name themeColor">待评价</span>
          <span v-else class="name themeColor">已关闭</span>
        </div>

        <div class="common-style cost">
          <p class="price">{{ item.actualPayTotalAmount | formatMoney }}积分</p>
          <!-- <p class="tips mt5">(含运费：￥1.00元)</p> -->
        </div>
        <div class="common-style operation">
          <!-- 各种状态下对应的操作按钮 -->
          <blockquote v-if="item.orderStatus == '1'">
            <van-button block color="#2283E2" size="small">去付款</van-button>
            <van-button block color="#2283E2" size="small" @click.prevent="cancer(item.parentOrderNo)">取消订单</van-button>
          </blockquote>
          <blockquote v-else-if="item.orderStatus == '2' || item.orderStatus == '3'">
            <van-button block color="#2283E2" size="small" @click.prevent="apply(item.parentOrderNo)">申请售后</van-button>
          </blockquote>
          <blockquote v-else-if="item.orderStatus == '4'">
            <van-button block color="#2283E2" size="small" @click.prevent="confirm(item.parentOrderNo)">确认收货</van-button>
            <van-button block color="#2283E2" size="small" @click.prevent="toLogistics(item.parentOrderNo)">查看物流</van-button>
            <van-button block color="#2283E2" size="small" @click.prevent="apply(item.parentOrderNo)">申请售后</van-button>
          </blockquote>
          <blockquote v-else-if="item.orderStatus == '5'">
            <van-button block color="#2283E2" size="small" @click.prevent="toLogistics(item.parentOrderNo)">查看物流</van-button>
            <van-button block color="#2283E2" size="small" @click.prevent="apply(item.parentOrderNo)">申请售后</van-button>
          </blockquote>
          <blockquote v-else-if="item.orderStatus == '6'">
            <van-button block color="#2283E2" size="small" @click.prevent="evaluate(item)">去评价</van-button>
          </blockquote>
          <span class="tip" @click="goDetail(item.parentOrderNo)">查看详情</span>
        </div>
      </div>
    </div>
    </div>
    
    <nodata v-else></nodata>

    <!-- 物流弹窗 -->
    <logistics v-if="showLogistics" :info="logisticsInfo" @closeLogistics="closeLogistics"></logistics>
  </div>
  
</template>
<script>
import logistics from '@/components/logistics';
import { getOrderList,confirmShip,cancelOrder,getLogistics } from '@/api/api';
import nodata from '@/components/noData';

export default {
  name: 'list',
  components: {
    logistics,
    nodata
  },

  data() {
    return {
      active: 0,                                        // tab选中状态下标
      searchValue: '',                                  // 搜索订单号
      showLogistics: false,                             // 是否显示物流弹窗
      orderStatus: 0,                                   // 订单类型
      orderList: [],                                    // 订单列表数据
      logisticsInfo: {},                                // 物流信息数据
    }
  },

  methods: {
    // tab切换
    tab(value) {
      this.active = value;
      this.orderStatus = value;
      this.orderList = [];
      this.getList();
    },

    // 搜索订单
    search() {
      console.log('do');
    },

    // 跳转到订单详情
    goDetail(order) {
      this.$router.push({
        path: `/order/orderDetail?id=${order}`
      })
    },

    // 获取订单列表数据
    async getList() {
      let params = {
        orderStatus: this.orderStatus,
        start: 1,
        limit: 100,
      };
      const res = await getOrderList(params);
      if( res.code == '0000' ) {
        this.orderList = res.data;
      }else {
        this.$toast(res.msg);
      }
    },

    // 取消订单
    cancer(number) {
      this.$dialog.confirm({
        title: '提示',
        message: '确定要取消该订单？'
      }).then(() => {
        this.cancers(number);
      }).catch(() => {
      });
    },

    // 确认取消接口
    async cancers (number) {
      let params = {
        parentOrderNo : number
      }
      let id = number;
      let lists = this.orderList;
      const res =await cancelOrder(params);
      if(res.code === '0000'){
        this.$toast("订单已取消");
        lists.forEach((item)=>{
          if(item.parentOrderNo === id) {
            item.orderStatus = '7'
          }
        })
      }else{
        this.$toast(res.message);
      }
    },

    // 申请售后
    apply(number) {
      let id = number;
      this.$router.push({
        path: `/order/orderDetail?id=${id}`
      });
    },

    // 确认收货
    async confirm(number){
      let params = {
        orderNo : number
      }
      let id = number;
      let lists = this.orderList;
      const res =await confirmShip(params);
      if(res.code === '0000'){
        this.$toast("确认收货成功");
        lists.forEach((item)=>{
          if(item.parentOrderNo === id) {
            item.orderStatus = '5'
          }
        })
      }else{
        this.$toast(res.message);
      }
    },

    // 显示物流弹窗
    async toLogistics(number) {
      let params = {
        orderNo: number,
      };
      const res = await getLogistics(params);
      if(res.code === '0000') {
        this.logisticsInfo = res.data;
        this.showLogistics = true;
      }else {
        this.$toast(res.msg);
      }
    },

    // 关闭物流弹窗
    closeLogistics() {
      this.logisticsInfo = {};
      this.showLogistics = false;
    },

    // 去评价
    evaluate(item) {
      const orderNo = item.orderList[0].orderNo
      const id = item.orderList[0].goodsInfoList[0].goodsId
        this.$router.push({
        path: `/user/addEvaluate?orderNo=${orderNo}&goodsId=${id}`
      })
    },

  },

  mounted() {
    this.getList();
  },
}
</script>
<style lang="scss" scoped>
.list-wrap {
  width: 100%;
  height: auto;
  .tap-wrap {
    display: inline-block;
    border-bottom: 2px solid #eaeaea;
    .common-tab {
      display: inline-block;
      width: 110px;
      padding: 10px 0;
      font-size: 18px;
      color: #333;
      margin-bottom: -2px;
      text-align: center;
      cursor: pointer;
      font-size: 16px;
      position: relative;
    }
    .common-tab::after {
      content: '|';
      position: absolute;
      right: 0;
      top: 12px;
      color: #ccc;
    }
    .common-tab:last-child::after {
      content: '';
    }
    .common-tab.active {
      color: $themeColor;
      border-bottom: 2px solid $themeColor;
    }
  }
  .search-wrap {
    width: 290px;
    float: right;
    margin-top: 10px;
    font-size: 14px;
    .order-input {
      width: 240px;
      height: 28px;
      border: 1px solid #ddd;
      float: left;
      padding-left: 12px;
    }
    .order-button {
      width: 51px;
      height: 28px;
      line-height: 28px;
      margin-left: -1px;
      border: 1px solid #ddd;
      border-radius: 0;
      float: left;
      text-align: center;
      background-color: #f5f5f5;
      cursor: pointer;
    }

  }
  /* 订单数据 */ 
  .list {
    border: 1px solid #e8e8e8;
    margin-top: 20px;
    .title {
      background-color: #f5f5f5;
      height: 43px;
      line-height: 43px;
      text-align: left;
      font-weight: 400;
      padding-left: 10px;
      .store-image {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-top: 10px;
      }
    }
    /* 商品部分 */ 
    .goods-wrap {
      border-top: 1px solid #e8e8e8;
      display: flex;
      .common-style {
        border-right: 1px solid #e8e8e8;
        padding: 20px 0;
        .image-wrap {
          float: left;
          position: relative;
          width: 100px;
          height: 100px;
          margin: 0 10px;
          .image {
            background-color: #f4f4f4;
            display: block;
            width: 100px;
            height: 100px;
          }
        }
        .name {
          float: left;
          width: 220px;
          margin-top: 40px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
        }
      }
      .common-style:last-child {
        border-right: 0 none;
      }
    }
    /* 价格 运费部分 */ 
    .cost {
      width: 200px;
      text-align: center;
      .price {
        font-size: 16px;
        font-weight: 700;
        margin-top: 20px;
      }
    }
    /* 操作部分 */ 
    .operation {
      width: 215px;
      text-align: center;
      .common-button {
        display: block;
        width: 100px;
        height: 30px;
        line-height: 30px;
        // background-color: #ccc;
        background-color: $themeColor;
        color: #fff;
        margin: 10px auto;
        border-radius: 5px;
      }
      .tip {
        color: #69c;
        cursor: pointer;
      }
    }
  }
}
.van-button--block {
  margin-bottom: 15px;
  font-size: 14px;
}
</style>


