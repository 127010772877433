<template>
  <div class="hair-swiper">
    <div class="swiper-title">
      <span class="title-text">{{ swiperData.data.title }}</span>
      <div class="title-more">
        <span>查看全部</span>
        <img src="../../../static/icon/hair_right.png" alt="" />
      </div>
    </div>
    <div class="swiper-main">
      <ul class="swiper-inside">
        <li
          class="swiper-item"
          v-for="item in swiperData.data.list"
          :key="item.id"
          @click="goOut(item.url)"
        >
          <div class="swiper-top">
            <img :src="item.img" alt="" />
          </div>
          <div class="swiper-bottom">
            <span class="bottom-title">{{ item.title }}</span>
            <div class="bottom-tag">
              <span>{{ item.content }}</span>
            </div>
            <span class="bottom-price">{{ item.price }}积分</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    swiperData: {
      type: Object,
      default: () => {
        return {
          data: {
            title: "热门兑换",
            list: [
              {
                img: "https://i.loli.net/2020/11/23/dziM7wuCRqGAh4t.jpg",
                url: "https://haierpc.dreamane.com/#/list/detail?id=5812",
                price: "3400",
                title: "Apple Watch Nike SE",
                content: "血氧检测,运动记录",
              },
              {
                img:
                  "https://img10.360buyimg.com/seckillcms/s500x500_jfs/t1/115672/14/10112/234600/5ee86b56E481e805e/84cc77691fc10eec.jpg",
                url: "https://haierpc.dreamane.com/#/list/detail?id=5812",
                price: "4399",
                title: "红魔5G氘锋透明版",
                content: "12G+256G 144Hz屏幕刷新率",
              },
              {
                img:
                  "https://img11.360buyimg.com/seckillcms/s500x500_jfs/t1/145626/4/15455/105576/5fc0a472Eb09d98e6/293e23dd1c249e23.jpg",
                url: "https://haierpc.dreamane.com/#/list/detail?id=5812",
                price: "195",
                title: "网易云音乐真无线耳机",
                content: "延迟低至0.04秒",
              },
              {
                img:
                  "https://img11.360buyimg.com/seckillcms/s500x500_jfs/t1/136035/22/17401/137480/5fbf4e61E59afced4/b202a0d7f47b53c9.jpg",
                url: "https://haierpc.dreamane.com/#/list/detail?id=5812",
                price: "8658",
                title: "戴尔新G5学生电竞游戏设计师本",
                content: "i7处理器，高分屏",
              },
              {
                img:
                  "https://img11.360buyimg.com/seckillcms/s500x500_jfs/t1/149708/7/16104/108291/5fc32e76Efc314af7/9b6c26915eac3b4d.jpg",
                url: "https://haierpc.dreamane.com/#/list/detail?id=5812",
                price: "2888",
                title: "华为畅享20plus 手机星河银8+128",
                content: "4200mAh大电池",
              },
              {
                img: "https://img13.360buyimg.com/seckillcms/s500x500_jfs/t1/148857/12/12764/44106/5f9d5962Ec85ab55e/2e49ffe6c17d3af2.jpg",
                url: "https://haierpc.dreamane.com/#/list/detail?id=5812",
                price: "3555",
                title: "菲拉格慕男士GANCINI牛皮革腰带",
                content: "双面可调节",
              },
              {
                img: "https://img13.360buyimg.com/seckillcms/s500x500_jfs/t1/123343/13/6430/40994/5f02f6e1E0e1b652c/8c76aa08928f130b.jpg",
                url: "https://haierpc.dreamane.com/#/list/detail?id=5812",
                price: "419",
                title: "清华同方 好体知智能体脂秤M2",
                content: "专利技术精准测脂",
              },
            ],
          },
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    goOut(url) {
      window.open(url, "_blank");
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.hair-swiper {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  .swiper-title {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title-text {
      color: #666666;
      font-size: 16px;
    }
    .title-more {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        margin-left: 6px;
      }
    }
  }

  .swiper-main {
    width: 100%;
    height: 260px;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    .swiper-inside {
      width: auto;
      height: 250px;
      .swiper-item {
        width: 176px;
        height: 250px;
        margin-right: 10px;
        transition: box-shadow 0.2s linear; /*反向过渡*/
        display: inline-flex;
        flex-direction: column;
        .swiper-top {
          height: 160px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 100px;
            width: 100px;
          }
        }
        .swiper-bottom {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .bottom-title {
            padding: 0 12px;
            color: #222222;
            font-size: 16px;
            font-weight: bold;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .bottom-tag {
            color: #bbb;
            font-size: 12px;
          }
          .bottom-price {
            font-size: 15px;
            margin-top: 6px;
            color: #ed2856;
          }
        }
      }
      .swiper-item:hover {
        transition: box-shadow 0.2s linear; /*反向过渡*/
        box-shadow: 0 6px 8px rgba($color: #000000, $alpha: 0.1);
      }
    }
  }
}
</style>