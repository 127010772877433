<template>
  <div class="register">
    <div class="p">
      为了回馈海尔用户的支持，同时提高海尔的服务质量，创造更好的用户交互平台。海尔特别提出了"海尔海贝商城"以及“海贝计划”。
    </div>
    <div class="p">
      作为海尔用户的您，可以尽享海贝商城网上兑换商品和商品门到门的配送服务，您可以足不出户的享受和海尔品牌的互动交流，同样，您也可以为您购买的海尔产品选择更多的优质售后服务。 
    <div class="p">
      海尔希望和所有用户的共同努力下，让海尔海贝商城成为深受海尔会员所喜爱的沟通、交互、奖励平台。同时为了可以满足不同会员的兑换需求，海尔海贝商城也将不断的完善和丰富兑换奖励品的方式，并随时听取所有用户的意见和建议能更好有效的服务所有海尔用户。
    </div>
    <div class="p">
      在未来的日子里，海尔海贝商城会一如既往的为您提供优质的服务和丰富多彩的活动，使您的生活变得更丰盛！如有疑问请及时与客户服务部联系，我们会尽快为您解决。 
    </div>
    <h2 class="title">海尔海贝商城：</h2>
    <div class="p">
      是面向所有海尔官网注册用户提供兑换奖励商品的平台。官网
    </div>
    <h2 class="title">海贝商场主要支付手段：</h2>
    <div class="p">
      海贝
    </div>
    <h2 class="title">海贝获取渠道：</h2>
    <div class="p">
      支付兑换海尔海贝商城货品和服务的海贝可以在海知友、海尔社区等交互渠道中完成各项任务和互动环节获取海贝。
    </div>
    <h2 class="title">海贝的用途： </h2>
    <div class="p">
      海尔用户可以使用海贝兑换各类实物、虚拟礼品或海尔产品的清洗保养、延保服务、以及其它增值服务。
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },

  methods: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  color: #808080;
  margin-top: 5px;
}
.p {
  margin-top: 5px;
  text-indent: 2em;
  font-size: 16px;
  color: #808080;
  line-height: 2;
  // padding-bottom: 10px;
}
</style>
