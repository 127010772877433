<template>
  <div class="CouponList">
    <div :class="[{ 'over':tabIndex==2 },'tabsMain']">
      <div class="tabsItem">
        <p
          v-for="(item,index) in tabs"
          :key="index"
          :class="[{'on':tabIndex==index},'item']"
          @click="tabFun(index)"
        >{{item}}</p>
      </div>
      
      <div class="tabsList">
        <ul v-if="list.length > 0">
          <li v-for="(item,index) in list" :key="index">
            <div class="flex">
              <p class="price">{{item.couponAmount}}元</p>
              <div class="tag">
                <p>{{item.promName}}</p>
                <p>{{item.couponUseEndTime}}</p>
                <button v-if="item.status === 1">去使用</button>
              </div>
            </div>
            <div :class="['validity',{'validityFlag':item.validityFlag ==false}]" @click="switchValidity(item)">
              <i></i>{{item.chineseDesc}}
            </div>
          </li>
        </ul>
        <nodata v-else></nodata>
      </div>
    </div>
  </div>
</template>

<script>
import {queryMyCoupon} from '@/api/api'
import nodata from '@/components/noData'
import store from '@/store/store';
export default {
  name: 'dis',
  components: {
    nodata
  },
  data() {
    return {
      tabIndex: 0,
      tabs: ["未使用", "已使用", "已过期"],
      list: [],
    };
  },
  
  created(){
  },
  
  methods: {
    // 查询优惠券信息
    async queryMyCoupon(status) {
      const params = {start: 1, limit: 100, status: status ,token:store.getToken}
      const res = await queryMyCoupon(params)
      if (res.code === '0000') {
        this.list = res.data.rows
      } else {
        this.$toast(res.msg)
      }
    },
    
    //tab切换
    tabFun(ind) {
      this.tabIndex = ind;
      this.queryMyCoupon(++ind)
    },
    
    switchValidity(item) {
      this.list = this.list.map(m => {
        console.log(m.id, item.id);
        if (m.id == item.id) {
          m.validityFlag = !m.validityFlag;
        }
        return m;
      });
    }
  },
  
  mounted() {
    this.queryMyCoupon(1)
    this.list = this.list.map(f => {
      f.validityFlag = false;
      return f;
    });
  }
};
</script>

<style lang="scss" scoped>
.CouponList {
  .tabsMain {
    &.over {
      .tabsList {
        ul {
          li {
            color: #fff;
            .flex {
              background: #cfcfcf;
            }
            .validity {
              background: #e0dede;
              border-top: 1px dashed #b3b3b3;
              i {
                border-bottom: 1px solid #b3b3b3;
                border-right: 1px solid #b3b3b3;
              }
            }
          }
        }
      }
    }
  }
  .tabsList {
    ul {
      display: flex;
      li {
        padding: 5px;
        width: 282px;
        color: #ff615e;
        margin-right: 10px;
        border-radius: 5px;
        position: relative;
        &::before,
        &::after {
          content: "";
          width: 16px;
          height: 16px;
          background: #fff;
          border-radius: 16px;
          position: absolute;
          top: 97px;
          z-index: 999;
        }
        &::before {
          left: -5px;
        }
        &::after {
          right: -5px;
        }
        .flex {
          height: 100px;
          display: flex;
          justify-content: flex-start;
          padding: 20px;
          background: #fff1f1;
          .price {
            font-size: 30px;
            padding-right: 20px;
          }
          .tag {
            p {
              width: 130px;
              line-height: 1.6;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            button {
              margin-top: 2px;
              padding: 1px 4px;
              background: #fff;
            }
          }
        }
        .validity {
          overflow: hidden;
          padding: 8px 24px 8px 14px;
          border-top: 1px dashed #ff615e;
          background: #fff1f1;
          font-size: 12px;
          position: relative;
          line-height: 1.6;
          cursor: pointer;

          &.validityFlag {
            height: 34px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            i {
              transform: rotate(225deg);
              top: 15px;
            }
          }
          i {
            display: block;
            width: 8px;
            height: 8px;
            border-bottom: 1px solid #ff615e;
            border-right: 1px solid #ff615e;
            transform: rotate(45deg);
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }
      }
    }
  }
}
</style>