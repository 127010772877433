<template>
  <div class="swiper" v-if="variablesObject">
    <template v-if="variablesObject.imgUlrList">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item,index) in variablesObject.imgUlrList" :key="index">
          <router-link to>
            <img :src="item.imgUrl" alt @click="goOut(item.backUrl)" />
          </router-link>
        </swiper-slide>
      </swiper>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    variablesObject: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      swiperOption: {
        //开启循环模式
        loop: true,
        slidesPerView: 8
      }
    };
  },
  methods: {
    goOut(url) {
      window.open(url, "_blank");
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.swiper {
  width: 1440px;
  margin: 6px auto 30px;
}
/deep/ .swiper-container {
  .swiper-slide {
    width: 180px;
    height: 106px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>