<template>
    <div class="userCenter-wrap">

        <div class="info-wrap">
            <!-- 基础信息 -->
            <div class="info">
                <router-link to="" class="avatar fl">
                    <!-- <span class="iconfont icon-touxiang"></span> -->
                    <img :src="myInformation.headImage" alt class="user-image" width="50" />
                    <span class="id ml10">用户名：{{myInformation.nickName}}</span>
                </router-link>
            </div>
            <!-- 成长值 -->
            <div class="growth-value">
                <div class="title">成长值</div>
                <div class="level">
          <span class="wrap">
            <span class="inner" :style="{width:(myInformation.growthNumber/myInformation.growthValueMax)*100+'%'}"></span>
          </span>
                    <span class="value">{{myInformation.growthNumber}}/{{myInformation.growthValueMax}}</span>
                </div>
            </div>
        </div>

        <!-- 余额  积分 优惠券 充值卡部分 -->
        <div class="user-detail">
            <!-- <div class="common-style">
                <div class="iconfont icon-qian"></div>
                <div class="number-wrap">
                    <router-link to="/user/card" class="title mb10">我的余额</router-link>
                    <p class="value" v-if="isshowMoney">{{myInformation.myBalance}}</p>
                    <p class="value" v-else>￥***</p>
                </div>
                <div class="recharge-wrap">
                    <router-link to="/user/card" class="add">充值</router-link>
                    <div class="iconfont icon-yanjing" v-if="isshowMoney" @click="isshowMoney=false"></div>
                    <div class="iconfont icon-biyan" v-else @click="isshowMoney=true"></div>
                </div>
            </div> -->
            <div class="common-style">
                <div class="iconfont icon-jifen"></div>
                <div class="number-wrap">
                    <router-link to="/user/integral" class="title">我的积分</router-link>
                    <p class="value">{{myInformation.integral}}</p>
                </div>
            </div>
            <div class="common-style">
                <div class="iconfont "></div>
                <div class="number-wrap">
                    <router-link to="/user/discountCouponList" class="title">我的优惠券</router-link>
                    <p class="value">{{myInformation.myCoupon}}</p>
                </div>
            </div>
            <div class="common-style">
                <div class="iconfont icon-icon-test"></div>
                <div class="number-wrap">
                    <router-link :to="{path:'/user/card',query: {number: myInformation.myRechargeCard}}" class="title">我的充值卡</router-link>
                    <p class="value toggle" v-if="isshowCard">{{myInformation.myRechargeCard}}</p>
                    <p class="value toggle" v-else>￥***</p>
                    <div class="iconfont icon-yanjing toggle" v-if="isshowCard" @click="isshowCard=false"></div>
                    <div class="iconfont icon-biyan toggle" v-else @click="isshowCard=true"></div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import {queryMember} from '@/api/api';
    export default {
        name: 'userCenter',
        components: {},
        data() {
            return {
                isshowMoney: true,         // 是否显示余额
                isshowCard: true,          // 是否显示充值卡余额
                myInformation: {

                },
            }
        },

        methods: {
            // 获取个人数据
            async getUserInfo() {
                const res = await queryMember();
                if (res.code === "0000") {
                    this.myInformation = res.data;
                    this.myInformation.growthValueMax =1000
                } else {
                    this.$toast(res.msg || '请求失败')
                }
            }
        },
        mounted() {
            // 获取个人数据
            this.getUserInfo();
        },
    }
</script>
<style lang="scss" scoped>
    .userCenter-wrap {
        display: block;
        width: 100%;
        height: 100%;
        .info-wrap {
            background: rgba(134, 142, 150, 0.05);
            border-right: none;
            border: 1px solid #E6EAF0;
            width: auto;
            padding: 15px 20px;
            display: flex;
            height: 100px;
            /* 基础信息 */
            .info {
                display: inline-block;
                width: 35%;
                height: 100%;
                padding-right: 50px;
                border-right: 1px dashed #dde0e5;
                .icon-touxiang {
                    color: #ccc;
                    font-size: 66px;
                    margin: 0 auto;
                    vertical-align: middle;
                }
                .id {
                    display: inline-block;
                    vertical-align: middle;
                    color: #868E96;
                    font-size: 16px;
                }
            }
            /* 成长值 */
            .growth-value {
                display: inline-block;
                width: 500px;
                height: 100%;
                padding: 12px 36px;
                .level {
                    display: block;
                    width: 100%;
                }
                .wrap {
                    display: inline-block;
                    width: 200px;
                    border: 1px solid $themeColor;
                    border-radius: 10px;
                    height: 10px;
                    margin: 10px 0 30px 0;
                    position: relative;
                    .inner {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: inline-block;
                        background-color: $themeColor;
                        // width: 50%;
                        height: 100%;
                    }
                }
                .value {
                    display: inline-block;
                    vertical-align: middle;
                    margin-bottom: 60px;
                    margin-left: 20px;
                    color: $themeColor;
                    font-size: 16px;
                }
            }
        }
        /* 余额 优惠券 积分 充值卡部分 */
        .user-detail {
            display: block;
            width: 100%;
            height: auto;
            border: 1px solid #E6EAF0;
            border-top: 0 none;
            display: flex;
            padding: 30px;
            .common-style {
                display: flex;
                width: 25%;
                text-align: center;
                border-right: 1px dashed #dde0e5;
                justify-content: center;
                .iconfont {
                    display: inline-block;
                    font-size: 46px;
                    color: #ccc;
                    margin-right: 10px;
                    vertical-align: middle;
                }
                .icon-biyan {
                    font-size: 20px;
                }
                .number-wrap {
                    font-size: 14px;
                    color: #333;
                    margin-top: 2px;
                    text-align: left;
                    .title {
                        display: block;
                        margin-bottom: 5px;
                        cursor: pointer;
                    }
                    .value.toggle {
                        display: inline-block;
                        margin-right: 10px;
                    }
                    .iconfont.toggle {
                        display: inline-block;
                        font-size: 20px !important;
                        color: $themeColor;
                    }
                }
                .recharge-wrap {
                    width: 100px;
                    text-align: center;
                    .add {
                        display: block;
                        background: #212529;
                        border-radius: 13px;
                        width: 50px;
                        height: 24px;
                        text-align: center;
                        line-height: 24px;
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        margin-left: 24px;
                        color: #FFFFFF;
                    }
                    .iconfont {
                        margin-right: 0;
                        font-size: 20px;
                        margin-top: 10px;
                        cursor: pointer;
                        color: $themeColor;
                    }
                }
            }
            .common-style:last-child {
                border-right: 0 none;
            }
        }
    }
</style>

