<template>
<div id="app">
    <!-- 顶部 -->
    <haierHeader></haierHeader>
    <!-- 主页路由 -->
    <div style="background: #F5F5F5;">
        <div class="home-container">
            <router-view></router-view>
        </div>
    </div>

    <!-- 底部 -->
    <haierFooter></haierFooter>

    <!-- 登录弹窗 -->
    <logins v-if="$store.state.showLogin"></logins>
    <loading v-show="loading"></loading>

</div>
</template>

<script>
import haierHeader from "@/components/haier/common/header";
import haierFooter from "@/components/haier/common/footer";
import logins from "@/components/login";
import loading from '@/components/loading.vue'

import {
    getUserToken,
    getUserInfo,
    addMember
} from "@/api/api";
import {
    mapState
} from 'vuex'
export default {
    name: "App",
    computed: {
        ...mapState([
            'loading'
        ])
    },
    components: {
        haierHeader,
        haierFooter,
        logins,
        loading
    },
    data() {
        return {
            code: "",
        };
    },

    methods: {
        //获取用户token
        appInit() {
            // debugger;
            let url = window.location.href;
            
            let code = url.match(/code=(\S*)&/);

            if (code !== null) {
                this.code = code[1];
                this.getUserToken();
            }
        },
        async getUserToken() {
            let params = {
                code: this.code,
            };

            const res = await getUserToken(params);
            // debugger;
            if (res.code == "0000") {
                localStorage.setItem("hbscToken", res.result.token);
                this.getUserInfo();
            } else {
                console.log("无法获取token");
            }
        },
        async getUserInfo() {
            let params = {};

            let userInfo = await getUserInfo(params);
            localStorage.setItem("memberId", userInfo.data.idsUserId);
            localStorage.setItem("memberName", userInfo.data.nickname);
            // localStorage.setItem("memberName", "testName");
            localStorage.setItem("memberPhone", userInfo.data.cellphoene);
            this.insertMember()
            let url = window.location.href;
            let redirecturi = url.match(/redirect_uri=(\S*)&code/);

            if (redirecturi !== null) {
                this.redirecturi = redirecturi[1];
                window.location.href= this.redirecturi
            }
        },
        // 添加用户会员
        async insertMember(info){
            let params ={
                id:localStorage.getItem("memberId"), 
                name:localStorage.getItem("memberName"),
                phone:localStorage.getItem("memberPhone"),
                status:0,
                sex:0
            }
            const res = await addMember(params);
            // debugger;
            
        },
    },

    mounted() {
        this.appInit();
        if (localStorage.getItem("hbscToken") !== null ){
            this.insertMember()
        }
    },
};
</script>

<style lang="scss" scoped>
.home-container {
    height: auto;
    // width: 1200px;
    margin: 0px auto;
}

.footer_icon {
    width: auto;
}

.el-tabs__item.is-active {
    color: #2283e2;
}
</style>
