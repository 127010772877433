<template>
  <div class="orderIndex main-container">
    <div class="mb20 address">
      <h4 class="title">收货信息</h4>
      <div class="flex addressEdit" v-if="showFlag">
        <div class="form">
          <div class="item">
            <span>所在地址:</span>
            <vdistpicker
              @province="onChangeProvince"
              @city="onChangeCity"
              @area="onChangeArea"
              :province="provinceData.provinceName"
              :city="provinceData.cityName"
              :area="provinceData.regionName"
            ></vdistpicker>
          </div>
          <div class="item textarea">
            <span>详细地址:</span>
            <input type="textarea" v-model="provinceData.recieverAddress" />
          </div>
          <div class="flex">
            <div class="item">
              <span>收货人:</span>
              <input type="text" v-model="provinceData.reciever" />
            </div>
            <div class="item">
              <span>手机号码:</span>
              <input type="tel" maxlength="11" v-model="provinceData.recieverTel" />
            </div>
          </div>
        </div>
        <div class="btns">
          <div class="mb20">
            <label class="label">
              <input type="checkbox" :checked="checkedFlag" @click="checkFun()" />
              <span>设为默认</span>
            </label>
          </div>
          <div class="flex">
            <button @click="saveAddressFun()">保存地址</button>
            <button @click="showFlag=false">取消</button>
          </div>
        </div>
      </div>
      <div class="flex addressInfo" v-else>
        <div class="addressDetail">
          <div class="edit" v-show="defaultAddress.id">
            <p>默认地址</p>
            <button @click="edit(defaultAddress.id)">修改</button>
          </div>
          <p>收货人：{{defaultAddress.reciever}}</p>
          <p>联系方式：{{defaultAddress.recieverTel}}</p>
          <p>收货地址：{{defaultAddress.recieverAddress}}</p>
        </div>
        <div class="btns">
          <p class="mb20" @click="getAddressListFun(),showAddressListFlag = true">地址切换</p>
          <button @click="addNew()">新增地址</button>
        </div>
      </div>
    </div>

    <div class="mb20 goods">
      <table>
        <thead>
          <tr>
            <th>商品信息</th>
            <th>单价</th>
            <th>数量</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in goodsList.shoppingCartSubInfoList">
            <tr v-for="(i,ind) in item.shoppingCartGoodsInfoList" :key="ind">
              <td class="flex">
                <img :src="i.goodsUrl" alt />
                <div class="t_l">
                  <p>{{i.goodsName}}</p>
                </div>
              </td>
              <td>{{i.buyUnitPrice|formatMoney}}</td>
              <td>{{i.goodsNum}}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <!-- 使用优惠券部分 -->
    <!-- <div class="coupon-wrap">
      <p class="title">使用优惠券</p>
      <div class="coupon-list">
        <ul v-if="list.length > 0">
          <li v-for="(item,index) in list" :key="index"  :class="{'active' : couponIndex == index}" @click="toggleCoupon(item,index)">
            <div class="flex">
              <p class="price">{{item.couponAmount}}元</p>
              <div class="tag">
                <p>{{item.promName}}</p>
                <p>{{item.couponUseEndTime}}</p>
              </div>
            </div>
            <div :class="['validity',{'validityFlag':item.validityFlag ==false}]">
              <i></i>{{item.chineseDesc}}
            </div>
          </li>
        </ul>
        <nodata v-else></nodata>
      </div>
    </div> -->

    <div class="pay">
      <div class="needInvoice">
        <label class="label" v-show="false">
          <input type="checkbox" checked />
          我要开发票
        </label>
      </div>
      <div class="invoiceInfo">
        <p class="mb20">运费：{{fee|formatMoney}}</p>
        <p class="mb20">
          合计：
          <span class="themeColor">{{total-couponAmount > 0 ? total-couponAmount : 0}}积分</span>
        </p>
        <button
          :class="['mb20','onSubmit',{'notAgree':!agreeFlag || !this.defaultAddress.recieverTel}]"
          @click="onSubmit"
        >提交订单</button>
        <p class="mb10">
          <span class="mr20">{{defaultAddress.reciever}}</span>
          {{defaultAddress.recieverTel|formatMobile}}
        </p>
        <p>{{defaultAddress.recieverAddress}}</p>
      </div>
    </div>

    <label class="label agreement">
      <input type="checkbox" :checked="agreeFlag" @click="agreeFun()" />
      <p>
        我已同意
        <router-link to>《建投数据服务协议》</router-link>
      </p>
    </label>
    <!-- 地址切换弹框 -->
    <div class="pop" v-show="showAddressListFlag">
      <div class="box">
        <p class="title">选择地址</p>
        <div class="close" @click="showAddressListFlag=false"></div>
        <ul>
          <li
            v-for="(item,index) in addressList"
            :key="index"
            :class="{'on':item.isDefault==1}"
            @click="switchAddress(item),showAddressListFlag = false"
          >
            <p class="default" v-show="item.isDefault==1">默认地址</p>
            <div class="flex">
              <span>收货人：</span>
              <p>{{item.reciever}}</p>
            </div>
            <div class="flex">
              <span>联系方式：</span>
              <p>{{item.recieverTel}}</p>
            </div>
            <div class="flex">
              <span>收货地址：</span>
              <p>{{item.recieverAddress}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import vdistpicker from "v-distpicker";
import nodata from '@/components/noData'
import {
  saveAddress,
  getAddressList,
  directSettle,
  tradeSettleAddress,
  tradeSettleOrder,
  shoppingCartSettlement,
  queryMyCoupon
} from "@/api/api";
export default {
  name: "orderIndex",
  components: { vdistpicker },
  data() {
    return {
      showFlag: false, //是否显示添加地址form
      defaultAddress: {}, //默认地址
      goodsList: {}, //商品
      provinceData: {
        //添加的provinceData
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        regionId: "",
        regionName: "",
        recieverAddress: "", //详细地址
        reciever: "", //收件人
        recieverTel: "", //用户手机号码
        isDefault: true //设置默认地址状态
      },
      addressList: [], //地址列表
      showAddressListFlag: false, //选择地址弹框
      fee: 0,             // 运费
      total: 0,            // 总金额
      agreeFlag: true,     // 是否同意服务协议
      groupProductType: 0, // 是否是拼团商品  0--否  1--是
      groupType: "",       // 拼团订单类型    1--自己开团   2-参团
      groupId: "",         // 如果是参团，团ID
      checkedFlag: true,
      addNewFlag: true,    // 是否新增
      list: [],            // 优惠券数据
      couponIndex: '0',    // 优惠券选中状态ID
      couponId: '',        // 优惠券ID
      couponAmount: 0,    // 优惠券金额

    };
  },
  methods: {
    //提交订单
    async onSubmit() {
      if (!this.defaultAddress.recieverTel) {
        this.$toast("请填写收货地址！");
        return;
      }
      if (!this.agreeFlag) {
        this.$toast("请勾选服务协议！");
        return;
      }
      let sendData = {
        settleNum: this.goodsList.settleNum,
        shoppingCartId: this.goodsList.shoppingCartId,
        group_product_type: this.groupProductType,
        group_type: this.groupType,
        group_id: this.groupId
      };

      // 如果使用了优惠券，把优惠券的id传给后台
      if(this.couponId != '' && this.couponId != undefined) {
        sendData.promotionId = this.couponId;
        sendData.promotionType = 11;
      }
      const res = await tradeSettleOrder(sendData);
      if (res.code == "0000") {
        this.$router.push({
          path: `/order/toPay?number=${res.data}&money=${this.total}`
        });
      } else {
        this.$toast(res.msg);
      }
    },
    // 获取省，市，区具体信息
    onChangeProvince(params) {
      this.provinceData.provinceId = params.code;
      this.provinceData.provinceName = params.value;
    },
    onChangeCity(params) {
      this.provinceData.cityId = params.code;
      this.provinceData.cityName = params.value;
    },
    onChangeArea(params) {
      this.provinceData.regionId = params.code;
      this.provinceData.regionName = params.value;
    },
    //校验form
    check() {
      if (
        !this.provinceData.provinceName ||
        this.provinceData.provinceName == "省"
      ) {
        this.$toast("请选择省");
        return false;
      }
      if (!this.provinceData.cityName || this.provinceData.cityName == "市") {
        this.$toast("请选择市");
        return false;
      }
      if (
        !this.provinceData.regionName ||
        this.provinceData.regionName == "区"
      ) {
        this.$toast("请选择区");
        return false;
      }
      if (!this.provinceData.recieverAddress) {
        this.$toast("街道门牌、楼层房间号等信息");
        return false;
      }
      if (!this.provinceData.reciever) {
        this.$toast("请输入用户名");
        return false;
      }
      if (!this.provinceData.recieverTel) {
        this.$toast("请输入手机号");
        return false;
      }
      if (!/^1[3456789]\d{9}$/.test(this.provinceData.recieverTel)) {
        this.$toast("请输入正确手机号");
        return;
      }
      return true;
    },
    //保存地址
    async saveAddressFun() {
      if (!this.check()) {
        return false;
      }
      let sendData = {
        provinceId: this.provinceData.provinceId,
        provinceName: this.provinceData.provinceName,
        cityId: this.provinceData.cityId,
        cityName: this.provinceData.cityName,
        regionId: this.provinceData.regionId,
        regionName: this.provinceData.regionName,
        recieverAddress: this.provinceData.recieverAddress,
        reciever: this.provinceData.reciever,
        recieverTel: this.provinceData.recieverTel
      };
      sendData.isDefault = this.checkedFlag ? 1 : 0;
      if (!this.addNewFlag && this.defaultAddress && this.defaultAddress.id) {
        sendData.id = this.defaultAddress.id;
      }
      const res = await saveAddress(sendData);
      if (res.code == "0000") {
        this.$toast("添加成功");
        this.showFlag = false;
        this.getAddressListFun();
      } else {
        this.$toast(res.msg);
      }
    },
    //获取是否默认
    checkFun() {
      if (event.target.checked) {
        this.checkedFlag = true;
      } else {
        this.checkedFlag = false;
      }
    },
    //服务协议
    agreeFun() {
      this.agreeFlag = event.target.checked;
    },
    //编辑地址
    edit() {
      this.showFlag = true;
      this.addNewFlag = false;
      this.provinceData = this.defaultAddress;
    },
    //新增地址
    addNew() {
      this.showFlag = true;
      this.provinceData.id = "";
      this.provinceData.provinceId = "";
      this.provinceData.provinceName = "";
      this.provinceData.cityId = "";
      this.provinceData.cityName = "";
      this.provinceData.regionId = "";
      this.provinceData.regionName = "";
      this.provinceData.recieverAddress = "";
      this.provinceData.reciever = "";
      this.provinceData.recieverTel = "";
      this.checkedFlag = true;
      this.addNewFlag = true;
    },
    //地址列表
    async getAddressListFun() {
      let data={};
      data.start=1;
      data.limit=10;
      const res = await getAddressList(data);
      if (res.code == "0000" && res.data && res.data.rows) {
        this.addressList = res.data.rows.map(m => {
          return {
            id: m.id,
            provinceId: m.addressProvinceId,
            provinceName: m.addressProvinceId,
            cityId: m.addressCityId,
            cityName: m.addressCityId,
            regionId: m.addressRegionId,
            regionName: m.addressRegionId,
            recieverAddress: m.consigneeName,
            reciever: m.consigneeName,
            recieverTel: m.phone,
            isDefault: m.isDefault
          };
        });
        let defaultAddress = res.data.rows.filter(f => {
          return f.isDefault == 1;
        })[0];
        if (defaultAddress) {
          this.defaultAddress = {
            id: defaultAddress.id,
            provinceId: defaultAddress.addressProvinceId,
            provinceName: defaultAddress.addressProvinceId,
            cityId: defaultAddress.addressCityId,
            cityName: defaultAddress.addressCityId,
            regionId: defaultAddress.addressRegionId,
            regionName: defaultAddress.addressRegionId,
            recieverAddress: defaultAddress.addressDetail,
            reciever: defaultAddress.consigneeName,
            recieverTel: defaultAddress.phone,
            isDefault: defaultAddress.isDefault
          };
        }
      } else {
        this.$toast(res.msg);
      }
    },
    //切换地址
    async switchAddress(item) {
      this.addressList.forEach(f => {
        f.isDefault = 0;
      });
      item.isDefault = 1;
      let sendData = {
        id: item.id,
        provinceId: item.provinceId,
        provinceName: item.provinceName,
        cityId: item.cityId,
        cityName: item.cityName,
        regionId: item.regionId,
        regionName: item.regionName,
        recieverAddress: item.recieverAddress,
        reciever: item.reciever,
        recieverTel: item.recieverTel,
        isDefault: item.isDefault
      };
      const res = await saveAddress(sendData);
      if (res.code == "0000") {
        // this.showFlag = false;
        this.provinceData = item;
        this.getAddressListFun();
      } else {
        this.$toast(res.msg);
      }
    },
    //商品
    async directSettleFun() {
      let sendData = {
        goodsId: this.$route.query.goodsId,
        goodsNum: this.$route.query.goodsNum
      };
      const res = await directSettle(sendData);
      if (res.code == "0000") {
        this.goodsList = res.data;
        this.tradeSettleAddressFun(res.data.settleNum, res.data.shoppingCartId);
      } else {
        this.$toast(res.msg);
      }
    },
    //运费接口
    async tradeSettleAddressFun(settleNum, shoppingCartId) {
      let sendData = {
        receiverAddress: this.defaultAddress.recieverAddress,
        receiverCityId: 2,
        receiverCityName: this.defaultAddress.cityName,
        receiverCountryId: 3,
        receiverName: this.defaultAddress.reciever,
        receiverPhone: this.defaultAddress.recieverTel,
        receiverProvinceId: 1,
        receiverProvinceName: this.defaultAddress.provinceName,
        receiverRegionName: this.defaultAddress.regionName,
        settleNum: settleNum,
        shoppingCartId: shoppingCartId
      };
      const res = await tradeSettleAddress(sendData);
      if (res.code == "0000") {
        this.fee = res.data.logisticsAmount;
        this.total = this.goodsList.buyTotalAmount + this.fee;
      } else {
        this.$toast(res.msg);
      }
    },
    //购物车结算
    async getSettleMentByCart(cartId) {
      const sendData = {
        shoppingCartId: cartId
      };
      const res = await shoppingCartSettlement(sendData);
      if (res.code === "0000") {
        this.goodsList = res.data;
        this.shoppingCartSubInfoList = res.data.shoppingCartSubInfoList;
        this.tradeSettleAddressFun(res.data.settleNum, res.data.shoppingCartId);
      } else {
        console.log("请求失败");
      }
    },

    // 获取个人优惠券数据
    async queryMyCoupon() {
      const params = {start: 1, limit: 100, status: 1}
      const res = await queryMyCoupon(params)
      if (res.code === '0000') {
        this.list = res.data.rows;
        if( this.list.length > 0 ) {
          this.couponId = this.list[0].couponId;
          this.couponAmount = this.list[0].couponAmount;
        }
      } else {
        this.$toast(res.msg);
      }
    },

    // 切换优惠券使用
    toggleCoupon(item,index) {
      this.couponCode = item.couponCode;
      this.couponIndex = index;
      this.couponAmount = item.couponAmount;
    },
  },
  mounted() {
    if (this.$route.query.groupProductType) {
      this.groupProductType = this.$route.query.groupProductType;
    }
    if (this.$route.query.groupType) {
      this.groupType = this.$route.query.groupType;
    }
    if (this.$route.query.groupId) {
      this.groupId = this.$route.query.groupId;
    }
    if (this.$route.query.shoppingCartId) {
      //此处为购物车进入调用settle接口
      this.getSettleMentByCart(this.$route.query.shoppingCartId);
    } else {
      //否则调用direct-settle接口
      this.directSettleFun();
    }
    this.getAddressListFun();
    this.queryMyCoupon();
  }
};
</script>
<style lang="scss" scoped>
.orderIndex {
  margin-top: 50px;
  font-size: 14px;
  .flex {
    display: flex;
  }
  .label {
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
      -webkit-appearance: checkbox;
      width: 18px;
      height: 18px;
      border: 1px solid #00000026;
      margin-right: 10px;
    }
  }
  .addressInfo {
    button {
      width: 96px;
      height: 28px;
      line-height: 28px;
    }
  }
  .addressEdit {
    .btns {
      padding-left: 60px;
      .flex {
        button {
          width: 150px;
          height: 46px;
          line-height: 46px;
          margin-right: 20px;
        }
      }
    }
  }
  .address {
    border: 1px solid #00000026;
    .form {
      width: 617px;
      padding: 10px 30px 0;
      .flex {
        .item {
          &:last-child {
            margin-left: 26px;
          }
        }
      }
      .item {
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        span {
          width: 70px;
          margin-right: 20px;
          text-align: right;
          display: block;
        }
        input,
        select {
          height: 34px;
          padding: 0 9px;
          border: 1px solid #00000026;
          border-radius: 0.25rem;
        }
        &.textarea {
          input {
            width: 466px;
            height: 70px;
            border-radius: 0.25rem;
          }
        }
      }
    }
    .addressDetail {
      width: 805px;
      padding: 20px 30px 0;
      .edit {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        p {
          letter-spacing: 10px;
          margin: 0;
        }
        button {
          border: 1px solid $themeColor;
          cursor: pointer;
        }
      }
      p {
        margin-top: 10px;
      }
    }
    .btns {
      text-align: center;
      p {
        text-decoration: underline;
        cursor: pointer;
      }
      button {
        border: 1px solid $themeColor;
        font-size: 14px;
        margin: 0;
      }
    }
  }
  .btns {
    margin: 30px 0 30px 40px;
    width: 360px;
    padding: 57px 0 43px 0;
    border-left: 1px dashed #dedede;
    button {
      border-radius: 2px;
      font-size: 16px;
      cursor: pointer;
      &:nth-child(1) {
        background: $themeColor;
        border-color: $themeColor;
        color: #fff;
      }
    }
  }
  .goods {
    border: 1px solid #00000026;
    border-bottom: 0;
    table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      thead {
        height: 50px;
        line-height: 50px;
        background: rgba(134, 142, 150, 0.05);
        font-weight: normal;
        th {
          width: 33.33%;
        }
      }
      tbody {
        td {
          border-bottom: 1px solid #00000026;
          padding: 20px 30px;
          &.flex {
            justify-content: flex-start;
            align-items: flex-start;
          }
        }
      }
      img {
        width: 100px;
        height: 100px;
        margin-right: 20px;
        background: #00000026;
      }
    }
  }

  /* 使用优惠券部分样式 */ 
  .coupon-wrap {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    padding: 20px;
    .title  {
      font-weight: 700;
      border-bottom: 1px dashed #ccc;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    ul {
      display: flex;
      li {
        padding: 5px;
        width: 282px;
        color: #ff615e;
        margin-right: 10px;
        border-radius: 5px;
        position: relative;
        border: 2px solid #fff;
        white-space:nowrap;
        &.active {
          border: 2px solid red;
        }
        &::before,
        &::after {
          content: "";
          width: 16px;
          height: 16px;
          background: #fff;
          border-radius: 16px;
          position: absolute;
          top: 97px;
          z-index: 999;
        }
        &::before {
          left: -5px;
        }
        
        &::after {
          right: -5px;
        }
        .flex {
          height: 100px;
          display: flex;
          justify-content: flex-start;
          padding: 20px;
          background: #fff1f1;
          .price {
            font-size: 30px;
            padding-right: 20px;
          }
          .tag {
            p {
              width: 130px;
              line-height: 1.6;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            button {
              margin-top: 2px;
              padding: 1px 4px;
              background: #fff;
            }
          }
        }
        .validity {
          overflow: hidden;
          padding: 8px 24px 8px 14px;
          border-top: 1px dashed #ff615e;
          background: #fff1f1;
          font-size: 12px;
          position: relative;
          line-height: 1.6;
          cursor: pointer;
          &.validityFlag {
            height: 34px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            i {
              transform: rotate(225deg);
              top: 15px;
            }
          }
          i {
            display: block;
            width: 8px;
            height: 8px;
            border-bottom: 1px solid #ff615e;
            border-right: 1px solid #ff615e;
            transform: rotate(45deg);
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }
      }
    }
  }
  .pay {
    border: 1px solid #00000026;
    padding: 30px;
    background: rgba(134, 142, 150, 0.05);
    display: flex;
    justify-content: space-between;
    .needInvoice {
      display: flex;
      align-items: flex-start;
    }
    .invoiceInfo {
      text-align: right;
      .themeColor {
        font-size: 20px;
        font-weight: bold;
      }
      button {
        width: 168px;
        height: 50px;
        line-height: 50px;
        background: $themeColor;
        color: #fff;
        font-size: 18px;
        &.notAgree {
          background: #999;
          cursor: default;
        }
      }
    }
  }
  .agreement {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  /deep/ .distpicker-address-wrapper {
    width: 466px;
    display: flex;

    select {
      width: 150px;
      box-sizing: border-box;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .pop {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 999;
    .title {
      margin-bottom: 40px;
      font-size: 18px;
    }
    .box {
      width: 660px;
      height: 611px;
      padding: 50px;
      background: #fff;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: auto;
      .close {
        color: #ccc;
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        height: 30px;
        width: 30px;
        font-size: 24px;
        padding: 1px;
        right: 30px;
        top: 20px;
        position: absolute;
        cursor: pointer;
        &::before {
          content: "\2716";
        }
      }
      li {
        border: 1px solid #e8e8e8;
        padding: 20px 25px;
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;

        .default {
          position: absolute;
          right: 30px;
          bottom: 4px;
          font-size: 12px;
          color: #b4a078;
        }
        .flex {
          display: flex;
          span {
            color: #999;
            display: block;
            width: 70px;
            text-align: justify;
          }
        }
        &.on {
          border: 2px solid #b4a078;
          padding: 19px 24px;
          position: relative;
          &::before {
            content: "\2713";
            position: absolute;
            bottom: 0;
            right: 0;
            color: #fff;
            z-index: 10;
          }
          &::after {
            content: " ";
            width: 0;
            height: 0;
            border-bottom: 22px solid #b4a078;
            border-left: 22px solid transparent;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }
}
</style>