<template>
  <div class="list-wrap">
    <!-- 搜索框 -->
    <div class="top-wrap">
      <p class="title">售后列表</p>
      <div class="search-wrap">
        <input type="text" v-model="searchValue" placeholder="输入订单号搜索" class="order-input" />
        <span class="order-button" @click="search">搜索</span>
      </div>
    </div>

    <!-- 订单列表部分 -->
    <div v-if="orderList.length>0">
      <div class="list" v-for="(item,index) in orderList" :key="index">
        <p class="title">
          <span class="order fl ml20">申请单号：{{ item.orderNo }}</span>
          <span class="time fl ml20">下单时间:{{ item.orderTime }}</span>
          <span class="time fr mr20" v-if="item.afterSaleType == 1">仅退款未发货</span>
          <span class="time fr mr20" v-if="item.afterSaleType == 2">仅退款已发货</span>
          <span class="time fr mr20" v-if="item.afterSaleType == 3">退款退货</span>
          <span class="time fr mr20" v-if="item.afterSaleType == 4">换货</span>
        </p>
        <div class="goods-wrap">
          <div class="common-style goods">
            <div class="image-wrap">
              <img :src="item.goodsImgUrl" class="image" alt />
            </div>
            <div class="name-wrap">
              <p class="name">{{ item.goodsName }}</p>
            </div>
          </div>
          <div class="common-style cost">
            <p class="tips font14" v-if="item.status == '1010'">申请退款待处理</p>
            <p class="tips font14" v-if="item.status == '1020'">售后已取消</p>
            <p class="tips font14" v-if="item.status == '1030'">审核通过</p>
            <p class="tips font14" v-if="item.status == '1040'">审核拒绝</p>
            <p class="tips font14" v-if="item.status == '1050'">审核后取消</p>
            <p class="tips font14" v-if="item.status == '1060'">退款中</p>
            <p class="tips font14" v-if="item.status == '1070'">退款成功</p>
            <p class="tips font14" v-if="item.status == '2020'">取消售后</p>
          </div>
          <div class="common-style operation">
            <span
              class="tip"
              @click="goDetail(item.afterSaleNo)"
              :class="{mt30:item.status=='1010'||item.status=='2010'?false:true}"
            >查看详情</span>
            <span
              class="tip ml10"
              @click="cancelAfterSale(item.afterSaleNo)"
              v-if="item.status == '1010' || item.status == '2010'"
            >取消售后</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 当无数据的时候 -->
    <div v-else>
      <no-data></no-data>
    </div>
  </div>
</template>
<script>
import { afterList, cancelAfter } from "@/api/api";
import noData from "@/components/noData";
export default {
  name: "list",
  components: {
    noData
  },

  data() {
    return {
      searchValue: "", // 搜索订单号
      orderList: [],   // 列表数据
      start: 1,        // 当前页数
      limit: 100       // 当前条数
    };
  },
  created() {
    this.afterList();
  },
  methods: {
    // 搜索订单
    search() {},

    // 跳转到订单详情
    goDetail(value) {
      this.$router.push({
        path: `/afterSale/aftersaleDetail?num=${value}`
      });
    },

    // 获取订单列表数据
    async afterList() {
      const sendData = {
        start: this.start,
        limit: this.limit
      };
      const res = await afterList(sendData);
      if (res.code == "0000") {
        this.orderList = res.data.rows;
      } else {
        this.$toast(res.msg);
      }
      console.log(this.orderList);
    },

    // 取消售后
    async cancelAfterSale(value) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要取消售后？"
        })
        .then(() => {
          this.delete(value);
        })
        .catch(() => {
          this.$toast("取消成功");
        });
    },

    async delete(value) {
      const sendData = {
        afterSaleNo: value
      };
      const res = await cancelAfter(sendData);
      if (res.code == "0000") {
        this.orderList.forEach((item, index) => {
          if (item.afterSaleNo == value) {
            this.orderList[index].status = "1020";
          }
        });
        this.$toast("取消成功");
      } else {
        this.$toast(res.msg);
      }
    }
  },

  mounted() {}
};
</script>
<style lang="scss" scoped>
.list-wrap {
  width: 100%;
  height: auto;
  .top-wrap {
    width: 100%;
    height: 45px;
    overflow: hidden;
    .title {
      float: left;
      line-height: 45px;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .search-wrap {
    width: 290px;
    height: 30px;
    float: right;
    margin-top: 10px;
    font-size: 14px;
    .order-input {
      width: 240px;
      height: 28px;
      border: 1px solid #ddd;
      float: left;
      padding-left: 12px;
    }
    .order-button {
      width: 51px;
      height: 28px;
      line-height: 28px;
      margin-left: -1px;
      border: 1px solid #ddd;
      border-radius: 0;
      float: left;
      text-align: center;
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }
  /* 订单数据 */
  .list {
    border: 1px solid #e8e8e8;
    margin-top: 20px;
    .title {
      background-color: #f5f5f5;
      height: 43px;
      line-height: 43px;
      text-align: left;
      font-weight: 400;
      padding-left: 10px;
      .store-image {
        margin-top: 7px;
      }
    }
    /* 商品部分 */
    .goods-wrap {
      border-top: 1px solid #e8e8e8;
      display: flex;
      .common-style {
        border-right: 1px solid #e8e8e8;
        padding: 20px 0;
        .image-wrap {
          float: left;
          position: relative;
          width: 100px;
          height: 100px;
          margin: 0 10px;
          .image {
            background-color: #f4f4f4;
            display: block;
            width: 100px;
            height: 100px;
          }
        }
        .name-wrap {
          float: left;
          width: 400px;
          margin-top: 20px;
          text-align: center;
          font-size: 14px;
          .name {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .sku {
            text-align: left;
            margin-top: 10px;
            color: #999;
          }
        }
        .tips {
          margin-top: 40px;
        }
      }
      .common-style:last-child {
        border-right: 0 none;
      }
    }
    .goods {
      width: 600px;
    }
    /* 价格 运费部分 */
    .cost {
      width: 200px;
      text-align: center;
      .price {
        font-size: 16px;
        font-weight: 700;
        margin-top: 20px;
      }
    }
    /* 操作部分 */
    .operation {
      width: 215px;
      text-align: center;
      .common-button {
        display: block;
        width: 100px;
        height: 30px;
        line-height: 30px;
        background-color: $themeColor;
        color: #fff;
        margin: 10px auto;
        border-radius: 5px;
      }
      .tip {
        display: inline-block;
        cursor: pointer;
        margin-top: 5px;
        padding: 8px;
        margin-bottom: 20px;
        color: rgb(255, 255, 255);
        background: rgb(171, 0, 50);
        border-color: rgb(171, 0, 50);
        color: white;
        width: 135px;
        height: 30px;
        line-height: 16px;
        font-size: 16px;
        &:nth-of-type(2) {
          display: block;
          width: 135px;
          margin: 0 auto;
        }
      }
    }
  }
}
.mr20 {
  margin-right: 50px;
}
.ml10 {
  margin-left: 10px;
}
.mt30 {
  margin-top: 30px !important;
}
</style>


