<template>
  <div class="seckill">
    <div class="time">
      <p class="title">限时购</p>
      <div class="detail">
        <p class="spot">{{time}}点场</p>
        <p class="desc">距离结束还剩</p>
        <div class="cont-down">
          <span class="cd hh">{{hours}}</span>
          <span class="colon">:</span>
          <span class="cd mm">{{minutes}}</span>
          <span class="colon">:</span>
          <span class="cd ss">{{seconds}}</span>
        </div>
        <router-link to class="check">查看全部</router-link>
      </div>
    </div>
    <div class="goods">
      <div class="date">
        <div
          v-for="(item,index) in week"
          :key="index"
          class="tabs"
          :class="{'on':index==weekIndex}"
          @click="weekIndex=index"
        >
          <span class="msg">{{item.msg}}</span>
          <span class="status">{{item.status}}</span>
        </div>
      </div>
      <lists :list="list" class="smallImg6"></lists>
    </div>
  </div>
</template>
<script>
import lists from "@/components/list";
export default {
  components: {
    lists
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    let year = new Date().getFullYear();
    let month = new Date().getMonth();
    let day = new Date().getDate();

    return {
      time: new Date().getHours(),
      week: [
        {
          msg: "00:00-04:00",
          status: "已结束",
          date: new Date(year, month, day, 4, 0, 0)
        },
        {
          msg: "04:00-08:00",
          status: "已结束",
          date: new Date(year, month, day, 8, 0)
        },
        {
          msg: "08:00-12:00",
          status: "已结束",
          date: new Date(year, month, day, 12, 0)
        },
        {
          msg: "12:00-16:00",
          status: "已结束",
          date: new Date(year, month, day, 16, 0)
        },
        {
          msg: "16:00-20:00",
          status: "已结束",
          date: new Date(year, month, day, 20, 0)
        },
        {
          msg: "20:00-24:00",
          status: "已结束",
          date: new Date(new Date(year, month, day, 23, 59).getTime() + 60000)
        }
      ],
      weekIndex: 0, //时间段索引
      endTime: new Date(), //结束时间
      hours: "", //时分秒
      minutes: "", //时分秒
      seconds: "" //时分秒
    };
  },
  methods: {
    getIndexFun() {
      this.week.forEach((e, index) => {
        let start = Number(e.msg.split("-")[0].split(":")[0]);
        let end = Number(e.msg.split("-")[1].split(":")[0]);
        if (this.time < end && this.time >= start) {
          this.weekIndex = index;
          e.status = "进行中";

          this.endTime = e.date;

          window.setInterval(this.countFun, 1000);
        }
      });
    },
    countFun() {
      let dateTime = this.endTime.getTime() - new Date().getTime();
      //计算出小时数
      var leave1 = dateTime % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      this.hours = Math.floor(leave1 / (3600 * 1000));

      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      this.minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      this.seconds = Math.round(leave3 / 1000);
    }
  },
  mounted() {
    this.getIndexFun();
  }
};
</script>
<style lang="scss" scoped>
.seckill {
  width: 1200px;
  margin: 0 auto 30px;
  display: flex;
  .time {
    width: 270px;
    .title {
      color: #fff;
      font-size: 24px;
      background: #212529;
      border-radius: 8px 8px 0 0;
      height: 48px;
      line-height: 50px;
      text-align: center;
    }
    .detail {
      background: url(http://wstatic.zgt56.com/202005/202005090555301589018130137.png)
        center center no-repeat #fff;
      background-size: 100% 100%;
      width: 270px;
      height: 340px;
      padding-top: 50px;
      text-align: center;
      .spot {
        padding-bottom: 30px;
        font-size: 28px;
        color: #521426;
        line-height: 38px;
        position: relative;
        &::before {
          content: " ";
          width: 24px;
          height: 1px;
          background-color: #521426;
          display: block;
          position: absolute;
          top: 50px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .desc {
        font-size: 18px;
        color: #521426;
        line-height: 24px;
      }
      .cont-down {
        margin: 20px 0 45px 0;
        font-size: 18px;
        .cd {
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background: #521426;
          border-radius: 4px;
          color: #ffffff;
          display: inline-block;
        }
        .colon {
          padding: 0 4px;
          color: #521426;
        }
      }
      .check {
        opacity: 0.8;
        background: #212529;
        border-radius: 17px;
        width: 120px;
        height: 34px;
        line-height: 34px;
        display: block;
        margin: 0 auto;
        font-size: 14px;
        color: #ffffff;
        &::after {
          content: " ";
          width: 8px;
          height: 8px;
          border-top: 2px solid #fff;
          border-left: 2px solid #fff;
          transform: rotate(135deg);
          margin-left: 4px;
          display: inline-block;
        }
      }
    }
  }
  .goods {
    width: 100%;
    .date {
      height: 48px;
      border-bottom: 2px solid $themeColor;
      display: flex;
      .tabs {
        width: 16.67%;
        text-align: center;
        display: block;
        cursor: pointer;
        .msg {
          display: block;
          font-size: 16px;
          color: #212529;
          line-height: 28px;
        }
        .status {
          font-size: 12px;
          color: #868e96;
        }
        &.on {
          background: $themeColor;
          border-radius: 8px 8px 0 0;
          .msg {
            color: #fff;
          }
          .status {
            color: #fff;
          }
        }
      }
    }
    .product {
      width: 930px;
      margin: 0;
      overflow: hidden;
      li {
        margin: 0;
      }
    }
  }
}
</style>