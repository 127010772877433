<template>
  <div class="listDetail main-container" v-if="isRequestedFinished">
    <div class="bread">
      <!-- <router-link to="/">首页</router-link>
            <span></span>
            <router-link to>生活电器</router-link>
            <span></span>
      <router-link to>浙里</router-link>-->
    </div>
    <div class="product">
      <!-- 图 -->
      <div class="productLeft">
        <div class="bigImg">
          <img :src="bigImgSrc" alt />
        </div>
        <div class="imglist">
          <swiper :options="imgOption">
            <swiper-slide
              class="swiper-slide"
              v-for="(item,index) in goodsInfo.productMedia"
              :key="index"
              @click="swiperSlideFun(item)"
            >
              <img :src="item.img" />
            </swiper-slide>
          </swiper>
          <!-- 左右箭头 -->
          <div class="imglistPrev imgBtn" slot="button-prev"></div>
          <div class="imglistNext imgBtn" slot="button-next"></div>
        </div>
      </div>
      <!-- 价格 sku 按钮 -->
      <div class="productRight">
        <div class="countdown">
          <p>限时购</p>
          <div class="flex">
            <p>活动倒计时：</p>
            <van-count-down
              :time="goodsInfo.productInfo.activityEndTime-goodsInfo.productInfo.activityStartTime"
              @finish="active = false"
            >
              <template v-slot="timeData">
                <span class="item">{{ timeData.days }}</span>
                <span>天</span>
                <span class="item">{{ timeData.hours }}</span>
                <span>小时</span>
                <span class="item">{{ timeData.minutes }}</span>
                <span>分</span>
                <span class="item">{{ timeData.seconds }}</span>
                <span>秒</span>
              </template>
            </van-count-down>
          </div>
        </div>
        <div class="priceInfo">
          <p class="product-name">{{ goodsInfo.productInfo.name }}</p>
          <div class="item">
            <p class="label">价格</p>
            <div class>
              <div class="priceDetail flex">
                <p
                  class="themeColor price"
                >{{ this.issSerial ? this.skuPrice : goodsInfo.productInfo.marketPrice }}</p>
                <p class="color86">
                  市场价格：
                  <span>{{ goodsInfo.productInfo.salePriceWithTax }}</span>
                </p>
              </div>
              <!-- <div class="memberDetail">
                <span class="icon">超划算</span>
                <span class="member-price">
                  联盟会员专享价格
                  <span class="themeColor">55.3-55.3</span>
                </span>
                <router-link class="link" to>开通立享&gt;&gt;</router-link>
              </div>-->
            </div>
          </div>
          <div class="item">
            <p class="label">促销</p>
            <div class="flex complimentary">
              <span>满赠</span>
              <p class="themeColor">每满1件领取赠品</p>
            </div>
          </div>
          <div class="item">
            <p class="label">积分</p>
            <div>
              <p>购买最高可得79-79积分</p>
            </div>
          </div>
        </div>
        <div class="typeInfo">
          <div class="item" v-if="goodsInfo.productGuaranteeList.length > 0">
            <p class="label">服务</p>
            <div class="flex">
              <span
                class="ml10"
                v-for="(item,index) in goodsInfo.productGuaranteeList"
                :key="index"
              >･{{item.guaranteeName}}</span>
            </div>
          </div>

          <div class="item" v-for="(item,index) in allSkuList" :key="index">
            <div
              class="sku-wrap"
              :class="{'active': skuSelectedIndex == index}"
              v-if="item.productPropertyValue.length > 0"
              @click="toggleSelected(index,item.id,item)"
            >
              <span
                class="mr10"
                v-for="(i,ix) in item.productPropertyValue"
                :key="ix"
              >{{i.valueName}}</span>
            </div>
          </div>

          <div class="item">
            <p class="label">购买数量：</p>
            <van-stepper v-model="quantity" integer @change="buyNumber()" />
          </div>
          <div class="btns flex">
            <button @click="buyNow(goodsInfo,1)">我要开团</button>
            <router-link tag="button" :to="{path:'/list/detail/',query:{id:goodsId}}">单独购买</router-link>
            <button
              @click="toggleProductCollect(goodsInfo.memStoreInfo.isFavoriteProduct,goodsInfo.productInfo.storeId)"
            >
              <i class="mr5" v-if="goodsInfo.memStoreInfo.isFavoriteProduct == 0">收藏</i>
              <i class="iconfont icon-xin mr5 themeColor" v-else>已收藏</i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="join mb20">
      <h4 class="title">以下小伙伴正在发起团购，您可直接参与</h4>
      <ul>
        <li v-for="(item,index) in groupList" :key="index">
          <img :src="item.memberHead" alt />
          <p>{{ item.memberName }}</p>
          <p>还差{{item.differenceMembers }}人成团</p>
          <p class="flex">
            剩余时间：
            <van-count-down
              :time="item.expireTime-new Date().getTime()"
              class="red"
              format="DD 天 HH 时 mm 分 ss 秒"
            />
          </p>
          <button @click="join(item.groupInstCode)">去参团</button>
        </li>
        <!--<li>-->
        <!--<img-->
        <!--src="http://yanxuan.nosdn.127.net/8319d7be7b6c4f1654535aa9c80931b5.png?imageView&quality=65&thumbnail=330x330"-->
        <!--alt-->
        <!--/>-->
        <!--<p>小不点2</p>-->
        <!--<p>还差1人成团</p>-->
        <!--<p>剩余时间：{{countDownTime}}</p>-->
        <!--<button>去参团</button>-->
        <!--</li>-->
      </ul>
    </div>
    <!-- 大家都在看 start-->
    <div class="everyone">
      <h4 class="title">大家都在看</h4>
      <div class="list">
        <swipers :list="recommentList"></swipers>
      </div>
    </div>
    <!-- 大家都在看 end-->

    <div class="flex">
      <!-- 24小时热销榜 start-->
      <div class="hot">
        <h4 class="title">{{goodsInfo.memStoreInfo.storeName}}</h4>
        <div class="goodsBtn">
          <p>
            <i class="iconfont icon-home mr5"></i>进店逛逛
          </p>
          <p
            @click="toggleStoreCollect(goodsInfo.memStoreInfo.isFavorite,goodsInfo.productInfo.storeId)"
          >
            <i class="mr5" v-if="goodsInfo.memStoreInfo.isFavorite == 0">关注店铺</i>
            <i class="mr5 themeColor" v-else>已关注</i>
          </p>
        </div>
        <h4 class="title">24小时热销榜</h4>
        <ul class="listImgs">
          <li v-for="(l,li) in hotSaleList" :key="li" @click="goDetail(l.id)">
            <img :src="l.pictureUrl" alt :class="{'imageBorder':!l.pictureUrl}" />
            <div class="info">
              <p class="title">{{l.chineseName}}</p>
              <p class="price">
                <span class="nowPrice">{{l.salePriceWithTax|formatMoney}}</span>
                <span class="oldPrice">原价{{l.marketPrice|formatMoney}}</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
      <!-- 24小时热销榜 end-->

      <!-- 商品详情 常见问题 start-->
      <div class="details">
        <div class="tabs">
          <p :class="{on:tabsFlag}" @click="tabsFlag=true">商品详情</p>
          <p :class="{on:!tabsFlag}" @click="tabsFlag=false">常见问题</p>
        </div>
        <div class="detailsContainer detContainer" v-show="tabsFlag">
          <ul class="infos">
            <li>形状：长方形</li>
            <li>适用季节：四季通用</li>
            <li>材质：纯棉</li>
            <li>规格：35cmx80cm</li>
            <li>类别：A类</li>
          </ul>
        </div>
        <div class="detailsContainer queContainer" v-show="!tabsFlag">常见问题</div>
      </div>
      <!-- 商品详情 常见问题 end-->
    </div>
    <div class="pop" v-show="showPopFlag">
      <div class="box">
        <p class="title">参与{{goodsInfo.productInfo.name}}的拼单</p>
        <div class="close" @click="showPopFlag=false"></div>
        <div class="group-user">
          <template v-if="initData && initData.groupInstDetailResponseList">
            <img
              v-for="k in initData.groupInstDetailResponseList"
              :key="k.memberId"
              :src="k.memberHead"
              alt
              class="user-image"
            />
          </template>
          <template v-if=" initData && initData.differenceMembers">
            <img
              v-for="k in initData.differenceMembers"
              :key="k"
              src="../../../static/images/avatar.png"
              alt
              class="user-image"
            />
          </template>
        </div>
        <div class="flex">
          剩余
          <span class="red">
            {{
            initData.differenceMembers
            }}
          </span>
          个名额，
          <van-count-down
            :time="initData.expireTime-new Date().getTime()"
            class="red"
            format="DD 天 HH 时 mm 分 ss 秒"
          />后结束
        </div>
        <button  @click="buyNow(goodsInfo,2)">去参团</button>
      </div>
    </div>
  </div>
</template>
<script>
import swipers from "@/components/detailSwiper.vue";
import {
  getProductInfo,
  getRecommendedProductList,
  getProductsProperty,
  getPropertyDetail,
  addCart,
  toggleCollect,
  getCurrentJoinedGroupList,
  getCurrentJoinedGroupInfo
} from "@/api/api";
import utils from "@/utils/utils";

export default {
  name: "listDetail",
  components: { swipers },
  data() {
    let self = this;
    return {
      start: 1, // 请求页数
      limit: 100, // 每次请求条数
      groupList: [], //为成团列表数据
      num: 1, //数量
      bigImgSrc: "", //大图
      tabsFlag: true, //显示商品详情或者常见问题
      recommentList: [], // 大家都在看数据
      hotSaleList: [], // 24小时热销
      imgOption: {
        spaceBetween: 10,
        slideToClickedSlide: true,
        //设置点击箭头
        navigation: {
          nextEl: ".imglistNext",
          prevEl: ".imglistPrev"
        },
        //开启循环模式
        loop: true,
        slidesPerView: 3,
        observeParents: true,
        on: {
          slideChange() {
            self.bigImgSrc = this.imagesToLoad[this.activeIndex].src;
          }
        }
      },
      goodsInfo: {}, // 商品信息部分
      quantity: "1", // 购买数量
      isRequestedFinished: false, // 是否请求结束
      goodsId: "", // 商品ID
      allSkuList: [], // 后台已经组合好的sku数据
      issSerial: false, // 该商品是不是系列品
      serialProductId: "", // 系列品id
      skuSelectedIndex: "0", // sku选中下标
      skuPrice: "", // sku价格

      showPopFlag: false, //参团flag
      initData: "",
      groupId:""// 如果是参团，团ID
    };
  },
  methods: {
    //拼团推荐
    async getCurrentJoinedGroupListFun() {
      let this_ = this;
      const params = {
        start: this.start,
        limit: this.limit
      };
      const res = await getCurrentJoinedGroupList(params);
      if (res.code == "0000") {
        this.groupList = res.data.rows;
      }
    },
    // sku选中切换
    toggleSelected(index, id, item) {
      this.skuSelectedIndex = index;
      this.serialProductId = id;
      this.skuPrice = item.marketPrice;
    },

    // 获取商品详情
    async getProduct() {
      let params = {
        id: this.goodsId,
        promotionId: this.$route.query.promotionId,
        productType: 2
      };
      const res = await getProductInfo(params);
      if (res.code == "0000") {
        this.goodsInfo = res.data;
        // 处理商品大图数据
        this.goodsInfo.productMedia = utils.changeKey(
          this.goodsInfo.productMedia,
          ["img", "videoUrl"]
        );
        this.isRequestedFinished = true;
      } else {
        this.$toast(res.msg);
      }
    },

    // 获取商品sku数据
    async getProperty() {
      let params = {
        id: this.goodsId
      };
      const res = await getProductsProperty(params);
      if (res.code == "0000") {
        // 如果该商品时系列品
        if (
          res.data[0].productPropertyValue != null &&
          res.data[0].productPropertyValue.length > 0
        ) {
          this.getPropertyDetail();
          this.issSerial = true;
        }
      } else {
        this.$toast(res.msg);
      }
    },

    // 获取系列品明细
    async getPropertyDetail() {
      let params = {
        productId: this.goodsId
      };
      const res = await getPropertyDetail(params);
      if (res.code == "0000") {
        this.allSkuList = res.data;
        this.serialProductId = this.allSkuList[0].id;
        this.skuPrice = this.allSkuList[0].marketPrice;
      } else {
        this.$toast(res.msg);
      }
    },

    // 商品收藏切换
    async toggleProductCollect(flag, storeId) {
      const login = this.$store.state.isLogin;
      if (!login) {
        return this.$store.commit("showLoginModal", true);
      }
      let params = {
        storeId: storeId,
        entityId: this.goodsId,
        type: flag == 0 ? 1 : 0, // 0--取消收藏  --1收藏
        entityType: 1 // 1--商品   2--店铺
      };
      const res = await toggleCollect(params);
      if (res.code == "0000") {
        flag == 0 ? this.$toast("收藏成功") : this.$toast("取消成功");
        this.goodsInfo.memStoreInfo.isFavoriteProduct = flag == 0 ? 1 : 0;
      } else {
        this.$toast(res.msg);
      }
    },

    // 店铺收藏切换
    async toggleStoreCollect(flag, storeId) {
      const login = this.$store.state.isLogin;
      if (!login) {
        return this.$store.commit("showLoginModal", true);
      }
      let params = {
        storeId: storeId,
        entityId: storeId,
        type: flag == 0 ? 1 : 0, // 0--取消收藏  --1收藏
        entityType: 2 // 1--商品   2--店铺
      };
      const res = await toggleCollect(params);
      if (res.code == "0000") {
        flag == 0 ? this.$toast("收藏成功") : this.$toast("取消成功");
        this.goodsInfo.memStoreInfo.isFavorite = flag == 0 ? 1 : 0;
      } else {
        this.$toast(res.msg);
      }
    },

    // 新页面打开商品详情
    goDetail(id) {
      let routerUrl = this.$router.resolve({
        path: `/list/detail?id=${id}`
      });
      window.open(routerUrl.href, "_blank");
    },

    // 购买数量
    buyNumber() {},

    // 大家都在看prev按钮
    prevBtn() {
      this.$refs.everyoneSwipe.prev();
    },
    // 大家都在看next按钮
    nextBtn() {
      this.$refs.everyoneSwipe.next();
    },
    // 立即购买
    buyNow(info,params) {
      const login = this.$store.state.isLogin;
      if (!login) {
        return this.$store.commit("showLoginModal", true);
      }
      let sendData={
        goodsId: this.issSerial ? this.serialProductId : this.goodsId,
        goodsNum: this.quantity,
        promotionId: this.$route.query.promotionId,
        promotionType:"20",//后台要求写死，代表拼团类型
        groupType: params,// 拼团订单类型    1--自己开团   2-参团
        groupProductType:"1"// 是否是拼团商品  0--否  1--是
      }
      if(this.groupId){
        sendData.groupId=this.groupId;
      }
      this.$router.push({
        path: "/order/index",
        query: sendData
      });
    },

    // 获取推荐数据
    async getRecommended() {
      let params = {
        recommendType: 1
      };
      const res = await getRecommendedProductList(params);
      if (res.code == "0000") {
        this.recommentList = res.data;
      } else {
        this.$toast(res.msg);
      }
    },

    // 获取热销产品
    async getHotList() {
      let params = {
        recommendType: 2
      };
      const res = await getRecommendedProductList(params);
      if (res.code == "0000") {
        this.hotSaleList = res.data;
      } else {
        this.$toast(res.msg);
      }
    },
    //去参团
    join(groupInstCode) {
      this.groupId = groupInstCode;
      this.showPopFlag = true;
      let meGrouponFlag = 0;
      for (let i = 0; i < this.groupList.length; i++) {
        if (groupInstCode == this.groupList[i].groupInstCode) {
          let patchGrouponThemeId = this.groupList[i].groupInstCode;
          this.getGroupList(groupInstCode, meGrouponFlag);
        }
      }
    },
    // 获取热销产品
    async getGroupList(groupInstCode, meGrouponFlag) {
      let sendData = {
        groupInstCode: groupInstCode,
        meGrouponFlag: meGrouponFlag //1:表示自己的拼团详情，0:表示要参与的拼团详情
      };
      const res = await getCurrentJoinedGroupInfo(sendData);
      if (res.code == "0000") {
        this.initData = res.data;
      } else {
        this.$toast(res.msg);
      }
    }
  },
  mounted() {
    this.goodsId = this.$route.query.id;
    this.getCurrentJoinedGroupListFun();
    this.getRecommended();
    this.getHotList();
    this.getProduct();
    this.getProperty();
  }
};
</script>
<style lang="scss" scoped>
.listDetail {
  .red {
    color: #2283E2;
  }
  .flex {
    display: flex;
    justify-content: center;
  }
  .everyone {
    margin-bottom: 20px;
    border: 1px solid #e6eaf0;
  }
  .hot {
    border: 1px solid #e6eaf0;
    width: 250px;
    margin-right: 20px;
    .goodsBtn {
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      text-align: center;
      p {
        border: 1px solid #ddd;
        background-color: #f8f8f8;
        color: #666;
        width: 88px;
        height: 34px;
        line-height: 34px;
        padding: 0;
        font-size: 12px;
        cursor: pointer;
      }
    }
    .listImgs {
      li {
        width: 248px;
        margin-bottom: 0;
        padding: 10px 0 20px 0;
        border-bottom: 1px solid #e6eaf0;
        img {
          display: block;
          width: 190px;
          height: 190px;
          margin: 0 auto;
        }
        .imageBorder {
          border: 1px solid #e6eaf0;
          box-sizing: border-box;
        }
      }
    }
  }
  .details {
    border: 1px solid #e6eaf0;
    width: 930px;
    .tabs {
      border-bottom: 1px solid #e6eaf0;
      height: 50px;
      line-height: 50px;
      display: flex;
      p {
        width: 140px;
        font-size: 18px;
        text-align: center;
        color: #212529;
        border-right: 1px solid #e6eaf0;
        cursor: pointer;
        &.on {
          background: $themeColor;
          color: #fff;
          border-right-color: $themeColor;
        }
      }
    }
    .detailsContainer {
      padding: 15px 30px;
      &.detContainer {
        .infos {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            width: 420px;
            height: 30px;
            line-height: 30px;
            border-bottom: 1px dashed #e6eaf0;
          }
        }
      }
    }
  }
  .product {
    display: flex;
    margin-bottom: 30px;
    .productLeft {
      width: 430px;
      margin-right: 40px;
      .bigImg {
        width: 430px;
        height: 430px;
        margin-bottom: 15px;
        border: 1px solid #e6eaf0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .imglist {
        padding: 0 55px;
        position: relative;
        .swiper-slide {
          width: 100px;
          height: 100px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .swiper-slide-active {
          width: 100px;
          height: 100px;
          border: 2px solid $themeColor;
          box-sizing: border-box;
        }
      }
      .imgBtn {
        cursor: pointer;
        position: absolute;
        width: 40px;
        height: 100px;
        border: 1px solid #e6eaf0;
        background: none;
        color: #e6eaf0;
        font-size: 24px;
        line-height: 100px;
        text-align: center;
        top: 0;
        margin-top: 0;
        outline: none;
        &::before {
          margin: 0 8px 0 4px;
          content: "";
          width: 10px;
          height: 10px;
          display: block;
          top: 50%;
          transform: rotate(135deg) translateY(-50%);
          position: absolute;
          margin-top: -5px;
        }
      }
      .imglistPrev {
        left: 0;
        &::before {
          left: 8px;
          border-bottom: 1px solid #e6eaf0;
          border-right: 1px solid #e6eaf0;
        }
      }
      .imglistNext {
        right: 0;
        &::before {
          right: 12px;
          border-top: 1px solid #e6eaf0;
          border-left: 1px solid #e6eaf0;
        }
      }
    }
    .productRight {
      width: 730px;
      .priceInfo {
        padding: 12px 20px;
        background: rgba(134, 142, 150, 0.05);
        border-top: 1px dashed #e6eaf0;
        border-bottom: 1px dashed #e6eaf0;
        .product-name {
          font-size: 18px;
        }
      }
      .typeInfo {
        padding: 12px 20px;
      }
      .label {
        width: 67px;
      }
      .item {
        width: 100%;
        display: flex;
        margin: 12.5px 0;
      }
      .btn {
        min-width: 40px;
        height: 28px;
        line-height: 28px;
        padding: 0 5px;
        color: #212529;
        font-size: 12px;
        text-align: center;
        border: 1px solid #e6eaf0;
        display: inline-block;
        margin-right: 15px;
        cursor: pointer;
        &.on {
          background: $themeColor;
          color: #fff;
        }
      }
      .num {
        div {
          text-align: center;
          border: 1px solid #e6eaf0;
          width: 28px;
          height: 28px;
          line-height: 28px;
          cursor: pointer;
        }
        input {
          width: 60px;
          height: 28px;
          border-top: 1px solid #e6eaf0;
          border-bottom: 1px solid #e6eaf0;
          text-align: center;
        }
      }
      .price {
        font-size: 24px;
        margin-right: 14px;
      }
      .color86 {
        color: #868e96;
      }
      .priceDetail {
        margin-top: -8px;
        margin-bottom: 2px;
        line-height: 34px;
        justify-content: flex-start;
        .color86 {
          span {
            text-decoration: line-through;
          }
        }
      }
      .memberDetail {
        .icon {
          display: inline-block;
          line-height: 20px;
          width: 60px;
          height: 20px;
          border-radius: 10px;
          background: #212529;
          text-align: center;
          color: #fff;
          font-size: 10px;
          margin-right: 8px;
        }
        .member-price {
          margin-right: 18px;
        }
        .themeColor {
          font-size: 16px;
        }
        .link {
          color: #212529;
          text-decoration: underline;
        }
      }
      .complimentary {
        font-size: 16px;
        span {
          background: #eb646a;
          display: inline-block;
          color: #fff;
          height: 20px;
          padding: 0 6px;
          font-size: 12px;
          line-height: 20px;
          margin-right: 12px;
        }
      }
      .btns {
        justify-content: flex-start;
        button {
          margin-right: 20px;
          width: 150px;
          height: 46px;
          line-height: 46px;
          text-align: center;
          border-radius: 2px;
          font-size: 16px;
          cursor: pointer;
          &:nth-child(1) {
            background: $themeColor;
            color: #fff;
            cursor: pointer;
          }
          &:nth-child(2) {
            border: 1px solid $themeColor;
          }
        }
      }
      /deep/ .van-count-down {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        span {
          color: #fff;
          padding: 0 2px;
          display: block;
        }
        .item {
          width: auto;
          color: #2283E2;
          background: #fff;
          border-radius: 2px;
          margin: 0;
          padding: 0 4px;
          text-align: center;
        }
      }
    }
  }
  .countdown {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    background: $themeColor;
    color: #fff;
    font-size: 14px;
    .flex {
      display: flex;
    }
  }
  .join {
    background: #f9f9f9;
    .flex {
      align-items: center;
    }
    ul {
      padding: 10px;
      li {
        padding-left: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 40px;
        background: #fff;
        display: flex;
        margin-bottom: 4px;
        border: 1px solid #e6eaf0;
        text-align: center;
        img {
          width: 40px;
          height: 40px;
        }
        p {
          padding: 0 30px;
        }
        button {
          width: 100px;
        }
      }
    }
  }
  /* sku数据选择样式部分 */
  .sku-wrap {
    display: block;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    &.active {
      border: 1px solid #2283E2;
      background-color: #2283E2;
      color: #fff;
    }
  }
  .pop {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 999;
    .title {
      margin-bottom: 40px;
      font-size: 18px;
    }
    .flex {
      color: #888;
      font-size: 14px;
      align-items: center;
    }
    .box {
      width: 720px;
      height: 400px;
      padding: 50px;
      background: #fff;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: auto;
      button {
        width: 180px;
        height: 40px;
        line-height: 40px;
        color: #fff;
        background: #2283E2;
        display: block;
        margin: 80px auto 0;
        font-size: 14px;
      }
      .close {
        color: #ccc;
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        height: 30px;
        width: 30px;
        font-size: 24px;
        padding: 1px;
        right: 30px;
        top: 20px;
        position: absolute;
        cursor: pointer;
        &::before {
          content: "\2716";
        }
      }
      .group-user {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 10px;
          border: 1px solid #e7e7e7;
        }
      }
    }
  }
}
</style>