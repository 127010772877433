<template>
<div>
    <div class="container">
        <div class="header">商城公告</div>
        <div class="text" v-for="(item,index) in list" :key="index">
            <div class="title" @click="noticedetail(item)"> {{item.title}}</div>
        </div>

    </div>
</div>
</template>

<script>
import {
    sysNotice
} from '@/api/api'
export default {
    data() {
        return {
            list: []
        }
    },
    methods: {
        noticedetail(item) {
            this.$router.push({
                path: '/centre/noticeDetail',
                query: {
                    notice:JSON.stringify(item)
                }
            })
        },
        async getNotice() {
            let params = {
                type:2
            }
            const res = await sysNotice(params);
            console.log(res)
            if (res.code == "0000") {
                this.list = res.data.rows;
            } else {
                this.$message({
                    message: "已确认收货！",
                    type: "warning"
                });
            }
        }
    },
    mounted() {
        this.getNotice()
    }
}
</script>

<style lang="scss" scoped>
.text {
    padding-top: 20px;
}

.container {
    width: 100%;
    // border: 1px solid #2283e2;
    padding: 20px;
}

.header {
    font-size: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid #AC8C4F;
}

.paging {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.title {
    cursor: pointer;
    font-size: 14px;
}

.title:hover {
    color: #AC8C4F;
}
</style>
