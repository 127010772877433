<template>
  <div>
    <div class="success">
      <i class="el-icon-success" size="30px"></i>
      <div class="title">兑换成功!</div>
    </div>
    <div class="results">
      <div class="item">订单号：123457765533222</div>
      <div class="item">订单金额：1234</div>
      <div class="item">收货人：王思远</div>
      <div class="item">收货地址：上海市黄浦区河南南路398号</div>
      <div class="item">联系方式：13601825261</div>
    </div>
    <div class="btn" @click="goindex">返回首页</div>
  </div>
</template>

<script>
export default {
  name: "test",
  data() {
    return {
      countDown: 5,
      timer: "",
    };
  },
  methods: {
    goindex() {
      this.$router.push({ path: "/index" });
    },
  },
};
</script>
<style scoped lang="scss">
.results {
  margin-left: 520px;
  .item {
    line-height: 30px;
  }
}
.btn {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background: #2283e2;
  color: #fff;
  border-radius: 5px;
  margin: 0 auto;
  font-size: 14px;
  cursor: pointer;
  margin-top: 30px;
}
.success {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 20px;
}
.title {
  font-size: 25px;
  padding-left: 10px;
  color: rgb(82, 196, 26);
}
/deep/.el-icon-success {
  font-size: 50px;
  color: rgb(82, 196, 26);
}
</style>