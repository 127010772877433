<template>
<div class="orderlist-main">
    <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部"></el-tab-pane>
        <el-tab-pane label="待支付" name="1"></el-tab-pane>
        <el-tab-pane label="待发货" name="7"></el-tab-pane>
        <el-tab-pane label="已发货" name="8"></el-tab-pane>
        <el-tab-pane label="已签收" name="9"></el-tab-pane>
        <el-tab-pane label="已完成" name="10"></el-tab-pane>
        <!-- <el-tab-pane label="待审核" name="4"></el-tab-pane>
        <el-tab-pane label="审核失败" name="5"></el-tab-pane> -->
    </el-tabs>
    <orderitem @getOrderList="orderItemReload()" :orderList="orderList"></orderitem>
    <div class="paging">
        <el-pagination background layout="prev, pager, next" :page-sizes="[5, 15, 25, 30]" :total="total" :page-size="10" @current-change="changePage"></el-pagination>
    </div>
</div>
</template>

<script>
import orderitem from "../components/orderItem"
import {
    getOrdersList
} from '@/api/api'
export default {
    components: {
        'orderitem': orderitem
    },
    data() {
        return {
            activeName: "0",
            orderList: [],
            total: 0,
            type: "0",
        };
    },

    methods: {
        orderListInit() {
            //获取所有订单
            this.getOrdersList(this.type, 1);
        },
        async getOrdersList(type, page) {
            let params = {
                // "orderNo": "",
                "pageNum": page,
                "pageSize": 10,
                //"status": 0
            }
            if (type != "0") {
                params.status = type
            }

            let res = await getOrdersList(params)
            if (res.code == "0000") {
                if (res.result == null) {
                    this.orderList = [];
                    this.total = 0;
                } else {
                    this.orderList = res.result.list;
                    this.total = parseInt(res.result.total);
                }
            }
        },
        handleClick(targetName, action) {
            this.getOrdersList(targetName.paneName)
            this.type = targetName.paneName;
        },
        changePage(index) {
            this.getOrdersList(this.type, index);
        },
        orderItemReload() {
            this.getOrdersList(8, 1);
        },
    },
    mounted() {
        this.orderListInit();
    },
};
</script>

<style lang="scss" scoped>
/deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #AC8C4F;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #AC8C4F;
    color: #fff;

}

/deep/.el-tabs__active-bar {
    background-color: #AC8C4F;

}

/deep/.el-tabs__item.is-active {
    color: #AC8C4F;
}

/deep/.el-tabs__item:hover {

    color: #AC8C4F;
}

.paging {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
