<template>
<div class="customer-main">
    <div class="customer-title">客服</div>
    <div class="customer-content">
        <div class="question" v-if="isSend">
            <!-- <span>真实姓名：{{memDialog.memberName}}</span>
            <span>手机号/电话：{{memDialog.memberPhone}}</span> -->
            <span>问题描述：{{memDialog.headlineName}}</span>
        </div>
        <div class="answer" v-if="isSend">
            <span>我们已经收到了您的问题，稍后会有客服联系您，感谢您的提问，谢谢！</span>
        </div>
    </div>
    <div class="customer-submit">
        <el-input class="submit-input" v-model="memDialog.headlineName"  placeholder="请输入描述" ></el-input>
        <div class="submit-button" @click="sendQuestion()">发送</div>
    </div>
    <el-dialog v-model="memDialog" title="发送问题" :visible.sync="dialogFormVisible">
        <div class="add-main">
            <!-- <div class="add-item">
                <span class="add-title">真实姓名:</span>
                <el-input v-model="memDialog.memberName" placeholder="请输入姓名" style="width:300px"></el-input>
            </div>
            <div class="add-item">
                <span class="add-title">手机号/电话:</span>
                <el-input v-model="memDialog.memberPhone" placeholder="请输入手机号" style="width:300px"></el-input>
            </div> -->
            <div class="add-item">
                <span class="add-title">问题描述:</span>
                <el-input v-model="memDialog.headlineName"  type="textarea" :rows="5" placeholder="请输入描述" style="width:500px">
                </el-input>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="sendQuestion()">确 定</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
// import {saveOrUpdate} from "@/api/api";

import {saveOrUpdateSysMemDialog} from "@/api/api";

export default {
    data() {
        return {
            dialogFormVisible: false,
            isSend: false,
            memDialog:{
              memberName: "",
              memberPhone: "",
              headlineName: "",
            }
        };
    },

    methods: {
        showDialog() {
            if (this.isSend === false) {
                this.dialogFormVisible = true;
            } else {
                this.$message({
                    showClose: true,
                    message: '您已经提出问题，请耐心等待客服联系，谢谢！',
                    type: 'warning'
                });
            }
        },
        sendQuestion() {
          let this_ = this;
          this.isSend = true;
          this.dialogFormVisible = false;
          this_.saveOrUpdateSysMemDialog(this.memDialog);
        },

      /**
       * 调用
       * @returns {Promise<void>}
       */
      async saveOrUpdateSysMemDialog(data) {
        const res = await saveOrUpdateSysMemDialog(data);
        if (res.code === '0000') {
        //   // this.$message({
        //   //   showClose: true,
        //   //   message: '保存成功！',
        //   //   type: 'warning'
        //   // });
          this.$toast("保存成功！");
        } else {
          this.$toast(res.msg);
        }
      },


    },

    mounted() {

    },
};
</script>

<style lang="scss" scoped>
/deep/.el-button--primary:focus, .el-button--primary:hover{
    background-color: #AC8C4F;
    border-color: #AC8C4F;
}
/deep/.el-button--primary {
    background-color: #AC8C4F;
    border-color: #AC8C4F;
}

.customer-main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    border: 1px solid #E6E6E6;


    .customer-title {
        height: 50px;
        background-color: #F3F3F3;
        color: #555;
        line-height: 50px;
        font-weight: bold;
        border-bottom: 1px solid #E6E6E6;
        text-indent: 20px;
        font-size: 15px;
    }

    .customer-content {
        height: 300px;
        width: 100%;
        padding: 20px;
        background-color: #F3F3F3;
        display: flex;
        flex-direction: column;

        .question {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            span {
                padding: 10px;
                margin-bottom: 10px;
                background-color: #96EC69;
                border-radius: 4px;
                font-weight: bold;
            }
        }

        .answer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            span {
                padding: 10px;
                margin-bottom: 10px;
                background-color: #fff;
                border-radius: 4px;
                font-weight: bold;
            }
        }
    }

    .customer-submit {
        height: 60px;
        width: 100%;
        border-top: 1px solid #E6E6E6;
        background-color: #F3F3F3;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .submit-input {
            margin: 0 20px;
        }

        .submit-button {
            width: 60px;
            height: 30px;
            background-color: #AC8C4F;
            color: #fff;
            line-height: 30px;
            text-align: center;
            border-radius: 2px;
            margin-right: 20px;
            cursor: pointer;
            font-weight: bold;
        }
    }
}

.add-main {
    .add-title {
        margin-right: 12px;
        width: 100px;
        display: inline-block;
    }

    .add-item {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
</style>
