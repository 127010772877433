<template>
<div class="backtop">
    <a href="#" class="back-line">
        <div><img src="@/assets/images/lxkf.png" alt="" /></div>
        <div class="lx">联系客服</div>
    </a>
    <a href="#" class="back-line">
        <div><img src="@/assets/images/download.png" alt="" /></div>
        <div class="lx">下载APP</div>
    </a>
    <a href="#" class="back-line">
        <div><img src="@/assets/images/wechat.png" alt="" /></div>
        <div class="lx">关注微信</div>
    </a>
    <a href="#" class="back-line noline">
        <div><img src="@/assets/images/backtop.png" alt="" /></div>
        <div class="lx">回到顶部</div>
    </a>
</div>
</template>

<script>
export default {
    props: ["cmsData"],
    components: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
.backtop {
    background-color: #fff;
    box-shadow: 0px 0px 20px #ddd;
    width: 80px;
    padding: 14px 0;
    position: fixed;
    right: 20px;
    bottom: 100px;
    z-index: 100;

    .back-line {
        border-bottom: 1px solid #eee;
        display: block;
        width: 50px;
        margin-left: 15px;
        padding: 12px 0;
        text-align: center;

        img {
            width: 36px;
            height: 36px;
        }

        .lx {
            margin-top: 10px;
        }
    }

    .noline {
        border: none;
    }
}
</style>
