<template>
  <div class="sort">
    <div class="flex flexStart">
      <span>排序：</span>
      <div class="btns">
        <van-button
          type="default"
          :class="['defaultBtn',{'defaultBtnOn':salesVolumeFlag}]"
          icon="descending "
          @click="salesVolumeFun"
        >销量</van-button>
        <van-button
          type="default"
          :class="['defaultBtn',{'defaultBtnOn':newProductFlag}]"
          icon="descending "
          @click="newProductFun"
        >新品</van-button>
        <van-button
          type="default"
          :class="['defaultBtn',{'defaultBtnOn':priceFlag}]"
          icon="descending "
          @click="priceFun"
        >价格</van-button>
      </div>
      <!-- <p class="tips">
        共搜索到
        <span>2</span>个符合条件的商品
      </p> -->
    </div>
    <div class="flex flexEnd">
      <span class="fl10">关键字：</span>
      <input type="text" />
      <span class="fl10">价格：</span>
      <div>
        <input type="text" />
        -
        <input type="text" />
      </div>
      <van-button type="default" icon="search" class="fl10 searchBtn">搜索</van-button>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      salesVolumeFlag: false, //销量
      newProductFlag: false, //新品
      priceFlag: false //价格
      //数据列表
    };
  },
  methods: {
    //点击销量
    salesVolumeFun() {
      this.salesVolumeFlag = !this.salesVolumeFlag;
      this.newProductFlag = false;
      this.priceFlag = false;
    },
    //点击新品
    newProductFun() {
      this.newProductFlag = !this.newProductFlag;
      this.salesVolumeFlag = false;
      this.priceFlag = false;
    },
    //点击价格
    priceFun() {
      this.priceFlag = !this.priceFlag;
      this.salesVolumeFlag = false;
      this.newProductFlag = false;
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
//排序
.sort {
  height: 55px;
  padding: 10px 20px;
  border: 1px solid #e6eaf0;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #868e96;
  .van-button {
    height: 30px;
    line-height: 30px;
  }
  .tips {
    color: #212529;
    margin-left: 25px;
  }
  input {
    width: 100px;
    height: 30px;
    line-height: 30px;
    padding: 0 4px;
    border: 1px solid #e6eaf0;
  }
  .fl10 {
    margin-left: 10px;
  }
  .flex {
    display: flex;
    align-items: center;
    &.flexStart {
      justify-content: flex-start;
    }
    &.flexEnd {
      justify-content: flex-end;
    }
  }
  .searchBtn {
    padding: 0 4px;
    background: $themeColor;
    color: #fff;
  }
  .defaultBtn {
    height: 33px;
    line-height: 33px;
    margin: 0 5px;
    color: #868e96;
    padding: 0 8px;
    &.defaultBtnOn {
      background: $themeColor;
      color: #fff;
    }
  }
}
</style>