<template>
  <div class="protection" v-if="variablesObject">
    <titles :title="variablesObject"></titles>
    <div class="digital">
      <div
        class="imgSrc"
        v-if="variablesObject && variablesObject.bigImgList && variablesObject.bigImgList[0]"
      >
        <router-link :to="variablesObject.bigImgList[0].backUrl">
          <img :src="variablesObject.bigImgList[0].imgUrl" alt />
        </router-link>
      </div>
      <lists :list="list1" class="smallImg4"></lists>
      <div class="rank">
        <p class="txt">今日排行榜</p>
        <lists :list="list2" class="smallImg5"></lists>
      </div>
    </div>
  </div>
</template>
<script>
import titles from "@/components/indexComponents/title";
import lists from "@/components/list";
export default {
  components: {
    titles,
    lists
  },
  props: {
    variablesObject: {
      type: Object,
      default: () => {
        return {};
      }
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      list1: [],
      list2: []
    };
  },
  methods: {},
  mounted() {
    this.list1 = this.list.slice(4, 10);
    this.list2 = this.list.slice(0, 4);
  }
};
</script>
<style lang="scss" scoped>
.protection {
  width: 1200px;
  margin: 0 auto 30px;
  .digital {
    display: flex;
    .imgSrc {
      width: 296px;
      height: 500px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .rank {
      width: 309px;
      border: 1px solid #e6eaf0;
      .txt {
        height: 61px;
        line-height: 61px;
        font-size: 18px;
        color: #212529;
        text-align: center;
        border-bottom: 1px solid #e6eaf0;
      }
    }
  }
}
</style>