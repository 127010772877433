<template>
  <div class="integral">
    <div class="integralTop mb20">
      <div class="integralLeft">
        <p>邀请有奖</p>
        <p>邀请好友获得奖励</p>
      </div>
      <router-link to class="integralRight"></router-link>
    </div>
    <div class="font20">输入下级邀请码</div>
    <div style="margin:20px 0">
        <span v-for="(item,index) in inputList" :key='index'>
            <input type="text" maxlength="1" v-model="item.val" class="inputCommon" @keyup="nextFocus($event,index)" @keydown="changeValue(index)">
        </span>
    </div>
      <div class="font15">邀请须知：</div>
      <div class="font15" style="margin-bottom:20px">请仔细填写对方邀请码，填写后不可更改</div>
      <button class="button" @click="submit">确定</button>
  </div>
</template>

<script>
import { integralInfo, integralList, addHigherOrLower } from "@/api/api";
export default {
  components: {
  },
  data() {
    return {
        inputList: [{val: ""}, {val: ""}, {val: ""}, {val: ""}, {val: ""}, {val: ""}],
    };
  },
  created() {

  },
  methods: {
      /*对焦到下一个input框去*/
      nextFocus(el,index) {
          // console.log(el,index)
          var dom = document.getElementsByClassName("inputCommon"),
              currInput = dom[index],
              nextInput = dom[index + 1],
              lastInput = dom[index - 1];
          /*这里的keyCode 根据不同的平台或许不同,安卓就是不是8*/
          if (el.keyCode != 8) {
              if (index < (this.inputList.length - 1)) {
                  nextInput.focus();
              } else {
                  currInput.blur();
              }
          }else{
              if (index !=0) {
                  lastInput.focus();
              }
          }
      },
      /*当键盘按下的时候清空原有的数*/
      changeValue(index) {
          this.inputList[index].val = "";
      },
      async submit(){
        let value = "";
        for (let index = 0; index < this.inputList.length; index++) {
          value+=this.inputList[index].val;
        }
      let params={
        invitationCode:value
      }

      params.invitationType=2;

      let res = await addHigherOrLower(params);
      if (res.code == "0000") {
        this.$toast("邀请成功");
        setTimeout(()=>{          
          this.$router.push({
            path: `/user/distribution`
          });
        },1000)
      } else {
        this.$toast(res.msg);
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.integral {
  .integralTop {
    width: 892px;
    height: 265px;
    padding: 34px 60px;
    background: url(http://yanxuan.nosdn.127.net/054ae764bcee8cc6d6eee75366e5d83d.png?imageView&quality=95)
      no-repeat center;
    display: flex;
    justify-content: space-between;
    .integralLeft {
      width: 390px;
      height: 195px;
      text-align: center;
      color: $themeColor;
      padding: 34px 0;
      font-size: 16px;
      p {
        &:nth-child(2) {
          font-size: 30px;
          margin-top:30px
        }
      }
    }
    .integralRight {
      width: 490px;
      height: 195px;
      right: 0;
      cursor: pointer;
    }
  }
  .inputCommon{
    width:50px;
    height:50px;
    border:solid 2px #ccc;
    font-size:30px;
    margin-left:10px;
    text-align: center;
  }
  .font20{
      font-size:20px;
      margin-left:10px;
  }
    .font15{
        font-size:15px;
        margin-left:10px;
    }
  .button{
    background-color:#2283E2;
    width:200px;
    height:45px;
    font-size: 18px;
    color: #fff;
      margin-left: 10px;
  }
}
</style>
