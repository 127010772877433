<template>
<div class="orderitem">
    <div class="orderitem-main" v-for="(item, index) in orderList" :key="index">
        <div class="item-header">
            <span>订单日期:{{item.orderCreate}}</span>
            <span>订单号:{{item.orderNo}}</span>
        </div>
        <div class="order-content">
            <div class="order-list">
                <!-- @click="goproductdetail(item)" -->
                <div class="order-item" >
                    <div class="item-deatils">
                        <el-image :src="item.orderProductResponse.url" class="details-img"></el-image>
                        <div class="details-content">
                            <span>{{item.orderProductResponse.name}}</span>
                            <span>{{item.orderProductResponse.subtitle}}</span>
                        </div>
                    </div>
                    <div class="item-point">{{item.orderProductResponse.point}}积分</div>
                    <div class="item-count">x{{item.amount}}</div>
                </div>
            </div>
            <div class="order-total">{{item.payCredits}}积分</div>
            <div class="order-operation">
                <span v-if="item.status==1">待支付</span>
                <span v-if="item.status==2">已取消</span>
                <span v-if="item.status==3">已关闭</span>
                <span v-if="item.status==4">待发货</span>
                <span v-if="item.status==5">审核拒绝</span>
                <span v-if="item.status==6">已冻结</span>
                <span v-if="item.status==7">待发货</span>
                <span v-if="item.status==8">已发货</span>
                <span v-if="item.status==9">已签收</span>
                <span v-if="item.status==10">已完成</span>
                <span v-if="item.status==11">已结算</span>
                <span v-if="item.status==12">待冲账</span>
                <span @click="routeToDetails(item)">订单详情</span>
                <span @click="routeToLogistics(item.logisticsNo)" >查看物流</span>
            </div>
            <div class="order-evaluation">
                <span v-if="item.status==4" @click="examine(item)">审核资料</span>
                <span v-if="item.status==1" @click="routeToPay(item)">海贝支付</span>
                <!-- <span v-if="item.status==9" @click="exchange(item)">退换货</span> -->
                <span v-if="item.status==8" @click="openTakeOverDialog(item)">确认收货</span>
                <span v-if="item.status==9 && item.isComment==0" @click="goComment(item)">待评价</span>
                <span v-if="item.status==9 && item.isComment==1">已评价</span>
                <!-- <span v-else>已评价</span> -->
            </div>
        </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确认收货？</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmTakeOver()">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import {
    confirmReceipt
} from "@/api/api";

export default {
    name: "App",
    props: ['orderList'],
    data() {
        return {
            dialogVisible: false, //确认框
            tempItem: null
        };
    },

    methods: {
        routeToLogistics(logisticsNo) {
            this.$router.push({
                path: '/products/logistics',
                query: {
                    logisticsNo: logisticsNo
                    // logisticsNo: "780098068058"
                }
            });
        },
        examine(item) {
            this.$router.push({
                path: '/centre/toexamine',
                query: {
                    orderNo: item.orderNo,
                    parentOrderNo: item.parentOrderNo,
                }
            });
        },
        routeToDetails(item) {
            this.$router.push({
                path: '/centre/ordersDetails',
                query: {
                    details: {
                        orderNo: item.orderNo,
                        receiverName: item.receiverName,
                        receiver: item.receiver,
                        mark: item.mark,
                        orderCreate: item.orderCreate,
                        status: item.status,
                        name: item.orderProductResponse.name,
                        url: item.orderProductResponse.url,
                        subtitle: item.orderProductResponse.subtitle,
                        totalPoint: item.payCredits,
                        amount: item.amount,
                    }
                }
            });
        },
        exchange(item) {
            this.$router.push({
                path: '/centre/exchange',
                query: {
                    details: {
                        orderNo: item.orderNo,
                        receiver: item.receiver,
                        mark: item.mark,
                        orderCreate: item.orderCreate,
                        status: item.status
                    }
                }
            });
        },
        routeToPay(item) {
            this.$router.push({
                path: '/order/toPay',
                query: {
                    parentOrderNo: item.parentOrderNo,
                    saleTotalAmount: item.orderProductResponse.point,
                    orderNo: item.orderNo
                }
            });
        },
        openTakeOverDialog(item) {
            this.dialogVisible = true;
            this.tempItem = item
        },
        //确认收货
        async confirmTakeOver() {
            let params = {
                "buyerId": localStorage.getItem("memberId"),
                "orderNo": this.tempItem.orderNo
            }

            const res = await confirmReceipt(params);
            if (res.code == "0000") {
                this.$message({
                    message: "已确认收货！",
                });
                this.$emit('getOrderList');
                this.dialogVisible = false;
            } else {
                this.$message({
                    message: res.msg,
                    type: 'warning'
                });
            }
        },
        //评论
        goComment(item) {
            this.$router.push({
                path: '/orders/orderEvaluate',
                query: {
                    productId: item.productId,
                    productname: item.orderProductResponse.name,
                    orderNo: item.orderNo,
                }
            });
        },
        //商品详情
        goproductdetail(item) {
            let routeUrl = this.$router.resolve({
                path: "/products/productDetails?id=" + item.productId,
            });
            window.open(routeUrl.href, '_blank');
        }
    },

    mounted() {

    }
};
</script>

<style lang="scss" scoped>
/deep/.el-button--primary:focus, .el-button--primary:hover{
    background-color: #AC8C4F;
    border-color: #AC8C4F;
}
/deep/.el-button--primary {
    background-color: #AC8C4F;
    border-color: #AC8C4F;
}

.orderitem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.orderitem-main {
    width: 100%;
    border: 1px solid #ededed;
    margin-bottom: 20px;

    .item-header {
        height: 40px;
        background-color: #f1f1f1;

        span {
            margin-left: 30px;
            line-height: 40px;
            font-weight: bold;
        }
    }

    .order-content {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;

        .order-list {
            flex: 5;
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;

            .order-item {
                width: 100%;
                display: flex;
                flex-direction: row;
                border-right: 1px solid #ededed;

                .item-deatils {
                    flex: 5;
                    height: 100%;
                    padding: 20px;
                    display: flex;
                    flex-direction: row;

                    .details-img {
                        width: 80px;
                        height: 80px;
                        margin-right: 10px;
                    }

                    .details-content {
                        flex: 1;
                        display: flex;
                        flex-direction: column;

                        :first-child {
                            font-size: 13px;
                            color: #333;
                            font-weight: bold;
                            margin-bottom: 8px;
                        }

                        :last-child {
                            color: #999;
                        }
                    }
                }

                .item-point {
                    text-align: center;
                    flex: 1;
                    height: 100%;
                    padding-top: 20px;
                    color: #ED2856;
                }

                .item-count {
                    flex: 1;
                    height: 100%;
                    text-align: center;
                    padding-top: 20px;
                }
            }

            .order-item:not(:last-child) {
                border-bottom: 1px solid #ededed;
            }
        }

        .order-total {
            flex: 1;
            text-align: center;
            padding-top: 20px;
            color: #ED2856;
            border-right: 1px solid #ededed;
        }

        .order-operation {
            flex: 1;
            text-align: center;
            padding-top: 20px;
            border-right: 1px solid #ededed;
            display: flex;
            flex-direction: column;

            span {
                margin-bottom: 2px;
            }

            span:not(:first-child):hover {
                cursor: pointer;
                color: #AC8C4F;
            }

            span:first-child {
                color: #44BE3C;
            }
        }

        .order-evaluation {
            flex: 1;
            text-align: center;
            padding-top: 20px;
            flex-direction: column;
            display: flex;

            span:hover {
                cursor: pointer;
                color: #AC8C4F;
            }
        }
    }
}
</style>
