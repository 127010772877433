import Vue from "vue";
import Router from "vue-router";
import index from "@/pages/index/index"; // 首页
import index2 from "@/pages/index/index2"; // 首页2
import user from "@/pages/user/user"; // 个人中心入口页面
import userCenter from "@/pages/user/userCenter"; // 个人中心
import listIndex from "@/pages/list/index"; // 列表页
import listDetail from "@/pages/list/detail"; // 列表详情页
import orderList from "@/pages/user/orderList"; // 我的订单列表
import searchIndex from "@/pages/search/index"; // 搜索页面
import userCollection from "@/pages/user/userCollection"; // 我的收藏
import orderIndex from "@/pages/order/index"; // 订单确认
import orderToPay from "@/pages/order/toPay"; // 选择付款方式
import orderDetail from "@/pages/order/orderDetail"; // 订单详情
import apply from "@/pages/afterSale/apply"; // 申请售后
import addressManagement from "@/pages/user/addressManagement"; // 地址管理
import aftersaleList from "@/pages/afterSale/list"; // 售后列表
import aftersaleDetail from "@/pages/afterSale/detail"; // 售后详情
import userIntegral from "@/pages/user/integral"; // 我的积分
import userDiscountCouponList from "@/pages/user/discountCouponList"; // 优惠券
import card from "@/pages/user/card"; // 充值卡
import distribution from "@/pages/user/distribution"; // 分销中心
import commission from "@/pages/user/commission"; // 佣金明细
import myTeam from "@/pages/user/myTeam"; // 我的团队
import withdrawalDetails from "@/pages/user/withdrawalDetails"; // 提现明细
import userSafe from "@/pages/user/accountSecurity"; // 帐号安全
import changeAccount from "@/pages/user/changeAccount"; // 更换账户
import settingAccount from "@/pages/user/settingAccount"; // 设置账户
import listGroupDetail from "@/pages/list/groupDetail"; // 拼团列表详情页
import listGroupList from "@/pages/list/groupList"; // 拼团列表
import skillList from "@/pages/market/skillList"; // 秒杀列表
import groupList from "@/pages/market/groupList"; // 拼团列表
import myGroup from "@/pages/user/myGroup"; // 我的拼团
import groupDetail from "@/pages/order/groupDetail"; // 拼团详情
import feedBack from "@/pages/market/feedback"; // 我要反馈
// import cart from '@/pages/cart/cart' // 购物车
import userEvaluate from "@/pages/user/evaluate"; // 我的评价
import userAddEvaluate from "@/pages/user/addEvaluate"; // 添加评价
import checkEvaluate from "@/pages/user/checkEvaluate"; // 查看评价
import marketCoupon from "@/pages/market/coupon"; // 优惠券
import myBargain from "@/pages/user/myBargain"; //我的砍价

import shopping from "@/pages/market/shopping"; // 分销中心
import bargain from "@/pages/market/bargain"; // 分销中心

import demo from "@/pages/cart/demo"; // 优惠券
import addInvite from "@/pages/user/addInvite"; //添加上级邀请码
import addInviteNex from "@/pages/user/addInviteNex"; //添加上级邀请码
import shareLink from "@/pages/user/shareLink"; //分享链接
import inteShopping from "@/pages/user/inteShopping"; //积分商城

//商品模块
import productList from "@/modules/products/pages/productList"; //商品列表
import productDetails from "@/modules/products/pages/productDetails"; //商品列表
import productDetail from "@/modules/products/pages/productDetail"; //订单快照
import cart from "@/modules/products/pages/cart"; // 购物车
import logistics from "@/modules/products/pages/logistics"; // 物流

//订单模块
import submitOrders from "@/modules/orders/pages/submitOrders"; //提交订单
import orderEvaluate from "@/modules/orders/pages/orderEvaluate"; //订单评价
import orderresults from "@/modules/orders/pages/orderresults"; //订单结果
import submitCardOrders from "@/modules/orders/pages/submitCardOrders"; //提交实物卡结果

//会员中心模块
import aboutUs from "@/modules/centre/pages/aboutUs"; //关于商城
import addressManager from "@/modules/centre/pages/addressManager"; //地址管理
import customerService from "@/modules/centre/pages/customerService"; //帮助中心
import myCollection from "@/modules/centre/pages/myCollection"; //我的收藏
import ordersList from "@/modules/centre/pages/ordersList"; //订单列表
import ordersDetails from "@/modules/centre/pages/ordersDetails"; //订单列表
import toexamine from "@/modules/centre/pages/toexamine"; //订单审核
import exchange from "@/modules/centre/pages/exchange"; //退换货
import personalCenter from "@/modules/centre/pages/personalCenter"; //个人中心
import pointBilling from "@/modules/centre/pages/pointBilling"; //积分流水
import pointInvest from "@/modules/centre/pages/pointInvest"; //积分充值
import activation from "@/modules/centre/pages/activation"; //卡券激活步骤3
import Activationsuccessful from "@/modules/centre/pages/Activationsuccessful"; //激活成功
import examine from "@/modules/centre/pages/examine"; //审核成功
import cardList from "@/modules/centre/pages/cardList"; //实体卡
import notice from "@/modules/centre/pages/notice"; //公告
import noticeDetail from "@/modules/centre/pages/noticeDetail"; //公告

//帮助中心模块
import helpRegister from "@/modules/helpCenter/pages/helpRegister"; //会员注册 
import helpLogin from "@/modules/helpCenter/pages/helpLogin"; //会员登录
import helpcancellation from "@/modules/helpCenter/pages/helpcancellation"; //账号注销
import helpPassword from "@/modules/helpCenter/pages/helpPassword"; //找回密码
import helpGet from "@/modules/helpCenter/pages/helpGet"; //赚海贝
import helpPay from "@/modules/helpCenter/pages/helpPay"; //花海贝
import helpFlow from "@/modules/helpCenter/pages/helpFlow"; //兑换流程
import helpRule from "@/modules/helpCenter/pages/helpRule"; //兑换规则
import helpPlan from "@/modules/helpCenter/pages/helpPlan"; //海贝计划
import helpClause from "@/modules/helpCenter/pages/helpClause"; //交易条款
import helpQuestion from "@/modules/helpCenter/pages/helpQuestion"; //常见问题
import helpAboutUs from "@/modules/helpCenter/pages/helpAboutUs"; //关于我们
import helpContact from "@/modules/helpCenter/pages/helpContact"; //联系方式

// 引入项目依赖组件
import {
  Icon,
  Button,
  Toast,
  Tab,
  Tabs,
  Swipe,
  SwipeItem,
  Checkbox,
  CheckboxGroup,
  Uploader,
  Stepper,
  Dialog,
  CountDown,
} from "vant";
Vue.use(Icon)
  .use(Button)
  .use(Toast)
  .use(Tab)
  .use(Tabs)
  .use(Swipe)
  .use(SwipeItem)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Uploader)
  .use(Stepper)
  .use(Dialog)
  .use(CountDown);

Vue.use(Router);

Toast.setDefaultOptions({
  duration: 1000,
});

// 解决路由重复打印问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

export default new Router({
  linkActiveClass: "router-link-active",
  routes: [
    // 路由重定向
    {
      path: "/",
      component: index,
      redirect: {
        name: "index",
      },
    },
    {
      path: "/",
      name: "index",
      component: index,
    },
    {
      path: "/index2",
      name: "index2",
      component: index2,
    },
    {
      path: "/user/user",
      name: "user",
      component: user,
      children: [
        {
          path: "/user/userCenter",
          name: "userCenter",
          component: userCenter,
        },
        {
          path: "/user/orderList",
          name: "orderList",
          component: orderList,
        },
        {
          path: "/user/userCollection",
          name: "userCollection",
          component: userCollection,
        },
        {
          path: "/user/addressManagement",
          name: "addressManagement",
          component: addressManagement,
        },
        {
          path: "/user/integral",
          name: "userIntegral",
          component: userIntegral,
        },
        {
          path: "/user/discountCouponList",
          name: "userDiscountCouponList",
          component: userDiscountCouponList,
        },
        {
          path: "/order/orderDetail",
          name: "orderDetail",
          component: orderDetail,
        },
        {
          path: "/afterSale/apply",
          name: "apply",
          component: apply,
        },
        {
          path: "/afterSale/aftersaleList",
          name: "aftersaleList",
          component: aftersaleList,
        },
        {
          path: "/afterSale/aftersaleDetail",
          name: "aftersaleDetail",
          component: aftersaleDetail,
        },
        {
          path: "/user/card",
          name: "card",
          component: card,
        },
        {
          path: "/user/distribution",
          name: "distribution",
          component: distribution,
        },
        {
          path: "/user/commission",
          name: "commission",
          component: commission,
        },
        {
          path: "/user/myTeam",
          name: "myTeam",
          component: myTeam,
        },
        {
          path: "/user/withdrawalDetails",
          name: "withdrawalDetails",
          component: withdrawalDetails,
        },
        {
          path: "/user/accountSecurity",
          name: "userSafe",
          component: userSafe,
        },
        {
          path: "/user/changeAccount",
          name: "changeAccount",
          component: changeAccount,
        },
        {
          path: "/user/settingAccount",
          name: "settingAccount",
          component: settingAccount,
        },
        {
          path: "/user/myGroup",
          name: "myGroup",
          component: myGroup,
        },
        {
          path: "/order/groupDetail",
          name: "groupDetail",
          component: groupDetail,
        },
        {
          path: "/market/feedBack",
          name: "feedBack",
          component: feedBack,
        },
        {
          path: "/user/evaluate",
          name: "userEvaluate",
          component: userEvaluate,
        },
        {
          path: "/user/addEvaluate",
          name: "userAddEvaluate",
          component: userAddEvaluate,
        },
        {
          path: "/user/checkEvaluate",
          name: "checkEvaluate",
          component: checkEvaluate,
        },
        {
          path: "/user/addInvite",
          name: "addInvite",
          component: addInvite,
        },
        {
          path: "/user/addInviteNex",
          name: "addInviteNex",
          component: addInviteNex,
        },
        {
          path: "/user/shareLink",
          name: "shareLink",
          component: shareLink,
        },

        {
          path: "/user/inteShopping",
          name: "inteShopping",
          component: inteShopping,
        },
      ],
    },
    {
      path: "/list/index",
      name: "listIndex",
      component: listIndex,
    },
    {
      path: "/list/detail",
      name: "listDetail",
      component: listDetail,
    },
    {
      path: "/list/groupDetail",
      name: "listGroupDetail",
      component: listGroupDetail,
    },
    {
      path: "/search/index",
      name: "searchIndex",
      component: searchIndex,
    },
    {
      path: "/order/index",
      name: "orderIndex",
      component: orderIndex,
    },
    {
      path: "/order/toPay",
      name: "orderToPay",
      component: orderToPay,
    },
    {
      path: "/market/skillList",
      name: "skillList",
      component: skillList,
    },
    {
      path: "/market/groupList",
      name: "groupList",
      component: groupList,
    },
    {
      path: "/list/groupList",
      name: "listGroupList",
      component: listGroupList,
    },
    {
      path: "/cart/cart",
      name: "cart",
      component: cart,
    },
    {
      path: "/market/coupon",
      name: "marketCoupon",
      component: marketCoupon,
    },
    {
      path: "/market/shopping",
      name: "shopping",
      component: shopping,
    },
    {
      path: "/market/bargain",
      name: "bargain",
      component: bargain,
    },
    {
      path: "/cart/demo",
      name: "demo",
      component: demo,
    },

    //海贝商城的

    {
      path: "/products",
      name: "products",
      component: (resolve) => require(["@/modules/products/products"], resolve),
      children: [
        {
          path: "/products/productList",
          name: "productList",
          component: productList,
        },
        {
          path: "/products/productDetails",
          name: "productDetails",
          component: productDetails,
        },
        {
          path: "/products/productDetail",
          name: "productDetail",
          component: productDetail,
        },
        {
          path: "/products/cart",
          name: "cart",
          component: cart,
          meta: {
            login: true,
          },
        },
        {
          path: "/products/logistics",
          name: "logistics",
          component: logistics,
          meta: {
            login: true,
          },
        },
      ],
    },
    {
      path: "/orders",
      name: "orders",
      component: (resolve) => require(["@/modules/orders/orders"], resolve),
      children: [
        {
          path: "/orders/submitOrders",
          name: "submitOrders",
          component: submitOrders,
          meta: {
            login: true,
          },
        },
        {
          path: "/orders/orderEvaluate",
          name: "orderEvaluate",
          component: orderEvaluate,
          meta: {
            login: true,
          },
        },
        {
          path: "/orders/orderresults",
          name: "orderresults",
          component: orderresults,
          meta: {
            login: true,
          },
        },
        {
          path: "/orders/submitCardOrders",
          name: "submitCardOrders",
          component: submitCardOrders,
          meta: {
            login: true,
          },
        },
      ],
    },
    {
      path: "/centre",
      name: "centre",
      component: (resolve) => require(["@/modules/centre/centre"], resolve),
      children: [
        {
          path: "/centre/aboutUs",
          name: "aboutUs",
          component: aboutUs,
        },
        {
          path: "/centre/addressManager",
          name: "addressManager",
          component: addressManager,
          meta: {
            login: true,
          },
        },
        {
          path: "/centre/customerService",
          name: "customerService",
          component: customerService,
        },
        {
          path: "/centre/myCollection",
          name: "myCollection",
          component: myCollection,
          meta: {
            login: true,
          },
        },
        {
          path: "/centre/ordersList",
          name: "ordersList",
          component: ordersList,
          meta: {
            login: true,
          },
        },
        {
          path: "/centre/ordersDetails",
          name: "ordersDetails",
          component: ordersDetails,
          meta: {
            login: true,
          },
        },
        {
          path: "/centre/toexamine",
          name: "toexamine",
          component: toexamine,
          meta: {
            login: true,
          },
        },
        {
          path: "/centre/exchange",
          name: "exchange",
          component: exchange,
          meta: {
            login: true,
          },
        },
        {
          path: "/centre/personalCenter",
          name: "personalCenter",
          component: personalCenter,
          meta: {
            login: true,
          },
        },
        {
          path: "/centre/pointBilling",
          name: "pointBilling",
          component: pointBilling,
          meta: {
            login: true,
          },
        },
        {
          path: "/centre/pointInvest",
          name: "pointInvest",
          component: pointInvest,
          meta: {
            login: true,
          },
        },
        {
          path: "/centre/activation",
          name: "activation",
          component: activation,
          meta: {
            login: true,
          },
        },
        {
          path: "/centre/Activationsuccessful",
          name: "Activationsuccessful",
          component: Activationsuccessful,
          meta: {
            login: true,
          },
        },
        {
          path: "/centre/examine",
          name: "examine",
          component: examine,
          meta: {
            login: true,
          },
        },
        {
          path: "/centre/cardList",
          name: "cardList",
          component: cardList,
          meta: {
            login: true,
          },
        },
        {
          path: "/centre/notice",
          name: "notice",
          component: notice,
        },
        {
          path: "/centre/noticeDetail",
          name: "noticeDetail",
          component: noticeDetail,
        },
      ],
    },
    {
      path: "/helpCenter",
      name: "helpCenter",
      component: (resolve) =>
        require(["@/modules/helpCenter/helpCenter"], resolve),
      children: [
        {
          path: "/helpCenter/helpRegister",
          name: "helpRegister",
          component: helpRegister,
        },
        {
          path: "/helpCenter/helpcancellation",
          name: "helpcancellation",
          component: helpcancellation,
        },
        {
          path: "/helpCenter/helpLogin",
          name: "helpLogin",
          component: helpLogin,
        },
        {
          path: "/helpCenter/helpPassword",
          name: "helpPassword",
          component: helpPassword,
        },
        {
          path: "/helpCenter/helpGet",
          name: "helpGet",
          component: helpGet,
        },
        {
          path: "/helpCenter/helpPay",
          name: "helpPay",
          component: helpPay,
        },
        {
          path: "/helpCenter/helpFlow",
          name: "helpFlow",
          component: helpFlow,
        },
        {
          path: "/helpCenter/helpRule",
          name: "helpRule",
          component: helpRule,
        },
        {
          path: "/helpCenter/helpClause",
          name: "helpClause",
          component: helpClause,
        },
        {
          path: "/helpCenter/helpPlan",
          name: "helpPlan",
          component: helpPlan,
        },
        {
          path: "/helpCenter/helpQuestion",
          name: "helpQuestion",
          component: helpQuestion,
        },
        {
          path: "/helpCenter/helpAboutUs",
          name: "helpAboutUs",
          component: helpAboutUs,
        },
        {
          path: "/helpCenter/helpContact",
          name: "helpContact",
          component: helpContact,
        },
      ],
    },
  ],
});
