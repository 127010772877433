<!-- 首页左侧分类组件 -->
<template>
  <div class="classify-wrap">
    <!-- <div class="title">
      <i class="iconfont icon-leimupinleifenleileibie"></i>
      商品分类
    </div> -->
    <!-- 分类 -->
    <!-- <div class="list-wrap">
      <ul>
        <li class="item pl10 oh" v-for="(item,index) in classifyList" :key="index">
          <router-link to="#" class="wd80 f_l" @click.native="skip(item.id)">
            <i class="iconfont mr5">
              <img :src="item.pictureUrl" alt="#" class="icon-imgs" />
            </i>
            <span class="categoryName fw700">{{ item.name }}</span>
          </router-link>
          <van-icon name="arrow" class="f_l lh42"/>
        </li>
      </ul>
    </div> -->
  </div>
</template>
<script>
import { getMenuConfiguration } from "@/api/api";
export default {
  name: "classifys",
  components: {},
  props: {
    classList: {
      type: Array
    }
  },
  data() {
    return {
      classifyList: []
    };
  },
  methods: {
    // 获取菜单数据
    getData() {
      this.classList.forEach(item => {
        const newArray = [];
        newArray.push({
          id: item.id,
          pictureUrl: item.pictureUrl,
          name: item.name
        });
        this.classList = newArray
      });
    },

    // 点击菜单标题跳转页面
    skip(id) {
      const routerUrl = this.$router.resolve({
        path: `/list/index?fatherId=${id}`
      });
      window.open(routerUrl.href, "_blank");
    }
  },
  created(){
    this.getData();
  },
  watch: {
    classList(value1, value2) {
      this.classifyList = value1;
    }
  }
};
</script>
<style lang="scss" scoped>
.classify-wrap {
  width: 110px;
  position: fixed;
  left: 0;
  top: 225px;
  z-index: 999;
  
  .title {
    background: $themeColor;
    text-align: center;
    color: #fff;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: normal;
    .iconfont {
      margin-right: 6px;
    }
  }
  /*分类列表*/
  .list-wrap {
    border: 1px solid #e6eaf0;
    border-top: none;
    border-bottom: none;
    background: #fff;
    display: block;
    justify-content: center;
    flex-direction: row;
    .item {
      width: 100%;
      font-size: 12px;
      color: #888;
      line-height: 40px;
      border-bottom: 1px solid #e6eaf0;
      text-align: center;
      position: relative;
      &:hover{
        color:#d01836;
        .categoryName{
          color: #d01836;
        }
      }
    }
    .item:last-child {
      background-color: #f0f2f5;
    }
  }
}
.icon-imgs {
  display: inline-block;
  width: 10px;
  height: 10px;
}
.wd80 {
  display: block;
  width: 80px;
  text-align: left;
}
.pl10 {
  padding-left: 10px;
  box-sizing: border-box;
}
.f_l{
  float: left;
}
.oh{
  overflow: hidden;
}
.lh42{
  line-height: 42px;
}
.fw700{
  font-weight: 700;
}
</style>

