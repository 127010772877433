<template>
  <div class="searchIndex main-container">
    <!-- 排序start-->
    <sorts></sorts>
    <!-- 排序end -->
    <searchs :list="list" v-if="list.length > 0"></searchs>
    <nodata v-else></nodata>

  </div>
</template>
<script>
import sorts from "@/components/sort";
import searchs from "@/components/search";
import { getSearchList } from '@/api/api';
import nodata from '@/components/noData';
export default {
  name: "searchIndex",
  components: {
    sorts,
    searchs,
    nodata
  },
  data() {
    return {
      list: [],              // 搜索列表
      searchValue: '',       // 搜索数据名称
    };
  },

  methods: {
    // 搜索列表
    async getList() {
      let params = {
        chineseName: this.searchValue,
      };
      const res = await getSearchList(params);
      if(res.code == '0000') {
        this.list = res.data;
        console.log(this.list, '----------------this.list-------------');
      }else {
        this.$toast(res.msg);
      }
    },
  },

  mounted() {
    // 获取传来的搜索数据
    this.searchValue = this.$route.query.value;
    this.getList();
  },

};
</script>
<style lang="scss" scoped>

</style>