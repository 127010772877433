<template>
<div class="details-main">
    <!-- 商品详情 -->
    <div class="product-details">
        <!-- 图片预览 -->
        <div class="details-pic">
            <el-image fit="fill" class="pic-img" :src="productDetails.pictureUrl"></el-image>

        </div>
        <!-- 规格下单 -->
        <div class="details-place">
            <div class="place-titles">
                <span class="place-title">{{ productDetails.chineseName }}</span>
                <span class="place-subtitle">{{ productDetails.subtitleLan2 }}</span>
            </div>
            <div class="place-volumn">
                <span class="place-name">收货人姓名:</span>
                <span class="volumn-number">{{ productDetails.receiverName }}</span>
            </div>
            <div class="place-volumn">
                <span class="place-name">收货人电话:</span>
                <span class="volumn-number">{{ productDetails.receiverPhone }}</span>
            </div>
            <div class="place-volumn">
                <span class="place-name">所在区域:</span>
                <span class="volumn-number">{{ productDetails.receiverProvinceName + "-"+productDetails.receiverCityName +"-"+ productDetails.receiverRegionName}}</span>
            </div>
            <div class="place-point">
                <span class="place-name">海贝价:</span>
                <span class="point-price">{{ productDetails.recommendRetailPrice }} 海贝</span>
            </div>
            <div class="place-point">
                <span class="place-name">详情地址:</span>
                <span class="volumn-number">{{ productDetails.receiverAddress }} </span>
            </div>
            <div class="place-volumn">
                <span class="place-name">剩余数量:</span>
                <span class="volumn-number">{{ productDetails.availableBalance }}件</span>
            </div>
            <div class="place-volumn">
                <span class="place-name">商品类别:</span>
                <span class="volumn-number">{{ productDetails.category }}</span>
            </div>
            <div class="place-volumn">
                <span class="place-name">已兑出:</span>
                <span class="volumn-number">{{ productDetails.blockBalance }}件</span>
            </div>
            <div class="place-volumn">
                <span class="place-name">兑换方式:</span>
                <span class="volumn-number" v-if="productDetails.orderType==0">积分订单</span>
                <span class="volumn-number" v-if="productDetails.orderType==1">实物卡订单</span>

            </div>

            <div class="place-volumn">
                <span class="place-name">兑换渠道:</span>
                <span class="volumn-number" v-if="productDetails.sources==0">海尔智家</span>
                <span class="volumn-number" v-if="productDetails.sources==1">营销中台</span>
                <span class="volumn-number" v-if="productDetails.sources==2">兑呗</span>
                <span class="volumn-number" v-if="productDetails.sources==3">海尔旗舰店</span>
                <span class="volumn-number" v-if="productDetails.sources==4">小程序</span>
                <span class="volumn-number" v-if="productDetails.sources==5">H5</span>
                <span class="volumn-number" v-if="productDetails.sources==6">其他</span>
            </div>

        </div>
    </div>
    <!-- 商品下方 -->
    <div class="product-bottom">
        <!-- 类似商品 -->
        <!-- <div class="product-same">
            <div class="title">类似产品</div>
        </div> -->
        <!-- 规格详情评论 -->
        <div class="product-specification">
            
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="商品详情" name="first">
                    <!-- <div class="specification-list">
                        <div class="specification-item" v-for="item in specificationList" :key="item">
                            {{ item.title }}: {{ item.value }}
                        </div>
                    </div> -->
                    <el-image class="product-images" :src="productDetails.detailsUrl"></el-image>
                    <!-- <div class="text">
                        <p v-html="productDescribleContent"></p>
                    </div> -->
                </el-tab-pane>

            </el-tabs>
        </div>
    </div>
</div>
</template>

<script>
import {
    orderSnapshotByOrderNo,
    queryProductDescribe,
} from "@/api/api";
export default {
    name: "App",
    data() {
        return {
            banners: "",
            iac: [],
            num: "",
            isCollected: false,
            addressIndex: 0,
            productDetails: {},
            commentDetails: {},
            hasProduct: "", //区域是否有货
            radio: "1", //兑换方式
            activeName: "first", //商品详情tab
            options: null, //地区三级数据
            selectedOptions: [], //选中的地区
            specsIndex: 0, //默认规格
            count: 1, //商品购买数量
            previewIndex: 0,
            previewList: [], //图片列表
            exhibitionList: [], //图片列表
            //展示列表
            imgList: [],
            codeList: [],
            productDescribleContent: "",

        };
    },

    methods: {
        //初始化
        productDetailsInit() {
            this.getProduct();
        },

        //商品详情
        // 获取数据
        async getProduct() {
            var orderParentNo = this.$route.query.orderParentNo;
            let params = {
                orderParentNo: orderParentNo,
            };
            // debugger
            let res = await orderSnapshotByOrderNo(params);
            if (res.code === "0000") {
                this.productDetails = res.data;
            } else {
                this.$toast(res.msg);
            }
        },

        /**
         *获取商品描述信息
         *tzc
         *2021-3-23 10:42:18
         */
        async queryProductDescribe() {
            var id = this.$route.query.id;
            let params = {
                productId: id,
                // type: 1,
                // promotionId: 1,
                // productType:1
            };
            let res = await queryProductDescribe(params);
            if (res.code === "0000") {
                // debugger;
                console.log("详情查询" + res);

                this.productDescribleContent = res.data[0].content;
                // if (res.data.code == "") {
                //     this.codeList = ["默认规格"]
                // } else {
                //     this.codeList = res.data.code.split(",");
                // }
            } else {
                this.$toast(res.msg);
            }
        },

    },
    mounted() {
        this.productDetailsInit();
    },
};
</script>

<style lang="scss" scoped>
.chose-ck {
    overflow: hidden;
}

.chose-ck dt {
    padding: 7px 12px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    float: left;
    margin-right: 10px;
    font-size: 0.28rem;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.chose-ck dt.check {
    border: 1px solid #ed2856;
    color: #ed2856;
}

.chose-ck p {
    color: rgba(0, 0, 0, 0.87);
    line-height: 0.8rem;
}

.product-details {
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    .details-pic {
        width: 340px;
        height: auto;
        display: flex;
        flex-direction: column;

        .pic-img {
            width: 100%;
            height: 340px;
            border: 1px solid #e1e1e1;
        }

        .pic-preview {
            margin-top: 10px;
            height: 52px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            i {
                font-size: 23px;
                color: #6d6d6d;
                cursor: pointer;
            }

            .preview-list {
                width: 292px;
                height: 100%;
                overflow: hidden;
                position: relative;

                .preview-position {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: auto;
                    display: flex;
                    flex-direction: row;

                    .preview-item {
                        padding: 4px;
                        width: 52px;
                        height: 52px;
                        border: 1px solid #e1e1e1;
                        display: inline-block;
                    }

                    .preview-hover {
                        border: 1px solid #2283e2;
                    }

                    .preview-item:not(:last-child) {
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    .details-place {
        flex: 1;
        height: 420px;
        margin-left: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .place-name {
            display: inline-block;
            font-size: 14px;
            color: #333;
            font-weight: bold;
            width: 100px;
        }

        .place-titles {
            display: flex;
            flex-direction: column;

            .place-title {
                color: #333;
                font-size: 18px;
                font-weight: bold;
            }

            .place-subtitle {
                color: #ed2856;
                font-size: 16px;
                font-weight: bold;
            }
        }

        .place-type {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .place-point {
            .point-price {
                color: #ed2856;
                font-size: 16px;
                font-weight: bold;
            }
        }

        .place-opinion {
            .opinion-number {
                color: #ed2856;
                font-size: 14px;
            }
        }

        .place-distribution {
            .distribution-result {
                margin-left: 12px;
                color: #2283e2;
                font-weight: bold;
            }
        }

        .product-specs {
            display: flex;
            flex-direction: row;

            .specs-list {
                width: 300px;

                .specs-item {
                    height: 30px;
                    border: 1px solid #dcdfe6;
                    padding: 0 12px;
                    cursor: pointer;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: #333;
                    border-radius: 4px;
                    margin-right: 8px;
                }

                .specs-item-selected {
                    border: 2px solid #2283e2;
                    color: #2283e2;
                }
            }
        }

        .place-buy {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            border-bottom: 1px dashed #dedede;
            padding-bottom: 10px;

            .place-edit {
                display: flex;
                flex-direction: row;

                .number-edit {
                    display: flex;
                    flex-direction: row;
                    height: 24px;
                    align-items: center;

                    .edit-icon {
                        background-color: #f1f8ff;
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                        text-align: center;
                        color: #616161;
                        cursor: pointer;
                        border: 1px solid #f1f3f6;
                    }

                    input {
                        border: none;
                        border-top: 1px solid #f1f3f6;
                        border-bottom: 1px solid #f1f3f6;
                        height: 100%;
                        width: 45px;
                        text-align: center;
                    }
                }
            }

            .place-button {
                margin-left: 120px;
                width: 100%;
                height: 55px;
                display: flex;
                flex-direction: row;

                .button-buy,
                .button-cart {
                    width: 155px;
                    line-height: 55px;
                    text-align: center;
                    margin-right: 20px;
                    cursor: pointer;
                    border-radius: 4px;
                }

                .button-cart {
                    color: #2283e2;
                    border: 1px solid #2283e2;
                    font-size: 18px;
                    font-weight: bold;
                }

                .button-buy {
                    color: #fff;
                    background-color: #ed2856;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }

        .place-collection {
            height: 30px;
            display: flex;
            align-items: center;

            i {
                font-size: 26px;
                color: #ed2856;
                margin-right: 6px;
            }

            span {
                font-weight: bold;
                color: #333;
            }

            span:hover {
                cursor: pointer;
                color: #2283e2;
            }
        }
    }
}

.product-bottom {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    margin-top: 40px;

    .product-same {
        width: 220px;
        height: auto;
    }

    .product-specification {
        margin-left: 20px;
        flex: 1;
        height: auto;
        

        .specification-list {
            width: 100%;
            height: auto;
            border: 1px solid #e6e6e6;
            margin-bottom: 20px;
            padding: 20px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .specification-item {
                padding: 10px 20px;
                width: 370px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .product-images {
            width: 100%;
            height: auto;
        }

        .comment-list {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;

            .comment-item {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid #ddd;
                padding-bottom: 20px;

                .comment-userinfo {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .comment-avatar {
                        width: 30px;
                        height: 30px;
                        margin-right: 12px;
                    }

                    .comment-nickname {
                        font-size: 14px;
                        color: #333;
                    }
                }

                .comment-time {
                    color: #999;
                    margin-top: 6px;
                }

                .star-list {
                    margin-top: 10px;

                    i {
                        font-size: 18px;
                        color: #ed2856;
                    }
                }

                .comment-content {
                    margin-top: 12px;
                    display: flex;
                    flex-direction: column;

                    .comment-text {
                        font-size: 14px;
                        line-height: 26px;
                    }
                }

                .flag-list {
                    margin-top: 10px;
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: row;

                    .flag-item {
                        width: auto;
                        height: 30px;
                        padding: 0 12px;
                        border: 1px solid #2283e2;
                        color: #2283e2;
                        text-align: center;
                        line-height: 30px;
                        margin-right: 10px;
                        border-radius: 2px;
                        cursor: pointer;
                    }

                    .flag-item:hover {
                        color: #fff;
                        background-color: #2283e2;
                    }
                }

                .comment-add {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    border-top: 1px solid #f4f4f4;
                    font-size: 14px;
                    color: #999999;
                    padding-top: 10px;
                    margin-top: 10px;
                    line-height: 30px;

                    .add-title {
                        color: #2283e2;
                    }
                }

                .comment-shop {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    border-top: 1px solid #f4f4f4;
                    font-size: 14px;
                    color: #f5a623;
                    padding-top: 10px;
                    margin-top: 10px;
                    line-height: 30px;
                }
            }
        }
    }
}
</style>
