<template>
  <div class="register">
    <h2 class="title">什么是海贝？</h2>
    <p>
      海贝是专为海尔用户管理和使用积分的虚拟货币，海尔用户可以使用海贝兑换各类实物，虚拟礼品或海尔产品的清洁保养、延保服务以及其他增值服务。
    </p>
    <h2 class="title">二、怎样获得海贝？</h2>
    <p>海尔用户除了购买海尔产品获得海贝，还可以通过以下2种途径赚取海贝。 </p>
    <h3 class="title">1、微信搜索关注“海尔智家”微信公众或下载“海尔智家”APP或扫码直接下载“海尔智家”APP、参与“新品试用”活动赚取海贝，更有机会获取免费礼品。</h3>
    <div>
      <img
        src="http://jftupian.haier.com/jfmall/help_center/201608/W020201201507931573161.jpg"
        width="100%"
        alt=""
      />
    </div>
    <div><br><br></div>
    
    <h3 class="title">2、登录“海尔社区”完成打卡、发帖、社区互动、精彩活动等赚取海贝</h3>
    <div><br><br></div>
    <div>
      <img
        src="http://jftupian.haier.com/jfmall/help_center/201608/W020200429395826349963.jpg"
        width="100%"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },

  methods: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
.title {
  color: #808080;
  margin-top: 5px;
}
p{
  margin-top: 5px;
  text-indent: 2em;
  font-size: 16px;
  color: #808080;
  line-height: 1.5;
}
</style>
