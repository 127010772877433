<template>
<div class="member-main">
    <div class="member-con">
        <div class="model-title">热门推荐</div>
        <div class="men-content">
            <div class="con-left">
                <el-image fit="fill" class="banner-img" :src="cmsData.bannerImg" @click="detail(cmsData.bannerId)"></el-image>
            </div>
            <div class="con-right">
                <div class="mem" v-for="(item, index) in recommendList" :key="index" @click="detail(item.id)">

                    <div>
                        <div class="con-titles">{{ item.chineseName }}</div>
                        <div class="xian"></div>
                        <div class="con-point">{{ item.integralNum }} <span>积分</span></div>
                    </div>
                    <div>
                        <el-image class="item-img" :src="item.pictureUrl"></el-image>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    queryByProductId
} from "@/api/api";
export default {
    components: {},
    props: ["cmsData"],
    data() {
        return {
            Rankinglist: ["周", "月"],
            currentList: [],
            top5Index: 0,
            recommendList: [],
        };
    },

    methods: {
        choseTop(index) {
            this.top5Index = index;
            if (index == 0) {
                this.currentList = this.rank.weekRanking;
            } else {
                this.currentList = this.rank.monthRanking;
            }
        },
        detail: function (id) {
            let routeUrl = this.$router.resolve({
                path: "/products/productDetails?id=",
                query: {
                    id: id,
                },
            });
            window.open(routeUrl.href, "_blank");
        },
        async queryProduct() {
            let tempList = [];

            //周排行
            this.cmsData.recommendList.map((value) => {
                tempList.push({
                    id: value.id,
                });
            });
            let params = {
                list: tempList,
            };
            let res = await queryByProductId(params);
            this.recommendList = res.data;
        },
    },

    mounted() {
        this.queryProduct();
    },
};
</script>

<style lang="scss" scoped>
.model-title {
    font-size: 24px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
}

.model-title:before,
.model-title:after {
    content: "";
    display: inline-block;
    width: 4px;
    height: 24px;
    background: #bca06b;
    margin: auto 12px;
    vertical-align: sub;
}

.member-main {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

}

.member-con {
    margin-top: 40px;
    width: 100%;
    // background-color: #fff;
    // padding: 25px 20px;

    .mem-title {
        margin-bottom: 25px;

        .main-title {
            width: 96px;
            height: 24px;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
            margin-right: 20px;
        }

        .sub-title {
            width: 104px;
            height: 14px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 24px;

            img {
                margin-right: 8px;
            }
        }

        .more {
            width: 54px;
            height: 14px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 24px;
            float: right;
            cursor: pointer;

            img {
                margin-left: 4px;
                vertical-align: sub;
            }
        }

        .yz {
            line-height: 24px;
            margin-left: 220px;

            img {
                cursor: pointer;
                margin-left: 8px;
                vertical-align: sub;
                height: 24px;
            }
        }
    }
}

.men-content {
    display: flex;
    justify-content: space-around;

    .con-left {
        .banner-img {
            width: 300px;
            height: 460px;
        }
    }

    .con-right {
        display: flex;
        margin-left: 26px;
        justify-content: space-between;
        flex-wrap: wrap;

        .mem {
            width: 48%;
            text-align: center;
            cursor: pointer;
            margin-bottom: 20px;
            padding: 30px;
            position: relative;
            height: 220px;
            background-color: #fff;
            display: flex;
            justify-content: space-between;

            .item-img {
                width: 150px;
                height: 150px;
            }

            .top5-imgs {
                z-index: 2;
                top: -20px;
                left: -20px;
                position: absolute;
                width: 44px;
            }

            .con-titles {
                overflow: hidden;
                width: 150px;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 18px;
                font-size: 14px;
                // font-weight: 600;
                text-align: left;
                color: #9A9A9A;
                margin-top: 18px;
                margin-bottom: 48px;
            }

            .xian {
                width: 38px;
                height: 1px;
                border: 1px solid #707070;
                background: #707070;
                // margin-top: 30px ;
            }

            .con-point {
                height: 16px;
                font-size: 28px;
                font-weight: 500;
                text-align: left;
                color: #AC8C4F;
                line-height: 16px;
                // margin-bottom: 20px;
                margin-bottom: 6px;
                margin-top: 26px;

                span {
                    font-size: 16px;
                    color: #9a9a9a;
                }
            }
        }

        .mem:hover {
            box-shadow: 0 3px 8px rgba($color: #000000, $alpha: 0.3);
            transition: box-shadow 0.3s linear;
        }
    }
}
</style>
